import { Core, Form, Drawer } from 'connex-cds';
import React from 'react';
import { users, roles, carriers } from '../../../../../query-hooks';
import { InviteUser } from './invite-user/InviteUser';
import { UserEditor } from './UserEditor';

import _config from './config';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const View = () => {
  const { openDrawer } = Drawer.useDrawerContext();

  const listUsers = users.useListUsers({ isHauler: false });
  const listRoles = roles.useListRoles();
  const listCarriers = carriers.useListCarriers();

  const customActions = React.useMemo(() => {
    const onClick = () => {
      // TODO: Re-evaluate the refresh strategy here.  React-Query should be able to handle this.
      openDrawer?.({
        titleStringId: `inviteUser`,
        component: <InviteUser refetch={listUsers.refetch} refetchCarriers={listCarriers.refetch} />,
      });
    };
    return [{ stringId: 'inviteUser', testId: 'invite-user', onClick: onClick }];
  }, [openDrawer]);

  React.useEffect(() => {
    listCarriers?.refetch();
  }, []);

  const config = React.useMemo(() => {
    return _config(listRoles?.data, listCarriers?.data);
  }, [listCarriers, listRoles]);

  return (
    <Core.Spinner spin={listUsers.isLoading || listUsers.isFetching}>
      <ListAndDetail
        detailType={DETAIL_TYPES.DRAWER}
        Editor={UserEditor}
        showHeader={false}
        config={config}
        listQuery={listUsers}
        customActions={customActions}
      />
    </Core.Spinner>
  );
};
