import cn from 'classnames';
import React from 'react';
import { TEMPLATES, useTemplate } from './template-processor/useTemplate';

const MaterialInfo = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.MATERIAL_INFORMATION });

  return (
    <pre className={cn('material-info')} data-testid="material-info-text">
      {template}
    </pre>
  );
};

export default MaterialInfo;
