import React from 'react';
import { Form, Localization } from 'connex-cds';

const { Translate } = Localization;
const testId = 'activityGrid-waterRelease';

export const WaterRelease = () => {
  const { values } = Form.useFormContext();

  if (values.waterReleaseDeclined || !values.waterReleaseSignature?.image) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>
        <Translate stringId="waterReleaseAcknowledged" />
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};
