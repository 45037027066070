import { Core, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
const { Timeline, AccordionCard } = Core;
const { Translate, Time } = Localization;

const LastUpdate = styled.div`
  margin-left: 30px;
  display: flex;
  .label {
    white-space: nowrap;
  }
  .event {
    margin-left: 10px;
    margin-right: 20px;
    min-width: 100px;
    //flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time {
    margin-right: 20px;
    white-space: nowrap;
  }
`;

const TimelineWrapper = styled.div`
  margin-top: 40px;
  overflow-x: hidden;
  .ant-timeline-item-last {
    padding-bottom: 0;
  }
`;

export const Mobile = ({ items }) => {
  return (
    <AccordionCard>
      <div style={{ display: 'flex', flex: '1' }}>
        <div>
          <Typography.Subtitle style={{ whiteSpace: 'nowrap' }}>
            <Translate stringId="status" data-testid="statusLabel" /> (
            <Localization.TimeZone date={items[0]?.isoDateString} />)
          </Typography.Subtitle>
        </div>
        {items?.length ? (
          <LastUpdate>
            <div className="label"> <Translate stringId="lastUpdate" data-testid="lastUpdateLabel" /></div>
            <div className="event">{items?.[0]?.event}</div>
            <div className="time">
              <Time date={items[0]?.isoDateString} hideTimeZone />
            </div>
          </LastUpdate>
        ) : null}
      </div>
      <TimelineWrapper>
        <Timeline items={items} noData={<div>No Data</div>} desc />
      </TimelineWrapper>
    </AccordionCard>
  );
};
