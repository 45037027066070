import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization } from 'connex-cds';
import Style, { Label, StyledHeader, TableBody, TableCell, TableCellSpan, TableRow, Value } from './Style';
import { useDriverSetup, useMixByProductSetup, useVehicleSetup } from '../../../../MasterDataProvider';
import { find } from 'lodash';

const { Time, Translate, Uom } = Localization;
const testId = 'addedItemsGrid';
const nbsp = ': ';

const Styled = styled.section`
  ${Style}
`;
const DeliveryInformation = ({ ticket, order }) => {
  const translateMessage = Localization.useTranslateMessage();
  const { data: vehicles } = useVehicleSetup();
  const { data: mix, setProductRef } = useMixByProductSetup();
  const { data: driver, setDriverRef } = useDriverSetup();

  useEffect(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });
    if (primaryLineItem?.item?.productRef) {
      setProductRef(primaryLineItem?.item?.productRef);
    }
  }, [ticket?.lineItems, setProductRef]);

  useEffect(() => {
    const vehicle = find(vehicles, { id: ticket?.vehicleId });

    if (vehicle?.configuration?.driver?.driverRef) {
      setDriverRef(vehicle?.configuration?.driver?.driverRef);
    }
  }, [ticket?.vehicleId, vehicles]);

  const mixInfo = useMemo(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });
    const splitLoad = ticket?.lineItems.some(lineItem => lineItem.isSplitLoad === true);

    return {
      ...primaryLineItem,
      splitLoad,
      mix: { id: mix?.id, description: mix?.name },
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [ticket?.lineItems, mix, translateMessage]);

  const orderInfo = useMemo(() => {
    const primaryLineItem = find(order?.lineItems, { isPrimary: true });
    const uom = primaryLineItem?.orderedQuantity?.uomCode;

    const orderedQuantity = order?.deliverySchedule?.orderedQuantity ?? order?.sk?.orderedQuantity;
    const shippedQuantity = order?.deliverySchedule?.shippedQuantity ?? order?.sk?.shippedQuantity;

    return {
      ...order,
      ...order?.deliverySchedule,
      orderedQuantity: <Uom uom={{ uomCode: uom, value: orderedQuantity }} />,
      qtyDelivered: <Uom uom={{ uomCode: uom, value: shippedQuantity }} />,
    };
  }, [order]);

  const driverInfo = useMemo(() => {
    const driverName = [driver?.firstName, driver?.lastName].join(' ');
    return { driverName };
  }, [ticket?.vehicleId, driver]);

  const batchRecord = useMemo(() => {
    if (ticket?.ticketEvents?.LOADING_STARTED?.eventDateTime) {
      return new Date(ticket?.ticketEvents?.LOADING_STARTED?.eventDateTime).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }

    return '';
  }, [ticket?.ticketEvents?.LOADING_STARTED?.eventDateTime]);

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledHeader>
        <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
          <span>
            <Translate stringId="deliveryInformation" data-testid="products-label" />
            {nbsp}
          </span>
        </div>
      </StyledHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="setup_entity-mt" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.sk?.customerName}</Value>
          </TableCell>
          <TableCellSpan>
            <Label>
              <Translate stringId="address" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.origin?.address?.address1}</Value>
          </TableCellSpan>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="plant" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.origin?.description}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="orderId" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.dispatchOrder?.id}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="customer" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.sk?.customerName}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="ticketId" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.id}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="poTag" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.customData?.orderDetails?.customerPoNumber}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="job" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.sk?.customerJob}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="dot-contract-id">
            <Label>
              <Translate stringId="sp" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.supplierSalesOrder?.dotContractId}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="batchRecord" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{batchRecord}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="beginTime" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>
              <Time date={ticket?.ticketEvents?.LOADING_STARTED?.eventDateTime} />
            </Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="volume" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{mixInfo?.loadedQty}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="mixId" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{mixInfo?.item?.id}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="mixDesc" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{mixInfo?.item?.description}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="truckTag" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.vehicleId}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="driver" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{driverInfo?.driverName}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="qtyOrder" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{orderInfo?.orderedQuantity}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="qtyDelivered" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{orderInfo?.qtyDelivered}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="loads" data-testid="batch" />
              {nbsp}
            </Label>
            <Value>{ticket?.loadNumber}</Value>
          </TableCell>
        </TableRow>
      </TableBody>
    </Styled>
  );
};

export default DeliveryInformation;
