import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

import { useTicketContext } from '../../../TicketContext';
import { UnloadingPoint } from './UnloadingPoint';
import { LoadingPoint } from './LoadingPoint';

const Styled = styled.div`
  ${style}
`;

export const Detail = ({ data }) => {
  const { ticket } = useTicketContext();

  return (
    <Styled className={cn('detail')}>
      {ticket?.customData?.ticketType !== 'PMP' && <LoadingPoint />}
      <UnloadingPoint />
    </Styled>
  );
};
