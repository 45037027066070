import cn from 'classnames';
import { Form, Drawer, User, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { find, omit, values } from 'lodash';
import { getUserRbac, getUserRole } from '../../../../../query-hooks/users';
import { useListCarriers } from '../../../../../query-hooks/carriers';
import { useListRoles, useUpdateUserRole } from '../../../../../query-hooks/roles';

import style from './style';

const { Column, Row } = Layout;

const Styled = styled.div`
  ${style}
`;

const getInterimOptions = (currentFormValue, carriersQuery) => {
  if (!values(currentFormValue)?.join('')?.trim?.()?.length) return [];
  let carriers = [];
  if (currentFormValue?.length) {
    for (let carrier of currentFormValue) {
      const foundCarrier = find(carriersQuery?.data, { crn: carrier });
      if (foundCarrier) {
        carriers.push({ name: foundCarrier?.name, crn: foundCarrier?.crn, id: foundCarrier?.id });
      }
    }
  }
  return carriers;
};

export const UserEditor = () => {
  const { values, Components, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [busy, setBusy] = React.useState(false);
  const [allCarriers, setAllCarriers] = React.useState(null);
  const { user } = User.useUserContext();
  const haulerRbac = getUserRbac(user?.profileRef);

  const carriersQuery = useListCarriers();
  const [options, setOptions] = React.useState(getInterimOptions(values?.carriers), carriersQuery);

  const userInitialRole = getUserRole(values?.toRef);
  const rolesQuery = useListRoles();

  const updateUserRole = useUpdateUserRole(values?.toRef);
  const userRbac = getUserRbac(values?.toRef);

  const hiddenCarriers = React.useMemo(() => {
    const userCarriers = userRbac?.data?.carriers || [];
    let _hiddenCarriers = [];
    for (let carrier of userCarriers) {
      if (!haulerRbac?.data?.carriers?.find(carr => carr.carrierRef === carrier.carrierRef)) {
        _hiddenCarriers.push(carrier);
      }
    }
    return _hiddenCarriers;
  }, [haulerRbac, userRbac]);

  // Get the user's role set the default value
  React.useEffect(() => {
    if (userInitialRole?.data && rolesQuery?.data) {
      let currentRole;
      for (let userInitRole of userInitialRole?.data) {
        const foundRole = find(rolesQuery?.data, { crn: userInitRole?.crn });
        if (foundRole) {
          currentRole = foundRole;
        }
      }
      setFieldValue('role', { ...omit(currentRole, 'value'), roleRef: currentRole?.crn });
    }
  }, [userInitialRole?.data, rolesQuery?.data, values?.fromRef, setFieldValue]);

  const handleEditOrDelete = React.useCallback(
    async isDelete => {
      setBusy(true);

      let carriers = [];
      if (values?.carriers?.length) {
        for (let carrier of values?.carriers) {
          const foundCarrier = find(carriersQuery.data, { crn: carrier });
          if (foundCarrier) {
            carriers.push({ name: foundCarrier?.name, carrierRef: foundCarrier?.crn, id: foundCarrier?.id });
          }
        }
      }
      const finalCarriers = isDelete ? [...hiddenCarriers] : [...carriers, ...hiddenCarriers];

      const response = await updateUserRole({
        carriers: finalCarriers,
        entityRef: values?.fromRef,
        profileRef: values?.toRef,
        roleRef: values?.role?.roleRef,
      });
      setBusy(false);

      if (response?.success === 'ok') {
        closeDrawer();
      }
    },
    [
      carriersQuery.data,
      closeDrawer,
      updateUserRole,
      values?.carriers,
      values?.fromRef,
      values?.role?.roleRef,
      values?.toRef,
    ]
  );

  const _options = React.useMemo(() => {
    const allCarriers = carriersQuery?.data;
    setAllCarriers(allCarriers);
    if (carriersQuery?.isSuccess) {
      let filteredCarriers = [];
      if (haulerRbac) {
        carriersQuery?.data?.forEach(carriers => {
          haulerRbac?.data?.carriers?.forEach(haulerRbac => {
            if (carriers.crn === haulerRbac.carrierRef) {
              filteredCarriers.push(carriers);
            }
          });
        });
      }
      return filteredCarriers?.filter?.(carrier => carrier.status === 'ACTIVE');
    }

    return allCarriers?.filter?.(carrier => carrier.status === 'ACTIVE');
  }, [carriersQuery?.data, carriersQuery.isSuccess]);

  React.useEffect(() => {
    setOptions(_options || []);
  }, [_options, values?.carriers]);

  return (
    <Styled className={cn('user-editor')}>
      <Components.FirstName disabled />
      <Components.LastName disabled />
      <Components.Email disabled />
      <Components.Phone disabled />
      {values?.status === 'REGISTERED' && <Components.Role options={rolesQuery?.data} busy={rolesQuery?.isLoading} />}
      {!!values?.role?.permissions?.['3p'] && (
        <Components.Carriers options={options || []} busy={carriersQuery?.isLoading} />
      )}
      <div className="actions">
        <Row>
          {values?.status === 'REGISTERED' && (
            <Column>
              <Components.DeleteButton onDelete={() => handleEditOrDelete(true)} />
            </Column>
          )}
          <Components.CancelButton onCancel={closeDrawer} enabled />
          {values?.status === 'REGISTERED' && (
            <Components.SaveButton onUpdate={() => handleEditOrDelete(false)} suppressDisabledStyling />
          )}
        </Row>
      </div>
    </Styled>
  );
};
