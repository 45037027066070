import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCreateItem, useCarriersSetup, useUpdateItem } from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const CarriersEditor = () => {
  const { Components, values, mode, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const carriersSetup = useCarriersSetup();

  const busy = React.useMemo(() => {
    return carriersSetup.isLoading;
  }, [carriersSetup.isLoading]);

  const create = useCreateItem();
  const update = useUpdateItem();

  const editSetupData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const saveSetupData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(() => {
    return saveSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveSetupData, values]);

  const handleEdit = React.useCallback(() => {
    return editSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editSetupData, values]);

  React.useEffect(() => {
    if (typeof values?.companyFleet !== 'boolean') {
      setFieldValue('companyFleet', false);
    }
  }, [setFieldValue, values?.companyFleet]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('carriers-editor')}>
        <Components.Id disabled={mode === 'edit'} />
        <Components.Name />
        <Components.CompanyFleet />
        <Components.Status />
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
