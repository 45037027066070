import loggingService from './loggingService';
import sendToTrackIt from './sendToTrackIt';

const _localLogging = (severity, ...args) => {
  const stack = new Error().stack.split('\n');
  const caller = stack[3] || '';
  console?.[severity]?.(...args);
  sendToTrackIt(`${args?.join(' ')} ${caller.trim()}`, severity);
};

const _logger = (severity, ...args) => {
  _localLogging(severity, ...args);
  loggingService({ message: args, severity });
};

const sendBatch = messages => {
  messages.forEach(message => _localLogging(message.severity, message.message));
  loggingService(messages);
};

export const Logger = {
  debug: (...args) => _logger('debug', ...args),
  log: (...args) => _logger('log', ...args),
  info: (...args) => _logger('info', ...args),
  warn: (...args) => _logger('warn', ...args),
  error: (...args) => _logger('error', ...args),
  sendBatch,
};
