import { css } from 'styled-components';
import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';

export const desktopStyle = css`
  display: flex;
  height: 100%;
  gap: 0.5rem;
  .payment-method-options {
    flex: 23;
    .ant-input {
      &::placeholder {
        color: var(--color-text-placeholder);
      }
      border: 1px solid var(--color-border-container);
    }
  }
  .products-table-section {
    flex: 16;
  }
  div:nth-child(3) {
    flex: 11;
  }
`;

export const mobileStyle = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 0.5rem;
  .payment-method-options {
    flex: 23;
    .payment-method-row {
      padding-bottom: 1rem;
    }
    .ant-input {
      &::placeholder {
        color: var(--color-text-placeholder);
      }
      border: 1px solid var(--color-border-container);
    }
  }
  .products-table-section {
    flex: 16;
  }
  div:nth-child(3) {
    flex: 11;
  }
`;

export const StyledTextarea = styled(TextareaAutosize)`
  border-radius: var(--radius-container);
  color: var(--color-text-container);
  border: 1px solid var(--color-border-container);
  background-color: var(--color-background-container);
  padding: 1rem;
`;

export const StyledSelect = styled.select`
  border-radius: 4px;
  color: var(--color-text-container);
  border: 1px solid var(--color-border-container);
  background-color: var(--color-background-container);
  height: 35px;
  padding: 4px 11px;
`;
