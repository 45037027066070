import React from 'react';
import { useUserContext } from './components/user';
import { Header } from './layout/header/Header';
import { usePostMessageContext } from './post-message/PostMessage';
import { useAppContext } from './util/app-provider/context';
import { useEntityContext } from './util/entity-provider/context';
import { useChatContext } from './components/chat';

export const AppHeader = () => {
  const appContext = useAppContext();
  const entityContext = useEntityContext();
  const userContext = useUserContext();
  const { handleChatClose } = useChatContext();

  const { sendMessage } = usePostMessageContext();

  const handleAppChange = React.useCallback(
    (appRef, shouldOpenInNewTab = false) => {
      sendMessage({ type: 'app-change', appRef, shouldOpenInNewTab });
      handleChatClose();
    },
    [sendMessage, handleChatClose]
  );

  const handleProfileClick = React.useCallback(() => {
    sendMessage({ type: 'profile-clicked' });
  }, [sendMessage]);

  return (
    <Header
      className="app-header"
      onChange={handleAppChange}
      appContext={appContext}
      entityContext={entityContext}
      userContext={userContext}
      onProfileClick={handleProfileClick}
    />
  );
};
