import { useCallback } from 'react';

import { useUserContext } from '../components/user';
import { useEntityContext } from '../util/entity-provider/context';
import { removeWhatFix, showWhatFix } from '../whatfix';
import { useLocalizationContext } from '../components/localization';

export const useWhatFix = () => {
  const { user, userApps, role } = useUserContext();
  const { entityRef } = useEntityContext();
  const { userLocale } = useLocalizationContext();

  const turnOnWhatFix = useCallback(() => {
    showWhatFix({ userLocale, entityRef, user, userApps, isCompanyAdmin: role?.isCompanyAdmin });
  }, [entityRef, role?.isCompanyAdmin, user, userApps, userLocale]);

  const turnOffWhatFix = () => {
    removeWhatFix();
  };

  return { turnOnWhatFix, turnOffWhatFix };
};
