import cn from 'classnames';
import { Layout } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from './context/Provider';
import { CopyFromCustomValues } from './copy-custom-values/CopyFromCustomValues';

const Styled = styled(Layout.Column)`
  textarea {
    width: 100%;
    height: 100%;
    font-family: monospace;
    padding: 5px 10px;
  }
`;

export const TemplateTextCustomValues = ({ onChange, templateName, language, entities }) => {
  const { state } = useTemplateContext();

  const value = React.useMemo(() => {
    return get(state, [templateName, language], '');
  }, [state, templateName, language]);

  return (
    <Styled className={cn('template-text')}>
      <CopyFromCustomValues templateName={templateName} entities={entities} visible={!value?.length} />
      <textarea value={value} onChange={onChange} />
    </Styled>
  );
};
