import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { appRefToIconMap, categoryOrderMap, default as iconDefault } from './appIconMap';

const Styled = styled.div`
  ${style}
`;

export const AppIcons = ({ apps, onClick, appContext }) => {
  const { allApps } = appContext;

  const filteredApps = React.useMemo(() => {
    if (!apps?.length) return [];

    const caiApps = allApps?.filter(app => app.type === 'CX_APP_CAI').map(app => app.crn);

    const appsWithIcons = apps
      .filter(app => caiApps?.includes?.(app.appRef))
      .map(app => ({
        ...app,
        appIconImage: appRefToIconMap[app.appRef] || iconDefault,
        category:
          Object.keys(categoryOrderMap).find(category => categoryOrderMap[category].includes(app.appRef)) || '5',
      }));

    return appsWithIcons.sort((a, b) => a.category - b.category);
  }, [apps, allApps]);

  return (
    <Styled className={cn('app-icons')} $apps={apps}>
      <div className="app-icons-inner">
        {filteredApps?.map?.(app => (
          <div
            className="app-icon"
            onClick={() => onClick(app.appRef)}
            key={app.appRef}
            style={{ order: app.category }}
          >
            <img src={app.appIconImage} alt={app.name} />
            {app.appIconImage === iconDefault ? <span className="app-name-default">{app.name}</span> : <></>}
          </div>
        ))}
      </div>
    </Styled>
  );
};
