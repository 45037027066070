import { Localization } from 'connex-cds';
import React from 'react';

export const CopyFromSelect = ({ onChange, options = {} }) => {
  return (
    <select onChange={onChange}>
      <option value="">
        <Localization.Translate stringId="copyFrom" data-testid="copy-from-default" />
      </option>
      {Object.keys(options).map(key => (
        <option value={key} key={key}>
          {options[key]}
        </option>
      ))}
    </select>
  );
};
