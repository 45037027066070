/* eslint no-restricted-globals: off */
import { isObject } from 'lodash';

export default (message, severity) => {
  if (isObject(message)) {
    const messageObject = JSON.stringify(message, null, 2);
    parent.postMessage(JSON.stringify({ type: 'debug', severity, messageObject }), '*');
  } else {
    parent.postMessage(JSON.stringify({ type: 'debug', severity, message }), '*');
  }
};
