import React from 'react';
import { Form, usePostMessageContext } from 'connex-cds';
import { useTicketContext } from './TicketContext';
import { isObject } from 'lodash';
import { usePostMessageListener } from './datastore/usePostMessageListener';

export const useProbeListener = () => {
  const { setFieldValue } = Form.useFormContext();
  const { ticket } = useTicketContext();
  const { sendMessage } = usePostMessageContext();

  const listener = React.useCallback(
    event => {
      let message;

      // TODO: move this into the usePostMessageListener hook.
      if (isObject(event?.data)) {
        message = event?.data;
      } else {
        try {
          message = JSON.parse(event?.data);
        } catch (e) {
          message = event?.data;
        }
      }

      if (message?.type === 'leftoverConcrete') {
        sendMessage(JSON.stringify({ type: 'debug', ack: message }));
        const newTicket = {
          ...ticket,
          returnConcreteOnTruck: {
            value: message.concreteVolume,
            uomCode: message.concreteUom,
          },
          returnConcreteSource: { id: message.source },
        };
        //It is necessary to set field values here because otherwise polling will overwrite
        //Putting source on returnConcreteOnTruck for use in ReturnedMaterial.js to know when driver changes a value
        //we are not storing the source property on returnedQuantity in the backend processLineItems.js this would cause schema errors
        setFieldValue('returnConcreteOnTruck', {
          value: message.concreteVolume,
          uomCode: message.concreteUom,
          source: message.source,
        });
        setFieldValue('returnConcreteSource', { id: message.source });
        setFieldValue('driverDidNotAdd', typeof message.concreteVolume === 'number' && message.concreteVolume === 0);
      }
    },
    [sendMessage, setFieldValue, ticket]
  );

  usePostMessageListener(listener);
};
