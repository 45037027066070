import cn from 'classnames';
import { Core, Layout, Localization, Typography } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .container {
    padding: 15px 25px;
    position: relative;
    overflow: visible;
    .has-error-icon {
      font-size: 1.5em;
      position: absolute;
      top: -5px;
      left: -5px;
      display: none;
    }
    &.hasError {
      border-color: var(--color-background-button-primary-dangerous);
      .has-error-icon {
        display: block;
        color: var(--color-background-button-primary-dangerous);
      }
    }
  }

  table {
    width: 100%;
    margin-left: 60px;
    td {
      width: 25%;
    }
    th {
      text-align: left;
    }
  }

  .content {
    h2,
    h3 {
      margin-bottom: 0;
      white-space: nowrap;
    }

    h3 {
      margin-top: -5px;
      min-width: 125px;
    }

    display: flex;
    align-items: center;
    & > .left {
      flex: 1;
    }
    .second-row {
      display: flex;
    }
  }

  .didNotAdd {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
`;

export const ListDesktop = props => {
  const { paths } = props;

  return (
    <Styled className={cn('custom-list-component')}>
      {props?.data?.map?.((item, idx) => {
        const flags = {
          isPrimary: get(item, paths.isPrimary),
          isReasonFieldEnabled: get(item, paths.isReasonFieldEnabled) === true, // || isUndefined(get(item, paths.isReasonFieldEnabled)),
          isConcreteOnTruckFieldEnabled: get(item, paths.isConcreteOnTruckFieldEnabled) === true, //|| isUndefined(get(item, paths.isConcreteOnTruckFieldEnabled)),
          isTimeFieldEnabled: get(item, paths.isTimeFieldEnabled) === true, // || isUndefined(get(item, paths.isTimeFieldEnabled)),
          isSplitLoad: get(item, paths.isSplitLoad),
        };

        if (!flags.isPrimary && !flags.isSplitLoad) {
          let description = '';

          if (Array.isArray(paths.description)) {
            description = paths.description
              ?.map(path => get(item, path))
              ?.filter(Boolean)
              ?.reduce((acc, curr) => {
                if (acc === '') {
                  return `${acc}${curr}`;
                }

                return acc;
              }, '');
          }

          if (!Array.isArray(paths.description)) {
            description = get(item, paths.description);
          }

          return (
            <Layout.Container
              onClick={() => props.handleRowClick(item)}
              className={cn({ hasError: !!props.errors?.[paths.errors]?.[idx] })}
            >
              <i className="has-error-icon fa-sharp fa-solid fa-circle-exclamation" />
              <div className="content">
                <div className="left">
                  <div className="description">
                    <Typography.H3 data-testid="product-value">{description}</Typography.H3>
                  </div>
                  <div className="second-row">
                    <div className="description-quantity">
                      {get(item, paths.driverDidNotAdd) !== true && (
                        <Typography.H3 data-testid="product-quantity">
                          <Localization.Uom uom={item.quantity} />
                        </Typography.H3>
                      )}
                    </div>
                    {get(item, paths.driverDidNotAdd) !== true ? (
                      <table>
                        <thead>
                          <tr>
                            {flags.isReasonFieldEnabled && (
                              <th>
                                <Localization.Translate stringId="reason" data-testid="reason-header" />
                              </th>
                            )}
                            {flags.isConcreteOnTruckFieldEnabled && (
                              <th>
                                <Localization.Translate stringId="onBoard" data-testid="concreteOnTruck-header" />
                              </th>
                            )}
                            {flags.isTimeFieldEnabled && (
                              <th>
                                <Localization.Translate stringId="time" data-testid="time-header" />
                              </th>
                            )}
                            {Boolean(item.unitPrice) && (
                              <th>
                                <Localization.Translate stringId="unitPrice" data-testid="unit-price-header" />
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {flags.isReasonFieldEnabled && (
                              <td data-testid="reason-cell">{get(item, paths.reason)}&nbsp;</td>
                            )}
                            {flags.isConcreteOnTruckFieldEnabled && (
                              <td data-testid="concreteOnTruck-cell">
                                <Localization.Uom uom={get(item, paths.concreteOnTruck)} />
                              </td>
                            )}
                            {flags.isTimeFieldEnabled && (
                              <td data-testid="time-cell">
                                <Localization.Time date={get(item, paths.time)} />
                              </td>
                            )}
                            {Boolean(item.unitPrice) && (
                              <td data-testid="unit-price-cell">{get(item, paths.unitPrice)}</td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <div className={'didNotAdd'}>
                        <Localization.Translate stringId="driverDidNotAdd" data-testid="driverDidNotAddText" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="right">
                  <div className="deleteButton">
                    <Core.Button
                      onClick={e => {
                        props.handleDeleteClick(item);
                        e.stopPropagation();
                      }}
                      stringId="delete"
                      data-testid="delete-button"
                      type="primary"
                      danger
                      disabled={props.disabled(item)}
                    />
                  </div>
                </div>
              </div>
            </Layout.Container>
          );
        }
        return null;
      })}
    </Styled>
  );
};
