import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { company } from '../api';

export const useGetCompanyAdmin = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['company-setup', entityRef],
    queryFn: () => company.getCompanyAdmin({ entityRef }),
    staleTime: Infinity,
    enabled: !!entityRef,
  });

  return query;
};
