import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Core, Form } from 'connex-cds';
import { useCompanySetup, useCreateSummary, useGetSummary, useUpdateSummary } from '../MasterDataProvider';
import { useTicketContext } from '../TicketContext';
import { BuildQrCode } from './BuildQrCode';
import { usePreparedContext } from './usePreparedContext';
import { useTemplate } from './qr-code/useTemplate';

const Styled = styled.div`
  display: flex;
  justify-content: center;
`;

const QRCode = () => {
  const companySetup = useCompanySetup();
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();
  const { isValid, compile } = useTemplate();
  const { data, loading } = useGetSummary(ticket?.crn);
  const [summaryRef, setSummaryRef] = React.useState();
  const create = useCreateSummary();
  const update = useUpdateSummary();
  const preparedContext = usePreparedContext({ ticket, mobileTicket: values });

  const stringData = React.useMemo(() => {
    if (isValid) {
      return compile(preparedContext);
    }
    return null;
  }, [compile, isValid, preparedContext]);

  const isLinkBased = React.useMemo(() => {
    if (!companySetup?.data?.qrMethod) {
      return true;
    }

    return companySetup?.data?.qrMethod === 'linkBased';
  }, [companySetup?.data?.qrMethod]);

  React.useEffect(() => {
    const setSummaryRefCb = summary => {
      setSummaryRef(summary?.crn);
    };

    async function save(item, isEdit = false) {
      if (isEdit) {
        update(item).then(setSummaryRefCb);
      }

      if (!isEdit) {
        create(item).then(setSummaryRefCb);
      }
    }

    if (isLinkBased && loading === false && !data) {
      save({ crn: ticket?.crn, context: stringData });
    }

    if (isLinkBased && loading === false && data) {
      save({ ...data, context: stringData }, true);
    }

    if (!isLinkBased) {
      setSummaryRef(stringData);
    }
  }, [loading, data, ticket, stringData, isLinkBased, setSummaryRef]);

  return (
    <Styled className={cn('qr-tab')}>
      <Core.Spinner spin={loading}>
        <BuildQrCode summaryRef={summaryRef} />
      </Core.Spinner>
    </Styled>
  );
};

export default QRCode;
