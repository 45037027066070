import { css } from 'styled-components';

export default css`
  padding: 0;
  width: 100%;

  .layout-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
    gap: 20px;

    & > div {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--color-border-thumbnail);
      color: var(--color-text-thumbnail);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: var(--boxShadow-thumbnail);
      height: 100px;
      gap: 10px;
      transition: all 0.3s;
      text-align: center;

      &:hover {
        border: 1px solid var(--color-border-thumbnail-hover);
        color: var(--color-text-thumbnail-hover);
        background-color: var(--color-background-thumbnail-hover);
      }

      .icon {
        font-size: 32px;
      }

      span {
        width: 95%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
