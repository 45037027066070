import { css } from 'styled-components';

export default css`
  &.tickets {
    padding: 5px;
    display: flex;
    flex-direction: row;
    .left {
      flex: 1;
      padding-left: 10px;
    }
  }

  &.ticket-list {
    .spinner {
      margin-top: 10%;
    }
    & > * {
      margin-top: 10px;
    }
  }
  &.ticket {
    padding: 10px;
    .label {
      font-weight: bold;
      white-space: nowrap;
    }
    .id-status {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      padding-bottom: 3px;
      border-bottom: 1px solid var(--color-border-container);
      & > * {
        &:first-child {
          margin-right: 10px;
          flex: 1;
        }

        &:last-child {
          display: flex;
        }
      }
    }
    .ticket-item {
      display: flex;
      flex-direction: row;
      &.date-time,
      &.site-address {
        @media (max-width: 950px) {
          flex-direction: column;
        }
      }
      .label {
        margin-right: 10px;
        &:after {
          content: ':';
        }
      }
    }
  }

  //&.detail {
  //  & > .container {
  //    padding: 10px;
  //    .subtitle {
  //      margin-bottom: 10px;
  //    }
  //  }
  //  .unloading-point {
  //    margin-top: 20px;
  //  }
  //}
`;
