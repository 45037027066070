import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useTicketContext } from '../../TicketContext';
import style from './style';
import { Localization, Typography } from 'connex-cds';

const { Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

const DriverInstructions = () => {
  const { ticket } = useTicketContext();

  return (
    <Styled className={cn('driver-instructions')}>
      <Subtitle>
        <Translate stringId="driverInstructions" data-testid="driverInstructionsLabel" />
      </Subtitle>
      <div>
        <div data-testid="driverInstructionsValue">
          <pre className={'driver-instructions-value'}>{ticket?.destination?.instructions}</pre>
        </div>
      </div>
    </Styled>
  );
};

export default DriverInstructions;
