import React from 'react';
import { CompanyProfile } from '../../../company-profile/CompanyProfile';
import { ArrowDropDown } from '@mui/icons-material';
import { ButtonStyled } from './styles';

const EntityButton = ({ id, handleClick, selectedEntity, isOpen, currentEntitySetup, disabled }) => {
  return (
    <ButtonStyled
      disableFocusRipple
      isOpen={isOpen}
      startIcon={<CompanyProfile entity={currentEntitySetup} />}
      aria-describedby={id}
      variant="contained"
      onClick={handleClick}
      endIcon={<ArrowDropDown className="cai-entity-switcher-icon cai-entity-switcher-select-icon " />}
      disabled={disabled}
    >
      {selectedEntity?.label}
    </ButtonStyled>
  );
};

export default EntityButton;
