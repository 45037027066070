import { fullStoryInit } from 'connex-cds';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const FullStory = ({ isOuterScript }) => {
  React.useEffect(() => {
    fullStoryInit({ isOuterScript });
  }, [isOuterScript]);

  return <Outlet />;
};
