import { Responsive } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';
import React from 'react';
import LoadingPointDesktop from './LoadingPointDesktop';
import LoadingPointMobile from './LoadingPointMobile';

export const LoadingPoint = () => {
  const { ticket } = useTicketContext();

  return (
    <Responsive>
      <LoadingPointMobile ticket={ticket} />
      <LoadingPointDesktop ticket={ticket} />
    </Responsive>
  );
};
