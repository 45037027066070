import cn from 'classnames';
import { Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useListTickets } from '../../../../../../query-hooks/tickets';
import { Ticket } from './Ticket';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const TicketsList = ({ isDLV, ticketId }) => {
  const ticketsQuery = useListTickets();

  React.useEffect(() => {
    ticketsQuery?.refetch();
  }, []);

  const tickets = React.useMemo(() => {
    if (!isDLV) {
      return ticketsQuery?.data;
    }
    return ticketsQuery?.data?.filter(
      ticket => {
        if (ticket?.customerStatus?.statusCode === 'PENDING' && ticketId === ticket.id) {
          return true;
        }

        if (ticket?.customerStatus?.statusCode !== 'PENDING') {
          return true;
        }

        if (typeof ticket?.customerStatus === 'string') {
          return true;
        }

        return false;
      }
    );
  }, [ticketsQuery]);

  return (
    <Styled className={cn('ticket-list')}>
      <Core.Spinner spin={ticketsQuery?.isLoading}>
        {tickets?.map?.(ticket => (
          <Ticket ticket={ticket} />
        ))}
      </Core.Spinner>
    </Styled>
  );
};
