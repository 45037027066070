import React from 'react';
import { Form } from 'connex-cds';
import styled from 'styled-components';

const Image = styled.img`
  height: 65px;
  max-width: 220px;
  -webkit-filter: var(--signature-image-invert);
  filter: var(--signature-image-invert);
`;

export const ProducerSignatureImage = () => {
  const { values } = Form.useFormContext();

  const signature = React.useMemo(() => {
    return values?.producerSignature?.image;
  }, [values?.producerSignature?.image]);

  return signature ? <Image src={signature} /> : null;
};
