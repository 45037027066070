import React from 'react';
import { AddProducts } from './add-products-services/AddProducts';
import { AddWater } from './add-water/AddWater';
import { ReturnedMaterials } from './returned-material/ReturnedMaterials';
import { TicketDetail } from './ticket-detail/TicketDetail';
import { Acceptance } from './acceptance/Acceptance';
import { MaterialCompliance } from './material-compliance/MaterialCompliance';
import { TotalPumpedQuantity } from './total-pumped-quantity/TotalPumpedQuantity';
import { RiskAssessment } from './risk-assessment/RiskAssessment';
import { PaymentMethod } from './payment-method/PaymentMethod';
import { DotWorkflow } from './dot-workflow/DotWorkflow';

export default [
  { id: 'ticketDetail', testId: 'ticketDetail', component: <TicketDetail /> },
  {
    id: 'riskAssessment',
    testId: 'riskAssessment',
    component: <RiskAssessment />,
    path: 'riskAssessment',
  },
  { id: 'water', testId: 'water', component: <AddWater />, path: 'waterAddedEvents' },
  {
    id: 'materialCompliance',
    testId: 'materialCompliance',
    component: <MaterialCompliance />,
    path: 'productCompliance',
  },
  { id: 'dotWorkflow', testId: 'dotWorkflow', component: <DotWorkflow />},
  { id: 'materialService', testId: 'materialService', component: <AddProducts />, path: 'lineItems' },
  {
    id: 'returnedMaterial',
    testId: 'returnedMaterial',
    component: <ReturnedMaterials />,
    path: ['returnConcreteOnTruck', 'returnReason'],
  },
  {
    id: 'totalPumpedQuantity',
    testId: 'totalPumpedQuantity',
    component: <TotalPumpedQuantity />,
    path: ['totalPumpedQuantity'],
  },
  {
    id: 'paymentMethod',
    testId: 'paymentMethod',
    component: <PaymentMethod />,
    path: 'paymentMethod',
  },
  { id: 'finalize', testId: 'finalize', component: <Acceptance /> },
];
