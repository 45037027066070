import { toPairs } from 'lodash';
import { useParams } from 'react-router-dom';
import { GenericErrorBoundary } from '../../../../GenericErrorBoundary';

export const MobileTicketErrorBoundary = ({ children }) => {
  const params = useParams();
  const paramsToPairs = toPairs(params)?.map?.(pair => {
    return [`PARAM::${pair[0]}`, JSON.stringify(pair[1])];
  });

  const localStorageToPairs = toPairs(window?.localStorage)?.map?.(pair => {
    return [`LS::${pair[0]}`, JSON.stringify(pair[1])];
  });

  const debugInfo = [...paramsToPairs, ...localStorageToPairs];

  return <GenericErrorBoundary debugInfo={debugInfo}>{children}</GenericErrorBoundary>;
};
