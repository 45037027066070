import { Form } from 'connex-cds';
import React from 'react';
import { SignatureContainer } from '../signature-section/signature/SignatureContainer';
import { FinalizeActions } from './FinalizeActions';

export const FinalizeSection = ({ ticketId, flow }) => {
  const { values } = Form.useFormContext();

  const sigField = React.useMemo(() => {
    return values?.signature?.image;
  }, [values?.signature?.image]);

  const rejectionReason = React.useMemo(() => {
    return values?.customerRejectReason?.description;
  }, [values?.customerRejectReason]);

  if (sigField) {
    return <SignatureContainer />;
  }

  if (rejectionReason) {
    return <div data-testid="rejectionReason">{rejectionReason}</div>;
  }

  return <FinalizeActions ticketId={ticketId} flow={flow} />;
};
