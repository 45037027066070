import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import order from '../api/order';

export const useGetOrder = (orderRef, companySetup) => {
  const { entityRef } = useParams();

  const isEnablePaymentAndCostWorkflows = useMemo(
    () => companySetup?.data?.isEnablePaymentAndCostWorkflows ?? false,
    [companySetup?.data?.isEnablePaymentAndCostWorkflows]
  );

  const isFetchingEnabled = useMemo(() => {
    if (!isEnablePaymentAndCostWorkflows) {
      return false;
    }

    return Boolean(entityRef) && Boolean(orderRef);
  }, [entityRef, orderRef, isEnablePaymentAndCostWorkflows]);

  const query = useQuery({
    queryKey: ['orders', entityRef, orderRef],
    queryFn: () => order.getOrder({ entityRef, orderRef }),
    staleTime: Infinity,
    enabled: isFetchingEnabled,
    refetchOnWindowFocus: false,
  });

  return query;
};

export const useCreateOrderPrice = orderRef => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['order-pricing', entityRef, orderRef];

  const mutation = useMutation({
    mutationFn: lineItems => order.createOrderPrice({ entityRef, orderRef, lineItems }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
