import cn from 'classnames';
import { Core, Form, Localization, Modal } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  height: 35px;
  min-width: 120px;
  max-width: 150px;
  border: 1px solid var(--color-border-input);
  background-color: var(--color-background-input);
  border-radius: 4px;
  color: var(-color-text-input);
  padding: 4px 10px;
  text-align: center;
  cursor: pointer;
`;

export const TimeDrawer = React.forwardRef((props, ref) => {
  const { openModal, closeModal } = Modal.useModalContext();
  const { timeZone } = Localization.useLocalizationContext();
  const { isSubmitting, disabled, setTouched, touched } = Form.useFormContext();

  const { onChange } = props;

  const onClose = React.useCallback(() => {
    setTouched({ ...touched, [props.name]: true });
  }, [props.name, setTouched, touched]);

  const handleChange = React.useCallback(
    (...args) => {
      onChange?.(...args);
      closeModal();
    },
    [closeModal, onChange]
  );

  const value = React.useMemo(() => props.value || new Date().toISOString(), [props.value]);

  const handleClick = React.useCallback(() => {
    if (disabled || isSubmitting || props?.disabled) {
      return;
    }

    setTouched({ ...touched, [props.name]: true });
    openModal({
      titleStringId: 'Time',
      onClose: onClose,
      component: (
        <Core.TouchTimePicker
          testId={`${props.testId}-form-field`}
          local={props.local}
          timeZone={timeZone}
          rolloverDays={1}
          onChange={handleChange}
          value={value}
          name={props.name}
          ref={ref}
          onBlur={props.handleBlur}
          disabled={disabled || isSubmitting || props?.disabled}
        />
      ),
    });
  }, [
    setTouched,
    touched,
    props.name,
    props.testId,
    props.local,
    props.handleBlur,
    props.disabled,
    openModal,
    onClose,
    timeZone,
    handleChange,
    value,
    ref,
    disabled,
    isSubmitting,
  ]);

  return (
    <Styled className={cn('time-drawer')} onClick={handleClick}>
      <Localization.Time date={props.value} />
    </Styled>
  );
});
