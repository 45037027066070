import { useMediaQuery } from '@mui/material';

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const useMediaQueries = () => {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const isMobileSmaller = useMediaQuery('(max-width: 768px)');
  const isMobileXs = useMediaQuery('(max-width:430px)');

  return { isMobile, isMobileSmaller, isMobileXs, isTouchDevice: isTouchDevice() };
};
