import React from 'react';
import cn from 'classnames';
import { Core, Drawer, Form, Localization } from 'connex-cds';
import { find } from 'lodash';
import styled from 'styled-components';
import {
  useCompanySetup,
  useCreateSetupItem,
  useProductTypeSetup,
  useUpdateSetupItem,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import { languagesNames, pascalCase } from '../../languagesNames';
import style from './style';

const allCountries = require('../../countryList');

const Styled = styled.div`
  ${style}
`;

export const ProductEditor = ({ config }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { userLocale } = Localization.useLocalizationContext();

  const vehicleTypeSetup = useVehicleTypeSetup();
  const productTypeSetup = useProductTypeSetup();

  const companySetup = useCompanySetup();

  const currentUserLocale = React.useMemo(() => {
    const [defaultLanguage] = Object.keys(companySetup?.data)
      .filter(key => key?.includes('default') && key?.includes('Language') && companySetup?.data[key])
      .map(key => {
        const variableName = key?.replace(/(default)|Language$/gi, '');
        const languagesName = find(languagesNames, { variableName });
        return languagesName?.locale;
      });
    return defaultLanguage;
  }, [companySetup?.data, userLocale]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue(`i18n.${currentUserLocale}`, values?.name);
    }
  }, [setFieldValue, currentUserLocale, values?.name]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue('name', values?.i18n[`${currentUserLocale}`]);
    }
  }, [setFieldValue, currentUserLocale]);

  const busy = React.useMemo(() => {
    return companySetup.isLoading || vehicleTypeSetup.isLoading || productTypeSetup.isLoading;
  }, [companySetup.isLoading, productTypeSetup.isLoading, vehicleTypeSetup.isLoading]);

  //TODO: This is just a bandaid, need to find cause of different formats
  const waterType = React.useMemo(() => {
    let waterProductType;
    if (Array.isArray(companySetup.data)) {
      waterProductType = companySetup.data?.[0]?.waterProductType;
    } else {
      waterProductType = companySetup.data?.waterProductType;
    }
    return waterProductType;
  }, [companySetup.data]);

  const create = useCreateSetupItem();
  const update = useUpdateSetupItem();

  const editMasterData = React.useCallback(
    async item => {
      return update(item);
    },
    [update]
  );

  const saveMasterData = React.useCallback(
    async item => {
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(async () => {
    const response = await saveMasterData(values);
    closeDrawer();
    return response;
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(async () => {
    const response = await editMasterData(values);

    closeDrawer();
    return response;
  }, [closeDrawer, editMasterData, values]);

  const countries = React.useMemo(() => {
    if (!companySetup?.data) {
      return [];
    }

    return companySetup?.data?.countries?.map?.(country => find(allCountries, { id: country }));
  }, [companySetup?.data, allCountries]);

  const vehicles = React.useMemo(() => {
    if (!vehicleTypeSetup?.data) {
      return [];
    }

    return vehicleTypeSetup.data;
  }, [vehicleTypeSetup.data]);

  const allowedLanguages = React.useMemo(() => {
    return (
      Object.keys(companySetup?.data)
        ?.filter(key => {
          const allowedLanguage = new RegExp(/^(allow)\w*Language$/i);
          return allowedLanguage.test(key);
        })
        ?.map(key => companySetup?.data[key])
        ?.filter(language => Boolean(language)).length > 1
    );
  }, [companySetup?.data]);

  const fieldComponents = React.useMemo(() => {
    const accumulator = { general: [], mobileTicket: [], waterConfig: [] };
    if (allowedLanguages) {
      accumulator['translations'] = [];
    }

    return config?.fields
      ?.filter(c => (companySetup?.data?.isMulticountry !== true ? c.path !== 'countries' : c))
      ?.reduce((acc, field) => {
        try {
          const FieldComponent = Components[pascalCase(field.name ?? field.path)];
          FieldComponent.displayName = field.name ?? field.path;

          if (field.path.startsWith('mobileTicket.')) {
            acc.mobileTicket.push(<FieldComponent />);
            return acc;
          }

          if (field.path === 'countries') {
            acc.general.push(<FieldComponent options={countries} busy={companySetup?.isLoading} />);
            return acc;
          }

          if (field.path === 'vehicleTypes') {
            acc.general.push(<FieldComponent options={vehicles} busy={vehicleTypeSetup?.isLoading} />);
            return acc;
          }

          const isDescriptionField = field.path.toLowerCase().includes('i18n');
          if (allowedLanguages && isDescriptionField) {
            acc.translations.push(<FieldComponent disabled={field.path.includes(currentUserLocale)} />);
            return acc;
          }

          if (!allowedLanguages && isDescriptionField) {
            return acc;
          }

          acc.general.push(<FieldComponent />);

          return acc;
        } catch (error) {
          return acc;
        }
      }, accumulator);
  }, [
    Components,
    companySetup?.data,
    companySetup.isLoading,
    config?.fields,
    vehicleTypeSetup.data,
    vehicleTypeSetup.isLoading,
    countries,
    vehicles,
    allowedLanguages,
    currentUserLocale,
  ]);

  React.useEffect(() => {
    if (values?.type && productTypeSetup.isSuccess) {
      const defaultProductType = find(productTypeSetup.data, r => r.id === values?.type);
      setFieldValue('uomCode', values?.uomCode || defaultProductType?.uomCode?.id);
    }
  }, [productTypeSetup.data, productTypeSetup.isSuccess, setFieldValue, values?.type, values?.uomCode]);

  const tabConfig = React.useMemo(() => {
    const config = {
      tabs: [
        {
          stringId: 'general',
          testId: 'general-tab',
          component:
            values.type === waterType?.id
              ? fieldComponents.general.filter(c => c?.type?.displayName !== 'isDriverSellable')
              : fieldComponents.general,
        },
      ],
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };

    const waterFields = [
      'mobileTicket.isConcreteOnTruckFieldEnabled',
      'mobileTicket.isTimeFieldEnabled',
      'mobileTicket.isReasonFieldEnabled',
    ];

    if (values?.isDriverSellable || values.type === waterType?.id) {
      config.tabs.push({
        stringId: 'mobileTicket',
        testId: 'mobileTicket-tab',
        component:
          values.type === waterType?.id
            ? fieldComponents.mobileTicket.filter(c => waterFields.includes(c?.type?.displayName))
            : fieldComponents.mobileTicket,
      });
    }

    if (allowedLanguages) {
      config.tabs.push({
        stringId: 'translations',
        testId: 'translations-tab',
        component: fieldComponents.translations,
      });
    }

    return config;
  }, [
    fieldComponents.general,
    fieldComponents.mobileTicket,
    values?.isDriverSellable,
    values.type,
    waterType,
    allowedLanguages,
  ]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('product-editor', { tabbed: tabConfig?.tabs?.length > 1 })}>
        {tabConfig?.tabs?.length > 1 ? <Core.Tabs config={tabConfig} /> : tabConfig?.tabs?.[0]?.component}
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
