import React from 'react';
import { Form, Localization } from 'connex-cds';
import { Styled } from './style';

const { Translate } = Localization;
const testId = 'activityGrid-waterRelease';

export const WaterRelease = () => {
  const { values } = Form.useFormContext();

  if (values.waterReleaseDeclined || !values.waterReleaseSignature?.image) {
    return null;
  }

  return (
    <Styled className="water-release-mobile">
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            <Translate stringId="waterReleaseAcknowledged" />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </Styled>
  );
};
