import { Form, Responsive } from 'connex-cds';
import React from 'react';
import { omit } from 'lodash';
import { useTicketContext } from '../../../../TicketContext';
import { FLOW } from '../Acceptance';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';
import { cleanupLineItems } from '../../../../../../../../util/cleanupLineItems';

export const Header = ({ flow, signed, entitySupportedLanguages }) => {
  const { values, errors } = Form.useFormContext();
  const { submitted, finalized } = useTicketContext();

  const submitAcceptanceVisible = React.useMemo(() => {
    if (!submitted && finalized && !values?.signature?.image && flow === FLOW.SIGNATURE_NO_SIGNATURE) {
      return false;
    }

    return submitted || finalized;
  }, [submitted, finalized, values?.signature?.image, flow]);

  const errorsToCheck = React.useMemo(() => cleanupLineItems(errors, values, 'ticketEvents'), [errors, values]);

  return (
    <Responsive>
      <HeaderMobile
        flow={flow}
        submitAcceptanceVisible={submitAcceptanceVisible}
        signed={signed}
        entitySupportedLanguages={entitySupportedLanguages}
        errors={errorsToCheck}
      />
      <HeaderDesktop
        flow={flow}
        submitAcceptanceVisible={submitAcceptanceVisible}
        entitySupportedLanguages={entitySupportedLanguages}
        errors={errorsToCheck}
      />
    </Responsive>
  );
};
