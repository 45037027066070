import { useCallback } from 'react';

import { useUserContext } from '../components/user';
import { useEntityContext } from '../util/entity-provider/context';

export const useFullStory = () => {
  const { user } = useUserContext();
  const { entityName, entityRef } = useEntityContext();

  const turnOnFullStory = useCallback(() => {
    if (user?.profileRef) {
      const displayName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || undefined;

      const payload = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        entityName,
        entityRef,
        displayName,
        isPlatformAdmin: user?.isPlatformAdmin,
        isPlatformSupport: user?.isPlatformSupport,
      };

      window?.FS?.identify?.(user?.profileRef, payload);
    } else {
      window?.FS?.anonymize?.();
    }
  }, [user, entityName, entityRef]);

  const turnOffFullStory = () => {
    window?.FS?.anonymize?.();
  };

  return { turnOnFullStory, turnOffFullStory };
};
