import { Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  text-align: left;
`;

export const PasswordRequirements = () => {
  return (
    <Styled className={cn('password-requirements')}>
      <Typography.Helper>
        <Localization.Translate stringId="password-requirements" />
      </Typography.Helper>
    </Styled>
  );
};
