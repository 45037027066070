import cn from 'classnames';
import { Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { CopyFromSelectCustomValues } from './CopyFromSelectCustomValues';
import { useExternalTemplatesSetup } from '../../../../MasterDataProvider';
import { useTemplateContext } from '../context/Provider';

const Styled = styled.div`
  display: flex;
  gap: 15px;
  align-content: center;
  justify-content: flex-end;
  select {
    height: 26px;
    min-width: 150px;
  }
`;

export const CopyFromCustomValues = ({ templateName, entities, visible }) => {
  const { data: dataTemplates, loading, setEntityRef } = useExternalTemplatesSetup();
  const { dispatch } = useTemplateContext();

  const data = React.useMemo(() => {
    if (dataTemplates) {
      return dataTemplates?.[0];
    }

    return {};
  }, [dataTemplates]);

  const disabled = React.useMemo(() => {
    return !data || loading;
  }, [data, loading]);

  const handleChange = React.useCallback(
    e => {
      if (e?.target?.value) {
        setEntityRef(e?.target?.value);
      }
    },
    [setEntityRef]
  );
  const handleClick = React.useCallback(() => {
    if (data) {
      const customValues = data[templateName];
      Object.keys(customValues).forEach(lang => {
        dispatch({ type: 'change', payload: { template: templateName, language: lang, value: customValues[lang] } });
      });
    }
  }, [data, templateName]);

  return entities && visible ? (
    <Styled className={cn('copy-from')}>
      <CopyFromSelectCustomValues onChange={handleChange} options={entities} />
      <Core.Button
        size="small"
        type="primary"
        stringId="Copy"
        data-testid="copy-button"
        onClick={handleClick}
        disabled={disabled}
      />
    </Styled>
  ) : null;
};
