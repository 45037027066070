import { css } from 'styled-components';

export default css`
  &.hasError {
    border-color: var(--color-background-button-primary-dangerous);
  }

  .description-delete {
    display: flex;
    .description {
      flex: 1;
    }
    .delete {
      .delete-icon {
        color: var(--color-background-button-primary-dangerous);
        &.disabled {
          color: var(--color-text);
        }
      }
    }
  }
  h3 {
    margin: 0;
  }
  padding: 5px 10px 10px 10px;
  &:not(:first-child) {
    margin-top: 10px;
  }

  .label-value {
    display: flex;
    .label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
`;
