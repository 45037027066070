import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';

const Styled = styled(Layout.Container)`
  padding: 10px 15px;
  margin-top: 10px;
  height: 120px;
`;
const { Column } = Layout;
const { Translate } = Localization;
const { Subtitle } = Typography;

export const ShowSignature = ({ Signature, signatureName, labelId }) => {
  return (
    <Styled className={cn('show-signature')}>
      <Column style={{ justifyContent: 'space-between' }}>
        <Subtitle>
          <Translate stringId={labelId} data-testid={`${labelId}Label`} />
        </Subtitle>
        <Column style={{ gap: '0' }}>
          <Signature />
          <Typography.Helper>{signatureName}</Typography.Helper>
        </Column>
      </Column>
    </Styled>
  );
};
