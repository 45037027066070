import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyES_CO = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>
          POLÍTICA DE PRIVACIDAD DE
          <br />
          COMMAND ALKON INCORPORATED
        </h4>
      </div>
      <div id="overview_content">
        <p>
          <em>Última modificación: 26 de septiembre de 2023</em>
        </p>
        <h2>Resumen</h2>
        <p>
          Command Alkon Incorporated, incluidas sus empresas relacionadas (en adelante, “CAI” o “nosotros”), es una
          organización comercial internacional que provee soluciones de red, software y servicios a empresas en la
          industria de concreto premezclado, agregado y remolque a granel a través de métodos en las instalaciones,
          software como servicio (“SaaS”) y otros métodos basados en la red. CAI respeta la privacidad de los usuarios
          de sus productos y servicios y se compromete a proteger la privacidad y mantener la confianza. Este Aviso de
          privacidad describe nuestras prácticas respecto de la recopilación, el uso, el almacenamiento, la
          transferencia, la divulgación y otros tipos de procesamiento de sus datos personales (referidos también como
          Información personal) en relación con el uso de los productos o servicios, sitios web, aplicaciones y páginas
          de redes sociales de CAI, así como sus interacciones con el personal de CAI en reuniones y eventos de CAI,
          incluidas las actividades de comercialización y ventas
          <br />
          fuera de línea. Lea atentamente este Aviso de privacidad para comprender nuestras políticas y prácticas
          respecto del tratamiento de sus datos personales. Al acceder a nuestro sitio web o usar nuestros productos o
          servicios, usted nos autoriza a recopilar, usar y divulgar sus datos personales de la forma descrita en este
          Aviso de privacidad. Si tiene alguna pregunta o inquietud de privacidad, comuníquese con nosotros usando la
          información de la sección “Contáctenos” al final de este Aviso de privacidad.
        </p>
        <h2>Contenido</h2>
        <ul>
          <li>
            <a href="#summary_of_privacy_notice">Resumen del Aviso de privacidad</a>
          </li>
          <li>
            <a href="#controller_processor">Controlador/Procesador</a>
          </li>
          <li>
            <a href="#to_whom_does_this_notice_apply">Para quién aplica este Aviso</a>
          </li>
          <li>
            <a href="#how_we_collect_personal_data">
              Cómo recopilamos y usamos los datos personales
              <br />
            </a>
          </li>
          <li>
            <a href="#automatically_collected_information">Información recopilada automáticamente</a>
          </li>
          <li>
            <a href="#how_we_use_the_information_we_collect">Cómo usamos la información que recopilamos</a>
          </li>
          <li>
            <a href="#how_we_disclose_the_information_we_collect">Cómo divulgamos la información que recopilamos</a>
          </li>
          <li>
            <a href="#third_party_services">Servicios de terceros</a>
          </li>
          <li>
            <a href="#security">Seguridad</a>
          </li>
          <li>
            <a href="#retention_of_data">Retención de información</a>
          </li>
          <li>
            <a href="#your_rights_and_choices">Sus derechos y elecciones</a>
          </li>
          <li>
            <a href="#international_data_transfers_and_privacy_shield">
              Transferencias internacionales de datos y el Marco de Privacidad de Datos
            </a>
          </li>
          <li>
            <a href="#lawful_basis_for_processing">Base legal para el procesamiento</a>
          </li>
          <li>
            <a href="#children_policy">Privacidad de menores</a>
          </li>
          <li>
            <a href="#changes_to_the_privacy_notice">Cambios al Aviso de privacidad</a>
          </li>
          <li>
            <a href="#contact_us">Contáctenos</a>
          </li>
        </ul>
      </div>
      <div id="summary_of_privacy_notice">
        <h1>Resumen del Aviso de privacidad</h1>
        <p>
          <strong>Aplicación:</strong> &ndash; Nuestro Aviso de privacidad aplica al procesamiento de los datos
          personales provistos por: 1) visitantes y usuarios de los diversos sitios, aplicaciones, productos y servicios
          de CAI; 2) asistentes a eventos de CAI y de la industria; 3) clientes y potenciales clientes y sus
          representantes; 4) suscriptores a nuestros avisos y boletines informativos; 5) proveedores y socios
          comerciales y sus representantes; 6) visitantes a nuestras oficinas; y 7) cualquier otra persona que brinde
          sus datos personales a CAI por cualquier otro motivo. Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Tipo de datos procesados:</strong> &ndash; CAI recopila los datos personales que usted proporciona.
          Por ejemplo, cuando se registra para una cuenta, podemos recopilar información como nombres, direcciones de
          correo electrónico, direcciones físicas, números telefónicos, etc. También puede proporcionar datos
          personales, por ejemplo, para obtener más información sobre nuestros productos o registrarse para recibir
          notificaciones. Podemos procesar los siguientes datos personales, los cuales podemos recopilar (según las
          circunstancias) durante sus visitas al sitio web, interacciones de comercialización, uso de aplicaciones y uso
          de nuestros productos y servicios durante el transcurso de los acuerdos celebrados con usted o con su
          empleador: su nombre, datos de contacto (dirección de correo electrónico, número telefónico, etc.),
          información de empleo (empleador, cargo, supervisor, etc.), ciertos números de identificación (licencia de
          <br />
          conducir, empleado, etc.), información de rendimiento laboral y certificaciones, información de pago,
          dirección IP, geolocalización, firma, imagen fotográfica, nombre de usuario y contraseña, información sobre
          sindicatos, etnia, información médica relacionada con el trabajo, mensajería, información conductual sobre
          usted recibida a través de la colocación de cookies y pixeles de seguimiento durante el uso de la plataforma.
          Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Propósito del procesamiento:</strong> &ndash; Procesamos sus datos personales con los siguientes
          fines: 1) celebración de acuerdos con usted o su empleador; 2) control, desarrollo, soporte y administración
          de aplicaciones, sitios web y plataformas; 3) seguridad y prevención de fraude; 4) nuestra comercialización
          (no vendemos sus datos personales); 5) nuestra administración comercial; y 6) análisis conductual asociado con
          el uso de la plataforma. Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Fundamento legal del procesamiento: </strong> &ndash; En la medida en que aplique a usted, algunas
          jurisdicciones requieren que cualquier procesamiento de datos personales esté respaldado por un fundamento
          legal. Generalmente nos basamos en las siguientes justificaciones legales para nuestras actividades de
          procesamiento: 1) celebración de acuerdos con usted o su empleador; 2) perseguir nuestros intereses legítimos,
          siempre que no anulen sus intereses, derechos y libertades; 3) su consentimiento; y 4) cumplimiento de una
          obligación legal. Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Transferencia de datos:</strong> &ndash; Podemos transferir sus datos personales a otras filiales de
          CAI o a terceros (por ejemplo, socios comerciales, revendedores, etc.), entidades adquirentes o adquiridas,
          proveedores de servicios y, de conformidad con la ley aplicable, autoridades gubernamentales, tribunales,
          consultores externos y terceros similares. Algunas transferencias de datos pueden incluso ser internacionales.
          Aplicaremos todas las medidas razonables para garantizar que sus datos personales sigan siendo confidenciales
          al ser transferidos. No vendemos sus datos personales a terceros. Además, CAI no compartirá sus datos con
          terceros sin divulgación total, de otra manera que no sea la establecida en este Aviso de privacidad. Para más
          información, consulte a continuación.
        </p>
        <p>
          <strong>Retención y eliminación de datos:</strong> &ndash;Sus datos personales serán eliminados una vez que ya
          no sean necesarios para los fines del procesamiento original, interés legítimo o según lo requerido por la ley
          aplicable. Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Sus elecciones y derechos:</strong> &ndash; Según su jurisdicción, es posible que tenga ciertos
          derechos en relación con sus datos personales, los cuales pueden incluir el derecho a acceder a sus datos
          personales, el derecho a obtener una copia de sus datos personales, el derecho a que sus datos personales sean
          transferidos a un tercero, el derecho a corregir sus datos personales, el derecho a restringir ciertos
          procesamientos y el derecho a que se borren sus datos personales. Para ejercer sus derechos en relación con
          sus datos personales, comuníquese con nosotros usando la información de la sección “Contáctenos” al final de
          este Aviso de privacidad. Para más información, consulte a continuación.
        </p>
        <p>
          <strong>Cambios a este Aviso de privacidad:</strong> &ndash; Nos reservamos el derecho a cambiar los términos
          de este Aviso de privacidad en cualquier momento y a nuestra discreción, según lo requieren las prácticas
          cambiantes o la legislación sobre privacidad. La versión más actual de este Aviso de privacidad siempre estará
          disponible desde un enlace en nuestros sitios web, plataformas o a través de la información de la sección
          “Contáctenos” al final de este Aviso de privacidad. Revise este Aviso de privacidad periódicamente para
          mantenerse al tanto de las prácticas y procedimientos más actualizados.
        </p>
      </div>
      <div id="controller_processor">
        <h1>Aviso de privacidad detallado </h1>
        <p>
          <strong>Controlador/Procesador &mdash;</strong> CAI puede ser el Controlador de datos o el Procesador de datos
          para fines de procesamiento de datos personales, dependiendo de las circunstancias. Cuando CAI procesa datos
          en nombre de nuestros clientes o sus filiales, CAI generalmente actúa como Procesador de datos. Cuando CAI
          procesa datos personales para sus propios fines e independientemente de la instrucción de sus clientes o
          filiales, CAI puede actuar como Controlador de datos. Si tiene alguna pregunta acerca de la función de CAI en
          relación con sus datos personales, comuníquese con nosotros usando la información de la sección “Contáctenos”
          al final de este Aviso de privacidad.
        </p>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <p>
          <strong>Para quién aplica este Aviso: &mdash;</strong>
          Nuestro Aviso de privacidad aplica a todo el procesamiento de datos personales, no relacionado con el empleo,
          realizado por CAI, independientemente de la fuente de recopilación. Este Aviso de privacidad aplica al
          procesamiento de los datos personales provistos por: 1) visitantes y usuarios de los diversos sitios,
          aplicaciones, productos y servicios de CAI; 2) asistentes a eventos de CAI y de la industria; 3) clientes y
          potenciales clientes y sus representantes; 4) suscriptores a nuestros avisos, correos electrónicos y boletines
          informativos; 5) proveedores y socios comerciales y sus representantes; 6) visitantes a nuestras oficinas; y
          7) cualquier otra persona que brinde sus datos personales a CAI por cualquier otro motivo.
        </p>
      </div>
      <div id="how_we_collect_personal_data">
        <h2>Cómo recopilamos los datos personales: &mdash;</h2>
        <h3>Datos personales que usted proporciona:</h3>
        <p>
          CAI recopila los datos personales que usted o su representante proporcionan. Esto podría suceder en un evento
          de CAI, durante un evento de soporte, a través de comercialización, durante una interacción en persona, etc.
          Algunos ejemplos de los tipos de datos personales que CAI puede recopilar incluyen, entre otros, su: nombre,
          dirección, número telefónico, dirección de correo electrónico, empleador, cargo, fecha de contratación,
          supervisor, antigüedad, número de licencia de conducir, número de empleado, número de seguro social, otros
          números de identificación tributaria, información sobre rendimiento laboral y certificaciones, información de
          pago, dirección IP o identificador de dispositivo, geolocalización, firma, imagen fotográfica o de video,
          nombre de usuario y contraseña, información sobre sindicatos, etnia, información médica relacionada con el
          trabajo, mensajería, información conductual sobre usted recibida a través de la colocación de cookies y
          pixeles de seguimiento durante el uso de la plataforma. Si tiene alguna pregunta o inquietud acerca de
          cualquiera de estos tipos de datos personales, comuníquese con nosotros usando la información de la sección
          “Contáctenos” al final de este Aviso de privacidad.
        </p>
        <h3>Datos recopilados a través de nuestros productos y servicios:</h3>
        <p>
          CAI obtiene datos personales en conexión con la entrega de sus productos y servicios, entre los que se
          incluyen:
        </p>
        <p>(i) Productos y servicios SaaS alojados para los clientes de CAI; </p>
        <p>
          (ii) Productos o servicios basados en la red para soluciones de comercio colaborativo que cubren todo el
          proceso de pedido y compra en el sector del mercado de negocio a negocio;
        </p>
        <p>
          (iii)Productos de software en el lugar autorizados con licencia a un cliente para uso en sus propias
          instalaciones a través de la entrega de servicios profesionales;
        </p>
        <p>(iv) Productos de hardware en el lugar vendidos a un cliente para uso en sus propias instalaciones; y </p>
        <p>
          (v) Servicios de soporte al cliente relacionados con los productos de software y hardware en las
          instalaciones, basados en la red y SaaS.
        </p>
        <p>
          Por ejemplo, cuando nuestros clientes usan los productos o servicios antes mencionados, es posible que brinden
          detalles sobre sus empleados, incluidos sus nombres, cargos, direcciones de correo electrónico, credenciales
          de inicio de sesión, números telefónicos, direcciones, fechas de nacimiento, números de licencia de conducir y
          otra información. Algunas de nuestras soluciones basadas en la red permiten a los clientes, por ejemplo,
          enviar datos personales para crear usuarios de la solución, almacenar documentos de transacciones que pueden
          incluir datos personales de los firmantes o contactos comerciales, usar la geolocalización y almacenar
          información de contacto asociada a los socios comerciales.
        </p>
        <p>
          A menudo procesamos datos personales en nombre de nuestros clientes, de conformidad con un contrato escrito.
          No controlamos las prácticas de protección o procesamiento de datos de nuestros clientes (que pueden ser su
          empleador, proveedor de servicios o socio comercial), por lo tanto, sus términos pueden diferir de los
          términos establecidos en este Aviso de privacidad. Tenga en cuenta que cuando CAI recopila datos personales a
          través de productos o servicios controlados por nuestros clientes, ellos son los Controladores de datos
          respecto de qué datos se recopilan, y cómo se usan y se divulgan. En esos casos, CAI actúa como Procesador de
          datos únicamente. Cualquier pregunta relacionada con cómo nuestros clientes procesan, usan o comparten los
          datos personales que recopilan a través de nuestros productos o servicios debe dirigirse al cliente en
          cuestión.
        </p>
      </div>
      <div id="automatically_collected_information">
        <h3>Información recopilada automáticamente:</h3>
        <p>
          Mientras navega e interactúa con nuestros sitios web o productos y aplicaciones SaaS, es posible que usemos
          tecnologías de recopilación automática de datos para reunir cierta información sobre sus equipos, acciones y
          patrones (“Información de la actividad del usuario”), incluidos: (i) detalles de su uso, que incluyen datos de
          tráfico, datos de ubicación a través de tecnología de geolocalización, registros y otros datos de comunicación
          y recursos a los que accede y usa; e (ii) información sobre su dispositivo, que incluye su sistema operativo,
          dirección IP y otros datos móviles de inicio de sesión. Parte de esta información puede considerarse datos
          personales en su jurisdicción.
        </p>
        <p>
          La Información de la actividad del usuario que recopilamos nos ayuda a mejorar nuestros sitios web y
          productos, y a entregar un servicio mejor y más personalizado al permitirnos calcular patrones de uso,
          visualizar la información que usted solicita en su zona horaria relevante y reconocerlo cuando regresa a
          nuestro sitio web o producto.
        </p>
        <p>
          Las tecnologías que usamos para esta recopilación automática de datos pueden incluir balizas web, etiquetas de
          pixeles, scripts o cookies. Una cookie es un pequeño archivo que se ubica en el disco duro de su dispositivo.
          Usamos cookies para ayudar a analizar el uso, personalizar nuestros servicios, medir la efectividad y promover
          la confianza y la seguridad. El uso de cookies se analiza en detalle más adelante.
        </p>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <h2>Cómo usamos la información que recopilamos: &ndash;</h2>
        <p>
          CAI usa la información que recopila con el objetivo de lograr una operación efectiva, brindar sus productos y
          servicios a los clientes, facilitar el comercio entre las partes de la industria y administrar y gestionar sus
          relaciones con los clientes. También usamos la información que recopilamos para procesar, evaluar y responder
          a sus solicitudes; responder las consultas y las aplicaciones; crear, administrar y comunicarnos con usted
          respecto de su cuenta (incluidas sus compras y pagos); operar, evaluar y mejorar los negocios de CAI (incluido
          el desarrollo de nuevos productos y servicios, la gestión de las comunicaciones, el desarrollo de
          investigaciones de mercado, el análisis de los sitios web/servicios/productos de CAI; y el desarrollo de
          actividades de contabilidad, auditoría, facturación, reconciliación y recopilación); garantizar la seguridad
          de los recursos y servicios de red de CAI; y cumplir con los requisitos legales aplicables.
        </p>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <h2>Cómo divulgamos la información que recopilamos: &ndash;</h2>
        <p>Es posible que divulguemos algunos datos personales que recopilamos de la siguiente manera: </p>
        <ul>
          <li>A nuestras filiales o socios comerciales con quienes tenemos relaciones contractuales;</li>
          <li>
            A terceros, como contratistas, proveedores de servicios, consultores y otros agentes (“Proveedores de
            servicios”), que brindan asistencia a nuestra empresa. CAI trabaja con Proveedores de servicios en algunos
            casos por diversas razones, entre ellas, procesamiento de pagos con tarjeta de crédito, registro de horas de
            servicio, almacenamiento de datos y contabilidad. Los Proveedores de servicios con quienes compartimos datos
            personales generalmente tienen obligaciones de confidencialidad y privacidad. Puede encontrar una lista de
            esos Proveedores de servicios en nuestro sitio web (www.commandalkon.com), en la pestaña “Legal” (Lista de
            subprocesadores);
          </li>
          <li>Para lograr el propósito para el cual usted o su empleador proporcionó dichos datos personales; </li>
          <li>
            A un comprador u otro sucesor de CAI o cualquiera de nuestras filiales en el caso de adquisición, fusión,
            desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de la totalidad o
            parte de los activos o patrimonio de CAI o una filial, ya sea como una empresa en funcionamiento o como
            parte de una quiebra, liquidación o procedimiento similar, en los que los datos personales se encuentran
            entre los activos transferidos; y
          </li>
          <li>Para cualquier otro propósito divulgado por nosotros cuando usted suministra los datos personales. </li>
        </ul>
        <p>
          Sin perjuicio de cualquier otra disposición de este Aviso de privacidad, también nos reservamos el derecho de
          acceder, preservar y divulgar cualquier información que recopilemos cuando lo consideremos razonablemente
          necesario para lo siguiente: (i) responder a requisitos legales, que incluyen cumplir con órdenes judiciales o
          procesos legales y responder a cualquier solicitud regulatoria o gubernamental, como responder a funcionarios
          gubernamentales o de orden público en relación a una solicitud verificada relacionada con una investigación
          penal o actividad ilegal sospechada; (ii) hacer cumplir o aplicar nuestras políticas y acuerdos; (iii)
          detectar, evitar o de otra forma abordar problemas de fraude, seguridad, confianza o técnicos; (iv) responder
          a solicitudes de soporte del usuario; o (v) proteger los derechos, propiedad, salud o seguridad de CAI,
          nuestros usuarios, terceros o público en general.
        </p>
        <h2>Información adicional para residentes de California y otros estados de los EE. UU. &ndash;</h2>
        <p>
          La información personal que recopilamos incluye información dentro de las categorías de datos de la tabla que
          figura a continuación. Estas categorías también representan las categorías de información personal que hemos
          recopilado durante los últimos 12 meses. Tenga en cuenta que las categorías detalladas a continuación se
          definen por la ley estatal de California. La inclusión de una categoría de la lista a continuación indica solo
          que, dependiendo de los servicios y productos que le brindemos a usted o su empleador y socio comercial,
          podemos recopilar o deshacernos de cierta información dentro de dicha categoría. No necesariamente significa
          que recopilamos o divulgamos toda la información detallada en una categoría en particular, ni que recopilamos
          todas las categorías de información para todas las personas.
        </p>
        <p>
          Asimismo, a pesar de que incluimos datos recopilados en el contexto de negocio a negocio, los cuales se
          consideran datos personales en virtud de ciertas leyes de privacidad, como en el Reglamento General de
          Protección de Datos (“GDPR”) de la UE y Reino Unido, y la Ley de Derechos de Privacidad en California, es
          posible que estos no se consideren datos personales en otras jurisdicciones, incluso dentro de otros estados
          de los EE. UU.
        </p>
        <p>
          Hemos divulgado información en cada una de las categorías a continuación con nuestras filiales y proveedores
          de servicios para fines comerciales dentro de los últimos 12 meses.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Categoría </strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Fuentes</strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Propósito del procesamiento</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Identificadores, como un nombre real, alias, dirección postal, identificador personal único,
                  identificador en línea, dirección de protocolo de Internet, dirección de correo electrónico, nombre de
                  la cuenta, número de Seguro Social, número de licencia de conducir u otros identificadores similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
                <p>
                  Además, estos datos, así como la información respecto de sus tendencias de compra obtenida de nuestros
                  socios comerciales, también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Información que identifica, se relaciona, describe o es capaz de asociarse con una persona en
                  particular, incluidos, entre otros, su nombre, firma, número de Seguro Social, características físicas
                  o descripción, dirección, número de teléfono, licencia de conducir o número de tarjeta de
                  identificación del estado, información médica.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
                <p>
                  Además, estos datos también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Información comercial, incluidos registros de propiedad personal, productos o servicios comprados,
                  obtenidos o considerados, u otros historiales o tendencias de compra y de consumo.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, o registros que
                  tenemos acerca de usted mientras brindamos servicios o productos.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
                <p>
                  Además, estos datos, así como la información respecto de sus tendencias de compra obtenida de nuestros
                  socios comerciales, también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Internet u otra información electrónica de actividad de redes, incluidos, entre otros, historial de
                  navegación, historial de búsqueda e información respecto de la interacción de un consumidor con un
                  sitio web de Internet, aplicación o publicidad.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, o a través del
                  uso de cookies y tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan para ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
                <p>
                  Además, estos datos también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">Datos de geolocalización. </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
                <p>
                  Además, estos datos, así como la información respecto de sus tendencias de compra obtenida de nuestros
                  socios comerciales, también se usan para fines de comercialización, incluso para ofrecerle y brindarle
                  productos y servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Información de audio, electrónica, visual, térmica, olfatoria o similar. </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
                <p>
                  Para los visitantes, esta información se recopila a través de nuestras cámaras CCTV, y para los que
                  llaman, se recopila a través de grabaciones de audio.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso la grabación de llamadas de venta y otras llamadas. Para los
                  visitantes, los datos visuales pueden ser procesados a fin de mantener la seguridad y responsabilidad,
                  y para cumplir con las leyes locales, incluidas aquellas relacionadas con la salud y la seguridad.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">Información profesional o relacionada con el empleo.</td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, o registros que
                  tenemos acerca de usted mientras brindamos servicios o productos.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, y para la gestión del
                  cumplimiento.
                </p>
                <p>
                  Además, estos datos también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Inferencias obtenidas de cualquiera de las categorías de información antes mencionadas para crear un
                  perfil sobre un consumidor que refleje las preferencias, características, tendencias psicológicas,
                  predisposiciones, conductas, actitudes, inteligencia, habilidades y aptitudes del consumidor.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  Además, estos datos también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Además, estos datos también se usan para fines de comercialización, incluso para ofrecerle productos y
                  servicios que pueden interesarle tanto por publicidad directa como de un socio.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Esta información se recopila directamente de usted o su empleador, nuestros socios comerciales,
                  nuestros proveedores de servicios, su interacción con nuestros servicios y productos, registros que
                  tenemos acerca de usted mientras hemos brindado servicios o productos, o a través del uso de cookies y
                  tecnologías similares.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  Estos datos se procesan en conexión con varias de nuestras funciones operativas para brindarle
                  productos y servicios, incluso facilitar negocios entre las partes en la industria y administrar
                  relaciones con los clientes.
                </p>
                <p>
                  También se procesan a fin de ayudar a administrar y gestionar su cuenta, y detectar incidentes de
                  seguridad, proteger contra actividad maliciosa, engañosa, fraudulenta o ilegal, para la gestión del
                  cumplimiento, análisis de datos y desarrollo tecnológico de nuestros sistemas.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          CAI no vende información a otros, ni siquiera por los propósitos de la Ley de Privacidad de Consumidores de
          California de 2018 (“CCPA”) y la Ley Derechos de Privacidad en California 2020 (“CPRA”).
        </p>
      </div>
      <div id="third_party_services">
        <h2>Servicios de terceros &ndash;</h2>
        <p>
          Es posible que tenga la oportunidad de elegir acceder y usar servicios o aplicaciones de terceros (“Servicios
          de terceros”) a través de alguno de nuestros sitios web o productos. Este Aviso de privacidad aborda solo la
          recopilación, uso y divulgación que hace CAI de la información recopilada por CAI. No aplica a las prácticas
          de terceros de las que no somos propietarios, controlamos, empleamos o administramos, incluidos, entre otros,
          los Servicios de terceros. Al acceder o usar servicios o aplicaciones de terceros a través de nuestros sitios
          web o productos, nos permite divulgar su información al Servicio de terceros en su nombre, según lo solicitado
          por dicho tercero, y usted acuerda que el uso o la divulgación por parte del tercero estarán regidos por su
          política de privacidad, la cual puede ser diferente a cómo nosotros usamos y divulgamos su información.
        </p>
      </div>
      <div id="security">
        <h2>Seguridad &ndash;</h2>
        <p>
          CAI utiliza controles de seguridad física, administrativos y técnicos razonables y apropiados compatibles con
          el tipo de datos que procesa para proteger los datos personales contra la pérdida, el uso indebido y el
          acceso, la divulgación, la alteración o la destrucción no autorizados. A pesar de que nos esforzamos para
          proteger su privacidad, ningún control de seguridad es 100% efectivo y no podemos garantizar la seguridad de
          la información o que sus datos personales o comunicaciones privadas siempre se mantengan en privacidad. El
          ingreso o uso no autorizado, las fallas de hardware o software y otros factores pueden comprometer la
          seguridad de la información del usuario.
        </p>
        <p>
          La seguridad de su información también depende de usted. Si le entregamos (o usted elige) credenciales para
          inicio de sesión (es decir, un nombre de usuario y una contraseña) para acceder a determinadas partes de
          nuestros servicios o aplicaciones, usted es responsable de mantener la confidencialidad de dichas credenciales
          de la cuenta de usuario. Le pedimos que no comparta sus credenciales con nadie. Usted es el único responsable
          de la protección de las credenciales de la cuenta de usuario y de todo el uso de sus credenciales de la cuenta
          bajo su control.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Retención de la información &ndash;</h2>
        <p>
          Por lo general, retendremos datos personales mientras sea necesario para cumplir con los propósitos descritos
          en este Aviso de privacidad. Esta retención generalmente se mantiene durante toda la relación más un breve
          período de tiempo, como se establece en nuestro cronograma de retención de datos. Sin embargo, podemos retener
          cierta información durante un período más largo, según sea exigido por la ley aplicable (como requisitos
          impositivos, contables o legales) o para fines comerciales legítimos. Una vez que ya no necesitemos usar sus
          datos personales para cumplir con sus obligaciones, los eliminaremos de nuestros sistemas y registros o
          tomaremos las medidas necesarias para anonimizarlos correctamente, de manera tal que usted ya no pueda ser
          identificado. Cada uno de los usos establecidos en este Aviso de privacidad constituye un interés legítimo de
          CAI de procesar o utilizar los datos personales recopilados o es necesario para cumplir con un contrato. Si no
          está de acuerdo con este enfoque, puede oponerse al procesamiento o uso de sus datos personales por parte de
          CAI, comunicándose por escrito con CAI a través de los métodos que se enumeran en la sección “Contáctenos” al
          final de este Aviso de privacidad.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Sus derechos y elecciones &ndash;</h2>
        <h3>Información de cuenta y solicitudes: </h3>
        <p>
          De conformidad con la ley aplicable dependiendo de la jurisdicción (como, por ejemplo, el Espacio Económico
          Europeo (”EEE”) desde el 25 de mayo de 2018 en adelante o el Estado de California desde el 1 de enero de
          2020), es posible que usted tenga los siguientes derechos que se detallan a continuación respecto de sus datos
          personales:
        </p>
        <p>
          1) el derecho a acceder (esto significa que puede pedirnos que le brindemos una copia de los datos personales
          que hayamos recopilado sobre usted, las categorías de las fuentes de las que recopilamos los datos, los fines
          para recopilarlos, las categorías de los terceros con quienes hemos compartido los datos y las categorías de
          los datos personales que hemos compartido con terceros para un fin comercial);
        </p>
        <p>
          2) el derecho a corregir (esto significa que puede pedirnos, a través de la sección “Contáctenos” al final de
          este Aviso de privacidad, que corrijamos cualquier error en sus datos personales o actualizar sus
          preferencias; es posible que no podamos ajustarnos a su solicitud si creemos que eso violaría cualquier ley o
          requisito legal o que causaría que la información fuera incorrecta; por lo general se excluyen los datos que
          únicamente se retienen para fines de respaldo de datos);
        </p>
        <p>
          3) el derecho a transferir (esto significa que puede pedirnos que le brindemos una copia de sus datos
          personales a un tercero de su elección);
        </p>
        <p>
          4) el derecho a restringir (cuando nuestro procesamiento de sus datos personales es necesario para nuestros
          legítimos intereses, dependiendo de la ley de privacidad/protección de datos que aplique, usted puede objetar
          este procesamiento en cualquier momento, sujeto a ciertas limitaciones y excepciones; también es posible que
          pueda restringir ciertos tipos de procesamiento de sus datos personales en ciertas circunstancias en
          particular, puede solicitar que restrinjamos nuestro uso de sus datos personales si disputa su precisión, si
          se determina que el procesamiento de sus datos personales es ilegítimo, o si ya no necesitamos sus datos
          personales para el procesamiento, pero los hemos retenido según lo permitido por ley);
        </p>
        <p>
          5) el derecho a retirar su consentimiento (esto significa que, en la medida en que nuestro procesamiento de
          sus datos personales se base en su consentimiento, usted puede retirar su consentimiento en cualquier momento;
          retirar su consentimiento, sin embargo, no afectará la legalidad del procesamiento basado en su consentimiento
          antes de decidir retirarlo, y no afectará la legalidad de nuestro procesamiento continuo basado en cualquier
          otro fundamento legal para el procesamiento de sus datos personales);
        </p>
        <p>
          6) el derecho a eliminar (esto significa que puede solicitarnos que borremos sus datos personales de nuestros
          sistemas y lo haremos, a menos que se nos exija retener dicha información para brindarle servicios a usted o
          que necesitemos dichos datos personales para cumplir con nuestras obligaciones comerciales o legales en virtud
          de la ley aplicable); o
        </p>
        <p>
          7) el derecho a decir que &#8220;no&#8221; a la venta de sus datos personales (Nota: CAI no vende datos
          personales).
        </p>
        <p>
          Es posible que pueda ejercer algunos de estos derechos, o todos ellos, ingresando en su cuenta dentro de los
          productos que usa. De lo contrario, para solicitar dicha información directamente, envíe una solicitud por
          escrito, usando los detalles provistos en la sección “Contáctenos” al final de este Aviso de privacidad.
        </p>
        <p>
          Tenga en cuenta que, en virtud de la ley de California, solo estamos obligados a responder a las solicitudes
          de acceso a información personal del mismo consumidor un máximo de dos veces a lo largo de un período de 12
          meses. En virtud de la ley de California y la UE, si una persona realiza solicitudes sin fundamento,
          repetitivas o excesivas (según se determine por nuestro criterio razonable) para acceder a los Datos
          personales, podemos cobrar una tarifa sujeta a un máximo determinado por ley.
        </p>
        <p>
          Recuerde que, en ciertas regiones, también tiene el derecho de quejarse ante una autoridad de protección de
          datos (“DPA”) sobre la recopilación y el uso de sus datos personales. Para más información, contáctese con su
          DPA local.
        </p>
        <p>
          En el caso de que CAI procese sus datos personales en nombre de un cliente, remita sus consultas sobre
          privacidad y solicitudes de acceso, corrección o eliminación de datos personales a dicho cliente.
        </p>
        <p>
          Antes de brindar la información que solicite de acuerdo con ciertos derechos, debemos poder verificar su
          identidad. A fin de verificar su identidad, es posible que deba enviar información sobre usted, entre lo que
          se incluye, en el alcance permitido, respuestas a preguntas de seguridad, su nombre, número de identificación
          del gobierno, fecha de nacimiento, información de contacto u otra información de identificación personal.
          Verificaremos esta información con la información que hayamos recopilado previamente de usted para verificar
          su identidad y su solicitud. Si no podemos verificar su identidad como parte de su solicitud, no podremos
          satisfacer su pedido. No estamos obligados a recopilar información adicional para permitir verificar su
          identidad. Para las solicitudes de eliminación, se le pedirá que envíe una solicitud de eliminación
          verificable.
        </p>
        <p>
          Si desea designar un agente autorizado para realizar la solicitud en su nombre, deberá brindar al agente un
          permiso escrito y firmado para enviar las solicitudes de derechos de privacidad en su nombre o presentar una
          carta de su representante legal. El agente o representante legal deben entregar esta autorización al momento
          de la solicitud. Podemos pedirle que verifique su identidad con nosotros directamente antes de brindar
          cualquier información solicitada a su agente autorizado.
        </p>
        <p>La información recopilada para fines de verificación de su solicitud solo se usará para la verificación.</p>
        <p>
          Si desea hacer uso de cualquiera de estos derechos, en el alcance aplicable, la ley estatal de los EE. UU. nos
          prohíbe discriminarlo en base a su elección de ejercer sus derechos de privacidad. Sin embargo, podemos
          aplicar una tarifa diferente o brindar un nivel de servicio diferente en la medida permitida por la ley.
        </p>
        <h3>No rastrear señales: </h3>
        <p>
          Algunos navegadores web le permiten transmitir una señal a sitios web y servicios en línea, indicando una
          preferencia de &#8220;no rastrear&#8221; sus actividades en línea. En este momento, no aceptamos dichas
          señales y no modificamos la información que recopilamos o cómo usamos dicha información dependiendo de si
          dicha señal es transmitida o recibida por nosotros.
        </p>
        <h3>Comunicaciones promocionales: </h3>
        <p>
          Ocasionalmente, es posible que le enviemos comunicaciones de comercialización sobre nuestros productos, de
          acuerdo con sus preferencias. Puede optar por dejar de recibir nuestros mensajes promocionales en cualquier
          momento, siguiendo las instrucciones en dichos mensajes (por lo general, habrá un aviso o enlace al final del
          mensaje). Si opta por dejar de recibirlos, es posible que aún podamos enviarle comunicaciones no
          promocionales, como aquellas respecto de su cuenta o relaciones comerciales en curso. También puede enviar las
          solicitudes para dejar de recibir los mensajes promocionales a CAI por escrito a través de los métodos que se
          enumeran en la sección “Contáctenos” al final de este Aviso de privacidad.
        </p>
        <h3>Cookies:</h3>
        <p>
          Usted puede administrar nuestro uso de cookies, desde el cartel de cookies que aparece cuando accede a nuestro
          sitio. Si lo prefiere, por lo general, puede elegir configurar su navegador para eliminar o rechazar las
          cookies del navegador o eliminar el almacenamiento local. También puede enviar una solicitud de administración
          de cookies, por escrito a través de los métodos que se enumeran en la sección “Contáctenos” al final de este
          Aviso de privacidad. Tenga en cuenta que, si decide eliminar o rechazar las cookies, esto podría afectar la
          funcionalidad de nuestro sitio web y servicios. Para mayores detalles, puede encontrar una Política sobre
          cookies separada en nuestro sitio web (<b>www.commandalkon.com</b>), en la pestaña Legal.
        </p>
        <h3>Información de ubicación:</h3>
        <p>
          Puede activar y desactivar servicios basados en la ubicación, ajustando las configuraciones de su dispositivo
          móvil. Tenga en cuenta que, si decide desactivar los servicios basados en la ubicación, esto podría afectar la
          funcionalidad completa de nuestros productos y servicios.
        </p>
        <h3>Derechos de privacidad en California: </h3>
        <p>
          La legislación de California permite a los usuarios residentes de California que soliciten y obtengan de
          nosotros, una vez por año, sin cargo, una lista de los terceros a quienes hemos divulgado nuestros datos
          personales (en caso de haberlo hecho) para fines de comercialización directa durante el año calendario previo,
          así como el tipo de datos personales divulgados a esos terceros. Salvo que este Aviso de privacidad establezca
          lo contrario, o de acuerdo con los datos en cuestión, CAI no vende datos personales a terceros para fines de
          comercialización de estos.
        </p>
      </div>
      <div id="international_data_transfers_and_privacy_shield">
        <h2>Transferencias internacionales de datos y el Marco de Privacidad de Datos: &ndash;</h2>
        <p>
          CAI se encuentra en los Estados Unidos y tiene oficinas a nivel mundial. Para facilitar nuestras operaciones y
          ayudar en la entrega de nuestros productos y servicios, podemos transferir datos personales a los EE. UU. y
          cualquier otro país donde operen nuestras filiales, vendedores, consultores o proveedores de servicios. Dichos
          países pueden tener leyes que son diferentes y que, posiblemente, no brinden la protección de las leyes de su
          país de residencia. Si usted es un Controlador de datos que usa CAI como Procesador de datos y si el contenido
          o los datos almacenados en el software, aplicaciones o sitios web de CAI, o a través de estos, contiene los
          datos personales de personas del Espacio Económico Europeo (”EEE”), usted acepta que tiene la facultad legal
          para transferir los datos personales a CAI, incluida la transferencia a países, como los Estados Unidos, donde
          las protecciones de privacidad y los derechos de las autoridades a acceder a los datos personales pueden no
          ser equivalentes a aquellos en el EEE.
        </p>
        <p>
          Cuando transferimos datos personales al exterior, tomamos las medidas de seguridad apropiadas para proteger la
          información de acuerdo con este Aviso de privacidad y buscamos garantizar que nosotros, junto con los
          receptores en el exterior, cumplamos con las leyes de privacidad aplicables. CAI se basa en las Cláusulas
          contractuales tipo (“SCC”) de la UE para la transferencia legal de datos personales entre sus entidades desde
          jurisdicciones sujetas al Reglamento General de Protección de Datos (“GDPR”) de la UE a los EE. UU. CAI se
          basa en el Acuerdo de Transferencia de Datos del Reino Unido para la transferencia legal de datos personales
          entre sus entidades desde jurisdicciones sujetas al Reglamento General de Protección de Datos del reino Unido
          (“UK GDPR”) a los EE. UU.
        </p>
        <p>
          Además de las SCC, si CAI toma conocimiento de que cualquier autoridad gubernamental (incluidas instituciones
          de orden público) desea acceder o copiar parte o todos los datos personales procesados por CAI, ya sea con
          carácter voluntario u obligatorio, con fines relacionados con la inteligencia de seguridad nacional, entonces,
          a menos que esté prohibido o una obligación legal exija lo contrario, CAI hará lo siguiente: 1) notificar de
          inmediato a la parte a quien corresponden los datos personales (es decir, el cliente o proveedor); 2) informar
          a la autoridad gubernamental pertinente que CAI no ha sido autorizada a divulgar los datos y, a menos que esté
          prohibido por ley, necesitará notificar de inmediato a la parte a quien corresponden los datos personales; 3)
          informar a la autoridad gubernamental que debe dirigir todas las solicitudes y demandas directamente a la
          parte a quien corresponden los datos personales; y 4) no brindar acceso a los datos personales hasta no tener
          una autorización por escrito de la parte a quien corresponden los datos personales o hasta que no esté
          legalmente obligado a hacerlo. Si está legalmente obligado a hacerlo, CAI tomará todas las medidas legales y
          razonables necesarias para objetar dicha prohibición u obligación. Si CAI está legalmente obligado a entregar
          los datos personales, CAI divulgará dichos datos únicamente en la medida legalmente necesaria, de conformidad
          con el proceso legal aplicable.
        </p>
        <p>
          CAI utiliza Adendas al Tratamiento de Datos (“DPA”) con los subprocesadores que procesan los datos personales
          en nombre de CAI cuando las leyes de privacidad aplicables lo requieren.
        </p>
        <p>
          Cuando transferimos datos personales desde el Espacio Económico Europeo (“EEE”), el Reino Unido o Suiza a los
          Estados Unidos, CAI cumple con las normas UE-EE.UU. Marco de Privacidad de Datos (UEEE.UU. DPF), la extensión
          del Reino Unido al Acuerdo UE-EE.UU. DPF y el Marco de Privacidad de Datos Suiza-EE. UU. (DPF Suiza-EE. UU.),
          según lo establecido por el Departamento de Comercio de EE. UU. CAI ha certificado ante el Departamento de
          Comercio de EE.UU. que se adhiere al Acuerdo UE-EE.UU. Principios del marco de privacidad de datos (Principios
          DPF UE-EE. UU.) con respecto al procesamiento de datos personales recibidos de la Unión Europea de conformidad
          con los Principios Marco de Privacidad UE-EE. UU. DPF y del Reino Unido (y Gibraltar) basándose en la
          extensión del Reino Unido a la UEEE.UU. FAP. CAI ha certificado ante el Departamento de Comercio de EE. UU.
          que se adhiere al Acuerdo Suiza-EE. UU. Principios del Marco de Privacidad de Datos (Principios DPF Suiza-EE.
          UU.) con respecto al procesamiento de datos personales recibidos de Suiza de conformidad con los Principios
          Marco de Privacidad Suiza-EE. UU. FAP. Si existe algún conflicto entre los términos de este Aviso de
          Privacidad y las normas UE-EE.UU. Principios DPF y/o los Principios Suizo-Estados Unidos. Principios del DPF,
          regirán los Principios. Para obtener más información sobre el programa Marco de privacidad de datos (DPF) y
          ver nuestra certificación, visite <b>https://www.privacyshield.gov/</b>.
        </p>
        <p>
          En cumplimiento de la UE-EE.UU. DPF, la extensión del Reino Unido a la UE-EE.UU. DPF y el gobierno
          suizo-estadounidense. DPF, CAI se compromete a resolver las quejas relacionadas con los Principios de DPF
          sobre nuestra recopilación y uso de su información personal. Personas de la UE, el Reino Unido y Suiza que
          tengan consultas o quejas sobre nuestro manejo de los datos personales recibidos de conformidad con las normas
          UE-EE.UU. DPF, la extensión del Reino Unido a la UE-EE.UU. DPF y/o el gobierno suizo-estadounidense. DPF debe
          comunicarse primero con CAI como se indica en la sección “Contáctenos” al final de este Aviso de Privacidad.
        </p>
        <p>
          Un individuo tiene la posibilidad, bajo ciertas condiciones, de invocar un arbitraje vinculante para quejas
          relacionadas con el cumplimiento del DPF no resueltas por ninguno de los otros mecanismos del DPF. Para
          obtener información adicional, consulte
          https://www.dataprivacyframework.gov/s/article/ANNEX-Iintroduction-dpf?tabset-35584=2. En cumplimiento de la
          UE-EE.UU. DPF, la extensión del Reino Unido a la UE-EE.UU. DPF y el gobierno suizo-estadounidense. DPF, CAI se
          compromete a remitir quejas no resueltas sobre nuestro manejo de datos personales recibidos de conformidad con
          las normas UE-EE.UU. DPF, la extensión del Reino Unido a la UE-EE.UU. DPF y/o el gobierno
          suizo-estadounidense. DPF a JAMS, un proveedor alternativo de resolución de disputas con sede en Estados
          Unidos. Si no recibe un acuse de recibo oportuno de su queja relacionada con los Principios DPF de nuestra
          parte, o si no hemos abordado su queja relacionada con los Principios DPF a su satisfacción, visite
          https://www.jamsadr.com/file-a-dpf -reclamar para obtener más información o presentar una queja. Los servicios
          de JAMS se proporcionan sin costo alguno para usted.
        </p>
        <p>
          La Comisión Federal de Comercio de los Estados Unidos tiene jurisdicción sobre el cumplimiento por parte de
          CAI de las normas UE-EE.UU. DPF, la extensión del Reino Unido al acuerdo UE-EE.UU. DPF y el gobierno
          suizo-estadounidense. FAP. Si CAI comparte datos personales recopilados en el EEE, el Reino Unido o Suiza con
          un proveedor de servicios externo que procesa los datos únicamente en nombre de CAI, entonces CAI será
          responsable del procesamiento de dichos datos por parte de ese tercero en violación de los Principios. , a
          menos que CAI pueda demostrar que no es responsable del hecho que dio lugar al daño.
        </p>
      </div>
      <div id="lawful_basis_for_processing">
        <h2>Fundamento legal para el procesamiento de datos personales (EEE/Reino Unido) &ndash;</h2>
        <p>
          Si usted se encuentra en el EEE o Reino Unido, nuestra base legal para la recopilación y el uso de datos
          personales dependerá de los datos personales en cuestión y del contexto específico en el que los recopilemos.
          Solo usaremos sus Datos personales para los fines para los que recopilamos dichos Datos personales según se
          detalla a continuación, a menos que necesitemos utilizarlos con posterioridad para otro fin que sea compatible
          con el fin original. Si necesitamos procesar aún más sus Datos personales para un fin que no es compatible con
          el fin original de recopilación, le notificaremos y brindaremos una explicación del fundamento legal que
          permite dicha acción. Sin embargo, generalmente nos basamos en las siguientes justificaciones legales para
          nuestras actividades de procesamiento: 1) celebración de acuerdos con usted o su empleador; 2) perseguir
          nuestros intereses legítimos, siempre que no anulen sus intereses, derechos y libertades; 3) su
          consentimiento; y 4) cumplimiento de una obligación legal. En algunos casos, también es posible que nos
          basemos en derogaciones de la Sección 49 del GDPR, tengamos una obligación legal de recopilar datos
          personales, o que necesitemos los datos personales por otro motivo para proteger sus intereses vitales o
          aquellos de otra persona. A continuación se brindan más detalles respecto del fundamento legal.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Fines para el procesamiento</strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Base legal para el procesamiento</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Procesar solicitudes y otros acuerdos para nuestros productos y servicios. </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento de sus datos personales es necesario para ejecutar un contrato o celebrar un
                      contrato con usted o su empleador.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Gestionar y administrar contratos, incluidos contratos de servicios con usted y su empleador. </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento de sus datos personales es necesario para que podamos cumplir con obligaciones
                      legales y reglamentarias.
                    </p>
                  </li>
                  <li>
                    <p>
                      El procesamiento es necesario para respaldar nuestros intereses legítimos en la administración de
                      nuestros negocios (o aquellos de un tercero) siempre que dichos intereses no se vean anulados por
                      sus intereses, derechos y libertados.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Mejorar nuestros productos y servicios, llevar a cabo investigación de mercado, realizar análisis de
                  datos, para fines de modelado de riesgos general, y para análisis estadístico.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento es necesario para respaldar nuestros intereses legítimos en la administración de
                      nuestros negocios (o aquellos de un tercero) siempre que dichos intereses no se vean anulados por
                      sus intereses, derechos y libertados.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Comercialización </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Pediremos su consentimiento para el procesamiento de sus datos personales para comercialización,
                      el cual podrá retirar en cualquier momento.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Prevención y detección de fraude, lavado de dinero y otros delitos </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento de sus datos personales es necesario para que podamos cumplir con las
                      obligaciones legales y reglamentarias o según lo permita la ley aplicable.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Administrar nuestra relación con usted y entre usted y su empleador.</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento de sus datos personales es necesario para ejecutar un contrato o celebrar un
                      contrato con usted o su empleador.
                    </p>
                  </li>
                  <li>
                    <p>
                      El procesamiento es necesario para respaldar nuestros intereses legítimos en la administración de
                      nuestros negocios (o aquellos de un tercero) siempre que dichos intereses no se vean anulados por
                      sus intereses, derechos y libertados.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Brindar el beneficio de nuestros productos y servicios. </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      El procesamiento de sus datos personales es necesario para ejecutar un contrato o celebrar un
                      contrato con usted o su empleador.
                    </p>
                  </li>
                  <li>
                    <p>
                      El procesamiento es necesario para respaldar nuestros intereses legítimos en la administración de
                      nuestros negocios (o aquellos de un tercero) siempre que dichos intereses no se vean anulados por
                      sus intereses, derechos y libertados.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="children_policy">
        <h2>Privacidad de menores &ndash;</h2>
        <p>
          El negocio de CAI no está dirigido a menores y CAI no recopila ni solicita deliberadamente información a
          menores de 13 años. CAI no permite deliberadamente que esas personas usen sus productos o servicios. En caso
          de tomar conocimiento de que, inadvertidamente, recopilamos datos personales de un menor de 13 años,
          eliminaremos esa información lo más rápido posible. Si cree que podemos tener información acerca de su hijo/a,
          comuníquese con nosotros a través de los métodos que se enumeran a continuación en la sección “Contáctenos” al
          final de este Aviso de privacidad.
        </p>
      </div>
      <div id="changes_to_the_privacy_notice">
        <h2>Cambios al Aviso de privacidad &ndash;</h2>
        <p>
          Podemos modificar este Aviso de privacidad en cualquier momento, publicando el Aviso de privacidad modificado
          en nuestros sitios web. En la parte superior de este Aviso de privacidad se indica la fecha en que se revisó
          por última vez. Todos los términos modificados tienen efecto automáticamente después de que se publican
          inicialmente en nuestro sitio web, a menos que un cambio en cualquier ley aplicable requiera una modificación
          inmediata. Usted es responsable de controlar periódicamente este Aviso de privacidad para conocer cualquier
          cambio.
        </p>
      </div>
      <div>
        <h2>Quejas: &ndash;</h2>
        <p>
          Sin perjuicio de cualquier otro recurso administrativo o judicial que pueda tener, usted puede tener el
          derecho a presentar una queja ante las autoridades de protección de datos/regulatorias estatales locales, si
          cree que hemos infringido los requisitos de protección de datos o privacidad aplicables en el procesamiento de
          sus datos personales.
        </p>
        <h3>En el Reino Unido, puede contactarse con: </h3>
        <p>The Information Commissioner&rsquo;s Office</p>
        <p>
          Water Lane, Wycliffe House
          <br />
          Wilmslow &#8211; Cheshire SK9 5AF
        </p>
        <p>Tel. +44 1625 545 700</p>
        <p>
          Correo electrónico: <b>casework@ico.org.uk</b>
        </p>
        <p>
          Sitio web: <b>https://ico.org.uk</b>
        </p>
        <h3>Para la UE, consulte:</h3>
        <p>
          <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
        </p>
      </div>
      <div id="contact_us">
        <h2>Contáctenos &ndash;</h2>
        <p>
          Para contactar a CAI con preguntas o inquietudes respecto de este Aviso de privacidad o las prácticas
          concernientes a los datos personales, use la información de contacto a continuación:
        </p>
        <p>
          Si usted reside en el EEE, tenga en cuenta que, si bien CAI actúa como controlador de datos responsable de sus
          datos personales, la entidad legal es Command Alkon Incorporated en los Estados Unidos. CAI responderá todas
          las preguntas y quejas relacionadas con este Aviso de privacidad dentro de un período razonable.
        </p>
        <p>
          <strong>Correo electrónico:</strong>
        </p>
        <p>
          <b>privacy@commandalkon.com</b>
        </p>
        <p>
          <strong>O escriba a:</strong>
        </p>
        <p>Command Alkon Incorporated</p>
        <p>Chief Privacy Officer c/o The Legal Department</p>
        <p>
          6750 Crosby Court
          <br />
          Dublin, Ohio 43016, USA
        </p>
        <p>
          <strong>O llame al: </strong>
        </p>
        <p>1-800-624-1872 (línea gratuita en EE. UU.)</p>
        <p>0-800-022-9682 (línea gratuita internacional) </p>
        <p>
          <strong>Sitio web:</strong>
        </p>
        <p>
          <b>www.commandalkon.com</b>
        </p>
      </div>
    </Styled>
  );
};
