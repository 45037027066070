import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Core, Localization, Typography } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;
export const TruckNumber = props => {
  const navigate = useNavigate();

  const [truckNumber, setTruckNumber] = React.useState('');

  const handleClick = React.useCallback(() => {
    navigate(truckNumber);
  }, [navigate, truckNumber]);

  const inputRef = React.useRef();

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Styled className={cn('truck-number')}>
      <div className="label">
        <Typography.H2>
          <Localization.Translate stringId="enterTruckNumber" data-testid="label" />
        </Typography.H2>
      </div>
      <div>
        <Core.Input onChange={setTruckNumber} value={truckNumber} ref={inputRef} />
      </div>
      <div>
        <Core.Button type="primary" onClick={handleClick} stringId="setTruckNumber" data-testid="truck-number-button" />
      </div>
    </Styled>
  );
};
