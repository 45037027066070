import React from 'react';
import { find } from 'lodash';
import * as yup from 'yup';
import { useProductSetup } from '../MasterDataProvider';

export const useMaterialServiceSchema = loadedQuantity => {
  const { data: products } = useProductSetup();

  const schema = React.useMemo(() => {
    return yup.object().shape({
      isPrimary: yup.boolean(),
      driverDidNotAdd: yup.boolean(),
      item: yup.object().required('required'),
      quantity: yup.object().when(['item', 'driverDidNotAdd'], {
        is: (item, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          return true;
        },
        then: schema =>
          schema.shape({
            value: yup.number().required('required'),
            uomCode: yup.string().required('required'),
          }),
        otherwise: schema => schema,
      }),
      reason: yup.object().when(['item', 'driverDidNotAdd'], {
        is: (item, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: item?.productRef });
          return targetProduct?.mobileTicket?.isReasonFieldEnabled;
        },
        then: schema => schema.required('required'),
        otherwise: schema => schema,
      }),
      concreteOnTruck: yup.object().when(['item', 'driverDidNotAdd'], {
        is: (item, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: item?.productRef });
          return targetProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled;
        },
        then: schema =>
          schema.shape({
            value: yup
              .number()
              .max(loadedQuantity.value, {
                key: 'exceedsLoadedQuantity',
                values: { loadedQuantity: loadedQuantity.value },
              })
              .required('required'),
            uomCode: yup.string().required('required'),
          }),
        otherwise: schema => schema,
      }),
      time: yup.mixed().when(['item', 'driverDidNotAdd'], {
        is: (item, driverDidNotAdd) => {
          if (driverDidNotAdd) {
            return false;
          }
          const targetProduct = find(products, { crn: item?.productRef });
          return targetProduct?.mobileTicket?.isTimeFieldEnabled;
        },
        then: schema => schema.required('required'),
        otherwise: schema => schema,
      }),
    });
  }, [loadedQuantity.value, products]);

  return schema;
};
