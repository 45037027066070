import { css } from 'styled-components';

export default css`
  .ant-tabs {
    flex: 1;
    .ant-tabs-content-holder {
      display: flex;
      flex-direction: column;
      flex: 1;
      .ant-tabs-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        .ant-tabs-tabpane {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
  }
`;
