import { get } from 'lodash';
import constants from '../constants';

export class Gt {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token, context } = params;
    if (params?.token?.startsWith?.(constants.GT)) {
      const sides = token.slice(4);
      const [path, leftSide] = sides.split(',');

      const valueAtPath = get(context, path);

      if (!valueAtPath) {
        return constants.EMPTY;
      }

      return valueAtPath > leftSide ? '' : constants.EMPTY;
    }

    return this?.next?.handle?.(params);
  }
}
