import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { PrivacyPolicyEN_US } from './PrivacyPolicyEN_US';
import { PrivacyPolicyES_CO } from './PrivacyPolicyES_CO';
import { PrivacyPolicyDE } from './PrivacyPolicyDE';
import { PrivacyPolicyPT } from './PrivacyPolicyPT';
import { PrivacyPolicyNL_NL } from './PrivacyPolicyNL_NL';
import { PrivacyPolicyFR_FR } from './PrivacyPolicyFR_FR';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicy = ({ userLocale }) => {
  const PrivacyPolicyForm = React.useMemo(() => {
    if (userLocale === 'en-US') {
      return PrivacyPolicyEN_US;
    }
    if (userLocale === 'es-CO') {
      return PrivacyPolicyES_CO;
    }
    if (userLocale === 'de') {
      return PrivacyPolicyDE;
    }
    if (userLocale === 'nl-NL') {
      return PrivacyPolicyNL_NL;
    }
    if (userLocale === 'fr-FR') {
      return PrivacyPolicyFR_FR;
    }
    if (userLocale === 'pt') {
      return PrivacyPolicyPT;
    }
    return PrivacyPolicyEN_US;
  }, [userLocale]);

  return (
    <Styled className={cn('privacy')}>
      <PrivacyPolicyForm />
    </Styled>
  );
};
