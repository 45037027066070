import { css } from 'styled-components';

export default css`
  display: flex;
  height: 40px;
  width: 100%;
  margin: 10px;
  align-items: center;

  .left-side {
    flex: 1;
    display: flex;
    align-items: center;

    [data-testid='back-button'] {
      margin-right: 20px;
    }
  }

  .right-side {
    display: flex;
    align-items: center;

    & > * {
      &:not(:last-child) {
        margin-right: 15px;

        @media (min-width: 950px) {
          margin-right: 200px;
        }
      }
    }

    button[data-testid='submit-acceptance-button'] {
      position: fixed;
      right: 15px;

      @media (min-width: 950px) {
        right: 30px;
      }
    }
  }
`;
