import { processToken } from './handlers';
import { find, findIndex, get, keys, pickBy } from 'lodash';

const escapeRegexChars = str => str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&');

const getTokens = template => {
  const found = [];
  const rxp = /{([^}]+)}/g;
  let curMatch;

  while ((curMatch = rxp.exec(template))) {
    found.push(curMatch[1]);
  }

  return found;
};

const renderUnicode = text =>
  text?.replace?.(/\\u[\dA-F]{4}/gi, match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));

const getIndexes = context => {
  if (!context) {
    return [];
  }

  const lineItems = get(context, 'lineItems', []);
  const containers = get(context, 'containers', []);

  if (!lineItems) {
    return [];
  }

  const rows = [];

  lineItems.forEach((lineItem, lineItemIndex) => {
    if (!lineItem.isPrimary && lineItem.isSplitLoad) {
      const row = { lineItem: lineItemIndex };

      const itemId = get(lineItem, 'item.id');
      const container = find(containers, { item: { id: itemId } });
      if (container) {
        const containerIndex = findIndex(containers, { item: { id: itemId } });
        row.container = containerIndex;
      }

      rows.push(row);
    }
  });

  return rows;
};

const processPath = ({
  line,
  rows,
  templatePath = '',
  contextPath = '',
  isIndex = true,
  referenceNumberIncrement = 0,
}) => {
  const lines = [];

  if (line?.includes(templatePath)) {
    rows?.forEach?.((row, index) => {
      const numberSuffix = isIndex ? `[${row?.lineItem}]` : row?.lineItem + referenceNumberIncrement;
      const baseLine = String(line)
        .replaceAll(templatePath, `${contextPath}${numberSuffix}`)
        .replaceAll('{', `[${index + 1}] {`);

      lines.push({ load: row?.lineItem, value: baseLine });
    });
  }

  return lines;
};

const splitLoad = ({ context = {}, templateString = '' }) => {
  const rows = getIndexes(context);

  const newLines = [];

  templateString.split('\n')?.forEach?.(line => {
    const lineItems = processPath({ line, rows, templatePath: 'primaryLineItem', contextPath: 'lineItems' });
    const containers = processPath({ line, rows, templatePath: 'splitLoadContainer', contextPath: 'containers' });
    const matSales = processPath({
      line,
      rows,
      templatePath: 'splitLoadMatSalesText',
      contextPath: 'customData.productDetails.matSalesText',
      isIndex: false,
    });
    const referenceNumbers = processPath({
      line,
      rows,
      templatePath: 'splitLoadReferenceNumber',
      contextPath: 'customData.referenceNumber',
      isIndex: false,
      referenceNumberIncrement: 1,
    });
    newLines.push(...lineItems, ...containers, ...matSales, ...referenceNumbers);
  });

  const result = newLines
    ?.sort((a, b) => a?.load - b?.load)
    ?.reduce((acc, lineItem) => {
      if (!acc?.includes(`[${lineItem?.load}]`)) {
        return `${acc}\n${lineItem?.value}\n`;
      }

      return `${acc}${lineItem?.value}\n`;
    }, '');

  return result?.startsWith('\n') ? result : `\n${result}`;
};

const processTemplatePure = ({ context, templateString, util }) => {
  if (!templateString || !context) {
    return '';
  }

  const modifiedTemplateString = `${templateString}\n${splitLoad({ context, templateString })}`;

  const finalTemplateString = getTokens(modifiedTemplateString).reduce((currentTemplate, token) => {
    const value = processToken({ token, context, util });
    const pattern = escapeRegexChars(`{${token}}`);
    const regex = new RegExp(pattern, 'g');
    return currentTemplate.replace(regex, value);
  }, modifiedTemplateString);

  return renderUnicode(finalTemplateString);
};

export default processTemplatePure;
