import { camelCase, toUpper } from 'lodash';
import { Form } from 'connex-cds';
const { FIELD_TYPES } = Form;

export const languagesNames = [
  { languageName: 'dansk', variableName: 'Dansk', locale: 'da' },
  { languageName: 'german', variableName: 'German', locale: 'de' },
  { languageName: 'english', variableName: 'English', locale: 'en-US' },
  { languageName: 'britain', variableName: 'Britain', locale: 'en-GB' },
  { languageName: 'spanish', variableName: 'Spanish', locale: 'es-CO' },
  { languageName: 'french', variableName: 'French', locale: 'fr-FR' },
  { languageName: 'nederlands', variableName: 'Nederlands', locale: 'nl-NL' },
  { languageName: 'norsk', variableName: 'Norsk', locale: 'no-NO' },
  { languageName: 'svenska', variableName: 'Svenska', locale: 'sv-SE' },
  { languageName: 'italian', variableName: 'Italian', locale: 'it-IT' },
  { languageName: 'polish', variableName: 'Polish', locale: 'pl-PL' },
];

export const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const labelStringIdBullder = (defaultLabelStringId, companySetup) => {
  if (!companySetup?.data) {
    return defaultLabelStringId;
  }

  const [defaultKey] = Object.keys(companySetup?.data).filter(key => {
    if (key.includes('default') && key.includes('Language')) {
      return companySetup?.data[key];
    }

    return false;
  });

  if (defaultKey) {
    const variableName = defaultKey.replace(/(default)|Language$/gi, '');
    return `${variableName}${pascalCase(defaultLabelStringId)}Default`;
  }

  return defaultLabelStringId;
};

export const buildLanguageFields = ({ companySetup, multiline = false, prefix = 'Name' }) => {
  if (!languagesNames) {
    return [];
  }
  return languagesNames
    ?.filter(language => {
      if (!companySetup?.data) {
        return false;
      }

      const { variableName } = language;
      return companySetup?.data[`allow${variableName}Language`];
    })
    ?.map(language => {
      const { variableName, locale } = language;
      const languageDefault = companySetup?.data[`default${variableName}Language`];
      const labelString = languageDefault ? 'Default' : '';
      return {
        path: `i18n.${locale}`,
        labelStringId: `${variableName}${prefix}${labelString}`,
        testId: `name${variableName}`,
        dataType: FIELD_TYPES.string,
        hidden: true,
        multiline,
      };
    });
};
