import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Typography, Localization } from 'connex-cds';

const { Subtitle } = Typography;
const { Translate } = Localization;
const { Column, Container } = Layout;

const Styled = styled(Column)`
  padding: 5px;
  & > * {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  [data-testid='dynamicWaiverValue'] {
    textarea {
      height: 70px;
      width: 100%;
      border: 0;
      background: transparent;
    }
  }
`;

export const DynamicWaiverMobile = ({ showField }) => {
  const field = showField();

  return (
    <Styled className={cn('dynamic-waiver')}>
      <Column>
        <Container flex={1} style={{ padding: '5px 10px' }}>
          <Subtitle>
            <Translate stringId="dynamicWaiver" data-testid="dynamicWaiverLabel" />
          </Subtitle>
          <div className="dynamic-waiver-section" style={{ padding: '5px' }}>
            <div data-testid="dynamicWaiverValue">{field}</div>
          </div>
        </Container>
      </Column>
    </Styled>
  );
};
