import { Logger } from './logger';

export default (message, otherArgs = {}) => {
  const errorMessages = [];
  const { error, errorInfo, debugInfo } = otherArgs;
  const errorMessage = `${error?.message || ''} ${errorInfo?.componentStack || ''}`.trim() || JSON.stringify(message);

  try {
    if (debugInfo?.length) {
      debugInfo.forEach(([key, value]) => {
        errorMessages.push({ severity: 'debug', message: `${key} ${value}` });
      });
    }
    errorMessages.push({ severity: 'error', message: errorMessage });

    Logger.sendBatch(errorMessages);
  } catch (e) {
    // ignore
  }
};
