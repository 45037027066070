import { findIndex } from 'lodash';
import constants from '../constants';

export class Containers {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token, context } = params;

    if (token.includes(`${constants.SPLIT_LOAD_CONTAINER}.`)) {
      const primaryLineItemIndex = findIndex(context?.lineItems, { isPrimary: true });
      if (primaryLineItemIndex >= 0) {
        const regex = new RegExp(constants.SPLIT_LOAD_CONTAINER, 'g');
        const newToken = token.replace(regex, `containers[${primaryLineItemIndex}]`);

        return this?.next?.handle?.({ ...params, token: newToken });
      }
    }
    return this?.next?.handle?.(params);
  }
}
