import cn from 'classnames';
import { Core, Drawer, Form, Layout, Modal, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { EnterRecipientsEditor } from './recipients/enter-recipients/EnterRecipientsEditor';
import { useCompanySetup } from '../../../../../MasterDataProvider';
import { PrivacyPolicy } from './recipients/privacy-policy/PrivacyPolicy';

const { Button } = Core;
const { useDrawerContext } = Drawer;
const { Row } = Layout;

const Styled = styled.div`
  ${style}
`;

export const AcceptanceSignature = ({ ticketId, onCancel }) => {
  const translateMessage = Localization.useTranslateMessage();
  const privacyPolicyTitle = translateMessage('privacyPolicy');
  const { userLocale } = Localization.useLocalizationContext();
  const { Components, values, setFieldValue } = Form.useFormContext();
  const { closeDrawer, openDrawer } = useDrawerContext();
  const { openModal, closeModal } = Modal.useModalContext();
  const companySetup = useCompanySetup();

  const handleOkClick = React.useCallback(() => {
    setFieldValue('customerStatus', 'ACCEPTED');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleEnterRecipients = React.useCallback(() => {
    openDrawer({
      onClose: onCancel,
      titleStringId: 'enterRecipientsButton',
      component: <EnterRecipientsEditor ticketId={ticketId} handleAcceptCancel={onCancel} />,
      width: '100%',
    });
  }, []);

  const handlePrivacyPolicy = React.useCallback(() => {
    openModal({
      onClose: closeModal,
      titleStringId: privacyPolicyTitle,
      component: <PrivacyPolicy userLocale={userLocale} />,
    });
  }, [closeModal, openModal, privacyPolicyTitle, userLocale]);

  const disabled = React.useMemo(() => {
    return !values?.signature?.image;
  }, [values?.signature]);

  return (
    <Styled className={cn('signature-comment')}>
      <Components.Signature edit ticketId={ticketId} />

      <Row className="actions">
        <Row>
          <Button
            type="primary"
            danger
            onClick={onCancel}
            stringId="cancel-signature-button"
            data-testid="cancel-signature-button"
          />
          <Button
            type="primary"
            disabled={disabled}
            onClick={handleOkClick}
            stringId="submit-signature-button"
            data-testid="ok-button"
          />
        </Row>
      </Row>
      <div className="privacy-policy-link">
        <Core.Link onClick={handlePrivacyPolicy} testId="privacy-policy-link" stringId="privacyPolicy" />
      </div>
      {companySetup?.data?.isPDFRecipientListEnable && (
        <Row className="recipients">
          <Button
            onClick={handleEnterRecipients}
            stringId="enterRecipientsButton"
            data-testid="enter-recipients-button"
          />
          {values?.hasSentRecipients && (
            <span>
              <i className="fa-regular fa-check-circle iconValid" />
            </span>
          )}
        </Row>
      )}
    </Styled>
  );
};
