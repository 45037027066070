import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { connexions } from '../api';

export const useDeleteConnexion = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['users', entityRef];

  const mutation = useMutation({
    mutationFn: profileRef => connexions.deleteConnexion({ entityRef, profileRef }),
    onMutate: async response => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.filter?.(item => item.connexionRef !== response);
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, connexionRef: response?.connexionRef };
    },
    onError: (err, response, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
