import { Drawer, Form, Localization, usePostMessageContext } from 'connex-cds';
import React from 'react';
import { useDataStore } from './datastore/DataStore';
import { DriverAcceptance } from './DriverAcceptance';
import { useCompanySetup } from './MasterDataProvider';
import { useTicketContext } from './TicketContext';
import { useViewport } from '../../../../util/useViewport';
import { ToastContainer, useToast } from './register/notification/Toast';
import useDeviceDetect from './useDeviceDetect';
import { Logger } from '../../../../util/log/logger';

const FormSubmit = ({ children }) => {
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { setOnSubmit } = Form.useFormContext();
  const translateMessage = Localization.useTranslateMessage();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();
  const { reset, setSubmitted } = useTicketContext();
  const { setFieldValue } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const dimensions = useViewport();
  const toast = useToast();
  const { isMobile } = useDeviceDetect();

  const showToast = React.useCallback(() => {
    if (isMobile) {
      toast.error(translateMessage('errors.errorDefault'));
    }
  }, [isMobile, toast, translateMessage]);

  const saveTicket = React.useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      Logger.debug(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/FormSubmit.js - saveTicket: ${JSON.stringify(
          response
        )}`
      );
      sendMessage(JSON.stringify({ type: 'submit', ticket, response }));
      return response;
    },
    [sendMessage, submitTicket]
  );

  const handleCancel = React.useCallback(() => {
    setFieldValue('driverAcceptanceReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const onSubmit = React.useCallback(
    async values => {
      if (values?.signature?.image || values?.driverAcceptanceReason || values?.customerRejectReason) {
        try {
          const ticket = await saveTicket(values);
          setSubmitted(true);
          reset(!companySetup?.data?.keepTicketOpenAfterSubmission === true);
          return ticket;
        } catch (error) {
          showToast();
          sendMessage(JSON.stringify({ type: 'debug', ticket: values, error: error.message }));
          Logger.error(
            `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/FormSubmit.js - onSubmit: ${error.message}`
          );
          throw error;
        }
      }

      openDrawer({
        titleStringId: 'driverAcceptance',
        component: <DriverAcceptance />,
        width: Math.min(750, dimensions?.width),
        onClose: handleCancel,
      });
    },
    [
      companySetup?.data?.keepTicketOpenAfterSubmission,
      dimensions?.width,
      handleCancel,
      openDrawer,
      reset,
      saveTicket,
      sendMessage,
      setSubmitted,
    ]
  );

  React.useEffect(() => {
    setOnSubmit(onSubmit);
  }, [onSubmit, setOnSubmit]);

  return (
    <>
      <ToastContainer duration={2000} />
      {children}
    </>
  );
};

export default FormSubmit;
