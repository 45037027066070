export const showWhatFix = ({
  userLocale = 'en-US',
  entityRef = '',
  user = {},
  userApps = [],
  isCompanyAdmin = false,
}) => {
  window.whatFix = {
    entityRef,
    username: user?.profileRef || '',
    userapps: userApps,
    preferredLocale: userLocale,
    isCompanyAdmin,
  };

  const whatFixContainer = document.getElementById('whatfix-container');

  if (!whatFixContainer || whatFixContainer?.hasChildNodes()) {
    return;
  }

  const newScript = document.createElement('script');

  // We are going to use a single whatfix url for stage and prod
  newScript.setAttribute('id', 'whatfix');
  newScript.setAttribute('language', 'javascript');
  newScript.setAttribute('async', 'true');
  newScript.setAttribute('type', 'text/javascript');
  newScript.setAttribute(
    'src',
    '//cdn.whatfix.com/prod/9397a453-c3ec-4406-ba7e-3ed2fb3df197/initiator/initiator.nocache.js'
  );

  document.getElementById('whatfix-container').appendChild(newScript);
};

export const removeWhatFix = () => {
  const whatFixContainer = document.getElementById('whatfix-container');

  if (!whatFixContainer || !whatFixContainer?.hasChildNodes()) {
    return;
  }

  window.whatFix = undefined;
  whatFixContainer.removeChild(whatFixContainer.firstElementChild);
};
