import { Core, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const { Timeline } = Core;
const { Translate } = Localization;

const Styled = styled(Layout.Container)`
  ${style}
`;
export const Desktop = ({ items }) => {
  return (
    <Styled className={cn('simple-grid-story')}>
      <div>
        <Typography.Subtitle>
          <Translate stringId="status" data-testid="statusLabel" /> (
          <Localization.TimeZone date={items[0]?.isoDateString} />)
        </Typography.Subtitle>
      </div>

      <Timeline items={items} noData={<div>No Data</div>} desc />
    </Styled>
  );
};
