import { Form, Localization } from 'connex-cds';
import React from 'react';
import { Styled } from './style';

const testId = 'activityGrid-totalPumpedQuantity';

export const TotalPumpedQuantity = () => {
  const { values } = Form.useFormContext();

  if (!values?.totalPumpedQuantity) {
    return null;
  }

  return (
    <Styled className="total-pumped-quantity-mobile">
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            Total Pumped Quantity
          </td>
        </tr>
        <tr>
          <td className="header-cell" data-testid={`${testId}-activity-value`}>
            <Localization.Translate stringId="quantity" data-testid="quantity-label" />
          </td>
          <td data-testid={`${testId}-quantity-value`}>
            <Localization.Uom uom={values?.totalPumpedQuantity} />
          </td>
        </tr>
      </table>
    </Styled>
  );
};
