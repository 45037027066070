import { Localization } from 'connex-cds';
import React from 'react';

export const CopyFromSelectCustomValues = ({ onChange, options = {} }) => (
  <select onChange={onChange}>
    <option value="">
      <Localization.Translate stringId="copyFrom" data-testid="copy-from-default" />
    </option>
    {options?.map(entity => (
      <option value={entity?.entityRef} key={entity?.entityRef}>
        {entity?.name}
      </option>
    ))}
  </select>
);
