import { Responsive } from 'connex-cds';
import React from 'react';
import { SignatureContainerMobile } from './SignatureContainerMobile';
import { SignatureContainerDesktop } from './SignatureContainerDesktop';

export const SignatureContainer = () => {
  return (
    <Responsive>
      <SignatureContainerMobile />
      <SignatureContainerDesktop />
    </Responsive>
  );
};
