import { Form, Localization } from 'connex-cds';
import * as Yup from 'yup';
import React from 'react';
import { find } from 'lodash';

const fields = (roles, carriers) => ({
  path: 'users',
  labelStringId: 'users',
  testId: 'users',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    username: Yup.string().required('required'),
    role: Yup.object().required('required'),
    carriers: Yup.array().min(1, 'required'),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    inviteLanguage: Yup.string().required('required'),
  }),

  fields: [
    {
      path: 'username',
      labelStringId: 'emailOrPhoneNumber',
      hidden: true,
      allowedModes: Form.MODES.CREATE,
      testId: 'username',
      dataType: Form.FIELD_TYPES.string,
    },
    { path: 'lastName', labelStringId: 'family_name', testId: 'family_name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given_name', dataType: Form.FIELD_TYPES.string },
    { path: 'email', labelStringId: 'email', testId: 'email', dataType: Form.FIELD_TYPES.string },
    { path: 'phone', labelStringId: 'phoneNumber', testId: 'phone', dataType: Form.FIELD_TYPES.string },
    {
      path: 'role',
      valuePath: 'roleRef',
      displayPath: 'name',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'role',
      testId: 'role',
      filterFn: (item, filterStrings) => {
        return item?.crn?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {roles?.map?.(role => (
              <option value={role.crn} key={role.crn}>
                {' '}
                {role.name}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-role'} data-testid={`row-${props.row.crn}-column-role`}>
          {props.row.role?.name}
        </div>
      ),
    },
    {
      path: 'carriers',
      labelStringId: 'carriers',
      testId: 'carriers',
      dataType: Form.FIELD_TYPES.array,
      arrayOf: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {carriers?.map?.(carrier => (
              <option value={carrier.crn} key={carrier.crn}>
                {' '}
                {carrier.name}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-carriers'} data-testid={`row-${props.row.crn}-column-carriers`}>
          {props.row.carriers?.map?.(carrier => find(carriers, { crn: carrier })?.name)?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'inviteLanguage',
      labelStringId: 'inviteLanguage',
      testId: 'inviteLanguage',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        // { id: 'da', label: 'Dansk' },
        //  { id: 'de', label: 'Deutsch' },
        { id: 'en-US', label: 'English (US)' },
        { id: 'fr-FR', label: 'Français' },
        { id: 'nl-NL', label: 'Nederlands' },
        // { id: 'no-NO', label: 'Norsk' },
        // { id: 'sv-SE', label: 'Svenska' },
      ],
      hidden: true,
    },
    {
      path: 'createDate',
      labelStringId: 'createDate',
      testId: 'createDate',
      dataType: Form.FIELD_TYPES.string,
      formatter: props => (
        <div data-testclass={'cell-column-createDate'} data-testid={`row-${props.row.crn}-column-createDate`}>
          {props.row.createDate?.substring(0, 10)}
        </div>
      ),
    },
    { path: 'status', labelStringId: 'status', testId: 'status', dataType: Form.FIELD_TYPES.string },
    {
      path: 'truckNumber',
      labelStringId: 'truckNumber',
      testId: 'truckNumber',
      dataType: Form.FIELD_TYPES.string,
      hidden: true,
    },
    {
      path: 'comments',
      labelStringId: 'comment',
      testId: 'comments',
      dataType: Form.FIELD_TYPES.string,
      multiline: true,
      hidden: true,
    },
  ],
});

export default fields;
