import { Form } from 'connex-cds';
import { get, isUndefined } from 'lodash';
import React from 'react';

const triggersLoss = (item, path) => {
  if (isUndefined(item.reason)) {
    return !!get(item, [path, 'isCertificationLossTrigger']);
  }

  return !!(get(item, [path, 'isCertificationLossTrigger']) && item?.reason?.isCertificationLossTrigger);
};

const lineItemTriggersLoss = lineItem => triggersLoss(lineItem, 'item');

const waterAddedEventTriggersLoss = event => triggersLoss(event, 'waterProduct');

export const useCertificationLoss = () => {
  const { values, setFieldValue } = Form.useFormContext();

  React.useEffect(() => {
    if (values?.lineItems?.some(lineItemTriggersLoss) || values?.waterAddedEvents?.some(waterAddedEventTriggersLoss)) {
      setFieldValue('isCertificationLost', true);
    } else {
      setFieldValue('isCertificationLost', false);
    }
  }, [setFieldValue, values?.lineItems, values?.waterAddedEvents]);

  return {};
};
