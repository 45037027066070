import { omit } from 'lodash';

export const cleanupLineItems = (errors, values, ...keysToOmit) => {
  let errorsToCheck = omit(errors, ...keysToOmit);

  const itemsNotSuggestedByDriver = values?.lineItems?.reduce((acc, el, pos) => {
    if (!el?.suggestedToDriver) acc.push(`lineItems[${pos}]`);
    return acc;
  }, []);

  if (itemsNotSuggestedByDriver?.length) {
    errorsToCheck = omit(errors, ...keysToOmit, ...itemsNotSuggestedByDriver);

    const lineItemsErrors = errorsToCheck?.lineItems?.filter(Boolean);
    if (!lineItemsErrors?.length) {
      delete errorsToCheck.lineItems;
    }
    if (lineItemsErrors?.length) {
      errorsToCheck.lineItems = lineItemsErrors;
    }
  }

  return errorsToCheck;
};
