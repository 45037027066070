export default `
General Delivery Info:
================================
Plant Name: {origin.description}
SAP Delivery Number: {sapDeliveryNumber}
Sales Order Number: {salesOrderNumber}
Date and time W/C contact: {dateTime::wcContactDateTime}
Customer Id: {customerParty.id}
PO Number: {purchaseOrder}
Customer PO Number: {customerPoNumber}
Order placed by name: {orderPlacedBy}
Order placed by phone number: {orderPlacedByPhone}
Resource Id:  {vehicleId}
Loaded Qty: {loadedQuantity}
Total Loaded Qty: {totalLoadedQuantity}
Ordered Qty: {orderedQuantity} 
Load Spacing: {loadSpacing}
Delivery Flow: {deliveryFlow}
Unloading Method: {unloadingMethod}
Total on site time/ordered unloading time: {unloadingTime}

Material Info
================================
Material:  {productId} / {productDescription} 
Sales text (material ID): {matSalesText}
Strength Class: {strengthClass}
Environment Exposure: {environmentExposure}
Met Exposure Classes: {metExposureClass}
Dmax: {dmax}
Standard Cert: {standardCert}
Specification: {specification}
Workability Target: {workabilityTarget}
Stone Quality: {stoneQuality}
Sand Quality: {sandQuality}
Target WC: {targetWc}
Control Class: {controlClass}
Chloride Class: {chlorideClass}
Type And Strength1: {strengthClass1}
Type And Strength2: {strengthClass2}
Type Of Addition: {additionType}
Type Of Admixture: {admixtureType}
Ordered Temp: {orderedTemp}
Type and content of fibers 1: {fiber1Type}
Type and content of fibers 2: {fiber2Type}
Calculated Maturity Time: {calculatedMaturityTime}
Mix Identity: {mixIdentity}
Grade: {grade}
Mix Type: {mixType}
Max Agg Size: {maxAggSize}
Max Agg Type: {maxAggType}
Cement Type: {cementType}
Admixtures: {admixtures}
Admix Description: {addmixDescription}
Slump: {slump}
Min Cement Content: {minCementContent}
Max Water/Cement Ratio: {maxWatCemRatio}
Max Water to Add: {maxWaterToAdd}
Mortar Min Working Life: {mortarMinWorkingLife}
Code: {code}
dcClass: {dcClass}
Chloride: {chloride}
Signed By Hanson: {signedByHanson}
Warm Stamp: {warmStamp}
Cold Stamp: {coldStamp}
Contains Sikatard: {containsSikatard}
Contains Delvo: {containsDelvo}

Ticket Events
================================
Printed: {dateTime::printed}
To Job: {dateTime::toJob}
Arrive Job: {dateTime::arriveJob}
Ready To Pump: {dateTime::readyToPump}
Unloading: {dateTime::unloading}
Pumping Started: {dateTime::pumpingStarted}
End Unloading: {dateTime::endUnloading} 
Pumping Complete: {dateTime::pumpingComplete}
Leave Job: {dateTime::leaveJob}
In Yard: {dateTime::inYard}
`;
