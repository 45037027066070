import { Core, Layout, usePostMessageListener } from 'connex-cds';
import React from 'react';

const sendMessage = message => {
  window.postMessage(message, '*');
};

const options = [
  { id: 'PRINTED', label: 'PRINTED' },
  { id: 'LOADING_STARTED', label: 'LOADING_STARTED' },
  { id: 'LOADING_COMPLETE', label: 'LOADING_COMPLETE' },
  { id: 'TO_JOB', label: 'TO_JOB' },
  { id: 'ARRIVE_JOB', label: 'ARRIVE_JOB' },
  { id: 'UNLOADING', label: 'UNLOADING' },
  { id: 'END_UNLOADING', label: 'END_UNLOADING' },
  { id: 'LEAVE_JOB', label: 'LEAVE_JOB' },
  { id: 'IN_YARD', label: 'IN_YARD' },
  { id: 'WASHING', label: 'WASHING' },
  { id: 'END_WASHING', label: 'END_WASHING' },
  { id: 'PUMPING_STARTED', label: 'PUMPING_STARTED' },
  { id: 'PUMPING_COMPLETE', label: 'PUMPING_COMPLETE' },
  { id: 'READY_TO_PUMP', label: 'READY_TO_PUMP' },
];

export const DevTools = ({ isMobile, setIsMobile }) => {
  const [isOnline, setIsOnline] = React.useState(true);
  const [currentEvent, setCurrentEvent] = React.useState();
  const [crn, setCrn] = React.useState();
  const [quantity, setQuantity] = React.useState();
  const [quantityWaterAdd, setQuantityWaterAdd] = React.useState();

  const handleMobileToggle = React.useCallback(() => {
    setIsMobile(s => !s);
  }, [setIsMobile]);

  const handleOnlineToggle = React.useCallback(val => {
    setIsOnline(val);
    sendMessage({ type: 'online', value: val });
  }, []);

  const handleEventChange = React.useCallback(eventId => {
    setCurrentEvent(eventId);
  }, []);

  const sendEvent = React.useCallback(() => {
    sendMessage({
      type: 'status-event',
      timestamp: new Date().toISOString(),
      name: currentEvent,
    });
  }, [currentEvent]);

  const handleCancelTicket = React.useCallback(() => {
    sendMessage({
      type: 'ticket',
      active: false,
    });
  }, []);

  const handleSendTicket = React.useCallback(() => {
    sendMessage({
      type: 'ticket',
      active: true,
      crn,
      number: 'asdf',
    });
  }, [crn]);

  const handleSendReturnedMaterial = React.useCallback(() => {
    sendMessage({
      type: 'leftoverConcrete',
      concreteVolume: Boolean(String(quantity).trim()) ? Number(quantity) : '',
      concreteUom: 'YDQ',
      source: 'PROBE',
    });
  }, [quantity]);

  const handleSendWaterAdd = React.useCallback(() => {
    sendMessage({
      type: 'waterAdd',
      waterAddQuantity: Number(quantityWaterAdd),
      waterAddUom: 'YDQ',
      source: 'PROBE',
    });
  }, [quantityWaterAdd]);

  const listener = React.useCallback(message => {
    if (message?.type === 'submit') {
      sendMessage({ type: 'ticket', active: false });
    }
  }, []);

  usePostMessageListener(listener);

  return (
    <Layout.Container className="dev-tools-section">
      <div style={{ display: 'flex', gap: '20px' }}>
        <Core.Switch
          onChange={handleOnlineToggle}
          checkedStringId="online"
          uncheckedStringId="offline"
          data-testid="online-toggle"
          value={isOnline}
        />
        <Core.Switch
          onChange={handleMobileToggle}
          checkedStringId="Mobile"
          uncheckedStringId="Desktop"
          data-testid="mobile-toggle"
          value={isMobile}
        />
      </div>
      <div className="input-and-button events">
        <Core.Select options={options} onChange={handleEventChange} value={currentEvent} />
        <Core.Button stringId="Send Event" data-testid="send-event" onClick={sendEvent} disabled={!currentEvent} />
      </div>
      <div className="input-and-button ticket">
        <Core.Input onChange={setCrn} value={crn} placeholder="Crn" />
        <Core.Button stringId="Send Ticket" data-testid="send-ticket" onClick={handleSendTicket} disabled={!crn} />
        <Core.Button stringId="Cancel Ticket" data-testid="cancel-ticket" onClick={handleCancelTicket} />
      </div>
      <div className="input-and-button probe">
        <Core.Input onChange={setQuantity} placeholder="Quantity" />
        <Core.Button
          stringId="Send Returned Material"
          data-testid="send-returned-material"
          onClick={handleSendReturnedMaterial}
          disabled={!quantity}
        />
      </div>
      <div className="input-and-button probe">
        <Core.Input onChange={setQuantityWaterAdd} placeholder="Quantity" />
        <Core.Button
          stringId="Water Add"
          data-testid="send-water-add"
          onClick={handleSendWaterAdd}
          disabled={!quantityWaterAdd}
        />
      </div>
    </Layout.Container>
  );
};
