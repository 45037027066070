import { http } from 'connex-cds';

export default {
  getEntity: ({ entityRef, truckNumber }) =>
    http.get({
      apiName: 'mt',
      path: `/entities/${entityRef}/${truckNumber}`,
    }),
  getTranslations: ({ entityRef }) => http.get({ apiName: 'mt', path: `/language-tokens/sources/${entityRef}` }),
};
