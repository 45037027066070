import { css } from 'styled-components';
import { calculateAppGridHeight } from '../style';

export default css`
  padding: 15px 20px;
  height: ${({ $apps }) => `${calculateAppGridHeight($apps) + 30}px`};
  overflow: auto;

  @media screen and (max-width: 430px) {
    height: 80vh;
    padding: 20px 14px;
  }

  .app-icons-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding-bottom: 14px;
    @media screen and (max-width: 430px) {
      gap: 10px;
    }

    .app-icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60px;
      height: 60px;
      cursor: pointer;
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;
      @media screen and (max-width: 430px) {
        width: 103px;
        height: 103px;
      }

      .app-name-default {
        position: absolute;
        color: white;
        top: 36px;
        @media screen and (max-width: 430px) {
          top: 64px;
          font-size: 15px;
          line-height: 15px;
        }
      }

      img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        object-fit: contain;
        @media screen and (max-width: 430px) {
          width: 103px;
          height: 103px;
        }
      }
    }
  }
`;
