import { ToastContainer as ToastifyContainer, Slide as ToastifySlide } from 'react-toastify';
import PropTypes from 'prop-types';

import 'react-toastify/dist/ReactToastify.css';
import './overrides.css';

const ToastContainer = ({ duration }) => {
  return <ToastifyContainer hideProgressBar transition={ToastifySlide} autoClose={duration || 1000} />;
};

ToastContainer.propTypes = {
  duration: PropTypes.number,
};

export default ToastContainer;
