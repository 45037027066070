import { Responsive } from 'connex-cds';
import React from 'react';
import { MaterialComplianceMobile } from './MaterialComplianceMobile';
import { MaterialComplianceDesktop } from './MaterialComplianceDesktop';

export const MaterialCompliance = () => {
  // const {
  //   values,
  //   setFieldValue,
  // } = Form.useFormContext();

  // Originally HC wanted this logic then they said to make it a manual checkbox
  // ...commenting out for now in case they change their mind again
  //
  // const materialComplianceSetup = useMaterialComplianceSetup();
  //
  // const airCompliance = React.useMemo(() => {
  //   if (materialComplianceSetup.isSuccess) {
  //     return (
  //       materialComplianceSetup?.data
  //         ?.filter?.(compliance => compliance.status === 'ACTIVE')
  //         ?.filter?.(compliance => compliance.classType === 'AIR') || []
  //     );
  //   }
  //   return [];
  // }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);
  //
  // const consistenceCompliance = React.useMemo(() => {
  //   if (materialComplianceSetup.isSuccess) {
  //     return (
  //       materialComplianceSetup?.data
  //         ?.filter?.(compliance => compliance.status === 'ACTIVE')
  //         ?.filter?.(compliance => compliance.classType === 'CONSISTENCE') || []
  //     );
  //   }
  //   return [];
  // }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);
  //
  // React.useEffect(() => {
  //   if (
  //     values?.productCompliance?.consistenceValue >= consistenceCompliance?.[0]?.minimumValue &&
  //     values?.productCompliance?.consistenceValue <= consistenceCompliance?.[0]?.maximumValue
  //   ) {
  //     setFieldValue('productCompliance.consistenceIsCompliant', true);
  //   } else {
  //     setFieldValue('productCompliance.consistenceIsCompliant', false);
  //   }
  // }, [setFieldValue, values?.productCompliance?.consistenceValue]);
  //
  // React.useEffect(() => {
  //   if (
  //     values?.productCompliance?.airContentValue >= airCompliance?.[0]?.minimumValue &&
  //     values?.productCompliance?.airContentValue <= airCompliance?.[0]?.maximumValue
  //   ) {
  //     setFieldValue('productCompliance.airContentIsCompliant', true);
  //   } else {
  //     setFieldValue('productCompliance.airContentIsCompliant', false);
  //   }
  // }, [setFieldValue, values?.productCompliance?.airContentIsCompliant, values?.productCompliance?.airContentValue]);

  return (
    <Responsive>
      <MaterialComplianceMobile />
      <MaterialComplianceDesktop />
    </Responsive>
  );
};
