import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../../components/core';
import { useClickOutside } from '../../../util/useClickOutside';
import { AppIcons } from './app-icons/AppIcons';
import style from './style';
import MenuIcon from '../menu-icon/MenuIcon';
import ResponsiveSlide from './ResponsiveSlide';

const Styled = styled.div`
  ${style}
`;

export const AppSwitcher = ({ onChange, userApps, isLoading, appContext }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  React.useEffect(() => {
    const handler = e => {
      if (isOpen && e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [isOpen]);

  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <>
      <Styled className={cn('app-switcher')} $apps={userApps} ref={ref}>
        <MenuIcon iconName="Apps" onClick={toggle} active={isOpen} />
        <div
          className={cn('overlay', { open: isOpen })}
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <div className={cn('menu-container')}>
          <ResponsiveSlide direction="up" in={isOpen} mountOnEnter unmountOnExit>
            <div className={cn('app-tray', { active: isOpen })}>
              <div className="app-tray-inner">
                <div className="tray-title">
                  <div className="tray-title-text">App Tray</div>
                </div>
                <Spinner spin={isLoading}>
                  <AppIcons apps={userApps} onClick={onChange} appContext={appContext} />
                </Spinner>
              </div>
            </div>
          </ResponsiveSlide>
        </div>
      </Styled>
    </>
  );
};
