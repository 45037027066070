import { useMemo } from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';

import PropTypes from 'prop-types';

import Icon from '../Icon';

import { ToastStyle } from './style';

const Styled = styled.div`
  ${ToastStyle}
`;

const Toast = ({ type, message }) => {
  const iconProps = useMemo(() => {
    let iconWeight = '';
    let iconType = '';

    if (type === 'SUCCESS') {
      iconWeight = 'fa-regular';
      iconType = 'circleCheck';
    } else if (type === 'ERROR') {
      iconWeight = 'fa-solid';
      iconType = 'circleExclamation';
    }

    return { iconWeight, iconType };
  }, [type]);

  return (
    <Styled
      className={cn({
        'toast-success': type === 'SUCCESS',
        'toast-error': type === 'ERROR',
      })}
    >
      <div className="content-wrapper">
        <p>{message}</p>
      </div>
      <div className="icon-wrapper">
        <Icon className={cn('icon')} {...iconProps} />
      </div>
    </Styled>
  );
};

Toast.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default Toast;
