import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const SearchFieldContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .MuiTextField-root {
      width: 100%;
    }
    .MuiInputBase-root {
      height: 40px;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: 0;
    }
    .collapse-search {
      cursor: pointer;
      display: flex;
    }
  `}
`;
