import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { tickets } from '../api';

export const useListTickets = () => {
  const { entityRef, truckNumber } = useParams();

  const query = useQuery({
    queryKey: ['tickets', entityRef, truckNumber],
    queryFn: tickets.listTickets,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};
