import React from 'react';
import { Slide } from '@mui/material';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

const ResponsiveSlide = ({ children, ...props }) => {
  const { isMobileXs } = useMediaQueries();

  return isMobileXs ? <Slide {...props}>{children}</Slide> : children;
};

export default ResponsiveSlide;
