import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Drawer, Form, Layout, Responsive } from 'connex-cds';
import { CommentsMobile } from './CommentsMobile';
import { CommentsDesktop } from './CommentsDesktop';
import { useTicketContext } from '../../../../TicketContext';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../../../../MasterDataProvider';
import { CommentEditor } from './CommentEditor';
import { useViewport } from '../../../../../../../../util/useViewport';
import { find } from 'lodash';

const Styled = styled(Layout.Column)`
  ${style}
`;

export const Comments = () => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { ticket } = useTicketContext();

  const dimensions = useViewport();

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const materialComplianceEnabled = React.useMemo(() => {
    if (!companySetup?.data?.isMaterialComplianceEnabled) {
      return false;
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialComplianceEnabled !== false &&
        vehicleTypeData?.isMaterialComplianceEnabled !== true
      ) {
        return false;
      }
    }
    return true;
  }, [companySetup?.data?.isMaterialComplianceEnabled, vehicleTypeData]);

  const handleCommentClick = React.useCallback(() => {
    if (!finalized || values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') return;

    openDrawer({
      titleStringId: 'customerComment',
      component: <CommentEditor onFinally={closeDrawer} />,
      width: Math.min(750, dimensions?.width),
    });
  }, [closeDrawer, dimensions?.width, finalized, openDrawer, values?.customerStatus]);

  return (
    <Styled className={cn('comments')}>
      <Responsive>
        <CommentsMobile
          companySetup={companySetup}
          materialComplianceEnabled={materialComplianceEnabled}
          handleCommentClick={handleCommentClick}
        />
        <CommentsDesktop
          companySetup={companySetup}
          materialComplianceEnabled={materialComplianceEnabled}
          handleCommentClick={handleCommentClick}
        />
      </Responsive>
    </Styled>
  );
};
