import { Form, usePostMessageContext } from 'connex-cds';

import { findIndex } from 'lodash';
import { useCallback } from 'react';
import { useDataStore } from './datastore/DataStore';
import { useTicketContext } from './TicketContext';
import { Logger } from '../../../../util/log/logger';
import { getCachedItem } from '../../../../util/cache';
const CACHE_KEY = 'tickets';

export const useAutomaticTicketSubmission = () => {
  const { reset, setSubmitted, submitted } = useTicketContext();
  const { values } = Form.useFormContext();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();

  const saveTicket = useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      Logger.debug(
        `Mobile Ticket -
        frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - saveTicket: ${JSON.stringify(
          response
        )}`
      );
      sendMessage(JSON.stringify({ type: 'submit', ticket, response }));
      return response;
    },
    [sendMessage, submitTicket]
  );

  const isWaitingForSubmission = useCallback(() => {
    const tickets = getCachedItem(CACHE_KEY);
    Logger.debug(
      `Checking  tickets in cache - 
      frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - isWaitingForSubmission: ${JSON.stringify(
        tickets
      )}`
    );
    return findIndex(tickets, { ticketRef: values?.ticketRef }) !== -1;
  }, [values?.ticketRef]);

  const ticketSubmission = useCallback(
    async keepTicketOpenAfterSubmission => {
      try {
        const isReadyToSubmit = [!submitted, !isWaitingForSubmission(), values?.customerStatus === 'ACCEPTED'].every(
          Boolean
        );
        if (isReadyToSubmit) {
          setSubmitted(true);
          Logger.debug(
            `Ready to submit ticket with the following values - 
            frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - ticketSubmission: ${JSON.stringify(
              values
            )}`
          );
          const ticket = await saveTicket(values);
          reset(!keepTicketOpenAfterSubmission === true);
          return ticket;
        }
      } catch (error) {
        sendMessage(
          JSON.stringify({
            type: 'debug',
            ticket: values,
            error: error.message,
          })
        );
        Logger.error(
          `Mobile Ticket -  frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - ticketSubmission: ${error.message}`
        );
        throw error;
      }
    },
    [isWaitingForSubmission, setSubmitted, saveTicket, sendMessage, Logger, reset, submitted, values]
  );
  return { ticketSubmission };
};
