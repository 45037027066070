import { Form, Localization } from 'connex-cds';
import React from 'react';
import { Styled } from './style';
import { useCompanySetup } from '../../../../../MasterDataProvider';

const testId = 'activityGrid-water';

export const WaterAdd = () => {
  const translateMessage = Localization.useTranslateMessage();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const waterEvents = React.useMemo(() => {
    return values.waterAddedEvents.filter(
      waterAddedEvent =>
        !waterAddedEvent.driverDidNotAdd || (companySetup?.data?.isWaterMandatory && waterAddedEvent.driverDidNotAdd)
    );
  }, [values.waterAddedEvents]);

  if (!values?.waterAddedEvents?.length) {
    return null;
  }

  return (
    <Styled className="water-add-mobile">
      {waterEvents?.map?.((waterEvent, index) => {
        const key = `SETUP_REASON-CODE_${waterEvent?.reason?.id}`.replace(/\s/gi, '_').toUpperCase();
        const translation = translateMessage(key);

        return (
          <table>
            <tr className="header-row">
              <td className="header-cell" colSpan="2">
                <Localization.Translate stringId="waterAdded" data-testid="water-added-label" />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="quantity" data-testid="quantity-label" />
              </td>
              <td data-testid={`${testId}-row-${index}-activity-value`}>
                <Localization.Uom uom={waterEvent.quantity} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="materialOnTruck" data-testid="water-added-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Uom uom={waterEvent.concreteOnTruck} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="reason" data-testid="reason-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-reason-value`}>
                {translation && translation !== key ? translation : waterEvent?.reason?.description}
              </td>
            </tr>
          </table>
        );
      })}
    </Styled>
  );
};
