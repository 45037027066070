import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization } from 'connex-cds';
import { get } from 'lodash';

import style, { StyledBody, StyledFooter, StyledHeader, StyledTable } from './style';
import { useGetCompanyAdmin } from '../../../../../../../../../query-hooks/company';

const { Translate, useCurrency } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'productsGrid';

export const Products = ({ productsData, values }) => {
  const { getCurrency } = useCurrency();
  const companyAdminSetup = useGetCompanyAdmin();

  const currencyString = companyAdminSetup?.data?.currency?.code || 'USD';

  for (let i = 0; i < productsData.length; i++) {
    if (!productsData[i].unitPrice) {
      const pricedLineItems = values?.pricing?.pricedLineItems;
      const targetPricedLineItem = pricedLineItems?.filter(
        lineItem => lineItem?.item?.productRef === productsData[i].item?.productRef
      );
      productsData[i].unitPrice = get(targetPricedLineItem, ['0', 'priceSummary', 'unitPrice'], 0);
    }
  }

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledTable className={cn('products-table')}>
        <StyledHeader>
          <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="paymentProducts" data-testid="products-label" />
          </div>
        </StyledHeader>
        <StyledBody>
          <div className={cn('products-row table-header')} data-testid={`${testId}-second-header-row`}>
            <Translate stringId="product" data-testid="product-label" />
            <Translate stringId="quantity" data-testid="quantity-label" />
            <Translate stringId="unitPrice" data-testid="unit-price-label" />
            <Translate stringId="amount" data-testid="amount-label" />
          </div>
          {productsData?.map((product, index) => {
            return (
              <div className={cn('products-row')} key={product.crn} data-testid={`${testId}-row-${index}`}>
                <div data-testclass="cell-column-product" data-testid={`${testId}-row-${index}-column-product`}>
                  {product?.item?.name ?? product?.item?.description}
                </div>
                <div data-testclass="cell-column-quantity" data-testid={`${testId}-row-${index}-column-quantity`}>
                  {`${product?.quantity?.value || 0} ${product?.quantity?.uomCode}`}
                </div>
                <div data-testclass="cell-column-unitPrice" data-testid={`${testId}-row-${index}-column-unitPrice`}>
                  {`${getCurrency(product?.unitPrice || 0, currencyString)}`}
                </div>
                <div data-testclass="cell-column-amount" data-testid={`${testId}-row-${index}-column-amount`}>
                  {`${getCurrency(product?.quantity?.value * (product?.unitPrice || 0), currencyString)}`}
                </div>
              </div>
            );
          })}
        </StyledBody>
        <StyledFooter>
          <div className={cn('products-row')}></div>
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
