import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;
export const PrivacyPolicyPT = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>
          AVISO DE PRIVACIDADE DA
          <br />
          COMMAND ALKON INCORPORATED
        </h4>
      </div>
      <div id="overview_content">
        <div>
          <p>
            <em>Última modificação: 26 de setembro de 2023</em>
          </p>
          <h2>Visão geral</h2>
          <p>
            A Command Alkon Incorporated, incluindo suas empresas relacionadas (“CAI” ou “nós”) é uma organização
            comercial internacional que fornece soluções de software, serviços e web para empresas no setor de
            transporte de alimentos semiprontos, agregados e a granel por meio de software como serviço (“SaaS”) no
            local e outros métodos baseados na web. A CAI respeita a privacidade dos usuários de seus produtos e
            serviços e tem o compromisso de proteger a privacidade e manter a confiança de seus usuários. Este Aviso de
            Privacidade descreve as nossas práticas para coleta, uso, armazenamento, transferência, compartilhamento e
            processamento de dados pessoais (referidos como Informações Pessoais) relacionadas ao seu uso dos produtos
            e/ou serviços, sites, aplicativos e páginas de redes sociais da CAI, assim como suas interações com a equipe
            da CAI em reuniões e eventos, incluindo vendas e atividades de marketing off-line. Leia este Aviso de
            Privacidade na íntegra para compreender nossas políticas e práticas quanto ao tratamento de dados pessoais.
            Ao acessar nosso site e/ou usar nossos produtos ou serviços, você consente com a coleta, o uso e a
            divulgação de dados pessoais conforme descrito neste Aviso de Privacidade. Caso tenha alguma dúvida ou
            preocupação sobre privacidade, entre em contato conosco através das informações na seção “Contato”, na parte
            inferior deste Aviso de Privacidade.
          </p>
          <h2>Sumário</h2>
          <ul>
            <li>
              <a href="#summary_of_privacy_notice">Resumo do Aviso de Privacidade </a>
            </li>
            <li>
              <a href="#controller_processor">Controlador/Processador</a>
            </li>
            <li>
              <a href="#to_whom_does_this_notice_apply">A quem este Aviso se aplica</a>
            </li>
            <li>
              <a href="#how_we_collect_personal_data">Como coletamos e usamos dados pessoais</a>
            </li>
            <li>
              <a href="#automatically_collected_information">Dados coletados automaticamente</a>
            </li>
            <li>
              <a href="#how_we_use_the_information_we_collect">Como usamos os dados que coletamos</a>
            </li>
            <li>
              <a href="#how_we_disclose_the_information_we_collect">Como divulgamos os dados que coletamos</a>
            </li>
            <li>
              <a href="#third_party_services">Serviços de terceiros</a>
            </li>
            <li>
              <a href="#security">Segurança</a>
            </li>
            <li>
              <a href="#retention_of_data">Retenção de dados</a>
            </li>
            <li>
              <a href="#your_rights_and_choices">Seus direitos e opções</a>
            </li>
            <li>
              <a href="#international_data_transfers_and_privacy_shield">
                Transferências internacionais de dados e Estrutura de Privacidade de Dados
              </a>
            </li>
            <li>
              <a href="#lawful_basis_for_processing">Justificativa legal para o processamento</a>
            </li>
            <li>
              <a href="#children_policy">Privacidade de crianças</a>
            </li>
            <li>
              <a href="#changes_to_the_privacy_notice">Alterações do Aviso de Privacidade</a>
            </li>
            <li>
              <a href="#contact_us">Contato</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="summary_of_privacy_notice">
        <div>
          <h4>Resumo do Aviso de Privacidade </h4>
          <p>
            <strong>Aplicação</strong> &ndash; nosso Aviso de Privacidade se aplica ao processamento de dados pessoais
            fornecidos por: 1) visitantes e usuários dos diversos sites, aplicativos, produtos e/ou serviços da CAI; 2)
            participantes de eventos da CAI e do setor; 3) clientes e clientes potenciais, assim como seus
            representantes; 4) assinantes que recebem nossos avisos e/ou boletins informativos/newsletters; 5)
            fornecedores e parceiros comerciais, assim como seus representantes; 6) visitantes de nossos escritórios; e
            7) qualquer pessoa que fornece dados pessoais à CAI por qualquer outro motivo. Para obter mais detalhes,
            consulte abaixo.
          </p>
          <p>
            <strong>Tipo de dados processados </strong> &ndash; a CAI coleta os dados pessoais que você fornece. Por
            exemplo, quando cadastra uma conta, podemos coletar informações como nomes, endereços de e-mail, endereço
            físico, números de telefone, etc. Você também pode fornecer dados pessoais para, por exemplo, saber mais
            sobre nossos produtos ou se cadastrar para receber nossas notificações. Podemos processar os seguintes dados
            pessoais, os quais podem ser coletados (dependo das circunstâncias) durante visitas a sites, interações de
            marketing, uso de aplicativos e de produtos e/ou serviços na vigência de acordos firmados com você e/ou com
            seu empregador: seu nome, informações de contato (endereço de e-mail, número de telefone, etc.), informações
            empregatícias (empregador, cargo, supervisor, etc.), alguns números de identificação (carteira de
            habilitação, ID de funcionário, etc.), informações e certificações de desempenho profissional, informações
            de pagamento, endereço IP, geolocalização, assinatura, imagem da câmera, nome de usuário e senha,
            informações sindicais, etnia, algumas informações médicas relacionadas ao trabalho, mensagens e dados e
            informações comportamentais sobre você recebidas por meio de cookies e pixels de rastreamento durante o uso
            da plataforma. Para obter mais detalhes, consulte abaixo.
          </p>
          <p>
            <strong>Finalidade do processamento</strong> &ndash; processamos os seus dados pessoais para as seguintes
            finalidades: 1) execução de acordos firmados com você e/ou com seu empregador; 2) monitoramento,
            desenvolvimento, suporte e administração de aplicativos, sites e plataformas; 3) segurança e prevenção de
            fraude; 4) nossos propósitos de marketing (nós não vendemos seus dados pessoais); 5) nossa administração
            comercial; e 6) análise comportamental associada ao uso da plataforma. Para obter mais detalhes, consulte
            abaixo.
          </p>
          <p>
            <strong>Justificativa legal para o processamento</strong> &ndash; na medida do que é considerado aplicável a
            você, algumas jurisdições exigem que qualquer processamento de dados pessoais tenha uma justificativa legal.
            De forma geral, contamos com as seguintes justificativas legais para nossas atividades de processamento: 1)
            execução de um acordo firmado com você e/ou com seu empregador; 2) busca de nossos interesses legítimos,
            desde que tais interesses não anulem seus interesses, direitos e liberdades; 3) sua autorização; e 4)
            cumprimento de uma obrigação legal. Para obter mais detalhes, consulte abaixo.
          </p>
          <p>
            <strong>Transferências de dados</strong> &ndash; podemos transferir seus dados pessoais a outras empresas
            afiliadas da CAI e/ou de terceiros (p.ex., parceiros comerciais, revendedores, etc.), entidades adquirentes
            ou adquiridas, provedores de serviços e, de acordo com a lei aplicável, autoridades governamentais,
            tribunais, consultores externos e terceiros semelhantes. Algumas transferências de dados podem ser
            transfronteiriças. Faremos todos os esforços razoáveis para garantir que seus dados pessoais permaneçam
            confidenciais quando forem transferidos. Nós não vendemos seus dados pessoais a nenhum terceiro. Além disso,
            a CAI não compartilhará seus dados com nenhum terceiro sem divulgação completa, salvo se especificado de
            outra forma neste Aviso de Privacidade. Para obter mais detalhes, consulte abaixo.
          </p>
          <p>
            <strong>Retenção e exclusão de dados</strong> &ndash; seus dados pessoais serão excluídos assim que não
            forem mais necessários para os fins do processamento original, interesse legítimo ou conforme exigido pela
            legislação aplicável. Para obter mais detalhes, consulte abaixo.
          </p>
          <p>
            <strong>Suas escolhas e direitos </strong> &ndash; a depender de sua jurisdição, você pode ter alguns
            direitos em relação aos seus dados pessoais, que podem incluir os direitos de acessar seus dados pessoais,
            obter uma cópia dos seus dados pessoais, transferir seus dados pessoais a um terceiro, corrigir seus dados
            pessoais, restringir alguns processamentos e/ou excluir seus dados pessoais. Para exercer seus direitos
            relacionados aos seus dados pessoais, use as informações na seção “Contato”, na parte inferior deste Aviso
            de Privacidade. Para obter mais detalhes, consulte abaixo.
          </p>
          <p>
            <strong>Alterações deste Aviso de Privacidade</strong> &ndash; reservamo-nos o direito de alterar os termos
            deste Aviso de Privacidade conforme preferirmos e a qualquer momento, conforme exigido pelas mudanças de
            práticas ou leis de privacidade. A versão atual deste Aviso de Privacidade sempre estará disponível através
            do link em nossos sites e plataformas ou através das informações da seção “Contato”, na parte inferior deste
            Aviso de Privacidade. Você deve revisar este Aviso de Privacidade periodicamente para ter conhecimento de
            nossas políticas e práticas vigentes.
          </p>
        </div>
      </div>
      <div id="controller_processor">
        <div>
          <h4>Aviso de Privacidade detalhado </h4>
          <p>
            <strong>Controlador/Processador &mdash;</strong> A CAI pode ser o Controlador de Dados ou o Processador de
            Dados para fins de processamento de dados pessoais, dependendo das circunstâncias. Nos locais em que a CAI
            processa dados em nome de nossos clientes e/ou suas afiliadas, a CAI frequentemente atua como Processador de
            Dados. Nos locais em que a CAI processa dados pessoas para seus próprios fins e fora da instrução de seus
            clientes e/ou suas afiliadas, a CAI pode ser um Controlador de Dados. Caso tenha alguma dúvida sobre o papel
            da CAI em relação aos seus dados pessoais, use as informações na seção “Contato”, na parte inferior deste
            Aviso de Privacidade.
          </p>
        </div>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <div>
          <p>
            <strong>A quem este Aviso se aplica &mdash;</strong> Nosso Aviso de Privacidade se aplica a todo o
            processamento de dados pessoais não-empregatício realizado pela CAI, independente da fonte de coleta. Este
            Aviso de Privacidade se aplica ao processamento de dados pessoais fornecidos por: 1) visitantes e usuários
            dos diversos sites, aplicativos, produtos e/ou serviços da CAI; 2) participantes de eventos da CAI e do
            setor; 3) clientes e clientes potenciais, assim como seus representantes; 4) assinantes que recebem nossos
            avisos, e-mails e/ou boletins informativos/newsletters; 5) fornecedores e parceiros comerciais, assim como
            seus representantes; 6) visitantes de nossos escritórios; e 7) qualquer pessoa que fornece dados pessoais à
            CAI por qualquer outro motivo.
          </p>
        </div>
      </div>
      <div id="how_we_collect_personal_data">
        <div>
          <h2>Como coletamos dados pessoais &mdash;</h2>
          <h3>Dados pessoais que você fornece:</h3>
          <p>
            A CAI coleta os dados pessoais que você ou seu representante fornecem. Isso pode acontecer em um evento CAI,
            um evento de apoio, por meio de marketing, durante uma interação presencial, etc. Exemplos dos tipos de
            dados pessoais que a CAI pode coletar são, entre outros: seu nome, endereço, número de telefone, endereço de
            e-mail, empregador, cargo, data da contratação, supervisor, tempo de serviço, número da carteira de
            habilitação, número de identificação de funcionário, CPF, outro número de identificação fiscal, informações
            e certificações de desempenho profissional, informações de pagamento, endereço IP e/ou identificador de
            dispositivo, geolocalização, assinatura, imagem estática e/ou de câmera de vídeo, nome de usuário e senha,
            informações sindicais, etnia, algumas informações médicas relacionadas ao trabalho, mensagens e dados e
            informações comportamentais sobre você recebidas por meio de cookies e pixels de rastreamento durante o uso
            da plataforma. Caso tenha alguma dúvida ou preocupação sobre quaisquer desses tipos de dados pessoais,
            consulte as informações da seção “Contato”, na parte inferior deste Aviso de Privacidade.
          </p>
          <h3>Dados coletados por meio de nossos produtos e serviços:</h3>
          <p>Dados coletados por meio de nossos produtos e serviços:</p>
          <p>(i) Dados coletados por meio de nossos produtos e serviços:</p>
          <p>
            (ii) Produtos ou serviços baseados na web para soluções de comércio colaborativo que abrangem todo o
            processo de compra e captação de pedidos no setor de mercado B2B (empresarial);
          </p>
          <p>
            (iii) Produtos de software locais licenciados a um cliente para uso em suas próprias instalações por meio do
            fornecimento de serviços profissionais;
          </p>
          <p>(iv) Produtos de hardware locais vendidos a um cliente para uso em suas próprias instalações; e </p>
          <p>
            (v) Serviços de atendimento ao cliente relacionados a SaaS e produtos de software e hardware locais e
            baseados na web.
          </p>
          <p>
            Por exemplo, quando nossos clientes usam os produtos ou serviços acima, eles podem fornecer detalhes sobre
            seus funcionários, incluindo nomes, cargos, endereços de e-mail, credenciais de login, números de telefone,
            endereços, datas de nascimento, números de carteira de habilitação e outras informações. Algumas de nossas
            soluções baseadas na web permitem que os clientes enviem, por exemplo, seus dados pessoais para criar
            usuários da solução, armazenar os documentos da transação (que podem incluir alguns dados pessoais dos
            signatários ou dos contratos de negócios), usar a geolocalização e armazenar informações de contato
            associadas aos parceiros comerciais.
          </p>
          <p>
            Frequentemente processamos dados pessoais em nome de nossos clientes, mediante a assinatura de um contrato.
            Não controlamos as práticas de processamento ou proteção de dados dos nossos clientes (que podem ser o seu
            empregador, provedor de serviços ou parceiro de negócios), portanto, os termos deles podem ser diferentes
            daqueles estabelecidos neste Aviso de Privacidade. Quando a CAI coleta dados pessoais por meio de produtos e
            serviços controlados por nossos clientes, nossos clientes atuam como Controladores de Dados em relação a
            quais dados são coletados e como são usados e divulgados. Nesses casos, a CAI atua apenas como Processador
            de Dados. Todas as dúvidas relacionadas a como nossos clientes processam, usam ou compartilham os dados
            pessoais que coletam por meio de nossos produtos ou serviços devem ser direcionadas ao cliente relevante.
          </p>
        </div>
      </div>
      <div id="automatically_collected_information">
        <div>
          <h3>Informações coletadas automaticamente: </h3>
          <p>
            Na medida em que você navega ou interage com nossos sites e/ou produtos SaaS e aplicativos, podemos usar
            tecnologias de coleta automática de dados para coletar informações específicas sobre seu equipamento, ações
            e padrões (“Informações da Atividade do Usuário”), incluindo: (i) detalhes da sua utilização, incluindo
            dados de tráfego, dados de localização por meio de tecnologia de geolocalização, registros/logs e outros
            dados de comunicação e os recursos que você acessa e usa; e (ii) informações sobre o seu dispositivo,
            incluindo seu sistema operacional, endereço IP e outros dados de login/acesso móvel. Algumas dessas
            informações podem ser consideradas dados pessoais em sua jurisdição.
          </p>
          <p>
            As Informações da Atividade do Usuário que coletamos ajudam-nos a melhorar nossos sites e produtos e a
            fornecer um serviço melhor e mais personalizado, permitindo que façamos previsões dos nossos padrões de uso,
            exibamos informações que você solicita no seu fuso horário apropriado e reconheçamos quando você volta ao
            nosso site ou produto.
          </p>
          <p>
            As tecnologias que usamos para essa coleta de dados automática podem incluir web beacons, pixel tags,
            scripts ou cookies. Cookie é um arquivo pequeno colocado no disco rígido do seu dispositivo. Usamos cookies
            para ajudar a analisar a utilização, personalizar nossos serviços, medir a efetividade e promover confiança
            e segurança. Falaremos sobre os cookies abaixo.
          </p>
        </div>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <div>
          <h2>Como usamos as informações que coletamos &ndash;</h2>
          <p>
            A CAI usa as informações coletadas para operar efetivamente, fornecer seus produtos e serviços aos clientes,
            facilitar negócios entre as partes no setor e administrar e gerenciar suas relações com os clientes. Também
            usamos as informações que coletamos para processar, avaliar e responder a suas solicitações; responder
            consultas e candidaturas; criar, administrar e comunicar a você informações sobre a sua conta (incluindo
            compras e pagamentos); operar, avaliar e aprimorar os negócios da CAI (incluindo desenvolvimento de novos
            produtos e serviços, gerenciamento de comunicações, realização de pesquisa de mercado, análise de
            produtos/serviços/sites da CAI e realização de atividades de contabilidade, auditoria, faturamento,
            reconciliação e cobrança); garantir a segurança dos serviços e recursos de rede da CAI; e cumprir os
            requisitos legais aplicáveis.
          </p>
        </div>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <div>
          <h2>Como divulgamos as informações que coletamos &ndash;</h2>
          <p>Podemos divulgar alguns dados pessoais que coletamos da seguinte forma:</p>
          <ul>
            <li>A nossas afiliadas e/ou parceiros comerciais com os quais temos relações contratuais; </li>
            <li>
              A terceiros, como contratados, prestadores de serviços, consultores e outros agentes (“Prestadores de
              Serviços”) que dão assistência ao nosso negócio. Em alguns casos, a CAI trabalha com Prestadores de
              Serviços por vários motivos, incluindo, por exemplo, processamento de pagamentos por cartão de crédito,
              monitoramento horas de serviço, hospedagem de dados e contabilidade. Os Prestadores de Serviços com quem
              compartilhamos tais dados pessoais são normalmente estão sujeitos a obrigações de confidencialidade e
              privacidade. A lista desses Prestadores está disponível em nosso site (www.commandalkon.com) na guia
              “Informações Legais” (Lista de subprocessador);
            </li>
            <li>Para cumprir as finalidades para as quais você ou seu empregador forneceram esses dados pessoais;</li>
            <li>
              A um comprador ou outro sucessor da CAI ou qualquer uma de nossas afiliadas em caso de aquisição, fusão,
              desinvestimento, reestruturação, reorganização, dissolução ou outra venda ou transferência de parte ou
              todo patrimônio ou ativos da CAI (ou de qualquer uma de suas afiliadas), liquidação ou procedimentos
              similares, nos quais dados pessoais estão entre os ativos transferidos; e
            </li>
            <li>Para qualquer outro propósito divulgado por nós no momento em que você fornece os dados pessoais</li>
          </ul>
          <p>
            Salvo disposições em contrário a este Aviso de Privacidade, também nos reservamos o direito de acessar,
            preservar e divulgar qualquer informação que coletarmos como acreditarmos razoavelmente necessário para: (i)
            responder a exigências legais, inclusive cumprir qualquer mandado judicial, lei ou processo legal e
            responder a qualquer solicitação governamental ou regulatória, inclusive responder à polícia ou a outros
            oficiais do governo quanto a uma solicitação verificada relacionada a uma investigação criminal ou suposta
            atividade ilegal; (ii) cumprir ou aplicar nossas políticas e acordos; (iii) detectar, prevenir ou de outra
            forma lidar com problemas de fraude, segurança, confiança e segurança ou problemas técnicos; (iv) responder
            a perguntas de suporte do usuário; ou (v) proteger os direitos, a propriedade, a saúde ou a segurança da
            CAI, dos nossos usuários, de quaisquer terceiros ou do público em geral.
          </p>
          <h2>Outras informações para residentes da Califórnia e de outros Estados dos EUA &ndash;</h2>
          <p>
            As informações pessoais que coletamos são informações nas categorias de dados presentes na tabela a seguir.
            Essas categorias também representam as categorias de informações pessoais que coletamos nos últimos 12
            meses. As categorias listadas a seguir são definidas pela legislação do Estado da Califórnia, EUA. A
            inclusão de uma categoria na lista abaixo indica somente que, dependendo dos serviços e produtos que
            fornecemos a você e/ou seu empregador e parceiro de negócios, podemos coletar ou descartar algumas
            informações dentro da categoria em questão. Isso não significa necessariamente que coletamos ou divulgamos
            todas as informações listados em uma categoria específica nem que coletamos todas as categorias de
            informações para todos os indivíduos.
          </p>
          <p>
            Ademais, embora incluamos dados coletados no contexto B2B que sejam considerados dados pessoais sob leis
            específicas de privacidade como o GDPR UE, o GDPR RU e a Lei de Direitos de Privacidade da Califórnia, eles
            podem não ser considerados dados pessoais em outras jurisdições, incluindo em outros estados dos EUA.
          </p>
          <p>
            Divulgamos informações em cada uma das categorias a seguir com nossas afiliadas e nossos prestadores de
            serviços para finalidades de nossos negócios nos últimos 12 meses.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Categoria</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Origens</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Objetivo do processamento</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Identificadores como nome real, pseudônimo (alias), endereço postal, identificador pessoal único,
                    identificador on-line, endereço de protocolo IP, endereço de e-mail, nome da conta, CPF, número da
                    carteira de habilitação ou outros identificadores semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados e as informações acerca de suas tendências de compra obtidas a partir de nossos
                    parceiros de negócios são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    As informações que identifiquem, façam relação, descrevam ou possam ser associadas a um indivíduo
                    específico, incluindo, entre outros, seu nome, assinatura, CPF, características ou descrição física,
                    endereço, número de telefone, carteira de habilitação ou número de RG ou informações médicas.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informações comerciais, incluindo registros de propriedade pessoal, produtos ou serviços adquiridos,
                    obtidos ou considerados, ou outros históricos ou tendências de compra ou consumo.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados e as informações acerca de suas tendências de compra obtidas a partir de nossos
                    parceiros de negócios são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informações acerca de atividade de internet ou outra rede eletrônica, incluindo, entre outras,
                    histórico de navegação, histórico de pesquisa e informações sobre a interação de um consumidor e um
                    site da internet, aplicativo ou publicidade.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos ou pelo uso
                    de cookies e tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Dados de geolocalização.</td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados e as informações acerca de suas tendências de compra obtidas a partir de nossos
                    parceiros de negócios são usados para fins de marketing, incluindo oferecer e fornecer a você
                    produtos e serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Informações de áudio, eletrônicas, visuais, térmicas, olfatórias ou semelhantes.</p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                  <p>
                    Para visitantes, essas informações são coletadas por nossas câmeras de CFTV e, para clientes fazendo
                    chamadas, podem ser coletadas por gravações de áudio.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo o registro de chamadas de vendas e outras chamadas. Para
                    visitantes, os dados visuais pode ser processados a fim de manter a segurança e a responsabilidade e
                    para cumprir a legislação local, incluindo aquelas relacionadas à saúde e segurança.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Informações profissionais ou relacionadas ao emprego.</td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais e
                    para gestão de conformidade (compliance).
                  </p>
                  <p>
                    Ademais, esses dados são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Inferências decorrentes de qualquer uma das categorias anteriores de informações para criar um
                    perfil sobre um consumidor, refletindo as preferências do consumidor, suas características,
                    tendências psicológicas, predisposições, comportamento, atitudes, inteligência, habilidades e
                    aptidões.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                  <p>
                    Ademais, esses dados são usados para fins de marketing, incluindo oferecer a você produtos e
                    serviços que possam ser de seu interesse por meio de publicidade direta e por parceiros.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informações pessoais sensível, incluindo qualquer informação pessoal que revele o CPF de um
                    indivíduo ou outro número de identificação; dados de login de conta e geolocalização; origem étnica;
                    participação em sindicato; conteúdo de correio, e-mail ou mensagens de texto, salvo se a empresa for
                    o destinatário pretendido da comunicação; e dados genéticos.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Essas informação são coletadas diretamente de você e/ou de seu empregador, nossos parceiros de
                    negócios, nossos prestadores de serviços, sua interação com nossos serviços e produtos, registros
                    que temos sobre você no curso de fornecimento de serviços ou produtos ou pelo uso de cookies e
                    tecnologias semelhantes.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Esses dados são processados em conexão com diversas de nossas funções operacionais para fornecer a
                    você produtos e serviços, incluindo facilitar os negócios entre partes no setor e administrar
                    relações com clientes.
                  </p>
                  <p>
                    Eles também são processados para ajudar a gerenciar e administrar sua conta, além de detectar
                    incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais,
                    para gestão de conformidade (compliance), data analytics e desenvolvimento de tecnologia de nossos
                    sistemas.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            A CAI não vende suas informações a terceiros, incluindo para fins da Lei de Privacidade do Consumidor da
            Califórnia (&ldquo;CCPA&rdquo;) de 2018 e da Lei de Direitos de Privacidade da Califórnia de 2020
            (&ldquo;CPRA&rdquo;), ambas dos EUA.
          </p>
        </div>
      </div>
      <div id="third_party_services">
        <div>
          <h2>Serviços de terceiros &ndash;</h2>
          <p>
            Você pode ter a oportunidade de optar por acessar e usar serviços ou aplicativos de terceiros (“Serviços de
            terceiros”) por meio dos nossos sites e/ou produtos. Este Aviso de Privacidade abrange apenas a coleta, o
            uso e a divulgação pela CAI dos dados coletados pela CAI. Ele não se aplica a práticas de terceiros que não
            possuímos, controlamos, empregamos ou gerenciando, incluindo, entre outros, quaisquer serviços de terceiros.
            Ao acessar ou usar serviços ou aplicativos de terceiros por meio de nossos sites e/ou produtos, você nos
            orienta a divulgar suas informações ao Serviços de Terceiros em seu nome conforme for solicitado por esse
            terceiro. Você também concorda que o uso ou a divulgação por parte do terceiro de suas informações serão
            regidos pela política de privacidade do terceiro e ela pode ser diferente de como usamos e divulgamos suas
            informações.
          </p>
        </div>
      </div>
      <div id="security">
        <div>
          <h2>Segurança &ndash;</h2>
          <p>
            A CAI utiliza controles de segurança administrativa, técnica e física razoáveis e apropriados e
            proporcionais aos tipos de dados que processa a fim de proteger os dados pessoais contra perda, uso indevido
            e acesso, divulgação, alteração e/ou destruição não autorizados. Embora nosso trabalho seja árduo para
            proteger sua privacidade, nenhum controle de segurança é 100% eficaz e não podemos garantir a segurança das
            informações ou que seus dados pessoais ou comunicações privadas sempre permaneçam privados. Acesso ou uso
            não autorizado, falha de hardware ou de software e outros fatores podem comprometer a segurança das
            informações do usuário.
          </p>
          <p>
            A proteção e a segurança das suas informações também dependem de você. Quando fornecemos a você (ou quando
            você escolhe) as credenciais de login de usuário (ou seja, um ID de usuário e senha) para acesso a partes
            específicas de nossos serviços ou aplicativos, é de sua responsabilidade manter a confidencialidade dessas
            credenciais. Pedimos que você não compartilhe suas credenciais com ninguém. Você é a única pessoa
            responsável pela proteção das credenciais da conta de usuário e pelo uso das credenciais da sua conta sob
            seu controle.
          </p>
        </div>
      </div>
      <div id="retention_of_data">
        <div>
          <h2>Retenção de informações &ndash;</h2>
          <p>
            Geralmente mantemos seus dados pessoais pelo tempo necessário para cumprir os objetivos descritos neste
            Aviso de Privacidade. Essas retenção costuma ser feito pela vida da solução somada a um período definido em
            nosso cronograma de retenção de dados. No entanto, podemos manter algumas informações por mais tempo
            conforme exigido pelas leis aplicáveis (como requisitos fiscais, de contabilidade ou outros legais) ou para
            fins legítimos do negócio. Assim que não precisarmos mais usar seus dados pessoais para cumprir nossas
            obrigações, iremos removê-los de nossos sistemas e registros e/ou tomaremos medidas para anonimizá-los
            adequadamente para que você não possa mais ser identificado a partir deles. Cada uso definido neste Aviso de
            Privacidade constitui interesse legítimo da CAI para processar ou usar os dados pessoais coletados ou
            necessários para atender a um contrato. Caso não concorde com esta abordagem, você pode contestar o
            processamento e a utilização dos seus dados pessoais pela CAI entrando em contato com a CAI por escrito
            através dos métodos listados abaixo na seção “Contato” na parte inferior deste Aviso de Privacidade.
          </p>
        </div>
      </div>
      <div id="your_rights_and_choices">
        <div>
          <h2>Seus direitos e opções &ndash;</h2>
          <h3>Informações da conta e solicitações: </h3>
          <p>
            De acordo com a lei aplicável e dependendo da jurisdição (por exemplo, o EEE de 25 de maio de 2018 em diante
            ou o estado da Califórnia a partir de 1º de janeiro de 2020), você pode ter os seguintes direitos listados
            em relação aos seus dados pessoais:
          </p>
          <p>
            1) o direito de acessar (significa que você pode solicitar uma cópia de seus dados pessoais que coletamos,
            as categorias de origens a partir das quais as informações foram coletadas, as finalidades para a coleta dos
            dados, as categorias de terceiros com que compartilhamos os dados e as categorias de dados pessoais que
            foram compartilhados com terceiros para uma finalidade de negócios);
          </p>
          <p>
            2) o direito de corrigir (significa que você pode nos notificar pela seção “Contato”, na parte inferior
            deste Aviso de Privacidade, para corrigir qualquer equívoco em seus dados pessoais ou atualizar suas
            preferências; também pode ser que não consigamos atender sua solicitação se acreditarmos que possa violar
            qualquer lei ou obrigação legal, ou fazer com que as informações estejam incorretas; os dados retidos
            unicamente para fins de backup de dados costumam ser excluídos);
          </p>
          <p>
            3) o direito de transferir (significa que você pode solicitar uma cópia de seus dados pessoais a um terceiro
            de sua escolha);
          </p>
          <p>
            4) o direito de restringir (onde nosso processamento de seus dados pessoais for necessário para nossos
            interesses legítimos, dependendo da legislação de privacidade/proteção de dados aplicável, você pode se opor
            a esse processamento a qualquer momento, sujeito a algumas limitações e exceções; você também pode
            restringir tipos específicos de processamento de seus dados pessoais sob circunstâncias específicas; em
            especial, você pode solicitar que restrinjamos nosso uso de seus dados pessoais se contesta a precisão
            desses dados, se o processamento de seus dados pessoais for considerado ilegal, ou se não mais precisemos de
            seus dados pessoais para processamento, mas os retemos conforme permitido pela lei);
          </p>
          <p>
            5) o direito de retirar o consentimento (significa que, na medida em que nosso processamento de seus dados
            pessoais se baseia em seu consentimento, você pode retirá-lo a qualquer momento; retirar seu consentimento,
            contudo, não afetará a legalidade do processamento com base em seu consentimento prévio, anterior à
            retirada, e não afetará a legalidade de nosso processamento continuado que se baseia em qualquer outra base
            legal para processamento de seus dados pessoais);
          </p>
          <p>
            6) o direito de excluir (significa que você pode solicitar a exclusão de seus dados pessoais de nossos
            sistemas e nós o faremos, salvo se formos obrigados a reter tais informações para fornecer serviços a você
            ou cumprir com nossas obrigações legais ou comerciais nos termos da lei aplicável); ou
          </p>
          <p>7) o direito de dizer “não” à venda de seus dados pessoais ( a CAI não vende dados pessoais). </p>
          <p>
            Você pode exercer parte ou todos esses direitos fazendo login na sua conta nos produtos que usa. Caso
            contrário, para solicitar tais informações diretamente, envie uma solicitação por escrito usando os dados
            fornecidos na seção “Contato” na parte inferior deste Aviso de Privacidade.
          </p>
          <p>
            Sob a lei do Estado da Califórnia (EUA), temos a obrigação somente de responder a até duas solicitações de
            acesso a informações pessoais do mesmo consumidor em um período de 12 meses. Sob a legislação da UE e da
            Califórnia, se um indivíduo fizer solicitações injustificáveis, repetitivas ou em excesso (conforme
            determinado em nossa discrição razoável) para acessar Dados Pessoais, podemos cobrar uma taxa sujeita a um
            valor máximo definido pela lei.
          </p>
          <p>
            Lembre-se de que, em algumas regiões, você também tem o direito de reclamar com uma autoridade de proteção
            de dados (“DPA”) sobre nossa coleta e utilização de suas Informações pessoais. Para obter mais informações,
            entre em contato com a DPA local.
          </p>
          <p>
            No caso de a CAI processar dados pessoais sobre você em nome de um cliente, envie suas consultas e
            solicitações de acesso, correção ou exclusão de seus dados pessoais ao respectivo cliente.
          </p>
          <p>
            Antes de fornecer as informações que você solicita em conformidade com alguns desses direitos, precisamos
            verificar sua identidade. Para isso, pode ser necessário que você envie informações sobre si, incluindo, na
            medida do aplicável, responder a perguntas de segurança, fornecer seu nome, número de documento oficial,
            data de nascimento, informações de contato ou outra informação de identificação pessoal. Faremos a
            correspondência entre essas informações e aquelas que coletamos anteiro sobre você para verificar sua
            identidade e sua solicitação. Se não conseguirmos confirmar sua identidade como parte de sua solicitação,
            não poderemos atendê-la. Não estamos obrigados a coletar outras informações a fim de permitir que você
            confirme sua identidade. Para solicitações de exclusão, será necessário que você envie uma solicitações
            verificável para exclusão.
          </p>
          <p>
            Se preferir indicar um agente autorizado para fazer a solicitação em seu nome, é necessário fornecer ao
            agente uma permissão por escrito assinada para envio de solicitações de direitos de privacidade em seu nome,
            ou fornecer uma carta de seu advogado. O agente ou advogado devem fornecer essa autorização no momento da
            solicitação. Podemos solicitar que você confirme sua identidade diretamente conosco antes de fornecermos
            qualquer informação solicitada ao seu agente aprovado.
          </p>
          <p>
            As informações coletadas para fins de confirmar sua solicitação serão usadas somente para verificação. Se
            escolher exercer qualquer um desses direitos, na medida em que se apliquem, a lei estadual dos EUA
            proíbe-nos de discriminar com base na escolha de exercer seus direitos de privacidade. Contudo, podemos
            cobrar uma taxa diferente ou fornecer um nível de serviço diferente na medida permitida por lei.
          </p>
          <h3>Sinais para não rastreio:</h3>
          <p>
            Alguns navegadores da web permitem a você transmitir um sinal a sites e serviços on-line indicando a
            preferência de que “não rastreiam” suas atividades on-line. No momento, não atendemos esses sinais e não
            modificamos quais informações coletamos ou como usamos essas informações com base na ocorrência de um sinal
            ser transmitido ou recebido por nós.
          </p>
          <h3>Comunicações promocionais:</h3>
          <p>
            Ocasionalmente, podemos enviar comunicados de marketing sobre produtos a você, de acordo com suas
            preferências. Você pode optar por cancelar o recebimento de nossas mensagens promocionais a qualquer momento
            seguindo as instruções nessas mensagens (normalmente haverá um aviso ou link na parte inferior da mensagem).
            Se você optar por cancelar o recebimento, podemos ainda enviar comunicação não promocional, como mensagens
            sobre sua conta ou relações de negócios em andamento. Os pedidos de exclusão do recebimento de comunicações
            promocionais também devem ser enviados à CAI por escrito através dos métodos listados abaixo na seção
            “Contato”, na parte inferior deste Aviso de Privacidade.
          </p>
          <h3>Cookies:</h3>
          <p>
            Você pode gerenciar o uso de cookies através do banner de cookies, exibido quando você acessa os nossos
            sites. Se você preferir, pode normalmente optar por configurar seu navegador para remover ou rejeitar
            cookies ou para limpar o armazenamento local. Você também pode fazer qualquer solicitação de gerenciamento
            de cookies entrando em contato conosco por escrito através dos métodos listados abaixo na seção “Contato” na
            parte inferior deste Aviso de Privacidade. Observe que se você optar por remover ou rejeitar alguns cookies,
            isso pode afetar a funcionalidade do nosso site e serviços. Temos uma Política de Cookies independente mais
            detalhada disponível em nosso site (<b>www.commandalkon.com</b>) na guia Informações legais.
          </p>
          <h3>Informações sobre localização: </h3>
          <p>
            Você pode ativar e desativar os serviços baseados em localização ajustando as configurações do seu
            dispositivo móvel. Observe que se você optar por desabilitar serviços baseados em localização, isso pode
            afetar a funcionalidade total de nossos produtos e serviços.
          </p>
          <h3>Direitos de privacidade do Estado da Califórnia:</h3>
          <p>
            A lei da Califórnia permite que os usuários residentes no Estado solicitem e obtenham anualmente, e de forma
            gratuita, uma lista dos terceiros para quem divulgamos seus dados pessoais (se for o caso) para fins de
            marketing direto no ano anterior, assim como o tipo de dados pessoais divulgados a esses terceiros. Salvo
            disposição em contrário neste Aviso de Privacidade ou segundo acordo com o sujeito dos dados, a CAI não
            vende dados pessoais a terceiros para fins de marketing próprio.
          </p>
        </div>
      </div>
      <div id="international_data_transfers_and_privacy_shield">
        <div>
          <h2>Transferências internacionais de dados e Estrutura de Privacidade de Dados &ndash;</h2>
          <p>
            A CAI é sediada nos Estados Unidos e tem escritórios em todo o mundo. Para facilitar nossas operações e
            ajudar a entregar nossos produtos e serviços, podemos transferir dados pessoais para os EUA e qualquer outro
            país em que nossas afiliadas, fornecedores, consultores e prestadores de serviços operam. Esses países podem
            ter leis diferentes e possivelmente com menor nível de proteção quanto as leis do seu país de residência. Se
            você for um Controlador de Dados usando a CAI como Processador de Dados e se o conteúdo ou dados que você
            armazena em software, aplicativos ou sites da CAI, ou por meio deles, contiver dados pessoais de indivíduos
            do EEE (Espaço Econômico Europeu), você concorda que possui autoridade legal para transferir os dados
            pessoais para a CAI, incluindo a transferência para países como os EUA onde as proteções e os direitos de
            privacidade das autoridades para acessar dados pessoais podem não ser equivalentes aos do EEE.
          </p>
          <p>
            Quando transferirmos dados pessoais para o exterior, usaremos todas as proteções apropriadas para proteger
            as informações de acordo com este Aviso de Privacidade e procuraremos garantir as leis de privacidade
            aplicáveis sejam cumpridas por nós e pelos destinatários internacionais. A CAI depende das Cláusulas
            Contratuais Padrão da UE (“SCCs UE”) para a transferência legal de dados pessoais entre suas entidades a
            partir de jurisdições que estão sujeitas ao Regulamento Geral de Proteção de Dados da UE (“GDPR UE”) para os
            EUA. A CAI depende do Acordo de Transferência de Dados do Reino Unido para a transferência legal de dados
            pessoais entre suas entidades a partir de jurisdições que estão sujeitas ao Regulamento Geral de Proteção de
            Dados do Reino Unido (“GDPR RU”) para os EUA.
          </p>
          <p>
            Em complemento às SCCs UE, se a CAI tomar conhecimento de que qualquer autoridade governamental (incluindo
            órgãos de execução da lei) deseja ter acesso ou obter cópia de alguns ou todos os seus dados pessoais
            processados pela CAI, seja de forma voluntária ou obrigatória, para fins relacionados à inteligência de
            segurança nacional, a menos que seja legalmente proibido ou mediante uma compulsão legal obrigatória que
            exija o contrário, a CAI: 1) notificará imediatamente a parte a quem os dados pessoais se aplicam (ou seja,
            cliente ou fornecedor); 2) informará a autoridade governamental relevante de que ela não está autorizada a
            divulgar os dados pessoais e, a menos que seja legalmente proibido, precisará notificar imediatamente a
            parte a quem os dados pessoais se aplicam; 3) informará a autoridade governamental que ela deve direcionar
            todas as solicitações ou demandas diretamente à parte a quem os dados pessoais se aplicam; e 4) não
            fornecerá acesso aos dados pessoais até que tenha autorização por escrito da parte a quem os dados pessoais
            se aplicam ou até que ela seja legalmente obrigada a fazê-lo. Se for legalmente obrigada a fazê-lo, a CAI
            envidará esforços razoáveis e legais para contestar tal proibição ou compulsão. Se a CAI for obrigada a
            fornecer dados pessoais, ela só divulgará esses dados na medida legalmente exigida para fazê-lo, de acordo
            com o processo legal aplicável.
          </p>
          <p>
            A CAI emprega Adendos de Proteção de Dados (“DPAs”) com subprocessadores que processam dados pessoais em
            nome da CAI quando necessário pelas leis aplicáveis de privacidade.
          </p>
          <p>
            Quando transferimos dados pessoais do Espaço Económico Europeu (“EEE”), do Reino Unido ou da Suíça para os
            Estados Unidos, a CAI cumpre os requisitos UE-EUA. Data Privacy Framework (EU-U.S. DPF), a extensão do Reino
            Unido à UE-EUA. DPF e a Estrutura de Privacidade de Dados Suíça-EUA (DPF SuíçaEUA), conforme estabelecido
            pelo Departamento de Comércio dos EUA. A CAI certificou ao Departamento de Comércio dos EUA que adere às
            regras UE-EUA. Princípios da Estrutura de Privacidade de Dados (Princípios DPF UE-EUA) no que diz respeito
            ao processamento de dados pessoais recebidos da União Europeia com base nos Princípios UE-EUA. DPF e do
            Reino Unido (e Gibraltar) com base na extensão do Reino Unido à UE-EUA. DPF. A CAI certificou ao
            Departamento de Comércio dos EUA que adere à Convenção Suíça-EUA. Princípios da Estrutura de Privacidade de
            Dados (Princípios DPF Suíça-EUA) no que diz respeito ao processamento de dados pessoais recebidos da Suíça
            com base nos Princípios SuíçaEUA. DPF. Se houver qualquer conflito entre os termos deste Aviso de
            Privacidade e os termos da UEEUA. Princípios DPF e/ou Swiss-U.S. Princípios do DPF, os Princípios regerão.
            Para saber mais sobre o programa Data Privacy Framework (DPF) e visualizar nossa certificação, visite
            <b>https://www.privacyshield.gov/</b>.
          </p>
          <p>
            Em conformidade com a UE-EUA. DPF, a extensão do Reino Unido à UE-EUA DPF e Suíça-EUA. A DPF, CAI
            compromete-se a resolver reclamações relacionadas com os Princípios da DPF sobre a nossa recolha e
            utilização das suas informações pessoais. Indivíduos da UE, do Reino Unido e da Suíça com dúvidas ou
            reclamações sobre o nosso tratamento de dados pessoais recebidos com base no Regulamento UE-EUA. DPF, a
            extensão do Reino Unido à UE-EUA DPF e/ou Swiss-U.S. O DPF deve primeiro entrar em contato com a CAI
            conforme indicado na seção “Fale Conosco” na parte inferior deste Aviso de Privacidade.
          </p>
          <p>
            Um indivíduo tem a possibilidade, sob certas condições, de invocar arbitragem vinculativa para reclamações
            relativas ao cumprimento do DPF não resolvidas por qualquer um dos outros mecanismos do DPF. Para obter
            informações adicionais, consulte
            https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2. Em conformidade com
            a UE-EUA. DPF, a extensão do Reino Unido à UE-EUA DPF e Suíça-EUA. DPF, CAI compromete-se a encaminhar
            reclamações não resolvidas relativas ao nosso tratamento de dados pessoais recebidos com base nas regras
            UE-EUA. DPF, a extensão do Reino Unido à UE-EUA DPF e/ou Swiss-U.S. DPF para JAMS, um provedor alternativo
            de resolução de disputas com sede nos Estados Unidos. Se você não receber nosso reconhecimento oportuno de
            sua reclamação relacionada aos Princípios DPF, ou se não tivermos atendido sua reclamação relacionada aos
            Princípios DPF de forma satisfatória, visite <b>https://www.jamsadr.com/file-a-dpf</b> -pedir mais
            informações ou registrar uma reclamação. Os serviços da JAMS são fornecidos sem nenhum custo para você.
          </p>
          <p>
            A Comissão Federal de Comércio dos Estados Unidos tem jurisdição sobre a conformidade da CAI com as normas
            UE-EUA. DPF, a extensão do Reino Unido à UE-EUA DPF e Suíça-EUA. DPF. Se a CAI compartilhar dados pessoais
            coletados no EEE, no Reino Unido ou na Suíça com um prestador de serviços terceirizado que processe os dados
            exclusivamente em nome da CAI, então a CAI será responsável pelo processamento de tais dados por esse
            terceiro, em violação dos Princípios , a menos que a CAI possa provar que não é responsável pelo evento que
            deu origem ao dano.
          </p>
        </div>
      </div>
      <div id="lawful_basis_for_processing">
        <div>
          <h2>Justificativa legal para o processamento de dados pessoais de indivíduos (EEE/RU) &ndash;</h2>
          <p>
            Se você for um indivíduo do EEE ou do Reino Unido, nossa justificativa legal para coleta e utilização de
            dados pessoais dependerá dos dados pessoais relacionados e do contexto específico em que coletamos os dados.
            Usaremos seus Dados Pessoais somente para os fins para os quais coletamos esses Dados Pessoais conforme
            descrito a seguir, salvo se precisarmos usá-los posteriormente para outra finalidade compatível com a
            finalidade original. Se precisarmos processar seus Dados Pessoais para uma finalidade incompatível com a
            finalidade original para coleta, notificaremos você e forneceremos uma explicação acerca da justificativa
            legal que nos permite a fazê-lo. No entanto, geralmente contamos com as seguintes justificativas legais para
            nossas atividades de processamento: 1) execução de um acordo firmado com você e/ou com seu empregador; 2)
            busca de nossos interesses legítimos, desde que tais interesses não anulem seus interesses, direitos e
            liberdades; 3) sua autorização; e 4) cumprimento de uma obrigação legal. Em alguns casos, também podemos
            depender das derrogações do artigo 49 do GDPR, ter a obrigação legal de coletar dados pessoais ou de
            precisar, de outra forma, dos dados pessoais para proteger seus interesses vitais ou os de outra pessoa.
            Mais detalhes sobre a justificativa legal estão disponíveis a seguir.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Finalidades do processamento </strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Justificativa legal para o processamento</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Processar aplicativos e outros acordos para nossos produtos e serviços. </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento de seus dados pessoais é necessário para firmar ou celebrar um contrato ou
                        acordo com você e/ou seu empregador.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Gerenciar e administrar contratos, incluindo contratos de serviço, celebrados com você e seu
                    empregador.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento de seus dados pessoais é necessário para que possamos cumprir as obrigações
                        legais e regulatórias.
                      </p>
                    </li>
                    <li>
                      <p>
                        O processamento é necessário para promover nossos interesses legítimos na gestão de nossos
                        negócios (ou aqueles de um terceiro), desde que tais interesses não sejam substituídos por seus
                        interesses, direitos e liberdades.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Aprimorar nossos produtos e serviços, fazer pesquisa de mercado, fazer data analytics, fins de
                    modelagem de risco geral e para análises estatísticas
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento é necessário para promover nossos interesses legítimos na gestão de nossos
                        negócios (ou aqueles de um terceiro), desde que tais interesses não sejam substituídos por seus
                        interesses, direitos e liberdades.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Marketing</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Buscaremos sua concordância com o processamento de seus dados pessoais para marketing, sendo que
                        você pode retirar esse consentimento a qualquer momento.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Buscaremos sua concordância com o processamento de seus dados pessoais para marketing, sendo que
                    você pode retirar esse consentimento a qualquer momento.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento de seus dados pessoais é necessário para que possamos cumprir as obrigações
                        legais e regulatórias ou conforme for autorizado pela lei aplicável.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Gerenciar nossa relação com você e entre você e seu empregador </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento de seus dados pessoais é necessário para firmar ou celebrar um contrato ou
                        acordo com você e/ou seu empregador.
                      </p>
                    </li>
                    <li>
                      <p>
                        O processamento é necessário para promover nossos interesses legítimos na gestão de nossos
                        negócios (ou aqueles de um terceiro), desde que tais interesses não sejam substituídos por seus
                        interesses, direitos e liberdades.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Fornecer os benefícios de nossos produtos e serviços</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        O processamento de seus dados pessoais é necessário para firmar ou celebrar um contrato ou
                        acordo com você e/ou seu empregador.
                      </p>
                    </li>
                    <li>
                      <p>
                        O processamento é necessário para promover nossos interesses legítimos na gestão de nossos
                        negócios (ou aqueles de um terceiro), desde que tais interesses não sejam substituídos por seus
                        interesses, direitos e liberdades.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="children_policy">
        <div>
          <h2>Privacidade de crianças &ndash;</h2>
          <p>
            Os negócios da CAI não são direcionados a crianças e a CAI não coleta ou solicita intencionalmente qualquer
            informação de crianças ou de qualquer pessoa com idade inferior a 13 anos. A CAI não permite
            intencionalmente que tais pessoas utilizem nossos produtos ou serviços. Se soubermos que coletamos
            inadvertidamente dados pessoais de uma criança ou de uma pessoa com idade inferior a 13 anos, excluiremos
            essas informações o mais rápido possível. Se você acredita que possamos ter qualquer informação de ou sobre
            uma criança, entre em contato conosco através dos métodos listados abaixo na seção “Contato” na parte
            inferior deste Aviso de Privacidade.
          </p>
        </div>
      </div>
      <div id="changes_to_the_privacy_notice">
        <div>
          <h2>Alterações do Aviso de Privacidade &ndash;</h2>
          <p>
            Podemos revisar este Aviso de Privacidade a qualquer momento mediante a publicação do Aviso de Privacidade
            alterado em nossos sites. A data em que o Aviso de Privacidade foi revisado pela última vez está
            identificada na parte superior deste Aviso de Privacidade. Todos os termos alterados entram automaticamente
            em vigor depois da publicação inicial no nosso site, a menos que uma alteração em qualquer lei aplicável
            exija emenda imediata. Você é responsável por verificar periodicamente este Aviso de Privacidade quanto a
            alterações.
          </p>
        </div>
      </div>
      <div>
        <div>
          <h2>Reclamações &ndash;</h2>
          <p>
            Sem prejuízo de qualquer outro recurso administrativo ou judicial a que você tenha direito, você pode ter o
            direito de fazer uma queixa às autoridades de proteção de dados/reguladoras locais se acreditar que
            infringimos os requisitos aplicáveis de proteção de dados ou privacidade ao processar dados pessoais a seu
            respeito.
          </p>
          <h3>No Reino Unido, entre em contato com:</h3>
          <p>The Information Commissioner’s Office</p>
          <p>
            Water Lane, Wycliffe House
            <br />
            Wilmslow &#8211; Cheshire SK9 5AF
          </p>
          <p>Tel. +44 1625 545 700</p>
          <p>
            Email: <b>casework@ico.org.uk</b>
          </p>
          <p>
            Website: <b>https://ico.org.uk</b>
          </p>
          <h3>Na União Europeia, consulte:</h3>
          <p>
            <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
          </p>
        </div>
      </div>
      <div id="contact_us">
        <div>
          <h2>Contato &ndash;</h2>
          <p>
            Para entrar em contato com a CAI com perguntas ou preocupações sobre este Aviso de Privacidade ou práticas
            da CAI quanto aos dados pessoais, utilize os dados de contato abaixo: Se você for residente do EEE, observe
            que onde a CAI atua como controladora de dados responsável pelos seus dados pessoais, a pessoa jurídica é a
            Command Alkon Incorporated, nos Estados Unidos. A CAI abordará todas as perguntas e reclamações relacionadas
            a este Aviso de Privacidade em um período razoável.
          </p>
          <p>
            <strong>E-mail:</strong>
          </p>
          <p>
            <b>privacy@commandalkon.com</b>
          </p>
          <p>
            <strong>Ou escreva para:</strong>
          </p>
          <p>Command Alkon Incorporated</p>
          <p>Chief Privacy Officer c/o The Legal Department</p>
          <p>
            1800 International Park Drive
            <br />
            Suite 400
            <br />
            Birmingham, Alabama 35243
          </p>
          <p>
            <strong>Ou telefone para:</strong>
          </p>
          <p>
            1-800-624-1872 (chamada gratuita dentro dos
            <br />
            EUA)
          </p>
          <p>0-800-022-9682 (chamada gratuita fora dos EUA)</p>
          <p>
            <strong>Website:</strong>
          </p>
          <p>
            <b>www.commandalkon.com</b>
          </p>
        </div>
      </div>
    </Styled>
  );
};
