import { css } from 'styled-components';

export default css`
  padding: 30px;

  .buttons-signature {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .finalizeButton {
    width: 200px;
  }
  
  button {
    width: 100%;
    height: 50px;
    font-size: 1.2em;
  }

  &.finalize {
    flex: 1;
    text-align: right;
  }

  &.accept-reject {
    flex: 1;
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    & > * {
      &:first-child {
        margin-right: 20px;
        flex: 1;
        button {
          background: green;
        }
      }
      &:last-child {
        margin-left: 20px;
      }
    }
  }
`;
