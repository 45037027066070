import { Drawer, Form, Responsive } from 'connex-cds';
import React from 'react';
import { useTicketContext } from '../../../TicketContext';
import { PaymentMethodDesktop } from './PaymentMethodDesktop';
import { PaymentMethodMobile } from './PaymentMethodMobile';

export const PaymentMethod = () => {
  const { validateForm, resetForm, setFieldValue, values } = Form.useFormContext();
  const { ticket, paymentMethod } = useTicketContext();

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  React.useEffect(() => {
    if (ticket === null) {
      resetForm({ reinitialize: true });
    }
  }, [resetForm, ticket]);

  React.useEffect(() => {
    if (!values?.paymentMethodRef) {
      setFieldValue('paymentMethodRef', paymentMethod?.crn);
    }
  }, [paymentMethod?.crn, setFieldValue]);

  return (
    <Drawer.DrawerProvider>
      <Responsive>
        <PaymentMethodMobile />
        <PaymentMethodDesktop />
      </Responsive>
    </Drawer.DrawerProvider>
  );
};
