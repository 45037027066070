import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { TicketNumber } from './TicketNumber';
import { TimeStamp } from './TimeStamp';

const { Column, Row, Container } = Layout;

const Styled = styled(Column)`
  & > .row {
    & > .column {
      margin-right: 10px;
    }
  }
  ${style}
`;

export const SignatureContainerDesktop = () => {
  const {
    Components: { Signature },
  } = Form.useFormContext();

  return (
    <Styled className={cn('signature-section')}>
      <Row flex={1}>
        <Column>
          <Container flex={1} style={{ padding: '5px 10px' }}>
            <Signature />
            <div className="caption">
              <TicketNumber /> <TimeStamp />
            </div>
          </Container>
        </Column>
      </Row>
    </Styled>
  );
};
