import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import orderRecipients from '../api/order-recipients';

export const useGetOrderRecipients = (orderRef, ticket, companySetup) => {
  const { entityRef } = useParams();

  const isPDFRecipientListEnabled = companySetup?.data?.isPDFRecipientListEnable ?? false;

  const query = useQuery({
    queryKey: ['order-recipients', entityRef, orderRef, ticket],
    queryFn: () => orderRecipients.getOrderRecipients({ entityRef, orderRef }),
    staleTime: Infinity,
    enabled: isPDFRecipientListEnabled && !!entityRef && !!orderRef,
    refetchOnWindowFocus: false,
  });

  return query;
};

export const useUpdateOrderRecipients = orderRef => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['order-recipients', entityRef, orderRef];

  const mutation = useMutation({
    mutationFn: item => orderRecipients.updateOrderRecipients({ entityRef, orderRef, item }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
