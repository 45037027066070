import { css } from 'styled-components';

export default css`
  padding: 10px 10px;
  margin-right: 10px;
  @media (max-width: 950px) {
    padding: 10px;
    margin: 5px;
  }
`;
