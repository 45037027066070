import React from 'react';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import getProducts from '../../../getProducts';
import { Products } from './costAndPayments/products/Products';
import { Summary } from './costAndPayments/summary/Summary';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { DynamicWaiver } from './dynamic-waiver/DynamicWaiver';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import { PriorSignatures } from './prior-signatures/PriorSignatures';
import style from './style';

const { Column, Row } = Layout;
const { useTranslateMessage } = Localization;

const Styled = styled(Column)`
  ${style}
`;

export const AcceptanceDesktop = ({
  lineItems,
  handleBackClick,
  signed,
  flow,
  activeFormsAndWaivers,
  entitySupportedLanguages,
  isEnablePaymentAndCostWorkflows,
}) => {
  const { finalized, submitted, ticket, paymentMethod } = useTicketContext();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const translateMessage = useTranslateMessage();

  const [isCod, setIsCod] = React.useState(false);

  React.useEffect(() => {
    if (paymentMethod?.isCOD && isEnablePaymentAndCostWorkflows) {
      setIsCod(true);
    }
  }, [paymentMethod, isEnablePaymentAndCostWorkflows]);

  const showDynamicWaiver = React.useMemo(() => {
    return !!companySetup?.data?.isDynamicWaiverEnabled && activeFormsAndWaivers?.length !== 0;
  }, [activeFormsAndWaivers?.length, companySetup?.data?.isDynamicWaiverEnabled]);

  const showPriorSignatures = React.useMemo(() => {
    return values?.damageWaiverSignature?.image || values?.waterReleaseSignature?.image || values?.producerSignature?.image;
  }, [values.damageWaiverSignature, values.waterReleaseSignature, values.producerSignature]);

  return (
    <Styled className="acceptance-desktop">
      <Column style={{ height: 'auto' }}>
        {(finalized || submitted) && (
          <div className="header">
            {signed || submitted ? null : (
              <Core.Button
                size="small"
                type="primary"
                stringId="back"
                data-testid="back-button"
                onClick={handleBackClick}
              />
            )}
            <Typography.H3>
              <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
            </Typography.H3>
            <Header flow={flow} entitySupportedLanguages={entitySupportedLanguages} />
          </div>
        )}
        <Row flex={1}>
          <Column flex={1} style={{ height: '420px' }}>
            <LogisticalInfo ticket={values} />
          </Column>
          <Column flex={2} className="scrollable">
            <Row flex={1} style={{ minHeight: '89px' }}>
              <Activity lineItems={lineItems} />
            </Row>
            <Row flex={1} style={{ minHeight: '98px' }}>
              <Comments />
            </Row>
            {(showPriorSignatures || showDynamicWaiver) && (
              <Row flex={1} style={{ minHeight: '98px' }}>
                {showPriorSignatures && (
                  <Column>
                    <PriorSignatures />
                  </Column>
                )}
                {showDynamicWaiver && (
                  <Column>
                    <DynamicWaiver />
                  </Column>
                )}
              </Row>
            )}
            {isCod && (
              <Row style={{ gap: '10px', minHeight: '200px' }}>
                <Column style={{ overflowY: 'auto', maxHeight: '500px' }}>
                  <Products
                    productsData={getProducts(ticket?.lineItems, values?.lineItems, submitted)}
                    values={values}
                  />
                </Column>
                <Column>
                  <Summary
                    show={true}
                    products={getProducts(ticket?.lineItems, values?.lineItems, submitted)}
                    pricing={values?.pricing}
                    paymentAmount={values?.paymentAmount}
                    paymentMethod={
                      values?.isExpectedPaymentMethod ? paymentMethod?.name : translateMessage('otherPaymentMethod')
                    }
                  />
                </Column>
              </Row>
            )}
            <Row flex={1} style={{ overflow: 'initial' }}>
              <FinalizeSection ticketId={values.id} flow={flow} />
            </Row>
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
