import { get, isUndefined } from 'lodash';
import constants from '../constants';

export class Uom {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token, context, util } = params;
    if (params?.token?.startsWith?.(constants.UOM)) {
      const path = token.slice(5);

      const uom = get(context, path) || '';

      if (isUndefined(uom?.value)) return constants.EMPTY;

      const stringId = `${uom?.uomCode}`;
      const values = { value: uom?.value };

      return util?.translateMessage?.(stringId, values) || '';
    }
    return this?.next?.handle?.(params);
  }
}
