import cn from 'classnames';
import { Core, Form, Drawer, Localization, Layout, Typography, getCachedItem } from 'connex-cds';
import React from 'react';
import Styled from './style';
import { AcceptanceSignature } from '../../AcceptanceSignature';
import { ContactCardInfo } from '../contact-card/ContactCard';

const { Row } = Layout;

export const EnterRecipientsEditor = ({ handleAcceptCancel, ticketId }) => {
  const { Components, setFieldValue, values } = Form.useFormContext();
  const { openDrawer } = Drawer.useDrawerContext();
  const { userLocale } = Localization.useLocalizationContext();

  const [recipients, setRecipients] = React.useState([]);

  React.useEffect(() => {
    if (!values.orderRecipients) return;
    const recipients = values.orderRecipients.map(recip => ({ ...recip, isValid: true }));
    setRecipients(prevRecipients => [
      ...prevRecipients,
      ...recipients.filter(recip => !prevRecipients.some(el => el.email === recip.email)),
    ]);
    recipients.length > 0 && setFieldValue('hasSentRecipients', true);
  }, [values.orderRecipients]);

  const onRemoveCard = React.useCallback(
    elem => {
      setRecipients(prevRecipients => prevRecipients.filter(recipient => recipient.email !== elem.email));
    },
    [setRecipients]
  );

  const handleClear = React.useCallback(() => {
    setFieldValue('enterRecipients', '');
  }, [setFieldValue]);

  const handleEnterRecipients = React.useCallback(() => {
    if (values?.enterRecipients) {
      const arrayEmailsWithoutValidation = values?.enterRecipients
        ?.split(',')
        .map(recip => ({ email: recip.trim() }))
        .filter(recip => recip.email !== '');

      const arrayEmailsWithValidation = arrayEmailsWithoutValidation.map(recipient => {
        const isValid = /^[a-zA-Z0-9._\-\+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(recipient.email);
        return {
          ...recipient,
          isValid,
          locale: userLocale,
        };
      });

      const finalArray = arrayEmailsWithValidation.filter(recip => !recipients.some(el => el.email === recip.email));

      setRecipients(prevRecipients => [...prevRecipients, ...finalArray]);
      handleClear();
    }
  }, [values?.enterRecipients, setRecipients, handleClear]);

  const handleSaveRecipients = React.useCallback(async () => {
    const arrayFiltered = recipients
      .filter(recip => recip.isValid)
      .map(recip => {
        const { isValid, ...restOfData } = recip;
        return restOfData;
      });
    setFieldValue('orderRecipients', arrayFiltered);
    setFieldValue('hasSentRecipients', true);
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId: 'customerSignature',
      component: <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />,
      width: '100%',
    });
  }, [setFieldValue, openDrawer, handleAcceptCancel, ticketId, recipients, setFieldValue]);

  return (
    <Styled className={cn('enter-recipients-editor')}>
      <div>
        <Components.EnterRecipients validationText={false} />
        <div className="recipients-instructions">
          <Localization.Translate stringId="recipientsInstructions" data-testid="recipients-instructions-label" />
        </div>
        <div className="buttons">
          <div>
            <Core.Button onClick={handleClear} stringId="clear" data-testid="clear-button" />
          </div>
          <div>
            <Core.Button type="primary" onClick={handleEnterRecipients} stringId="enter" data-testid="enter-button" />
          </div>
        </div>
        <Row style={{ flexWrap: 'wrap' }}>
          {recipients.map((elem, index) => (
            <div>
              <ContactCardInfo key={index} email={elem.email} onRemove={() => onRemoveCard(elem)} />
              {!elem.isValid && (
                <Typography.Helper className="invalid">
                  <Localization.Translate stringId="invalidEmailAddress" data-testid="invalid-email-address-label" />
                </Typography.Helper>
              )}
            </div>
          ))}
        </Row>
      </div>
      <div className="save-block">
        <span className="personal-data">
          <Localization.Translate stringId="personalDataPolicy" data-testid="personal-data-policy-label" />
        </span>
        <Core.Button type="primary" onClick={handleSaveRecipients} stringId="save" data-testid="save-button" />
      </div>
    </Styled>
  );
};
