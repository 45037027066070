import React from 'react';
import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import { find } from 'lodash';
import { useGetCompanyAdmin } from '../../../../../../../query-hooks/company';
import { languagesNames } from '../../../setup/languagesNames';
import {
  useCompanySetup,
  useFormsAndWaiversSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import { activeFormsAndWaiversArray } from '../../../getActiveForms';
import { useTicketContext } from '../../../TicketContext';
import { AcceptanceMobile } from './AcceptanceMobile';
import { AcceptanceDesktop } from './AcceptanceDesktop';

export const FLOW = {
  ACCEPT_REJECT: 'accept-reject',
  SIGNATURE_NO_SIGNATURE: 'signature-no-signature',
};

export const Acceptance = ({ handleBackClick }) => {
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const { ticket } = useTicketContext();
  const { userLocale } = Localization.useLocalizationContext();
  const { translateObjects } = Localization.useTranslateObjects();
  const translateMessage = Localization.useTranslateMessage();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const companyAdminSetup = useGetCompanyAdmin();

  const entitySupportedLanguages = React.useMemo(() => {
    const companyAllowedLanguages = Object.keys(companySetup?.data)
      .filter(key => key?.includes('allow') && key?.includes('Language') && companySetup?.data[key])
      .map(key => {
        const variableName = key?.replace(/(allow)|Language$/gi, '');
        const languages = find(languagesNames, { variableName });
        return languages?.locale;
      });

    const entityAllowedLanguages = companyAdminSetup.data?.languageSettings?.allowedLanguages.map(lang => lang.value);

    return companyAdminSetup.data?.languageSettings ? entityAllowedLanguages : companyAllowedLanguages;
  }, [companySetup?.data, userLocale, companyAdminSetup]);

  const isEnablePaymentAndCostWorkflows = React.useMemo(
    () => companySetup?.data?.isEnablePaymentAndCostWorkflows ?? false,
    [companySetup?.data?.isEnablePaymentAndCostWorkflows]
  );

  // TODO: This will come from company config.
  const flow = FLOW.SIGNATURE_NO_SIGNATURE;

  const signed = React.useMemo(() => {
    return !!values?.signature?.image;
  }, [values?.signature?.image]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'DYNAMICWAIVER',
        formsAndWaiversSetup,
        vehicleSetup,
        vehicleTypeSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, vehicleTypeSetup, companySetup, translateObjects, userLocale]
  );

  const translateLabelCallback = React.useCallback(
    item => {
      if (userLocale) {
        const tokenKey = `SETUP_PRODUCT_${item?.id}`.replace(/\s/gi, '_').toUpperCase();
        const tokenTranslated = translateMessage(tokenKey);
        return tokenKey !== tokenTranslated ? tokenTranslated : item?.name ?? item?.description ?? '';
      }

      return item?.name ?? item?.description ?? '';
    },
    [userLocale, translateMessage]
  );

  const lineItems = React.useMemo(() => {
    return values?.lineItems
      ?.filter(lineItem => !lineItem.isPrimary && !lineItem.driverDidNotAdd && !lineItem.isSplitLoad)
      ?.map(materialService => ({
        label: translateLabelCallback(materialService?.item),
        quantity: materialService?.quantity,
        concreteOnTruck: materialService?.concreteOnTruck,
        reason: materialService?.reason,
      }));
  }, [values?.lineItems, translateLabelCallback]);

  return (
    <Drawer.DrawerProvider>
      <Responsive>
        <AcceptanceMobile
          lineItems={lineItems}
          handleBackClick={handleBackClick}
          signed={signed}
          flow={flow}
          activeFormsAndWaivers={activeFormsAndWaivers}
          entitySupportedLanguages={entitySupportedLanguages}
          isEnablePaymentAndCostWorkflows={isEnablePaymentAndCostWorkflows}
        />
        <AcceptanceDesktop
          lineItems={lineItems}
          handleBackClick={handleBackClick}
          signed={signed}
          flow={flow}
          activeFormsAndWaivers={activeFormsAndWaivers}
          entitySupportedLanguages={entitySupportedLanguages}
          isEnablePaymentAndCostWorkflows={isEnablePaymentAndCostWorkflows}
        />
      </Responsive>
    </Drawer.DrawerProvider>
  );
};
