import React from 'react';
import { TemplateTab } from './TemplateTab';
import { TemplateTabCustomValues } from './TemplateTabCustomValues';
import { TemplateTabAvailableValues } from './TemplateTabAvailableValues';

const templateTabConfig = {
  tabs: [
    {
      stringId: 'materialSummary',
      testId: 'materialSummaryLabel',
      component: <TemplateTab templateName="materialSummary" />,
    },
    {
      stringId: 'generalDelivery',
      testId: 'generalDeliveryLabel',
      component: <TemplateTab templateName="generalDeliveryInfo" />,
    },
    {
      stringId: 'materialInfo',
      testId: 'materialInfoLabel',
      component: <TemplateTab templateName="materialInfo" />,
    },
    {
      stringId: 'availableCustomValues',
      testId: 'availableCustomValues',
      component: <TemplateTabCustomValues templateName="availableCustomValues" />,
    },
    {
      stringId: 'availableValues',
      testId: 'availableValues',
      component: <TemplateTabAvailableValues />,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};

export default templateTabConfig;
