import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { ProgressBarContainer, LinearProgressContainer, ProgressLabelContainer, StyledLinearProgress } from './styles';

const ProgressBar = props => {
  if (props.value === undefined || props.value === null) {
    return null;
  }
  return (
    <ProgressBarContainer>
      <LinearProgressContainer>
        <StyledLinearProgress variant="determinate" {...props} />
      </LinearProgressContainer>
      <ProgressLabelContainer>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </ProgressLabelContainer>
    </ProgressBarContainer>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};
