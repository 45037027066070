import { css } from 'styled-components';

export default css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  margin: 5px;
  height: 150px;
  padding: 0 15px;
  img {
    height: 90px;
    left: 28px;
  }
  .signature-actions {
  }
`;
