import React from 'react';
import Sidebar from './sidebar';

import { MainContainerStyled } from './styles';

const SidebarWrapper = ({ children, pathPrefix = '' }) => {
  return (
    <MainContainerStyled className="main-container">
      <Sidebar pathPrefix={pathPrefix} />
      <div className="content">{children}</div>
    </MainContainerStyled>
  );
};

export default SidebarWrapper;
