import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../../MasterDataProvider';

const { Row } = Layout;

const Styled = styled(Layout.Container)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .list-section {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .list-component {
      display: flex;
      flex-direction: column;
    }
  }
  .row {
    margin-bottom: 5px;
    .left {
      flex: 1;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 15px 0;
`;

export const ListSectionDesktop = ({
  requirementFulfilled,
  CustomListComponent,
  paths,
  onClick,
  autoEdit,
  buttonWaterReleaseSignature,
}) => {
  const {
    Components: { AddButton, ListComponent, RequiredIndicator },
    parentFormContext: { values: parentValues },
  } = Form.useFormContext();

  const companySetup = useCompanySetup();

  const addButtonDisabled = React.useMemo(
    () => parentValues?.lineItems?.length >= companySetup.data?.maxLineItems - 1,
    [companySetup.data?.maxLineItems, parentValues?.lineItems?.length]
  );

  const renderButtonWaterReleaseSignature = () => {
    if (buttonWaterReleaseSignature) {
      const buttonWaterReleaseSignatureHidden = buttonWaterReleaseSignature?.props?.hidden ?? true;
      return (
        <ButtonsContainer>
          {buttonWaterReleaseSignature}
          {buttonWaterReleaseSignatureHidden && <div />}
          <AddButton onClick={onClick} disabled={addButtonDisabled} />
        </ButtonsContainer>
      );
    }

    return null;
  };

  const renderAddButtonLegacy = () => {
    if (!buttonWaterReleaseSignature) {
      return (
        <div style={{ flexShrink: '0', marginTop: '15px', textAlign: 'right' }}>
          <AddButton onClick={onClick} disabled={addButtonDisabled} />
        </div>
      );
    }

    return;
  };

  return (
    <>
      {renderButtonWaterReleaseSignature()}

      <Styled className={cn('list')} style={{ overflow: 'visible' }}>
        <Row style={{ alignItems: 'flex-end', flexShrink: '0' }}>
          <div className="left">
            <RequiredIndicator disabled={requirementFulfilled} />
          </div>
        </Row>
        <div className="list-section">
          <ListComponent
            Component={CustomListComponent}
            onClick={onClick}
            hideWhenEmpty
            paths={paths}
            autoEdit={autoEdit}
          />
        </div>
      </Styled>

      {renderAddButtonLegacy()}
    </>
  );
};
