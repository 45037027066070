import cn from 'classnames';
import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import { filter, map, sortBy } from 'lodash';
import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useViewport } from '../../../../../../util/useViewport';
import { useCompanySetup } from '../../MasterDataProvider';
import style from '../../style';
import { useTicketContext } from '../../TicketContext';
import EditStatusTime from './EditStatusTime';
import { StatusTimesDesktop } from './StatusTimesDesktop';
import { StatusTimesMobile } from './StatusTimesMobile';

const { useTranslateObjects } = Localization;
const { useDrawerContext } = Drawer;

const Styled = styled.div`
  ${style}
`;

const StatusTimes = () => {
  const { entityRef } = useParams();
  const { ticket, refreshTicket, refreshing } = useTicketContext();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const ticketEvents = useMemo(
    () => map(ticket?.ticketEvents, (value, key) => ({ eventType: key, ...value })),
    [ticket?.ticketEvents]
  );

  const filteredEvents = useMemo(() => {
    return filter(ticketEvents, event => {
      switch (event?.eventType) {
        case 'PRINTED':
          if (!companySetup?.data?.hidePrintedEvent) return true;
          break;
        case 'LOADING_STARTED':
          if (!companySetup?.data?.hideLoadingStartedEvent) return true;
          break;
        case 'LOADING_COMPLETE':
          if (!companySetup?.data?.hideLoadingCompleteEvent) return true;
          break;
        case 'TO_JOB':
          if (!companySetup?.data?.hideToJobEvent) return true;
          break;
        case 'ARRIVE_JOB':
          if (!companySetup?.data?.hideArriveJobEvent) return true;
          break;
        case 'UNLOADING':
          if (!companySetup?.data?.hideUnloadingEvent) return true;
          break;
        case 'END_UNLOADING':
          if (!companySetup?.data?.hideEndUnloadingEvent) return true;
          break;
        case 'LEAVE_JOB':
          if (!companySetup?.data?.hideLeaveJobEvent) return true;
          break;
        case 'IN_YARD':
          if (!companySetup?.data?.hideInYardEvent) return true;
          break;
        case 'PUMPING_STARTED':
          if (!companySetup?.data?.hidePumpingStartedEvent) return true;
          break;
        case 'PUMPING_COMPLETE':
          if (!companySetup?.data?.hidePumpingCompleteEvent) return true;
          break;
        case 'READY_TO_PUMP':
          if (!companySetup?.data?.hideReadyToPumpEvent) return true;
          break;
        default:
          return false;
      }
    });
  }, [ticketEvents, companySetup]);

  const wCContact = useMemo(() => {
    if (ticket?.customData?.productDetails?.wcContactDateTime && !companySetup?.data?.hideFirstWaterCementContact) {
      return {
        eventType: 'FIRST_WCCONTACT',
        eventDateTime: ticket?.customData?.productDetails?.wcContactDateTime,
      };
    }
    return null;
  }, [ticket, companySetup]);

  const updatedEvents = useMemo(() => {
    if (wCContact) {
      return [...filteredEvents, wCContact];
    }
    return filteredEvents;
  }, [wCContact, filteredEvents]);

  const sortedEvents = useMemo(() => {
    return sortBy(updatedEvents, event => {
      return values?.ticketEvents?.[event.eventType]?.eventDateTime || event.eventDateTime;
    });
  }, [updatedEvents, values]);

  const { translateObjects } = useTranslateObjects();
  const translatedTicketEvents = useMemo(() => {
    return translateObjects(sortedEvents, {
      getPath: 'eventType',
      setPath: 'eventTypeTranslated',
    });
  }, [sortedEvents, translateObjects]);

  const { openDrawer } = useDrawerContext();

  const dimensions = useViewport();

  const handleRowEdit = useCallback(
    row => {
      openDrawer({
        titleStringId: 'updateStatusTime',
        width: Math.min(500, dimensions?.width),
        component: <EditStatusTime statusType={row?.eventType} />,
      });
    },
    [dimensions?.width, openDrawer]
  );

  const isRowEditable = useCallback(
    row => {
      switch (row?.eventType) {
        case 'PRINTED':
          if (companySetup?.data?.isPrintedEditable) return true;
          break;
        case 'LOADING_STARTED':
          if (companySetup?.data?.isLoadingStartedEditable) return true;
          break;
        case 'LOADING_COMPLETE':
          if (companySetup?.data?.isLoadingCompleteEditable) return true;
          break;
        case 'TO_JOB':
          if (companySetup?.data?.isToJobEditable) return true;
          break;
        case 'ARRIVE_JOB':
          if (companySetup?.data?.isArriveJobEditable) return true;
          break;
        case 'UNLOADING':
          if (companySetup?.data?.isUnloadingEditable) return true;
          break;
        case 'END_UNLOADING':
          if (companySetup?.data?.isEndUnloadingEditable) return true;
          break;
        case 'LEAVE_JOB':
          if (companySetup?.data?.isLeaveJobEditable) return true;
          break;
        case 'IN_YARD':
          if (companySetup?.data?.isInYardEditable) return true;
          break;
        case 'PUMPING_STARTED':
          if (companySetup?.data?.isPumpingStartedEditable) return true;
          break;
        case 'PUMPING_COMPLETE':
          if (companySetup?.data?.isPumpingCompleteEditable) return true;
          break;
        case 'READY_TO_PUMP':
          if (companySetup?.data?.isReadyToPumpEditable) return true;
          break;
        default:
          return false;
      }
    },
    [
      companySetup?.data?.isArriveJobEditable,
      companySetup?.data?.isEndUnloadingEditable,
      companySetup?.data?.isInYardEditable,
      companySetup?.data?.isLeaveJobEditable,
      companySetup?.data?.isLoadingCompleteEditable,
      companySetup?.data?.isLoadingStartedEditable,
      companySetup?.data?.isPrintedEditable,
      companySetup?.data?.isPumpingCompleteEditable,
      companySetup?.data?.isPumpingStartedEditable,
      companySetup?.data?.isReadyToPumpEditable,
      companySetup?.data?.isToJobEditable,
      companySetup?.data?.isUnloadingEditable,
    ]
  );

  const handleRefreshClick = useCallback(() => {
    refreshTicket(entityRef);
  }, [entityRef, refreshTicket]);

  return (
    <Styled className={cn('ticket-events', 'mt-container')}>
      <Responsive>
        <StatusTimesMobile
          ticketEvents={translatedTicketEvents}
          isRowEditable={isRowEditable}
          handleRowEdit={handleRowEdit}
          handleRefreshClick={handleRefreshClick}
          refreshing={refreshing}
        />
        <StatusTimesDesktop
          ticketEvents={translatedTicketEvents}
          isRowEditable={isRowEditable}
          handleRowEdit={handleRowEdit}
          handleRefreshClick={handleRefreshClick}
          refreshing={refreshing}
        />
      </Responsive>
    </Styled>
  );
};

export default StatusTimes;
