import React from 'react';
import handleError from './util/log/error';

export class GenericErrorBoundary extends React.Component {
  state = { hasError: false, visible: false };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    handleError(null, { error, errorInfo, debugInfo: this.props.debugInfo });
    this.setState({ stackTrace: errorInfo.componentStack });
  }

  handleClick = () => {
    this.setState(s => ({ ...s, visible: !s.visible }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px', backgroundColor: 'white' }}>
          <h3 onClick={this.handleClick} style={{ cursor: 'pointer' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: '#FF0000', marginRight: '15px', fontSize: '2.2em' }}>
                <i className="fa-solid fa-triangle-exclamation"></i>
              </div>
              <div>An error has occurred</div>
            </div>
          </h3>

          <div
            style={{
              padding: '20px',
              backgroundColor: '#FFF0F0',
              border: '1px solid #FF2F2F',
              borderRadius: '7px',
              color: '#FF2F2F',
            }}
          >
            <pre style={{ fontSize: '14px' }}>
              {this.state.error?.message}
              <hr />
              {this.state.error?.stack}
            </pre>
          </div>
          <div style={{ marginTop: '20px' }}>
            <span onClick={() => window?.location?.reload?.()}>Reload</span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
