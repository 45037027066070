import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetPublicSummary } from '../MasterDataProvider';
import { Core, Layout, Responsive } from 'connex-cds';
import styled from 'styled-components';

const { Container, Row, Column } = Layout;

const Styled = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
`;

export const PublicSummaryRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<PublicSummary />} />
    </Routes>
  );
};

export const PublicSummary = () => {
  const params = useParams();
  const { data, loading } = useGetPublicSummary(params?.data);

  const copyToClip = React.useCallback(() => {
    if (!data) {
      return;
    }

    navigator.clipboard.writeText(data?.context ?? '');
  }, [data]);

  return (
    <Core.Spinner spin={loading}>
      <Responsive>
        <Column>
          <Row flex={1} style={{ padding: '10px' }}>
            <Column>
              <Column style={{ overflow: 'hidden' }}>
                <Container flex={1}>
                  <div style={{ whiteSpace: 'pre-line' }}>{data?.context}</div>
                </Container>
              </Column>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Core.Button type="primary" stringId="copy" data-testid="test-id" onClick={copyToClip} />
              </Row>
            </Column>
          </Row>
        </Column>
        <Column>
          <Row flex={1} style={{ padding: '10px' }}>
            <Column />
            <Column flex={3}>
              <Column style={{ overflow: 'hidden' }}>
                <Container flex={1}>
                  <div style={{ whiteSpace: 'pre-line' }}>{data?.context}</div>
                </Container>
              </Column>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Core.Button type="primary" stringId="copy" data-testid="copy-ticket-context" onClick={copyToClip} />
              </Row>
            </Column>
            <Column />
          </Row>
        </Column>
      </Responsive>
    </Core.Spinner>
  );
};
