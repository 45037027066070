import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { users } from '../api';

export const useListUsers = options => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: [options.isHauler ? 'haulers' : 'users', entityRef],
    queryFn: () => users.listUsers({ entityRef, options }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const getUserRole = profileRef => {
  const { entityRef } = useParams();

  const queryKey = ['userRoles', entityRef, profileRef];

  const query = useQuery({
    queryKey,
    queryFn: () => users.getUserRole({ entityRef, profileRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const getUserRbac = profileRef => {
  const { entityRef } = useParams();

  const queryKey = ['userRbac', entityRef, profileRef];

  const query = useQuery({
    queryKey,
    queryFn: () => users.getUserRbac({ entityRef, profileRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useInviteUser = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['invitedUsers', entityRef];

  const mutation = useMutation({
    mutationFn: user =>
      users.inviteUser({
        entityRef,
        user,
      }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
