import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { CustomListComponent } from '../custom-list-component/CustomListComponent';
import { ListSectionDesktop } from '../custom-list-component/desktop/ListSectionDesktop';
import { ListSectionMobile } from '../custom-list-component/mobile/ListSectionMobile';
import { FormSection } from './FormSection';

const Styled = styled.div`
  flex: 1;
  overflow: visible;
  .list {
    overflow: visible;
    .list-section {
      overflow: visible;
      .list-component {
        overflow: visible;
      }
    }
  }
`;

const defaultPaths = {
  description: ['item.name', 'item.description'],
  driverDidNotAdd: 'driverDidNotAdd',
  quantity: 'quantity',
  isReasonFieldEnabled: 'item.mobileTicket.isReasonFieldEnabled',
  isConcreteOnTruckFieldEnabled: 'item.mobileTicket.isConcreteOnTruckFieldEnabled',
  isTimeFieldEnabled: 'item.mobileTicket.isTimeFieldEnabled',
  concreteOnTruck: 'concreteOnTruck',
  time: 'time',
  reason: 'reason.description',
  isPrimary: 'isPrimary',
  errors: 'lineItems',
  unitPrice: 'unitPrice',
  isSplitLoad: 'isSplitLoad',
};

export const View = () => {
  const {
    fieldConfig,
    resetMode,
    parentFormContext: { isValid, values },
  } = Form.useFormContext();

  const { openDrawer } = Drawer.useDrawerContext();

  const { userLocale } = Localization.useLocalizationContext();

  const paths = React.useMemo(
    () => ({
      ...defaultPaths,
      description: [`item.i18n.${userLocale}`, 'item.i18n.en-US', ...defaultPaths?.description],
    }),
    [userLocale]
  );

  const hasItems = React.useMemo(() => {
    const items =
      get(values, fieldConfig.path)?.filter?.(lineItem => !lineItem?.isPrimary && !lineItem?.isSplitLoad) || [];
    return !!items?.length;
  }, [fieldConfig.path, values]);

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'addMaterialService',
      component: <FormSection />,
      onClose: resetMode,
      width: '100%',
    });
  }, [openDrawer, resetMode]);

  return hasItems ? (
    <Styled>
      <Responsive>
        <ListSectionMobile
          requirementFulfilled={isValid}
          CustomListComponent={CustomListComponent}
          paths={paths}
          onClick={handleClick}
        />
        <ListSectionDesktop
          requirementFulfilled={isValid}
          CustomListComponent={CustomListComponent}
          paths={paths}
          onClick={handleClick}
        />
      </Responsive>
    </Styled>
  ) : (
    <FormSection />
  );
};
