import { css } from 'styled-components';

export default css`
  padding-top: 24px;
  &.loading-unloading-points {
    padding: 5px;
    display: flex;
    flex-direction: row;
    .left {
      flex: 1;
      padding-left: 10px;
    }
  }

  &.detail {
    & > .container {
      padding: 10px;
      .subtitle {
        margin-bottom: 10px;
      }
      .label {
        font-weight: bold;
      }
    }
    .unloading-point {
      margin-top: 20px;
    }
  }
`;
