import common from '../../i18n/common';

const DEFAULT_LANGUAGE = 'en-US';

export const matchLanguage = (language = '') => {
  const languages = Object.keys(common?.languages);

  if (!language || language === '') {
    return DEFAULT_LANGUAGE;
  }

  const languageMatch = languages.find(lang => lang === language);
  if (languageMatch) {
    return languageMatch;
  }

  const languagePartialMatch = languages.find(lang => lang.includes(String(language ?? '').slice(0, 2)));
  if (languagePartialMatch) {
    return languagePartialMatch;
  }

  return DEFAULT_LANGUAGE;
};
