import { Drawer, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { TicketsList } from './TicketsList';

const Styled = styled(Layout.Container)`
  ${style}
`;

export const Tickets = ({ isDLV, ticketId }) => {
  const { openDrawer } = Drawer.useDrawerContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'ticketList',
      component: <TicketsList isDLV={isDLV} ticketId={ticketId} />,
      width: '100%',
      getContainer: false,
    });
  }, [openDrawer]);

  return (
    <Styled className={cn('tickets')} onClick={handleClick}>
      <div className="left">
        <Typography.Subtitle>
          <Localization.Translate stringId="ticketList" data-testid="ticket-list-label" />
        </Typography.Subtitle>
      </div>
      <div className="right">
        <Typography.Subtitle>
          <i className="fa-regular fa-angle-right" />
        </Typography.Subtitle>
      </div>
    </Styled>
  );
};
