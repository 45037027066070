import constants from '../constants';

export class MatSalesText {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token } = params;

    if (token.includes(`${constants.SPLIT_LOAD_MAT_SALES_TEXT}`)) {
      const regex = new RegExp(constants.SPLIT_LOAD_MAT_SALES_TEXT, 'g');
      const newToken = token.replace(regex, 'customData.productDetails.matSalesText');

      return this?.next?.handle?.({ ...params, token: newToken });
    }

    return this?.next?.handle?.(params);
  }
}
