import { Form } from 'connex-cds';
import { ShowSignature } from './ShowSignature';

export const PriorSignaturesModal = () => {
  const { Components, values } = Form.useFormContext();

  return (
    <div>
      {values?.damageWaiverSignature?.image && (
        <ShowSignature
          Signature={Components.DamageWaiverSignature}
          signatureName={values?.damageWaiverSignature?.signerName}
          labelId="damageWaiverSignature"
        />
      )}
      {values?.waterReleaseSignature?.image && (
        <ShowSignature
          Signature={Components.WaterReleaseSignature}
          signatureName={values?.waterReleaseSignature?.signerName}
          labelId="waterReleaseSignature"
        />
      )}
      {values?.producerSignature?.image && (
        <ShowSignature
          Signature={Components.ProducerSignature}
          signatureName={values?.producerSignature?.signerName}
          labelId="producerSignature"
        />
      )}
    </div>
  );
};
