import { css } from '@emotion/react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

export const ButtonStyled = styled(Button)`
  ${({ theme, isOpen }) => css`
    cursor: pointer;
    font-family: Work Sans;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[100] })};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: background-color 0.3s;
    box-shadow: none;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0);
    border: unset;
    padding: 0px 10px;

    .cai-entity-switcher-icon {
      font-size: 28px !important;
      transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    .MuiButton-startIcon {
      margin-right: 10px;
    }

    &:hover {
      box-shadow: none;
      background-color: ${theme.validateMode({
        light: theme.colorsCA.darkBlue[95],
        dark: theme.colorsCA.neutral[30],
      })};
    }

    &:focus {
      box-shadow: none;
    }

    .cai-entity-switcher-label {
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    &.Mui-disabled {
      background-color: unset;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[100] })};

      .MuiButton-endIcon {
        display: none;
      }
    }
  `}
`;
