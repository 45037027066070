import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, Core } from 'connex-cds';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import AppRoutes from './AppRoutes';
import localMessages from './i18n';
import { mergeMessages } from './i18n/entitySpecific';
import menuConfig from './views/apps/entity-ref/menu-config';
import { useEntityTranslations } from './views/apps/entity-ref/mobile-ticket/MasterDataProvider';

const Styled = styled.div`
  height: 100vh;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        return failureCount < 3 && error.status >= 500;
      },
    },
  },
});

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);
  const { data: entityMessages, setEntityRef, loading: fetchingEntityMessages } = useEntityTranslations();

  const handleMessageEvent = React.useCallback(
    event => {
      const type = event?.data?.type;
      if (['entity-context', 'req-entity-context'].includes(type)) {
        setEntityRef(event?.data?.token?.entityRef);
      }
    },
    [setEntityRef]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  React.useEffect(() => {
    const [entityRef] = window?.location?.pathname?.split('/').filter(Boolean);
    if (entityRef) {
      setEntityRef(entityRef);
    }

    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  const messages = React.useMemo(() => {
    if (!fetchingEntityMessages) {
      return mergeMessages({ entityMessages, localMessages });
    }

    return localMessages;
  }, [entityMessages, fetchingEntityMessages]);

  return (
    <QueryClientProvider client={queryClient}>
      <Core.Spinner spin={isConfiguring || fetchingEntityMessages}>
        <BrowserRouter>
          <ConnexDesignSystem
            messages={messages}
            appId="mt"
            breakpoints={[950]}
            analytics={false}
            menuConfig={menuConfig}
            contentOnly={true}
            queryClient={queryClient}
            initialTimeZone="America/Chicago"
          >
            <Styled>
              <AppRoutes />
            </Styled>
          </ConnexDesignSystem>
        </BrowserRouter>
      </Core.Spinner>
    </QueryClientProvider>
  );
};

export default App;
