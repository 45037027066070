import React from 'react';
import { Form, Localization } from 'connex-cds';

const { Translate } = Localization;
const testId = 'activityGrid-damageWaiverAck';

export const DamageWaiverAck = () => {
  const { values } = Form.useFormContext();

  if (values.damageWaiverDeclined || !values.damageWaiverSignature?.image) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>
        <Translate stringId="damageWaiverAcknowledged" />
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};
