import { Core, Form, Layout, Typography, Localization } from 'connex-cds';
import React from 'react';
import { isEmpty } from 'lodash';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { languagesNames } from '../../../languagesNames';
import { getLanguagesConfig } from './helpers/getLanguagesComponents';

const { Column, Row } = Layout;

const formatVariableName = name => `${name[0].toUpperCase()}${name.slice(1)}`;

export const SupportedLanguages = ({ busy, onSave }) => {
  const { Components, errors, setFieldValue, values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const defaultLanguageSetup = React.useMemo(() => {
    const companySetupArr = Object.keys(companySetup?.data || {});
    const companySetupArrFilter = companySetupArr
      .filter(elem => /default[a-zA-Z]+Language/.test(elem))
      .filter(elem => companySetup?.data[elem])
      .map(elem => elem.replace('default', '').replace('Language', '').toLowerCase());
    return companySetupArrFilter.length > 0 ? companySetupArrFilter.reduce(elem => elem) : '';
  }, [companySetup?.data]);

  const [defaultLanguage, setDefaultLanguage] = React.useState(defaultLanguageSetup);

  const allowLanguages = React.useMemo(() => {
    const languages = {};
    Object.keys(values)
      .filter(elem => /allow[a-zA-Z]+Language/.test(elem))
      .forEach(elem => (languages[elem] = Boolean(values[elem])));
    return languages;
  }, [values]);

  const hasAllowLanguages = React.useMemo(() => {
    return Object.keys(values)
      .filter(elem => /allow[a-zA-Z]+Language/.test(elem))
      .map(elem => values[elem])
      .filter(elem => Boolean(elem));
  }, [values]);

  const handleDefaultLanguage = React.useCallback(
    languageName => {
      setDefaultLanguage(languageName);
      languagesNames.forEach(elem => {
        if (languageName === elem.languageName) {
          setFieldValue(`default${elem.variableName}Language`, true);
          setFieldValue(`allow${elem.variableName}Language`, true);
          return;
        }
        setFieldValue(`default${elem.variableName}Language`, false);
      });
    },
    [languagesNames, setDefaultLanguage, setFieldValue]
  );

  const handleAllowLanguage = React.useCallback(
    elem => {
      const currentVal = values[elem.allowVariable];
      const newVal = !currentVal;
      setFieldValue(elem.allowVariable, newVal);
      const isAllowEqualToDefault = elem.allowVariable.toLowerCase().includes(defaultLanguage);

      if ((hasAllowLanguages.length >= 2 && newVal) || hasAllowLanguages.length === 1 || !isAllowEqualToDefault) {
        return;
      }

      const currentLangs = Object.keys(allowLanguages)?.filter(lang => allowLanguages[lang]);
      const newDefaultField = currentLangs
        .find(element => !element.toLowerCase().includes(elem.languageName))
        ?.replace('allow', 'default');
      const newDefaultName = getLanguagesConfig({ Components }).find(
        elem => elem.defaultVariable === newDefaultField
      )?.languageName;

      setFieldValue(`default${formatVariableName(defaultLanguage)}Language`, false);
      setDefaultLanguage(newDefaultName);
      setFieldValue(newDefaultField, true);
      setFieldValue(elem.allowVariable, false);
      return;
    },
    [values, setFieldValue, hasAllowLanguages, defaultLanguage]
  );

  const allowComponent = React.useCallback(
    elem => {
      const { allowComponent: AllowComponent, languageName } = elem;
      const currentLang = Object.keys(allowLanguages)?.find(lang => lang?.toLowerCase()?.includes(languageName));
      return (
        <AllowComponent
          disabled={hasAllowLanguages.length < 2 && allowLanguages[currentLang]}
          checked={values[currentLang]}
          onClick={() => handleAllowLanguage(elem)}
        />
      );
    },
    [allowLanguages, hasAllowLanguages, values]
  );

  const defaultComponent = React.useCallback(
    elem => {
      const { defaultComponent: DefaultComponent, languageName } = elem;
      return (
        <DefaultComponent
          checked={defaultLanguage === languageName}
          onClick={() => handleDefaultLanguage(languageName)}
        />
      );
    },
    [defaultLanguage]
  );

  return (
    <Column flex={1}>
      {getLanguagesConfig({ Components, defaultLanguage }).map((elem, index) => (
        <Row key={index}>
          <Column flex={1}>
            <Typography.Subtitle>
              <Localization.Translate stringId={elem.labelId} data-testid={elem.testId} />
            </Typography.Subtitle>
          </Column>
          <Column flex={1}>{allowComponent(elem)}</Column>
          <Column flex={1}>{defaultComponent(elem)}</Column>
        </Row>
      ))}
      <div className="actions" style={{ padding: '15px' }}>
        <Core.Button
          loading={busy}
          type="primary"
          data-testid="save-button"
          stringId="save"
          onClick={onSave}
          disabled={!isEmpty(errors)}
        />
      </div>
    </Column>
  );
};
