import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FourG from './android-assets/4G.png';
import battery from './android-assets/battery.png';
import muted from './android-assets/muted.png';

const Styled = styled.div`
  .android-header {
    background-color: black;
    height: 23px;
    display: flex;
    .time {
      flex: 1;
      color: #fff;
      padding-left: 20px;
    }
    .icons {
      display: flex;
      img {
        margin-left: 10px;
      }
      padding-right: 20px;
    }
  }
  .trackit-header {
    background-color: #12415b;
    color: #fdffff;
    height: 67px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      padding-left: 40px;
      font-size: 40px;
      display: flex;
      align-items: center;
      gap: 30px;
      .name {
        font-size: 25px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 20px;
      padding-right: 25px;
      .date-time {
        font-size: 25px;
        color: #fff;
      }
      .icon {
        font-size: 40px;
        color: #6597b6;
      }
    }
  }
`;
export const TrackitHeader = ({ isMobile }) => {
  return (
    <Styled className={cn('trackit-header')}>
      <div className="android-header">
        <div className="time">10:10</div>
        <div className="icons">
          <img src={FourG} height="22" />
          <img src={muted} height="22" />
          <img src={battery} height="22" />
        </div>
      </div>
      {isMobile ? null : (
        <div className="trackit-header">
          <div className="left">
            <i className="fa-sharp fa-solid fa-arrow-left" />
            <div className="name">Demo User</div>
          </div>
          <div className="right">
            <i className="icon fa-solid fa-tower-cell" />
            <div className="date-time">10:10 | Sep 16</div>
            <i className="icon fa-sharp fa-solid fa-house" />
          </div>
        </div>
      )}
    </Styled>
  );
};
