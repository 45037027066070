import { useCallback } from 'react';

import { usePostMessageListener } from './util/entity-provider/usePostMessageListener';
import { useFullStory } from './hooks/useFullStory';

export const FullStoryIdentify = () => {
  // TODO: entityName is undefined.  Need to figure out why.
  const { turnOnFullStory, turnOffFullStory } = useFullStory();

  const handleMessageEvent = useCallback(
    message => {
      if (message.type === 'allow-full-story') {
        message.payload ? turnOnFullStory() : turnOffFullStory();
      }
    },
    [turnOnFullStory, turnOffFullStory]
  );

  usePostMessageListener(handleMessageEvent);
};
