import { Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { View } from './View';
import { roles, carriers } from '../../../../../../query-hooks';
import _config from '../config';

const Styled = styled.div`
  ${style}
`;

const initialValues = {
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
};

export const InviteUser = props => {
  const listRoles = roles.useListRoles();
  const listCarriers = carriers.useListCarriers();

  const config = React.useMemo(() => {
    return _config(listRoles?.data, listCarriers?.data);
  }, [listCarriers, listRoles]);

  return (
    <Styled className={cn('invite-user')}>
      <Form.FormProvider config={config} initialValues={initialValues}>
        <View refetch={props?.refetch} />
      </Form.FormProvider>
    </Styled>
  );
};
