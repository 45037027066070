import { css } from 'styled-components';

import colors from '../../../mui-theme/colors';

// TODO: theming

const GRID_WIDTH = 3;
const GAP = 16;
const TOP_MARGIN = 16;
const BOTTOM_MARGIN = 16;
const TITLE_HEIGHT = 53;
const APP_ICON_HEIGHT = 60;
const MAX_VISIBLE_ROWS = 3;

const calculateAppTrayHeight = apps => {
  const numberOfApps = apps?.length;
  const numberOfRows = Math.ceil(numberOfApps / GRID_WIDTH);
  const numberOfHiddenRows = numberOfRows - MAX_VISIBLE_ROWS;

  const gaps = (numberOfRows - 1) * GAP;

  const hiddenRowsHeight = numberOfHiddenRows * APP_ICON_HEIGHT + (numberOfHiddenRows - 1) * GAP;

  const fullHeight = numberOfRows * APP_ICON_HEIGHT + gaps + TITLE_HEIGHT + TOP_MARGIN + BOTTOM_MARGIN;
  const constrainedHeight = fullHeight - hiddenRowsHeight - BOTTOM_MARGIN;

  return Math.min(fullHeight, constrainedHeight);
};

export const calculateAppGridHeight = apps => {
  return calculateAppTrayHeight(apps) - TITLE_HEIGHT;
};

export default css`
  &&& {
    .menu-container {
      position: relative;
      @media screen and (max-width: 430px) {
        position: unset;
      }
    }

    .app-tray {
      z-index: 10;
      position: absolute;
      left: -292px;
      top: 20px;
      border: var(--mini-profile-border);
      border-radius: 20px;

      height: 0;
      opacity: 0;
      overflow: hidden;

      transition: opacity 0.3s, height 0.3s;
      background: var(--app-tray-background);
      box-shadow: var(--mini-profile-box-shadow);
      &.active {
        height: ${({ $apps }) => `${calculateAppTrayHeight($apps) + 32}px`};
        opacity: 1;
        @media screen and (max-width: 430px) {
          width: 100vw;
          height: calc(100vh - 78px);
          top: 75px;
          border-radius: 20px 20px 0 0;
          left: 0;
        }
      }

      .spinner {
        margin-top: 20px;
      }

      .tray-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 53px;
        padding: 0 20px;

        .tray-title-text {
          font-weight: 600;
          font-size: 23px;
          line-height: 27px;
          color: var(--sidebar-menu-icon-color);
          width: 100%;
          text-align: center;
          line-height: 53px;
          border-bottom: 1px solid ${colors.neutral[80]};
        }
      }

      .app-tray-inner {
        width: 330px;
        @media screen and (max-width: 430px) {
          width: 100%;
        }
      }
    }
    // }

    .overlay {
      &:not(.open) {
        display: none;
      }
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
`;
