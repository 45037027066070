import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyNL_NL = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>Command Alkon Incorporated PRIVACYVERKLARING</h4>
      </div>
      <div id="overview_content">
        <div>
          <p>
            <em>Laatst aangepast: 26 september 2023</em>
          </p>
          <h3>Overzicht</h3>
          <p>
            Command Alkon Incorporated, met inbegrip van zijn verbonden bedrijven (&#8216;CAI&#8217; of
            &#8216;wij&#8217;), is een
            <br />
            internationale commerciële organisatie die op locatie, als Software-as-a-Service en via andere online
            <br />
            methoden software, services en online oplossingen biedt aan bedrijven in de betonindustrie die
            <br />
            betonmortel, betonproducten en bulkmateriaal produceren. CAI respecteert de privacy van de gebruikers van
            zijn producten en services, en hecht grote waarde aan de bescherming van hun privacy en het behoud van hun
            vertrouwen. Deze privacyverklaring beschrijft onze praktijken voor het verzamelen, gebruiken, delen en
            anderszins verwerken van persoonsgegevens (verder Persoonlijke gegevens genoemd) in verband met uw gebruik
            van de producten en/of services, websites, apps en socialemediapagina&#8217;s van CAI, alsmede uw
            communicatie met het personeel van CAI tijdens bijeenkomsten en evenementen van CAI, waaronder offline
            verkoop- en marketingactiviteiten. Bestudeer deze Privacyverklaring zorgvuldig om onze beleidsregels en
            praktijken ten aanzien van de behandeling van persoonsgegevens te leren kennen. Door onze website te
            bezoeken en/of onze producten of services te gebruiken, geeft u toestemming om persoonsgegevens te
            verzamelen, gebruiken en openbaar te maken conform de beschrijving in deze Privacyverklaring. Met vragen of
            problemen over privacy, kunt u contact met ons opnemen via de informatie onder Contact onder aan deze
            Privacyverklaring.
          </p>
          <h3>Inhoud</h3>
          <ul>
            <li>
              <a href="#summary_of_privacy_notice">Overzicht van de Privacyverklaring</a>
            </li>
            <li>
              <a href="#controller_processor">Verwerkingsverantwoordelijke/Verwerker</a>
            </li>
            <li>
              <a href="#to_whom_does_this_notice_apply">Op wie deze verklaring van toepassing is</a>
            </li>
            <li>
              <a href="#how_we_collect_personal_data">Hoe we Persoonsgegevens verzamelen en gebruiken</a>
            </li>
            <li>
              <a href="#automatically_collected_information">Automatisch verzamelde gegevens</a>
            </li>
            <li>
              <a href="#how_we_use_the_information_we_collect">Hoe we de door ons verzamelde gegevens gebruiken</a>
            </li>
            <li>
              <a href="#how_we_disclose_the_information_we_collect">
                Hoe we de door ons verzamelde gegevens openbaar maken
              </a>
            </li>
            <li>
              <a href="#third_party_services">Services van derden</a>
            </li>
            <li>
              <a href="#security">Beveiliging</a>
            </li>
            <li>
              <a href="#retention_of_data">Bewaring van gegevens</a>
            </li>
            <li>
              <a href="#your_rights_and_choices">Uw rechten en keuzemogelijkheden</a>
            </li>
            <li>
              <a href="#international_data_transfers_and_the_data_privacy_framework">
                Internationale Gegevensdoorgifte en het Raamwerk Voor Gegevensprivacy
              </a>
            </li>
            <li>
              <a href="#lawful_basis_for_processing">Rechtsgrond voor verwerking</a>
            </li>
            <li>
              <a href="#children_policy">Privacy van kinderen</a>
            </li>
            <li>
              <a href="#changes_to_the_privacy_notice">Wijzigingen in de Privacyverklaring</a>
            </li>
            <li>
              <a href="#contact_us">Contact</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="summary_of_privacy_notice">
        <div>
          <h4>Overzicht van de Privacyverklaring</h4>
          <p>
            <strong>Toepassing –</strong> Onze Privacyverklaring is van toepassing op de verwerking van persoonsgegevens
            verstrekt door: 1) bezoekersen gebruikers van de verschillende locaties, apps, producten en/of services van
            CAI; 2) deelnemers aan evenementen van CAI en de betonindustrie; 3) klanten en potentiële klanten en hun
            vertegenwoordigers; 4) abonnees van onze mededelingen en/of nieuwsbrieven; 5) leveranciers en zakenpartners
            en hun vertegenwoordigers; 6) bezoekers van onze kantoren; en 7) alle anderen die om andere redenen
            persoonsgegevens verstreken aan CAI. Zie hieronder voor verdere informatie.
          </p>
          <p>
            <strong>Type verwerkte gegevens –</strong> CAI verzamelt de persoonsgegevens die u verstrekt. Als u
            bijvoorbeeld een account maakt, kunnen we gegevens verzamelen, zoals namen, e-mailadressen, een fysiek
            adres, telefoonnummers, enzovoorts. Ook kunt u persoonsgegevens verstrekken in het geval u meer over onze
            producten of mededelingen wilt weten. We kunnen het volgende type persoonsgegevens verwerken die we
            (afhankelijk van de omstandigheden) tijdens een bezoek aan onze website, marketinggesprekken, appgebruik en
            het gebruik van onze producten en/of services verzamelen gedurende de looptijd van overeenkomsten met u
            en/of uw werkgever: uw naam, contactgegevens (e-mailadres, telefoonnummer, enz.), gegevens over uw werk
            (werkgever, functie, leidinggevende, enz.), bepaalde identificatienummers (rijbewijs, personeelsnummer,
            enz.), prestatiegegevens en certificaten, betaalgegevens, IP-adres, geolocatie, handtekening, camerabeeld,
            gebruikersnaam en wachtwoord, gegevens over vakbondslidmaatschap, etnische afkomst, beroepsgerichte medische
            gegevens, berichten en ontvangen gegevens en informatie over uw gedrag door geplaatste cookies en
            trackingpixels tijdens uw gebruik van het platform. Zie hieronder voor verdere informatie.
          </p>
          <p>
            <strong>Verwerkingsdoeleinden –</strong> We verwerken uw persoonsgegevens voor de volgende doeleinden: 1)
            ter uitvoering van overeenkomsten met u en/of uw werkgever; 2) om apps, websites en platforms te bewaken,
            ontwikkelen, ondersteunen en beheren; 3) voor beveiliging en fraudepreventie; 4) ten behoeve van onze
            marketingdoelen (uw persoonsgegevens worden niet verkocht); 5) voor onze bedrijfsadministratie; en 6) ten
            behoeve van gedragsanalyse in verband met het gebruik van ons platform. Zie hieronder voor verdere
            informatie.
          </p>
          <p>
            <strong>Rechtsgrondslag voor verwerking –</strong> Voor zover op u van toepassing, moet de verwerking van
            persoonsgegevens in sommige rechtsgebieden worden ondersteund door een rechtsgrond. In het algemeen voeren
            we onze verwerkingsactiviteiten uit op grond van: 1) een overeenkomst met u en/of uw werkgever; 2) het
            nastreven van onze legitieme belangen, op voorwaarde dat deze uw belangen, rechten en vrijheden niet
            schenden; 3) uw instemming; en 4) naleving van wettelijke verplichtingen. Zie hieronder voor verdere
            informatie.
          </p>
          <p>
            <strong>Gegevensdoorgifte –</strong> We kunnen uw persoonsgegevens doorgeven naar andere gelieerde partijen
            en/of externe partijen van CAI (d.w.z. zakenpartners, wederverkopers, enz.), verwervende of verkochte
            entiteiten, serviceproviders, en, conform toepasselijke wetgeving, overheidsinstellingen, rechtbanken,
            externe adviseurs en soortgelijke derden. Sommige gegevensdoorgiften kunnen grensoverschrijdend zijn. We
            zullen alle redelijke maatregelen treffen om uw persoonsgegevens vertrouwelijk te houden tijdens doorgifte.
            We verkopen uw persoonlijke gegevens niet aan derden. Ook deelt CAI uw gegevens zonder volledige
            bekendmaking niet met derden, tenzij anders aangegeven in deze Privacyverklaring. Zie hieronder voor verdere
            informatie.
          </p>
          <p>
            <strong>Bewaren en verwijderen van gegevens –</strong> Uw persoonlijke gegevens worden verwijderd zodra deze
            niet langer nodig zijn voor het doel van de oorspronkelijke verwerking, legitieme belangen of de
            verplichtingen onder de toepasselijke wetgeving. Zie hieronder voor verdere informatie.
          </p>
          <p>
            <strong>Uw keuzen en rechten –</strong> U kunt, afhankelijk van uw jurisdictie, een aantal rechten hebben
            ten aanzien van uw persoonsgegevens, waaronder inzagerecht in uw persoonsgegevens, het recht op het
            verkrijgen van een kopie van uw persoonlijke gegevens, het recht op overdraagbaarheid van uw
            persoonsgegevens naar een derde, het recht op rectificatie van uw persoonsgegevens, het recht op beperking
            van bepaalde vormen van verwerking en/of het recht op gegevenswissing. Om uw rechten ten aanzien van uw
            persoonsgegevens uit te oefenen, kunt u gebruikmaken van de informatie onder Contact onder aan deze
            Privacyverklaring. Zie hieronder voor verdere informatie.
          </p>
          <p>
            <strong>Wijzigingen in deze Privacyverklaring –</strong> We behouden ons het recht voor om de voorwaarden
            van deze Privacyverklaring altijd naar behoefte te wijzigen wanneer gewijzigde praktijken of
            privacywetgeving daartoe aanleiding geven. De actuele versie van deze Privacyverklaring is altijd
            beschikbaar via een link op onze websites, platforms of de informatie onder Contact onder aan deze
            Privacyverklaring. Neem deze Privacyverklaring van tijd tot tijd door om op de hoogte te blijven van ons
            meest recente beleid en de meest recente praktijken.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <div id="controller_processor">
        <div>
          <h4>Volledige Privacyverklaring</h4>
          <p>
            <strong>Verwerkingsverantwoordelijke/Verwerker —</strong> Bij het verwerken van persoonsgegevens kan CAI,
            afhankelijk van de omstandigheden, de verwerkingsverantwoordelijke of de verwerker van de gegevens zijn. Bij
            het verwerken van gegevens namens onze klanten en/of hun gelieerde partijen is CAI meestal Verwerker van
            gegevens. Bij het verwerken van gegevens voor zijn eigen doeleinden en buiten de instructies van zijn
            klanten en/of hun gelieerde partijen kan CAI de verwerkingsverantwoordelijke zijn. Met vragen over de rol
            van CAI omtrent uw persoonsgegevens, kunt u gebruikmaken van de informatie onder Contact onder aan deze
            Privacyverklaring.
          </p>
        </div>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <div>
          <p>
            <strong>Op wie deze kennisgeving van toepassing is —</strong> Onze Privacyverklaring is van toepassing op
            gegevens die CAI verwerkt die niets met een arbeidsrelatie te maken hebben, ongeacht de verzamelingsbron.
            Deze Privacyverklaring is van toepassing op de verwerking van persoonsgegevens verstrekt door: 1) bezoekers
            en gebruikers van de verschillende locaties, apps, producten en/of services van CAI; 2) deelnemers aan
            evenementen van CAI en de betonindustrie; 3) klanten en potentiële klanten en hun vertegenwoordigers; 4)
            abonnees van onze mededelingen en/of nieuwsbrieven; 5) leveranciers en zakenpartners en hun
            vertegenwoordigers; 6) bezoekers van onze kantoren; en 7) alle anderen die om andere redenen
            persoonsgegevens verstrekken aan CAI.
          </p>
        </div>
      </div>
      <div id="how_we_collect_personal_data">
        <div>
          <h3>.Hoe we persoonsgegevens verzamelen —</h3>
          <h4>Persoonsgegevens die u verstrekt:</h4>
          <p>
            CAI verzamelt persoonsgegevens die door uzelf of uw vertegenwoordiger worden verstrekt. Ze kunnen worden
            verstrekt tijdens een evenement van CAI, wanneer we u ondersteuning bieden, via marketing, tijdens een
            persoonlijk gesprek, enz. Dit zijn voorbeelden van het type door CAI verzamelde persoonsgegevens, zonder
            hiertoe beperkt te zijn: uw: naam, adres, telefoonnummer, e-mailadres, werkgever, functie, datum
            indiensttreding, leidinggevende, senioriteit, rijbewijsnummer, personeelsnummer, burgerservicenummer, andere
            fiscale nummers, prestatiegegevens en certificaten, betaalgegevens, IP-adres en/of apparaat-id, geo-locatie,
            handtekening, camerabeeld, gebruikersnaam en wachtwoord, gegevens over vakbondslidmaatschap, etnische
            afkomst, beroepsgerichte medische gegevens, berichten en ontvangen gegevens en informatie over uw gedrag
            door geplaatste cookies en trackingpixels tijdens uw gebruik van het platform. Met vragen over dit type
            persoonsgegevens, kunt u gebruikmaken van de informatie onder Contact onder aan deze Privacyverklaring.
          </p>
          <h4>Gegevens die via onze producten en services worden verzameld:</h4>
          <p>CAI verkrijgt persoonsgegevens in verband met het leveren van zijn producten en services, waaronder:</p>
          <p>
            (i) SaaS-producten of -services gehost voor CAI-klanten;
            <br />
            (ii) online producten of services voor e-commercesamenwerkingsoplossingen waarin alle
            <br />
            processen van bestellingen tot inkoop in de business-to-business marktsector zijn opgenomen;
            <br />
            (iii) softwareproducten die een klant in huis op hun vestiging in licentie heeft voor het leveren van
            <br />
            professionele services;
            <br />
            (iv) hardwareproducten die een klant heeft aangeschaft die ze in hun eigen vestiging gebruiken;
            <br />
            (v) klantenservice voor SaaS: software online en op locatie plus hardwareproducten.
          </p>
          <p>
            Bij gebruik van de bovengenoemde producten of services kunnen klanten bijvoorbeeld gegevens verstrekken over
            hun medewerkers, zoals namen, functiebenamingen, e-mailadressen, gebruikersnamen, telefoonnummers, adressen,
            geboortedatums, rijbewijsnummers en andere gegevens. Met een deel van onze online oplossingen kunnen klanten
            persoonsgegevens indienen om gebruikers voor de oplossing te maken, transactiedocumenten op te slaan waarin
            persoonsgegevens van ondertekenaars of zakenpartners kunnen staan, geo-locatie te gebruiken en
            contactgegevens op te slaan van handelspartners.
          </p>
          <p>
            We verwerken vaak persoonsgegevens namens onze klanten op grond van een schriftelijke overeenkomst. We
            hebben geen invloed op de verwerkings- of beveiligingspraktijken van onze klanten (zoals uw werkgever,
            serviceprovider of zakenpartner). Zij kunnen andere voorwaarden hebben dan die in deze Privacyverklaring
            zijn vermeld. We willen u erop wijzen dat als CAI persoonsgegevens verzamelt via producten of services die
            onze klanten beheren, onze klanten de Verwerkingsverantwoordelijke zijn van de gegevens die worden verzameld
            en de wijze waarop deze worden gebruikt en gedeeld. In zulke gevallen fungeert CAI uitsluitend als Verwerker
            van de gegevens. Eventuele vragen over de manier waarop onze klanten via onze producten of services
            verzamelde persoonsgegevens verwerken, gebruiken of delen, moeten gericht worden aan de betreffende klant.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <div id="automatically_collected_information">
        <div>
          <h4>Automatisch verzamelde gegevens:</h4>
          <p>
            Tijdens uw navigatie op onze websites en/of interactie met onze SaaS-producten en apps kunnen we
            technologieën gebruiken om automatisch gegevens te verzamelen over uw apparatuur, activiteiten en patronen
            (&#8216;Informatie over gebruikersactiviteiten&#8217;), onder meer: (i) details over uw gebruik, met
            inbegrip van bezoekersgegevens, locatiegegevens met behulp van geo-locatietechnologie, logboeken en andere
            communicatiegegevens en de geopende en gebruikte hulpmiddelen; en (ii) informatie over het apparaat,
            waaronder het besturingssysteem, IP-adres en andere gegevens over de mobiele aanmelding. Deze informatie kan
            in uw jurisdictie deels gezien worden als Persoonsgegevens.
          </p>
          <p>
            Met de verzamelde gegevens over gebruikersactiviteiten kunnen we onze websites en producten verbeteren en
            een betere en meer gepersonaliseerde service bieden, omdat we daarmee gebruikspatronen kunnen inschatten,
            informatie die u aanvraagt in de voor u toepasselijke tijdzone kunnen weergeven en u kunnen herkennen als u
            onze website of ons product opnieuw bezoekt.
          </p>
          <p>
            De technologieen we gebruiken voor deze automatische gegevensverzameling kunnen webbakens, pixeltags,
            scripts of cookies zijn. Een cookie is een klein bestandje op de harde schijf van uw apparaat. We kunnen met
            behulp van cookies gebruik analyseren, onze services aanpassen, effectiviteit meten en vertrouwen en
            veiligheid bevorderen. We gaan hieronder dieper of cookies in.
          </p>
        </div>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <div>
          <h3>Hoe we de gegevens gebruiken die we verzamelen –</h3>
          <p>
            CAI gebruikt de informatie die we verzamelen om effectief te kunnen handelen, producten en services aan
            klanten te kunnen bieden, om zaken tussen partijen in de industrie te faciliteren en ten behoeve van
            klantenadministratie en klantenbeheer. We gebruiken de informatie die we verzamelen ook om uw aanvragen te
            verwerken, evalueren en beantwoorden, om te reageren op vragen en aanvragen, om uw account te maken en te
            beheren en om met u over uw account te communiceren (met inbegrip van eventuele aankopen en betalingen), om
            de bedrijfsvoering van CAI te realiseren, evalueren en verbeteren (met inbegrip van het ontwikkelen van
            nieuwe producten en services, het beheren van communicatie, het uitvoeren van marktonderzoek, het analyseren
            van producten/services/websites van CAI en het verzorgen van boekhoudingen, audits, facturering, afstemming
            en invorderingsactiviteiten), het waarborgen van de veiligheid van de netwerkservices en bronnen van CAI en
            het naleven van toepasselijke wettelijke eisen.
          </p>
        </div>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <div>
          <h3>
            Gegevens over beroep of dienstverband.Geo-locatiegegevens.Deze gegevens worden rechtstreeks verzameld van u
            of uw werkgever, onze zakenpartners, onze serviceproviders, uw interactie met onze services en producten of
            records die we over u hebben tijdens het leveren van services of producten.Hoe we de gegevens openbaar maken
            die we verzamelen –
          </h3>
          <p>
            <span class="font-family: inherit; font-weight: normal;">
              We kunnen door ons verzamelde persoonsgegevens als volgt openbaar maken:
            </span>
          </p>
          <ul>
            <li>Aan onze gelieerde partners en/of zakenpartners met wie we een overeenkomst zijn aangegaan</li>
            <li>
              Aan derden, zoals contractanten, serviceproviders, adviseurs en andere vertegenwoordigers
              (&#8216;Serviceproviders&#8217;), die hulp verlenen aan ons bedrijf. CAI werkt om diverse redenen in
              sommige gevallen met Serviceproviders, bijvoorbeeld om creditcardbetalingen af te handelen, openingstijden
              bij te houden en voor hosting van gegevens en de boekhouding. De Serviceproviders met wie we zulke
              persoonsgegevens delen, zijn in het algemeen gebonden aan vertrouwelijkheids- en privacyverplichtingen.
              Een lijst met deze Serviceproviders is te vinden op onze website (www.commandalkon.com) onder het tabblad
              Juridisch (lijst met subverwerkers);
            </li>
            <li>Om te voldoen aan de doelen waarvoor u of uw werkgever deze persoonsgegevens heeft verstrekt</li>
            <li>
              Aan een koper of andere opvolger van CAI of een van onze gelieerde partijen in geval van een overname,
              fusie, afsplitsing, herstructurering, reorganisatie, ontbinding of andere verkoop of overdracht van alle
              of een deel van de aandelen of activa van CIA of van een van onze gelieerde partijen – hetzij in het kader
              van de voortzetting van de activiteiten of als onderdeel van een faillissement, liquidatie of soortgelijk
              proces waarbij de persoonsgegevens tot de overgedragen middelen behoort
            </li>
            <li>Voor alle andere doelen die door ons zijn vermeld als u de persoonsgegevens verstrekt.</li>
          </ul>
          <p>
            <span class="font-family: inherit; font-weight: normal;"></span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;"></span>
            <span class="font-family: inherit;">
              Onverminderd de andere bepalingen in deze Privacyverklaring behouden we ons tevens het recht voor om alle
              informatie die we verzamelen te openen, te bewaren en openbaar te maken wanneer we dat redelijkerwijs
              noodzakelijk achten: (i) om te voldoen aan wettelijke verplichtingen, onder meer om te voldoen aan een
              gerechtelijk bevel, een wet of juridische procedure, en in reactie op een verzoek van de overheid of
              regelgevende instantie, met inbegrip van het beantwoorden van wetshandhavingsautoriteiten of andere
              overheidsfunctionarissen in reactie op een geverifieerd verzoek in verband met een strafrechtelijk
              onderzoek of een vermeende illegale activiteit, (ii) om ons beleid en onze overeenkomsten uit te voeren,
              (iii) om problemen met fraude, beveiliging, vertrouwen en veiligheid of technische zaken op te sporen, te
              voorkomen of op een andere manier aan te pakken, (iv) om te reageren op het verzoek om ondersteuning van
              gebruikers, of (v) om de rechten, eigendommen, gezondheid of veiligheid van CAI, onze gebruikers, derden
              of het publiek in het algemeen te beschermen.
            </span>
          </p>
          <h3>Aanvullende informatie voor ingezetenen van Californië of andere staten in de Verenigde Staten –</h3>
          <p>
            De door ons verzamelde persoonlijke gegevens bevatten informatie uit de gegevenscategorieën in de
            onderstaande tabel. Deze categorieën representeren tevens de categorieën persoonlijke gegevens die we in de
            afgelopen 12 maanden hebben verzameld. We willen u erop wijzen dat de hieronder vermelde categorieën
            gedefinieerd zijn door het Californische staatsrecht. Afhankelijk van de services en producten die we u
            en/of uw werkgever en zakenpartner leveren, betekent opname van een categorie in de onderstaande lijst
            uitsluitend dat we sommige gegevens in die categorie kunnen verzamelen of vernietigen. Het hoeft niet te
            betekenen dat we alle vermelde gegevens in een bepaalde categorie verzamelen of openbaar maken, ook
            verzamelen we niet alle categorieën gegevens voor alle individuen.
          </p>
          <p>
            Bovendien kunnen verzamelde gegevens in de business-to-business context, die beschouwd worden als
            persoonsgegevens onder bepaalde wetgeving in de AVG’s van de EU en het VK en de wet inzake privacyrechten in
            Californië, geen persoonsgegevens zijn in andere jurisdicties, onder meer in andere staten in de Verenigde
            Staten.
          </p>
          <p>&nbsp;</p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    <strong>Categorie</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    <strong>Bronnen</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    <strong>Verwerkingsdoeleinden</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Identificaties zoals een werkelijke naam, alias, postadres, unieke persoonlijke id, online id,
                    IP-adres, e- mailadres, accountnaam, burgerservicenummer, rijbewijsnummer of andere soortgelijke
                    identificatie.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden zowel deze gegevens als gegevens verkregen van onze zakenpartners over uw
                    inkooptendensen, gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden van producten
                    en services die interessant voor u kunnen zijn via zowel directe als partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Gegevens die een bepaald individu identificeren, betreffen, beschrijven of gekoppeld kunnen worden,
                    met inbegrip van, maar niet beperkt tot uw naam, handtekening, burgerservicenummer, lichamelijke
                    kenmerken of beschrijving, adres, telefoonnummer, het nummer van uw rijbewijs- of identiteitsbewijs
                    en medische gegevens.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden deze gegevens gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden
                    van producten en services die interessant voor u kunnen zijn via zowel directe als
                    partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer. Ook worden ze verwerkt voor accountadministratie en
                    -beheer, detectie van beveiligingsincidenten, bescherming tegen schadelijke, misleidende,
                    frauduleuze of illegale activiteit, voor nalevingsbeheer, gegevensanalyse en technische ontwikkeling
                    van onze systemen. Daarnaast worden deze gegevens gebruikt voor marketingdoeleinden, met inbegrip
                    van het u aanbieden van producten en services die interessant voor u kunnen zijn via zowel directe
                    als partneradvertenties.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten of records die we over u hebben
                    tijdens het leveren van services of producten.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden zowel deze gegevens als gegevens verkregen van onze zakenpartners over uw
                    inkooptendensen, gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden van producten
                    en services die interessant voor u kunnen zijn via zowel directe als partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Gegevens over internet- of activiteiten op andere elektronisch netwerken, met inbegrip van, maar
                    niet beperkt tot browse- en zoekopdrachtgeschiedenis en gegevens over interacties van consumenten
                    met een website, app of advertentie op internet.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten of met behulp van cookies en
                    soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt voor accountadministratie en -beheer, detectie van
                    beveiligingsincidenten, bescherming tegen schadelijke, misleidende, frauduleuze of illegale
                    activiteit, voor nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden deze gegevens gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden
                    van producten en services die interessant voor u kunnen zijn via zowel directe als
                    partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Geo-locatiegegevens.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden zowel deze gegevens als gegevens verkregen van onze zakenpartners over uw
                    inkooptendensen, gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden en leveren van
                    producten en services die interessant voor u kunnen zijn via zowel directe als partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Audio-, elektronische, visuele, thermische, olfactorische of soortgelijke gegevens.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                  <p>
                    Van bezoekers verzamelen we deze gegevens via onze camerabewakingssystemen en van bellers via audio-
                    opnamen.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder het opnemen van verkoopgesprekken en andere telefonische
                    gesprekken. Voor bezoekers kunnen visuele gegevens worden verwerkt uit oogpunt van beveiliging en
                    verantwoordelijkheidsplichten, en om te voldoen aan lokale wetten, waaronder wetten over gezondheid
                    en veiligheid.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Gegevens over beroep of dienstverband.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten of records die we over u hebben
                    tijdens het leveren van services of producten.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, en voor
                    nalevingsbeheer.
                  </p>
                  <p>
                    Daarnaast worden deze gegevens gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden
                    van producten en services die interessant voor u kunnen zijn via zowel directe als
                    partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Gevolgtrekkingen uit een van de hierboven vermelde gegevenscategorieën om een profiel te creëren die
                    de voorkeuren, kenmerken, psychologische trends, neigingen, gedrag, houding, intelligentie,
                    vermogens en talenten van de consument weergeeft.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                  <p>
                    Daarnaast worden deze gegevens gebruikt voor marketingdoeleinden, met inbegrip van het u aanbieden
                    van producten en services die interessant voor u kunnen zijn via zowel directe als
                    partneradvertenties.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Gevoelige persoonlijke gegevens, waaronder alle persoonlijke gegevens die het volgende tonen: het
                    burgerservicenummer of andere identificatiebewijsnummers; accountaanmelding; geo- locatie; etnische
                    afkomst; vakbondslidmaatschap; inhoud van post, e-mail of tekstberichten, tenzij het bedrijf de
                    beoogde ontvanger is van de communicatie, en genetische gegevens.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden rechtstreeks verzameld van u of uw werkgever, onze zakenpartners, onze
                    serviceproviders, uw interactie met onze services en producten, records die we over u hebben tijdens
                    het leveren van services of producten, of met behulp van cookies en soortgelijke technologieën.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    Deze gegevens worden verwerkt in verband met een aantal van de operationele taken waarmee we u
                    producten en services leveren, waaronder faciliteren van zakelijke activiteiten tussen partijen in
                    de industrie en klantenadministratie en -beheer.
                  </p>
                  <p>
                    Ook worden ze verwerkt voor accountadministratie en -beheer, detectie van beveiligingsincidenten,
                    bescherming tegen schadelijke, misleidende, frauduleuze of illegale activiteit, voor
                    nalevingsbeheer, gegevensanalyse en technische ontwikkeling van onze systemen.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            CAI verkoopt uw gegevens niet aan anderen, met inbegrip van de doeleinden die in de Californische
            consumentenprivacywet van 2018 (de “CCPA”) en de Californische privacyrechtenwet van 2020 (de“CPRA”) genoemd
            worden.
          </p>
        </div>
      </div>
      <div id="third_party_services">
        <div>
          <h3>Services van derden –</h3>
          <p>
            U kunt de mogelijkheid krijgen aangeboden om services of toepassingen van externe partijen (&#8216;Services
            van externe partijen&#8217;) te openen en gebruiken via sommige van onze websites en/of producten. Deze
            Privacyverklaring betreft alleen het verzamelen, gebruiken en openbaar maken van informatie die door CAI is
            verzameld. Het is niet van toepassing op de praktijken van derden die niet van ons zijn en waarop we geen
            invloed kunnen uitoefenen, en niet kunnen implementeren of beheren, met inbegrip van, maar niet beperkt tot
            services van derden. Door services of toepassingen van derden te openen of gebruiken via onze websites en/of
            producten, geeft u ons opdracht om namens u de door de derde gevraagde gegevens over u aan de externe
            service bekend te maken, en u stemt ermee in dat op het gebruik van of openbaar maken van uw gegevens het
            privacybeleid van de derde van toepassing zal zijn dat anders kan zijn dan hoe wij uw gegevens gebruiken en
            openbaar maken.
          </p>
        </div>
      </div>
      <div id="security">
        <div>
          <h3>Beveiliging –</h3>
          <p>
            CAI hanteert redelijke en toereikende administratieve, technische en fysieke beveiligingsmaatregelen die
            aansluiten op het type verwerkte gegevens om Persoonsgegevens te beschermen tegen verlies, misbruik en
            onbevoegde toegang, openbaarmaking, aanpassing en/of vernietiging. We werken hard om uw privacy te
            beschermen, maar beveiliging is nooit 100% effectief. We kunnen dus niet garanderen dat informatie veilig is
            of dat uw persoonsgegevens of privécommunicatie altijd privé zullen blijven. Toegang of gebruik door
            onbevoegden, het falen van hardware of software en andere factoren kunnen de veiligheid van
            gebruikersgegevens compromitteren.
          </p>
          <p>
            De veiligheid en beveiliging van uw informatie is ook van u afhankelijk. Als we u aanmeldingsgegevens hebben
            gegeven (of als u hiervoor hebt gekozen) (d.w.z. een gebruikersnaam en wachtwoord) voor toegang tot bepaalde
            onderdelen van onze services of apps, bent u er verantwoordelijk voor om deze aanmeldingsgegevens voor uw
            gebruikersaccount vertrouwelijk te houden. We vragen u deze aanmeldingsgegevens met niemand te delen. U bent
            als enige verantwoordelijk voor de bescherming van de aanmeldingsgegevens voor uw gebruikersaccount en voor
            al het gebruik van uw accountgegevens.
          </p>
        </div>
      </div>
      <div id="retention_of_data">
        <div>
          <h3>Bewaren van informatie –</h3>
          <p>
            In het algemeen zullen we persoonsgegevens zo lang bewaren als nodig is om te voldoen aan de beschreven
            doelen in deze Privacyverklaring. De bewaartermijn is in het algemeen voor de duur van de relatie plus een
            korte tijd erna conform ons bewaartijdschema. We kunnen echter bepaalde informatie langer bewaren als dit
            door toepasselijke wetgeving (zoals fiscale, boekhoudkundige of andere wettelijke verplichtingen) of voor
            legitieme zakelijke doeleinden wordt vereist. Zodra we uw persoonsgegevens niet meer nodig hebben om te
            voldoen aan onze verplichtingen, verwijderen we deze uit onze systemen en administratie en/of ondernemen we
            stappen om de gegevens anoniem te maken, zodat uw identiteit hieruit niet meer kan worden afgeleid. Elk
            beschreven gebruik in deze Privacyverklaring vormt een legitiem belang van CAI om de verzamelde
            persoonsgegevens te verwerken of gebruiken of is noodzakelijk om te voldoen aan een overeenkomst. In het
            geval u niet akkoord gaat met deze werkwijze, kunt u CAI schriftelijk op de hoogte stellen van uw bezwaar
            tegen de verwerking of het gebruik van uw persoonsgegevens via een van de vermelde methoden onder Contact
            onder aan deze Privacyverklaring
          </p>
        </div>
      </div>
      <div id="your_rights_and_choices">
        <div>
          <p>
            U kunt het gebruik van cookies beheren via de cookiebanner die te zien is als u onze sites opent. Wanneer u
            dat wenst, kunt u er meestal voor kiezen om uw browser zo in te stellen dat cookies worden verwijderd of
            geweigerd of om uw lokale opslag te wissen. Ook kunt u schriftelijk verzoeken indienen over cookiebeheer via
            een van de methoden onder Contact onder aan deze Privacyverklaring. Als u ervoor kiest om sommige cookies te
            weigeren of te verwijderen, kan dit gevolgen hebben voor de functionaliteit van onze website en services. We
            hebben een onafhankelijk cookiebeleid beschikbaar op onze website (www.commandalkon.com) op het tabblad
            Juridisch met meer informatie.
          </p>
          <h4>Locatie-informatie:</h4>
          <p>
            U kunt services op basis van uw locatie in- en uitschakelen door de instellingen van uw mobiele apparaat aan
            te passen. Als u ervoor kiest om op locatieservices uit te schakelen, kan dit gevolgen hebben voor de
            volledige functionaliteit van onze producten en services.
          </p>
          <h4>Privacyrechten in Californië:</h4>
          <p>
            Ingezetenen van Californië mogen volgens de Californische wetgeving eenmaal per jaar een verzoek indienen om
            gratis een lijst met derden toegezonden te krijgen aan wie we hun persoonsgegevens hebben doorgegeven
            (indien van toepassing) voor directe-marketingdoeleinden in het voorafgaande kalenderjaar, evenals het type
            persoonsgegevens dat aan deze partijen openbaar is gemaakt. Tenzij anders vermeld in deze Privacyverklaring,
            deelt CAI geen persoonsgegevens met derden voor hun marketingdoeleinden.
          </p>
        </div>
      </div>
      <div id="international_data_transfers_and_the_data_privacy_framework">
        <div>
          <h3>Internationale gegevensdoorgifte en Raamwerk Voor Gegevensprivacy –</h3>
          <p>
            CAI is gevestigd in de Verenigde Staten en heeft in de gehele wereld kantoren. We kunnen persoonsgegevens
            naar de VS en andere landen doorgeven, waarin onze gelieerde partijen, leveranciers, adviseurs en
            serviceproviders actief zijn om onze bedrijfsvoering te faciliteren en onze producten en services te kunnen
            aanbieden. Zulke landen kunnen andere wetten hebben dan die in uw eigen land en kunnen minder bescherming
            bieden dan de wet in uw land. Bent u een verwerkingsverantwoordelijke die CAI gebruikt als verwerker van
            gegevens, en slaat u content of gegevens op via de software, apps of websites van CAI die persoonsgegevens
            bevat van individuen uit de EER, dan verklaart u dat u rechtsbevoegd bent om de persoonsgegevens door te
            geven aan CAI, met in begrip van doorgifte aan landen zoals de VS waar de privacybescherming en het recht op
            inzage van autoriteiten betreffende persoonsgegevens niet hetzelfde hoeven zijn als die in de EER.
          </p>
          <p>
            Bij het doorgeven van persoonsgegevens naar het buitenland nemen we conform deze Privacyverklaring gepaste
            veiligheidsmaatregelen om de gegevens te beschermen en we spannen ons ervoor in om te waarborgen dat wij en
            ontvangers in het buitenland aan de toepasselijke privacywetten voldoen. CAI maakt gebruik van de
            Modelcontractbepalingen (&#8216;SCC&#8217;s&#8217;) van de EU voor de rechtmatige doorgifte van
            persoonsgegevens tussen zijn entiteiten vanuit rechtsgebieden waar de Algemene verordening
            gegevensbescherming (&#8216;AVG&#8217;) van toepassing is naar de Verenigde Staten. CAI maakt gebruik van de
            Britse Overeenkomst inzake Doorgifte van Gegevens voor rechtmatige doorgifte van persoonsgegevens tussen
            zijn entiteiten vanuit rechtsgebieden waar de Britse Algemene verordening gegevensbescherming (“VK AVG”) van
            toepassing is naar de Verenigde Staten.
          </p>
          <p>
            Aanvullend op de Modelcontractbepalingen geldt dat mocht CAI vernemen dat een overheidsinstantie (waaronder
            wetshandhavingsautoriteiten) toegang tot of een kopie wenst te krijgen van alle of een deel van de
            Persoonsgegevens van de klant die CAI heeft Verwerkt, op vrijwillige dan wel verplichte basis, voor
            doeleinden die de nationale veiligheid betreffen, zal CAI als volgt handelen, tenzij dit wettelijk niet is
            toegestaan of de wet verplicht anders te handelen: 1) het stelt onmiddellijk de partij op de hoogte waarop
            de persoonsgegevens betrekking hebben (d.w.z. de klant of leverancier); 2) het informeert de betreffende
            overheidsinstantie dat het niet bevoegd is om de persoonsgegevens openbaar te maken en, tenzij dit wettelijk
            verboden is, dat het onmiddellijk de partij op de hoogte dient te stellen waarop de persoonsgegevens
            betrekking hebben; 3) het stelt de overheidsinstantie op de hoogte dat deze alle verzoeken of eisen
            rechtstreeks naar de partij dient te sturen waarop de persoonsgegevens betrekking hebben; 4) het biedt pas
            toegang tot de persoonsgegevens wanneer het schriftelijke goedkeuring heeft ontvangen van de partij waarop
            de persoonsgegevens betrekking hebben of wanneer het hiertoe wettelijk wordt verplicht. Wanneer sprake is
            van een wettelijke verplichting, zal CAI binnen de wettelijke mogelijkheden redelijke inspanningen leveren
            om het verbod of de verplichting aan te vechten. In geval CAI wettelijke verplicht is gesteld de
            Persoonsgegevens van de klant op te leveren, zal CAI uitsluitend Persoonsgegevens van de klant bekendmaken
            voor zover wettelijk verplicht volgens de toepasselijke wettelijke procedure.
          </p>
          <p>
            CAI maakt gebruik van gegevensbeschermingsbijlagen (“DPA&#8217;s”) bij subverwerkers die namens CAI
            Persoonsgegevens verwerken, waar dit is vereist door de toepasselijke privacywetten.
          </p>
          <p>
            Wanneer we persoonlijke gegevens overdragen vanuit de Europese Economische Ruimte (“EER”), het Verenigd
            Koninkrijk of Zwitserland naar de Verenigde Staten, voldoet CAI aan de EU-VS-richtlijnen. Data Privacy
            Framework (EU-VS DPF), de Britse uitbreiding naar de EU-VS. DPF en het Zwitsers-Amerikaanse Data Privacy
            Framework (Zwitsers-Amerikaanse DPF) zoals uiteengezet door het Amerikaanse ministerie van Handel. CAI heeft
            aan het Amerikaanse ministerie van Handel verklaard dat het zich houdt aan de EU- VS-richtlijnen.
            Kaderprincipes voor gegevensbescherming (EU-VS DPF-principes) met betrekking tot de verwerking van
            persoonsgegevens ontvangen van de Europese Unie op basis van de EU-VS-richtlijnen. DPF en uit het Verenigd
            Koninkrijk (en Gibraltar) op basis van de Britse uitbreiding naar de EU-VS. DPF. CAI heeft aan het
            Amerikaanse ministerie van Handel verklaard dat het zich houdt aan de Swiss-U.S. Kaderprincipes voor
            gegevensbescherming (Zwitsers-Amerikaanse DPF-principes) met betrekking tot de verwerking van
            persoonsgegevens ontvangen uit Zwitserland op basis van de Zwitsers-Amerikaanse wetgeving. DPF. Als er een
            conflict bestaat tussen de voorwaarden in deze Privacyverklaring en de EU- U.S. DPF-principes en/of de
            Swiss-U.S. DPF-principes, de principes zijn bepalend. Voor meer informatie over het Data Privacy Framework
            (DPF)-programma en om onze certificering te bekijken, gaat u naar https://www.dataprivacyframework.gov/.
          </p>
          <p>
            In overeenstemming met de EU-U.S. DPF, de Britse uitbreiding naar de EU-VS. DPF en het Zwitsers- Amerikaanse
            DPF, CAI verbindt zich ertoe om DPF-principesgerelateerde klachten over onze verzameling en gebruik van uw
            persoonlijke gegevens op te lossen. Personen uit de EU, het VK en Zwitserland met vragen of klachten over
            onze omgang met persoonsgegevens die zijn ontvangen op basis van de EU-VS- wetgeving. DPF, de Britse
            uitbreiding naar de EU-VS. DPF en/of het Zwitsers-Amerikaanse DPF dient eerst contact op te nemen met CAI,
            zoals aangegeven in het gedeelte ‘Contact met ons opnemen’ onderaan deze Privacyverklaring.
          </p>
          <p>
            Een individu heeft, onder bepaalde voorwaarden, de mogelijkheid om bindende arbitrage in te roepen voor
            klachten over de naleving van de DPF die niet door een van de andere DPF-mechanismen zijn opgelost. Voor
            aanvullende informatie zie https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-
            dpf?tabset-35584=2. In overeenstemming met de EU-U.S. DPF, de Britse uitbreiding naar de EU-VS. DPF en het
            Zwitsers-Amerikaanse DPF, CAI verbindt zich ertoe om onopgeloste klachten over onze omgang met
            persoonsgegevens die we ontvangen op basis van de EU-VS-wetgeving, door te verwijzen. DPF, de Britse
            uitbreiding naar de EU-VS. DPF en/of het Zwitsers-Amerikaanse DPF aan JAMS, een aanbieder van alternatieve
            geschillenbeslechting gevestigd in de Verenigde Staten. Als u van ons geen tijdige bevestiging ontvangt van
            uw DPF Principles-gerelateerde klacht, of als wij uw DPF Principles-gerelateerde klacht niet naar
            tevredenheid hebben afgehandeld, ga dan naar https://www.jamsadr.com/file-a-dpf -claim voor meer informatie
            of om een klacht in te dienen. De diensten van JAMS worden u kosteloos aangeboden.
          </p>
          <p>
            De Federal Trade Commission van de Verenigde Staten heeft jurisdictie over de naleving door CAI van de
            EU-VS-richtlijnen. DPF, de Britse uitbreiding van de EU-VS-overeenkomst DPF en het Zwitsers- Amerikaanse
            DPF. Als CAI persoonlijke gegevens verzameld in de EER, het Verenigd Koninkrijk of Zwitserland deelt met een
            externe dienstverlener die de gegevens uitsluitend namens CAI verwerkt, dan is CAI aansprakelijk voor de
            verwerking van dergelijke gegevens door die derde partij in strijd met de Beginselen. , tenzij CAI kan
            bewijzen dat zij niet verantwoordelijk is voor de gebeurtenis die de schade veroorzaakt.
          </p>
        </div>
      </div>
      <div id="lawful_basis_for_processing">
        <div>
          <h3>Rechtsgrond voor Verwerking van Persoonsgegevens van individuen (EER/VK) –</h3>
          <p>
            Als u een individu uit de EER bent, zal onze rechtsgrond voor het verzamelen en gebruiken van
            persoonsgegevens afhankelijk zijn van de betreffende persoonsgegevens en de specifieke context waarin we
            deze verzamelen. We gebruiken uw Persoonsgegevens uitsluitend voor de doeleinden waarvoor we zulke
            Persoonsgegevens volgens de onderstaande beschrijving verzamelen, tenzij we ze later voor een ander
            doeleinde nodig hebben die verenigbaar is met het oorspronkelijke doel. In het geval dat we uw
            Persoonsgegevens verder moeten verwerken voor een doel dat niet verenigbaar is met het oorspronkelijke
            verzameldoel, lichten we u hierover en leggen u uit op grond van welke rechtsgrond we dat mogen doen. In het
            algemeen maken we echter gebruik van de volgende rechtsgronden voor onze verwerking: 1) de uitvoering van
            een overeenkomst met u en/of uw werkgever; 2) het nastreven van onze legitieme belangen op voorwaarde dat
            deze uw belangen, rechten en vrijheden niet schenden; 3) uw instemming; en 4) naleving van wettelijke
            verplichtingen. In sommige gevallen maken we gebruik van ontheffingen die in artikel 49 van de AVG zijn
            vermeld, zijn we wettelijk verplicht om persoonsgegevens te verzamelen of kunnen we uw persoonsgegevens
            anderszins nodig hebben om uw vitale belangen of die van een ander te beschermen. Hieronder vindt u nadere
            bijzonderheden over rechtsgronden.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    <strong>Doeleinde(n) voor Verwerking</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <p>
                    <strong>Rechtsgrond voor Verwerking</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Het verwerken van aanvragen en andere overeenkomsten voor onze producten en services.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken van uw persoonsgegevens is noodzakelijk om uitvoering te geven aan een
                        overeenkomst of een overeenkomst met u en/of uw werkgever aan te gaan.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Het beheren en uitvoeren van overeenkomsten, met inbegrip van service-overeenkomsten met u en uw
                    werkgever.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken van uw persoonsgegevens is noodzakelijk voor ons om te voldoen aan wettelijke en
                        regelgevende verplichtingen.
                      </p>
                    </li>
                    <li>
                      <p>
                        Het verwerken is noodzakelijk ter ondersteuning van onze legitieme belangen in het beheren van
                        ons bedrijf (of die van een derde), tenzij zulke belangen moeten wijken voor uw belangen,
                        rechten en vrijheden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Om onze producten en services te verbeteren, marktonderzoek uit te voeren, gegevensanalyses uit te
                    voeren, voor het modelleren van risico in het algemeen en voor statistische analyses.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken is noodzakelijk ter ondersteuning van onze legitieme belangen in het beheren van
                        ons bedrijf (of die van een derde), tenzij zulke belangen moeten wijken voor uw belangen,
                        rechten en vrijheden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Marketing</p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        We zullen uw toestemming vragen om uw persoonsgegevens voor marketing te mogen gebruiken. Die
                        toestemming kunt u altijd intrekken.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Om fraude, witwaspraktijken of andere delicten te voorkomen en detecteren.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken van uw persoonsgegevens is noodzakelijk voor ons om te voldoen aan wettelijke en
                        regelgevende verplichtingen of toegestaan door de toepasselijke wetgeving.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>
                    Om onze relatie met u en tussen u en uw
                    <br />
                    werkgever te onderhouden.
                  </p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken van uw persoonsgegevens is noodzakelijk om uitvoering te geven aan een
                        overeenkomst of een overeenkomst met u en/of uw werkgever aan te gaan.
                      </p>
                    </li>
                    <li>
                      <p>
                        Het verwerken is noodzakelijk ter ondersteuning van onze legitieme belangen in het beheren van
                        ons bedrijf (of die van een derde), tenzij zulke belangen moeten wijken voor uw belangen,
                        rechten en vrijheden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline;">
                  <p>Om te leveren wat onze producten en services te bieden hebben.</p>
                </td>
                <td class="vertical-align: baseline;">
                  <ul>
                    <li>
                      <p>
                        Het verwerken van uw persoonsgegevens is noodzakelijk om uitvoering te geven aan een
                        overeenkomst of een overeenkomst met u en/of uw werkgever aan te gaan.
                      </p>
                    </li>
                    <li>
                      <p>
                        Het verwerken is noodzakelijk ter ondersteuning van onze legitieme belangen in het beheren van
                        ons bedrijf (of die van een derde), tenzij zulke belangen moeten wijken voor uw belangen,
                        rechten en vrijheden.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="children_policy">
        <div>
          <h3>Privacy van kinderen –</h3>
          <p>
            De zakelijke activiteiten van CAI zijn niet gericht op kinderen en CAI verzamelt of vraagt niet bewust om
            informatie van kinderen of een jongere onder de 13-jarige leeftijd. CAI staat deze personen niet willens en
            wetens toe om onze producten of services te gebruiken. Wanneer we ontdekken dat we onbedoeld
            persoonsgegevens hebben verzameld van een kind of jongere onder de 13-jarige leeftijd, verwijderen we deze
            gegevens zo snel mogelijk. Als u meent dat we gegevens hebben van of over een kind, neemt u contact met ons
            op via de hieronder vermelde methoden onder Contact onder aan deze Privacyverklaring.
          </p>
        </div>
      </div>
      <div id="changes_to_the_privacy_notice">
        <div>
          <h3>Wijzigingen in de Privacyverklaring –</h3>
          <p>
            We kunnen deze Privacyverklaring op elk gewenst moment aanpassen door de aangepaste Privacyverklaring op
            onze websites te publiceren. De datum waarop de Privacyverklaring voor het laatst werd herzien, staat boven
            aan deze Privacyverklaring. Alle gewijzigde voorwaarden worden automatisch van kracht nadat ze op onze
            website worden gepubliceerd, tenzij een verandering in de toepasselijke wetgeving directe aanpassing
            vereist. Het is uw verantwoordelijkheid om periodiek te controleren of deze Privacyverklaring is gewijzigd.
          </p>
        </div>
      </div>
      <div>
        <div>
          <h3>Klachten –</h3>
          <p>
            Onverminderd andere mogelijkheden van administratief beroep of beroep in rechte die u hebt, kunt u het recht
            hebben om een klacht in te dienen bij lokale regelgevende instanties/gegevensbeschermingsautoriteiten als u
            van mening bent dat we geldende eisen ten aanzien van privacy of gegevensbescherming hebben geschonden bij
            het verwerken van persoonsgegevens over u.
          </p>
          <h4>In het VK kunt u contact opnemen met::</h4>
          <p>
            The Information Commissioner’s Office
            <br />
            Water Lane, Wycliffe House
            <br />
            Wilmslow &#8211; Cheshire SK9 5AF
            <br />
            Tel. +44 1625 545 700
            <br />
            e-mail: <b>casework@ico.org.uk</b>
            <br />
            Website: <b>https://ico.org.uk</b>
          </p>
          <p>
            Zie voor de EU: <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <div id="contact_us">
        <div>
          <h3>Contact –</h3>
          <p>
            Gebruik de onderstaande contactgegevens om contact met CAI op te nemen met vragen of twijfels over deze
            Privacyverklaring of praktijken van CAI met betrekking tot persoonsgegevens.
          </p>
          <p>
            We willen ingezetenen van de EER erop wijzen dat waar CAI als verwerkingsverantwoordelijke verantwoordelijk
            is voor uw persoonsgegevens, Command Alkon Incorporated in de Verenigde Staten de rechtspersoon is. CAI zal
            alle vragen en klachten over deze Privacyverklaring binnen een redelijk tijdsbestek beantwoorden.
          </p>
          <p>
            <strong>
              <span class="font-family: inherit;">E-mail:</span>
            </strong>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">privacy@commandalkon.com</span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              <span class="font-family: inherit;">Of richt uw schrijven aan:</span>
            </strong>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">Command Alkon Incorporated</span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">
              Chief Privacy Officer c/o The Legal Department
            </span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">6750 Crosby Court</span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">Dublin, Ohio 43016, USA</span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              <span class="font-family: inherit;">Of bel ons:</span>
            </strong>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">1-800-624-1872 (gratis in de VS)</span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">0-800-022-9682 (gratis internationaal)</span>
          </p>
          <p>
            <span class="font-family: inherit; font-weight: normal;">Website: www.commandalkon.com</span>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </Styled>
  );
};
