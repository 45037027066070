import cn from 'classnames';
import { Core, Localization } from 'connex-cds';
import { fromPairs, sortBy, toPairs } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { TemplateTextAvailableValues } from './TemplateTextAvailableValues';
import { useTemplatesPASetup } from '../../../MasterDataProvider';

const { Tabs } = Core;

const Styled = styled.div`
  display: flex;
  flex: 1;
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 5px 0px;
  }
  .templates {
    display: flex;
    flex-direction: column;
    .template-section {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    textarea {
      height: 100px;
      width: 100%;
    }
  }
`;

export const TemplateTabAvailableValues = () => {
  const { supportedLanguages } = Localization.useLocalizationContext();
  const translateMessage = Localization.useTranslateMessage();
  const templatesPASetup = useTemplatesPASetup();

  const tabsConfig = React.useMemo(() => {
    const translated = supportedLanguages.reduce((acc, lang) => {
      acc[lang] = translateMessage(`languages.${lang}`);
      return acc;
    }, {});

    const sorted = fromPairs(sortBy(toPairs(translated), 1));
    const sortedKeys = Object.keys(sorted || []);

    return {
      tabs: sortedKeys.reduce((acc, lang) => {
        acc.push({
          stringId: `languages.${lang}`,
          testId: `${lang}Label'`,
          component: <TemplateTextAvailableValues language={lang} templatesPASetup={templatesPASetup} />,
        });
        return acc;
      }, []),
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };
  }, [supportedLanguages, templatesPASetup, translateMessage]);

  return (
    <Styled className={cn('template-tab')}>
      <Tabs config={tabsConfig} tabPosition="left" />
    </Styled>
  );
};
