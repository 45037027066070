import decode from 'jwt-decode';
import React from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { cacheItem, clearCachedItem, getCachedItem } from '../../util/cache';
import { API } from '../api';
import { USER_KEY, useUserContext } from '../user';
import { AuthContext } from './AuthContext';

/**
 * Renders children when user is authenticated, otherwise render the given unauthenticatedComponent
 *
 * @param children
 * @param unauthenticatedComponent
 * @param onLogin
 * @param noAuthCheck
 * @returns {*}
 * @constructor
 */

API.setLoginToken(getCachedItem('x-connex-id'));

export const AuthProvider = ({ children, onLogin, noAuthCheck }) => {
  const [isUserAuthenticated, _setIsUserAuthenticated] = React.useState(!!getCachedItem('x-connex-id'));
  const [authorizedEntity, setAuthorizedEntity] = React.useState(null);
  const userContext = useUserContext();
  const { sendMessage } = usePostMessageContext();

  const setAuthentication = React.useCallback(loginResponse => {
    cacheItem('x-connex-id', loginResponse?.access_token);
    _setIsUserAuthenticated(true);
  }, []);

  const login = React.useCallback(
    async ({ credentials }) => {
      try {
        const rawResponse = await onLogin({ ...credentials });

        API.setLoginToken(rawResponse.access_token);
        sendMessage({ type: 'cx-login-token', token: rawResponse.access_token });

        const userObject = {
          ...rawResponse,
          apps: rawResponse?.apps?.filter?.(x => !!x) || [],
        };

        const decodedToken = decode(rawResponse.access_token);

        userObject.isPlatformAdmin = !!decodedToken?.cai?.pa;
        userObject.isPlatformSupport = !!decodedToken?.cai?.ps;

        userContext?.setUser?.(userObject);

        setAuthentication(userObject);

        return userObject;
      } catch (e) {
        console.log('e', e);
        console.log(e.stack);
      }
    },
    [onLogin, sendMessage, setAuthentication, userContext]
  );

  const logout = React.useCallback(() => {
    clearCachedItem('x-connex-id');
    clearCachedItem(USER_KEY);
    _setIsUserAuthenticated(false);
    setAuthorizedEntity(null);
    window?.FS?.anonymize?.();
  }, []);

  const isUserAuthorizedForEntity = React.useCallback(
    entityRef => {
      return entityRef === authorizedEntity;
    },
    [authorizedEntity]
  );

  const onUnauthenticated = React.useCallback(() => {
    sendMessage({ type: 'logout-exp' });
  }, [sendMessage]);

  React.useEffect(() => {
    API.setOnUnauthenticated(onUnauthenticated);
  }, [onUnauthenticated]);

  if (noAuthCheck) return children;

  return (
    <AuthContext.Provider
      value={{ login, logout, isUserAuthenticated, isUserAuthorizedForEntity, setAuthorizedEntity }}
    >
      {children}
    </AuthContext.Provider>
  );
};
