import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GenericErrorBoundary } from './GenericErrorBoundary';
import { cacheItem } from './util/cache';
import { Logger } from './util/log/logger';
import { matchLanguage } from './util/language';
import { API, getCachedItem } from 'connex-cds';

try {
  const queryParams = queryString.parse(window.location.search);
  if (!isEmpty(queryParams)) {
    const previousConnexId = localStorage.getItem('x-connex-id') || '';

    const connexId = queryParams['x-connex-id'] || previousConnexId;
    const source = queryParams['source'] || localStorage.getItem('source');
    const language = queryParams['language'] || localStorage.getItem('language');
    const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'dark';
    const entityRef = queryParams['entityRef'] || localStorage.getItem('entityRef');
    const ticketRef = queryParams['crn'];
    const apiKeys = queryParams['apiKeys'] || '';

    cacheItem('source', source || '');
    cacheItem('themeName', themeName);
    cacheItem('language', matchLanguage(language));
    cacheItem('entityRef', entityRef);
    cacheItem('ticketRef', ticketRef);

    const decodedApiKeys = isEmpty(apiKeys) ? '' : decodeURIComponent(apiKeys);
    cacheItem('apiKeys', decodedApiKeys);

    if (connexId) {
      cacheItem('x-connex-id', connexId);
    }

    // Strip query params off URL
    if (window?.history?.replaceState) {
      window.history.replaceState(
        {},
        null,
        `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`
      );
    }
  }
} catch (e) {
  Logger.debug(`Mobile Ticket - frontend/src/index.js: ${e.message}`);
}

API.setSource('mt');
try {
  API.setApiKeys(JSON.parse(getCachedItem('apiKeys') || {}));
} catch (e) {
  console.error('Error parsing apiKeys', e);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GenericErrorBoundary>
    <App />
  </GenericErrorBoundary>
);
