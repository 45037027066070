import { findIndex } from 'lodash';
import constants from '../constants';

export class PrimaryLineItem {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token, context } = params;

    if (token.includes(`${constants.PRIMARY_LINE_ITEM}.`)) {
      const primaryLineItemIndex = findIndex(context?.lineItems, { isPrimary: true });
      if (primaryLineItemIndex >= 0) {
        const regex = new RegExp(constants.PRIMARY_LINE_ITEM, 'g');
        const newToken = token.replace(regex, `lineItems[${primaryLineItemIndex}]`);
        return this?.next?.handle?.({ ...params, token: newToken });
      }
    }
    return this?.next?.handle?.(params);
  }
}
