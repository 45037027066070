import cn from 'classnames';
import React from 'react';
import { TEMPLATES, useTemplate } from '../../../delivery-information/template-processor/useTemplate';

export const Detail = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.MATERIAL_SUMMARY });

  return (
    <pre className={cn('material-summary')} data-testid="material-summary-text">
      {template}
    </pre>
  );
};
