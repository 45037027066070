import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import { camelCase, find, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  useCompanySetup,
  useCreateSetupItem,
  useUpdateSetupItem,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

const allCountries = require('../../countryList');

export const SimpleLayout = ({ config }) => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const create = useCreateSetupItem();
  const update = useUpdateSetupItem();

  const saveMasterData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const editMasterData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const handleSave = React.useCallback(() => {
    return saveMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(() => {
    return editMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editMasterData, values]);

  const companySetup = useCompanySetup();
  const vehicleTypesSetup = useVehicleTypeSetup();

  const busy = React.useMemo(() => {
    return companySetup.isLoading || vehicleTypesSetup.isLoading;
  }, [companySetup.isLoading, vehicleTypesSetup.isLoading]);

  const fieldComponents = React.useMemo(() => {
    const getCompanyConfig = async () => {
      return companySetup?.data?.countries?.map?.(country => find(allCountries, { id: country })) || [];
    };
    const options = getCompanyConfig();

    return (
      config
        ?.filter(c => (!companySetup?.data?.isMulticountry ? c.path !== 'countries' : c))
        ?.filter(c =>
          !companySetup?.data?.isReturnedMaterialInWorkflow ? c.path !== 'isReturnedMaterialInWorkflow' : c
        )
        ?.filter(c => (!companySetup?.data?.isWaterInWorkflow ? c.path !== 'isWaterInWorkflow' : c))
        ?.filter(c => (!companySetup?.data?.isMaterialServiceInWorkflow ? c.path !== 'isMaterialServiceInWorkflow' : c))
        ?.filter(c => (!companySetup?.data?.isMaterialComplianceEnabled ? c.path !== 'isMaterialComplianceEnabled' : c))
        ?.filter(c => (!companySetup?.data?.isUnloadingMethodEnabled ? c.path !== 'isUnloadingMethodEnabled' : c))
        ?.filter(c => (!companySetup?.data?.isTicketListEnabled ? c.path !== 'isTicketListEnabled' : c))
        ?.map?.(field => {
          const FieldComponent = Components[pascalCase(field.name || field.path)];
          if (field.path === 'countries') {
            return <FieldComponent options={options} key={field.name || field.path} />;
          }
          if (field.path === 'vehicleTypes') {
            return (
              <FieldComponent
                options={vehicleTypesSetup?.data?.filter?.(vehicleType => vehicleType?.status === 'ACTIVE') ?? []}
                key={field.name ?? field.path}
              />
            );
          }
          return field.path === 'crn' ? null : <FieldComponent key={field.name || field.path} />;
        }) || []
    );
  }, [Components, companySetup?.data, config, vehicleTypesSetup?.data]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('simple-setup-editor')}>
        {fieldComponents}
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
