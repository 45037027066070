import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { Core, Form, Layout, Typography } from 'connex-cds';

const Styled = styled.div`
  ${style}
`;

const { Column, Row, Container } = Layout;
const { Subtitle } = Typography;

export const RiskAssessmentDesktop = ({ categories, handleYesClick, handleNoClick }) => {
  const { values } = Form.useFormContext();

  return (
    <Styled className={cn('risk-assessment-desktop')}>
      <Container>
        {Object.keys(categories)?.map(key => (
          <>
            <Subtitle>
              <div data-testid="riskAssessmentCategory">{key}</div>
            </Subtitle>
            {categories[key]?.map?.(riskAssessment => (
              <Column>
                <Container flex={1} style={{ padding: '5px 10px' }}>
                  <Row style={{ alignItems: 'center' }}>
                    <div className="subcategory-section" style={{ padding: '5px' }}>
                      <div data-testid="riskAssessmentSubCategory">{riskAssessment?.subcategory}</div>
                    </div>
                    <div
                      className={cn('buttons', {
                        true: values?.riskAssessment?.[
                          values?.riskAssessment?.findIndex(risk => risk.subcategory === riskAssessment.subcategory)
                        ]?.passAssessment,
                      })}
                    >
                      <Core.Button
                        className="trueButton"
                        type={
                          values?.riskAssessment?.[
                            values?.riskAssessment?.findIndex(risk => risk.subcategory === riskAssessment.subcategory)
                          ]?.passAssessment
                            ? 'primary'
                            : undefined
                        }
                        onClick={e => {
                          handleYesClick(riskAssessment);
                          e.stopPropagation();
                        }}
                        stringId="yes"
                        data-testid="assessment-yes-button"
                        size="small"
                      />
                      <Core.Button
                        className="falseButton"
                        type={
                          values?.riskAssessment?.[
                            values?.riskAssessment?.findIndex(risk => risk.subcategory === riskAssessment.subcategory)
                          ]?.passAssessment
                            ? undefined
                            : 'primary'
                        }
                        onClick={e => {
                          handleNoClick(riskAssessment);
                          e.stopPropagation();
                        }}
                        stringId="no"
                        data-testid="assessment-no-button"
                        size="small"
                        danger={
                          values?.riskAssessment?.[
                            values?.riskAssessment?.findIndex(risk => risk.subcategory === riskAssessment.subcategory)
                          ]?.passAssessment === false
                        }
                      />
                    </div>
                  </Row>
                </Container>
              </Column>
            ))}
          </>
        ))}
      </Container>
    </Styled>
  );
};
