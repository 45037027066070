import cn from 'classnames';
import { Layout } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from './context/Provider';
import { CopyFrom } from './copy/CopyFrom';

const Styled = styled(Layout.Column)`
  textarea {
    width: 100%;
    height: 100%;
    font-family: monospace;
    padding: 5px 10px;
  }
`;

export const TemplateText = ({ onChange, templateName, language }) => {
  const { state } = useTemplateContext();

  const value = React.useMemo(() => {
    return get(state, [templateName, language], '');
  }, [state, templateName, language]);

  return (
    <Styled className={cn('template-text')}>
      <CopyFrom templateName={templateName} language={language} visible={!value?.length} />
      <textarea value={value} onChange={onChange} />
    </Styled>
  );
};
