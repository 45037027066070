import { Localization } from 'connex-cds';
import { fromPairs, get, sortBy, toPairs } from 'lodash';
import React from 'react';
import { useTemplateContext } from '../context/Provider';

export const useCopyFrom = ({ templateName, language }) => {
  const { state } = useTemplateContext();
  const translateMessage = Localization.useTranslateMessage();

  const otherLanguages = React.useMemo(() => {
    const templates = get(state, [templateName], {});
    return Object.keys(templates).filter(t => t !== language);
  }, [language, state, templateName]);

  const otherLanguagesWithContent = React.useMemo(() => {
    return otherLanguages?.reduce?.((acc, lang) => {
      const template = get(state, [templateName, lang]);
      if (template?.trim?.()?.length) {
        acc.push(lang);
      }
      return acc;
    }, []);
  }, [otherLanguages, state, templateName]);

  return React.useMemo(() => {
    const other = otherLanguagesWithContent.reduce((acc, lang) => {
      acc[lang] = translateMessage(`languages.${lang}`);
      return acc;
    }, {});

    const sorted = fromPairs(sortBy(toPairs(other), 1));

    return Object?.keys?.(other)?.length ? sorted : undefined;
  }, [otherLanguagesWithContent, translateMessage]);
};
