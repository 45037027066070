import React, { useCallback, useMemo, useState } from 'react';
import { find } from 'lodash';

import EntityList from './components/entities-list/EntitiesList';
import EntityButton from './components/entity-button/EntityButton';
import { getFilteredDataByProperties } from '../../../components/form/search-filter/searchFilter.helpers';
import { StyledPopover } from './styles';

const EntityPicker = ({ value, onChange, entities, currentEntitySetup, className }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [querySearch, setQuerySearch] = useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const options = useMemo(
    () =>
      entities
        ?.map?.(option => ({ id: option.entityRef, label: option.name }))
        ?.sort((a, b) => a?.label?.localeCompare(b?.label)) || [],
    [entities]
  );

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: options, query: querySearch, properties: ['label'] }),
    [options, querySearch]
  );

  const handleInputChange = useCallback(
    e => {
      e.preventDefault();
      const inputValue = e.target?.value;

      setQuerySearch(inputValue);
    },
    [setQuerySearch]
  );

  const handleClose = () => {
    setAnchorEl(null);
    setQuerySearch('');
  };

  const handleOnEntityChange = useCallback(
    newValue => {
      const entityName = find(entities, { entityRef: newValue })?.name;
      onChange(newValue, entityName);
      handleClose();
    },
    [entities, onChange]
  );

  const selectedEntity = useMemo(() => {
    return find(options, { id: value || '' }) || '';
  }, [value, options]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={className}>
      <EntityButton
        currentEntitySetup={currentEntitySetup}
        isOpen={open}
        id={id}
        handleClick={handleClick}
        selectedEntity={selectedEntity}
        disabled={entities?.length <= 1}
      />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <EntityList
          selectedEntity={selectedEntity}
          entities={filteredData}
          onSearch={handleInputChange}
          searchTerm={querySearch}
          onEntityChange={handleOnEntityChange}
          onClearSearch={() => setQuerySearch('')}
        />
      </StyledPopover>
    </div>
  );
};

export default EntityPicker;
