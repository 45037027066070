import cn from 'classnames';
import { Core } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from '../context/Provider';
import { CopyFromSelect } from './CopyFromSelect';
import { useCopyFrom } from './useCopyFrom';

const Styled = styled.div`
  display: flex;
  gap: 15px;
  align-content: center;
  justify-content: flex-end;
  select {
    height: 26px;
    min-width: 150px;
  }
`;

export const CopyFrom = ({ templateName, language, visible }) => {
  const [source, setSource] = React.useState();
  const { state, dispatch } = useTemplateContext();

  const options = useCopyFrom({ templateName, language });

  const handleClick = React.useCallback(() => {
    const sourceTemplate = get(state, [templateName, source]);
    if (sourceTemplate?.length) {
      dispatch({ type: 'change', payload: { template: templateName, language, value: sourceTemplate } });
    }
  }, [dispatch, language, source, state, templateName]);

  const handleChange = React.useCallback(e => {
    setSource(e.target.value);
  }, []);

  return options && visible ? (
    <Styled className={cn('copy-from')}>
      <CopyFromSelect onChange={handleChange} options={options} />
      <Core.Button
        size="small"
        type="primary"
        stringId="copy"
        data-testid="copy-button"
        onClick={handleClick}
        disabled={!source}
      />
    </Styled>
  ) : null;
};
