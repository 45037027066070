import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import paymentMethod from '../api/payment-method';

export const useGetPaymentMethod = (paymentRef, companySetup) => {
  const { entityRef } = useParams();

  const isEnablePaymentAndCostWorkflows = useMemo(
    () => companySetup?.data?.isEnablePaymentAndCostWorkflows ?? false,
    [companySetup?.data?.isEnablePaymentAndCostWorkflows]
  );

  const isFetchingEnabled = useMemo(() => {
    if (!isEnablePaymentAndCostWorkflows) {
      return false;
    }

    return Boolean(entityRef) && Boolean(paymentRef);
  }, [entityRef, paymentRef, isEnablePaymentAndCostWorkflows]);

  return useQuery({
    queryKey: [entityRef, 'payment-methods', paymentRef],
    queryFn: () => paymentMethod.getPaymentMethod({ entityRef, paymentRef }),
    staleTime: Infinity,
    enabled: isFetchingEnabled,
    refetchOnWindowFocus: false,
  });
};
