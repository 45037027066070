import React from 'react';
import cn from 'classnames';
import { Core, Drawer, Form, Localization } from 'connex-cds';
import styled from 'styled-components';
import { find } from 'lodash';
import {
  useCompanySetup,
  useCreateSetupItem,
  useUpdateSetupItem,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import { languagesNames, pascalCase } from '../../languagesNames';
import style from './style';

const allCountries = require('../../countryList');

const Styled = styled.div`
  ${style}
`;

export const ReasonCodesEditor = ({ config }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { userLocale } = Localization.useLocalizationContext();

  const create = useCreateSetupItem();
  const update = useUpdateSetupItem();

  const companySetup = useCompanySetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const currentUserLocale = React.useMemo(() => {
    const [defaultLanguage] = Object.keys(companySetup?.data)
      .filter(key => key?.includes('default') && key?.includes('Language') && companySetup?.data[key])
      .map(key => {
        const variableName = key?.replace(/(default)|Language$/gi, '');
        const languagesName = find(languagesNames, { variableName });
        return languagesName?.locale;
      });
    return defaultLanguage;
  }, [companySetup?.data, userLocale]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue(`i18n.${currentUserLocale}`, values?.description);
    }
  }, [setFieldValue, currentUserLocale, values?.description]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue('description', values?.i18n[`${currentUserLocale}`]);
    }
  }, [setFieldValue, currentUserLocale]);

  const saveMasterData = React.useCallback(
    async item => {
      return create(item);
    },
    [create]
  );

  const editMasterData = React.useCallback(
    async item => {
      return update(item);
    },
    [update]
  );

  const handleSave = React.useCallback(() => {
    return saveMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(() => {
    return editMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editMasterData, values]);

  const countries = React.useMemo(() => {
    if (!companySetup?.data) {
      return [];
    }

    return companySetup?.data?.countries?.map?.(country => find(allCountries, { id: country }));
  }, [companySetup?.data, allCountries]);

  const vehicles = React.useMemo(() => {
    if (!vehicleTypeSetup?.data) {
      return [];
    }

    return vehicleTypeSetup.data;
  }, [vehicleTypeSetup.data]);

  const allowedLanguages = React.useMemo(() => {
    return (
      Object.keys(companySetup?.data)
        ?.filter(key => {
          const allowedLanguage = new RegExp(/^(allow)\w*Language$/i);
          return allowedLanguage.test(key);
        })
        ?.map(key => companySetup?.data[key])
        ?.filter(language => Boolean(language)).length > 1
    );
  }, [companySetup?.data]);

  const fieldsComponents = React.useMemo(() => {
    return config?.fields
      ?.filter(c => (companySetup?.data?.isMulticountry !== true ? c.path !== 'countries' : c))
      ?.reduce(
        (acc, field) => {
          try {
            const FieldComponent = Components[pascalCase(field.name ?? field.path)];
            FieldComponent.displayName = field.name ?? field.path;

            if (field.path === 'crn') {
              return acc;
            }

            if (field.path === 'countries') {
              acc.general.push(<FieldComponent options={countries} busy={companySetup?.isLoading} />);
              return acc;
            }

            if (field.path === 'vehicleTypes') {
              acc.general.push(<FieldComponent options={vehicles} busy={vehicleTypeSetup?.isLoading} />);
              return acc;
            }

            const isDescriptionField = field.path.includes('i18n');
            if (allowedLanguages && isDescriptionField) {
              acc.translations.push(<FieldComponent disabled={field.path.includes(currentUserLocale)} />);
              return acc;
            }

            if (!allowedLanguages && isDescriptionField) {
              return acc;
            }

            acc.general.push(<FieldComponent />);
            return acc;
          } catch (error) {
            return acc;
          }
        },
        allowedLanguages ? { general: [], translations: [] } : { general: [] }
      );
  }, [config?.fields, companySetup?.data, allowedLanguages, countries, vehicles, currentUserLocale]);

  const tabsConfig = React.useMemo(() => {
    const tabs = [
      {
        stringId: 'general',
        testId: 'general-tab',
        component: fieldsComponents.general,
      },
    ];

    if (allowedLanguages) {
      tabs.push({
        stringId: 'translations',
        testId: 'translations-tab',
        component: fieldsComponents.translations,
      });
    }

    return {
      tabs,
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };
  }, [fieldsComponents?.general, fieldsComponents?.translations, allowedLanguages]);

  return (
    <Core.Spinner>
      <Styled className={cn('forms-and-waivers-editor', { tabbed: tabsConfig?.tabs?.length > 1 })}>
        {tabsConfig?.tabs?.length > 1 ? <Core.Tabs config={tabsConfig} /> : tabsConfig?.tabs?.[0]?.component}
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
