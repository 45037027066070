import cn from 'classnames';
import { Drawer, Layout, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import DeliveryInformation from '../../../delivery-information/DeliveryInformation';
import { useTicketContext } from '../../../TicketContext';
import Material from './Material';
import style from './style';

const Styled = styled(Layout.Container)`
  ${style}
`;

export const MaterialSummary = () => {
  const { openDrawer } = Drawer.useDrawerContext();
  const { ticket } = useTicketContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: ticket?.customData?.ticketType !== 'PMP' ? 'materialAndDeliveryInfo' : 'materialSummary',
      component: <DeliveryInformation />,
      width: '100%',
    });
  }, [openDrawer, ticket?.customData?.ticketType]);

  return (
    <Styled className={cn('materialSummary')} onClick={handleClick}>
      <div className="left">
        <Material />
      </div>
      <div className="right">
        <Typography.Subtitle>
          <i className="fa-regular fa-angle-right" />
        </Typography.Subtitle>
      </div>
    </Styled>
  );
};
