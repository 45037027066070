import React from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';

const Styled = styled.i``;

const Icon = ({ iconWeight, iconType, className: classNameProp = '' }) => {
  const iconNames = {
    search: 'fa-magnifying-glass',
    empty: 'fa-circle-xmark',
    history: 'fa-clock-rotate-left',
    delete_history: 'fa-xmark',
    box: 'fa-box',
    arrowDown: 'fa-caret-down',
    arrowUp: 'fa-caret-up',
    dollar: 'fa-dollar-sign',
    addCharges: 'fa-cart-plus',
    invoice: 'fa-receipt',
    check: 'fa-check',
    ban: 'fa-ban',
    calendar: 'fa-calendar',
    bookmark: 'fa-bookmark',
    arrowDownLine: 'fa-angle-down',
    arrowUpLine: 'fa-angle-up',
    trash: 'fa-trash',
    plus: 'fa-circle-plus',
    circleCheck: 'fa-circle-check',
    circleExclamation: 'fa-circle-exclamation',
  };
  return (
    <Styled
      className={cn('icon', iconWeight, iconNames[iconType], classNameProp)}
      aria-hidden="true"
      isType={iconType}
    />
  );
};

export default Icon;
