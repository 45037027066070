import { http } from 'connex-cds';

export default {
  getMasterData: ({ entityRef, typeId, truckNumber }) =>
    http.get({
      apiName: 'mt',
      path: `/${entityRef}/master-data/${typeId}?cb=${Date.now()}${truckNumber ? `&truckNumber=${truckNumber}` : ''}`,
    }),
  getAllActiveMasterData: ({ entityRef, truckNumber }) =>
    http.get({
      apiName: 'mt',
      path: `/${entityRef}/master-data?cb=${Date.now()}${truckNumber ? `&truckNumber=${truckNumber}` : ''}`,
    }),
  getMasterDataItem: ({ entityRef, typeId, itemRef }) =>
    http.get({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}/${itemRef}` }),
  updateMasterData: ({ entityRef, typeId, item }) =>
    http.put({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}/${item?.crn}`, data: item }),
  createMasterData: ({ entityRef, typeId, item }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}`, data: item }),
  createSetupData: ({ entityRef, itemType, item }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/${itemType}`, data: item }),
  updateSetupData: ({ entityRef, itemType, item }) =>
    http.put({ apiName: 'mt', path: `/${entityRef}/${itemType}/${item?.crn}`, data: item }),
  getPublicSummary: ({ itemRef }) =>
    http.get({ apiName: 'mt', path: `/get-summary/${itemRef}` }),
};
