import constants from './constants';

export const suppressEmpty = text => {
  const lines = text?.split?.('\n') || [];
  return lines.reduce((acc, value) => {
    if (!value.includes(constants.EMPTY)) {
      acc = `${acc}${value}\n`;
    }
    return acc;
  }, '');
};
