import { Form } from 'connex-cds';
import { find } from 'lodash';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
import { labelStringIdBullder, languagesNames, pascalCase } from '../languagesNames';
import { AssessmentRiskEditor } from '../custom-editors/assessment-risk/AssessmentRiskEditor';
const { FIELD_TYPES } = Form;

const buildField = (sourceField, companySetup, userLocale) => language => {
  const { variableName, locale } = language;
  const isLanguageDefault = companySetup?.data[`default${variableName}Language`];
  const labelString = isLanguageDefault ? 'Default' : '';
  return {
    path: `i18n.${sourceField}.${locale}`,
    labelStringId: `${variableName}${pascalCase(sourceField)}${labelString}`,
    testId: `${sourceField}_${variableName}`,
    dataType: FIELD_TYPES.string,
    hidden: !isLanguageDefault,
  };
};

const buildLanguageFields = (companySetup, userLocale) => {
  if (!languagesNames) {
    return [];
  }
  const languagesFiltered = languagesNames?.filter(language => {
    if (!companySetup?.data || !userLocale) {
      return false;
    }

    const { variableName } = language;
    return companySetup?.data[`allow${variableName}Language`];
  });

  return [
    ...languagesFiltered?.map(buildField('category', companySetup, userLocale)),
    ...languagesFiltered?.map(buildField('subcategory', companySetup, userLocale)),
  ];
};

const assessmentRisk = (setupVehicleTypes, companySetup, userLocale) => ({
  icon: <i className="icon fa-thin fa-triangle-exclamation" />,
  validationSchema: Yup.object().shape({
    category: Yup.string().required('required'),
    subcategory: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  customEditor: AssessmentRiskEditor,
  fields: [
    {
      path: 'product',
      labelStringId: 'vehicleType',
      testId: 'vehicle-type',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listTypeId: 'vehicle-type',
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'description',
      filterFn: (item, filterStrings) => item?.includes?.(filterStrings[0]),
      filterComponent: ({ innerRef, ...props }) => (
        <select ref={innerRef} {...props}>
          <option value="" />
          {setupVehicleTypes?.map?.(vehicleType => (
            <option value={vehicleType.id} key={vehicleType.id}>
              {vehicleType.description}
            </option>
          ))}
        </select>
      ),
      formatter: props => (
        <div data-testclass={'cell-column-vehicle-type'} data-testid={`row-${props.row.crn}-column-vehicle-type`}>
          {props.row.product
            ?.map?.(vehicleType => find(setupVehicleTypes, { id: vehicleType })?.description)
            ?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'category',
      labelStringId: 'category',
      testId: 'category',
      dataType: FIELD_TYPES.string,
      hidden: true,
    },
    {
      path: 'subcategory',
      labelStringId: 'subcategory',
      testId: 'subcategory',
      dataType: FIELD_TYPES.string,
      hidden: true,
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
    ...buildLanguageFields(companySetup, userLocale),
  ],
});

export default assessmentRisk;
