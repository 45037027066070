import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import { isEmpty } from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import styled from 'styled-components';
import style from './style';

const allCountries = require('../../../countryList');

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

const { useTranslateMessage } = Localization;

export const General = ({ busy, onSave }) => {
  const translateMessage = useTranslateMessage();
  const { Components, values, errors, setFieldValue } = Form.useFormContext();

  React.useEffect(() => {
    if (!values?.isQrCodeEnabled) {
      setFieldValue('qrMethod', null);
    }

    if (values?.isQrCodeEnabled && !values?.qrMethod) {
      setFieldValue('qrMethod', 'linkBased');
    }
  }, [values?.isQrCodeEnabled, values?.qrMethod]);

  React.useEffect(() => {
    if (!values?.isRejectLoadsEnable) {
      setFieldValue('rejectLoadStep', null);
    }

    if (values?.isRejectLoadsEnable && !values?.rejectLoadStep) {
      setFieldValue('rejectLoadStep', 'returnedMaterial');
    }
  }, [values?.isRejectLoadsEnable, values?.rejectLoadStep]);

  React.useEffect(() => {
    if (!values?.isRefreshEnabled) {
      setFieldValue('pollingInterval', '');
    } else if (values?.pollingInterval === '') {
      setFieldValue('pollingInterval', 0);
    }
  }, [values?.isRefreshEnabled, !values?.pollingInterval]);

  return (
    <Styled className={cn('general')}>
      <Column flex={1}>
        <Container flex={1} style={{ padding: '15px' }}>
          <Row stretchContent>
            <Components.Multicountry />
          </Row>
          {values.isMulticountry && (
            <Row stretchContent>
              <Components.Countries options={allCountries} />
            </Row>
          )}
          <Row stretchContent>
            <Components.TimeZone />
          </Row>
        </Container>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.RefreshEnabled />
            <Components.PollingInterval disabled={!values.isRefreshEnabled} />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.MaxLineItems />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Row className="driver-comment-config">
              <Components.DriverCommentEnabled />
              <Components.DriverCommentMaxCharacters />
            </Row>
          </Container>
        </Row>

        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Row className="customer-comment-config">
              <Components.CustomerCommentEnabled />
              <Components.CustomerCommentMaxCharacters />
            </Row>
          </Container>
        </Row>

        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.UnFinalizePassword />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.QrCodeEnabled />
            <RadioGroup
              labelStringId="QrMethod"
              defaultValue="linkBased"
              value={values?.qrMethod}
              onChange={event => setFieldValue('qrMethod', event?.target?.value)}
            >
              <FormControlLabel
                value="linkBased"
                control={<Radio />}
                label={translateMessage('QrMethod_linkBased')}
                disabled={!values?.isQrCodeEnabled}
              />
              <FormControlLabel
                value="contentBased"
                control={<Radio />}
                label={translateMessage('QrMethod_contentBased')}
                disabled={!values?.isQrCodeEnabled}
              />
            </RadioGroup>
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.RiskAssessmentEnabled />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Typography.H3>
              <Localization.Translate stringId="forms-and-waivers" data-testid="formsAndWaivers-title" />
            </Typography.H3>
            <Components.TermsAndConditionsEnabled />
            <Components.DamageWaiverEnabled />
            <Components.DynamicWaiverEnabled />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.TicketListEnabled />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.KeepTicketOpenAfterSubmission />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.PdfRecipientList />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.RejectLoads />
            <RadioGroup
              defaultValue="returnedMaterial"
              value={values?.rejectLoadStep}
              onChange={event => setFieldValue('rejectLoadStep', event?.target?.value)}
            >
              <FormControlLabel
                value="returnedMaterial"
                control={<Radio />}
                label={translateMessage('rejectLoad_returnedMaterial')}
                disabled={!values?.isRejectLoadsEnable}
              />
              <FormControlLabel
                value="returnedAndFinalize"
                control={<Radio />}
                label={translateMessage('rejectLoad_returnedAndFinalize')}
                disabled={!values?.isRejectLoadsEnable}
              />
            </RadioGroup>
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.IsEnablePaymentAndCostWorkflows />
          </Container>
        </Row>
        <div className="actions" style={{ padding: '15px' }}>
          <Core.Button
            loading={busy}
            type="primary"
            data-testid="save-button"
            stringId="save"
            onClick={onSave}
            disabled={!isEmpty(errors)}
          />
        </div>
      </Column>
    </Styled>
  );
};
