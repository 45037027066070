import { Form } from 'connex-cds';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

/*
    "code": "string",
    "username": "string",
    "firstName": "string",
    "lastName": "string",
    "password": "string",
    "phone": "string",
    "terms": {
      "version": "string",
      "accepted": "string"
    },
    "eula": {
      "version": "string",
      "accepted": "string"
    }
 */

export default {
  path: 'user',
  labelStringId: 'user',
  testId: 'user',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    code: Yup.string().required('required'),
    username: Yup.string().required('required'),
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required('required'),
    password: Yup.string()
      .password()
      .min(8, 'passwordMinCharacters')
      .max(99, 'passwordMaxCharacters')
      .minLowercase(1, 'passwordLowerCase')
      .minUppercase(1, 'passwordUpperCase')
      .minNumbers(1, 'passwordNumber')
      .minSymbols(1, 'passwordSpecialCharacter')
      .required('required'),
    passwordVerify: Yup.string()
      .oneOf([Yup.ref('password'), null], 'passwordsDontMatch')
      .required('required'),
    phone: Yup.string(),
  }),
  fields: [
    { path: 'code', labelStringId: 'verificationCode', testId: 'code', dataType: Form.FIELD_TYPES.string },
    { path: 'username', labelStringId: 'username', testId: 'user-name', dataType: Form.FIELD_TYPES.string },
    { path: 'lastName', labelStringId: 'family_name', testId: 'family-name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given-name', dataType: Form.FIELD_TYPES.string },
    { path: 'phone', labelStringId: 'phoneNumber', testId: 'phone-number', dataType: Form.FIELD_TYPES.string },
    {
      path: 'password',
      labelStringId: 'password',
      testId: 'password',
      password: true,
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'passwordVerify',
      labelStringId: 'verifyPassword',
      testId: 'verify-password',
      password: true,
      dataType: Form.FIELD_TYPES.string,
    },
  ],
};
