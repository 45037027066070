import { TemplatesEditor } from '../custom-editors/templates-editor/TemplatesEditor';

const templates = {
  icon: <i className="icon fa-thin fa-file-invoice" />,
  noList: true,
  customEditor: TemplatesEditor,
  fields: [],
};

export default templates;
