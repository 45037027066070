import { http } from 'connex-cds';

export default msgs => {
  const messages = [].concat(msgs);

  try {
    return http.post({ apiName: 'mt', path: `/analytics/logging`, data: messages }).catch(() => {});
  } catch (e) {
    // ignore
  }
};
