import { css } from 'styled-components';

const displayNone = props => (props.hasContent ? '' : 'display: none;');

export const hideableRow = css`
  ${displayNone}
`;

export default css`
  ${displayNone}
  margin-right: 10px;
  table {
    th {
      text-align: left;
    }
  }
`;
