import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyFR_FR = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>
          AVIS DE CONFIDENTIALITÉ DE
          <br />
          COMMAND ALKON INCORPORATED
        </h4>
      </div>
      <div
        id="overview_content"
        class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light"
      >
        <div>
          <p>
            <em>Dernière modification : 26 septembre 2023</em>
          </p>
          <h2>Aperçu</h2>
          <p>
            Command Alkon Incorporated, y compris ses sociétés connexes (« CAI » ou « nous ») est une organisation
            commerciale internationale qui fournit des logiciels, des services et des solutions web à des entreprises
            dans l’industrie des transporteurs en vrac, de granulat et de béton prémélangé avec des méthodes sur site,
            SaaS (« SaaS ») ou d’autres méthodes basées sur le web. CAI respecte la vie privée des utilisateurs de ses
            produits et services et s&#8217;engage à protéger leur vie privée et à maintenir leur confiance. Le présent
            Avis de confidentialité décrit nos pratiques en matière de collecte, d&#8217;utilisation, de stockage, de
            transfert, de divulgation et de traitement des données personnelles (autrement appelées Informations
            personnelles) en relation avec votre utilisation des produits ou services, des sites Web, des applications
            et des pages de réseaux sociaux de CAI, ainsi qu’avec vos interactions avec le personnel de CAI lors de
            réunions et événements de CAI, y compris les activités de vente et de marketing hors ligne. Veuillez lire
            attentivement le présent Avis de confidentialité pour comprendre nos politiques et nos pratiques concernant
            le traitement des données personnelles. En accédant à notre site et/ou en utilisant nos produits ou
            services, vous acceptez que nous collections, utilisions et divulguions des données personnelles comme
            décrit dans le présent Avis de confidentialité. Si vous avez des questions ou des inquiétudes concernant la
            confidentialité, contactez-nous par le biais des informations « Nous contacter » en bas du présent Avis de
            confidentialité.
          </p>
          <h2>Table des matières </h2>
          <ul>
            <li>
              <a href="#summary_of_privacy_notice">Résumé de l&#8217;Avis de confidentialité </a>
            </li>
            <li>
              <a href="#controller_processor">Responsable du traitement des données/sous-traitant des données</a>
            </li>
            <li>
              <a href="#to_whom_does_this_notice_apply">À qui s&#8217;applique cet Avis</a>
            </li>
            <li>
              <a href="#how_we_collect_personal_data">Comment nous collectons et utilisons vos données personnelles </a>
            </li>
            <li>
              <a href="#automatically_collected_information">Données collectées automatiquement </a>
            </li>
            <li>
              <a href="#how_we_use_the_information_we_collect">
                Comment nous utilisons les données que nous collectons
              </a>
            </li>
            <li>
              <a href="#how_we_disclose_the_information_we_collect">
                Comment nous divulguons les données que nous collectons
              </a>
            </li>
            <li>
              <a href="#third_party_services">Services tiers</a>
            </li>
            <li>
              <a href="#security">Sécurité</a>
            </li>
            <li>
              <a href="#retention_of_data">Conservation des données </a>
            </li>
            <li>
              <a href="#your_rights_and_choices">Vos droits et vos choix</a>
            </li>
            <li>
              <a href="#international_data_transfers_and_privacy_shield">
                Transferts de données internationaux et le Cadre de Confidentialité des Données
              </a>
            </li>
            <li>
              <a href="#lawful_basis_for_processing">Base juridique du traitement</a>
            </li>
            <li>
              <a href="#children_policy">Confidentialité des enfants</a>
            </li>
            <li>
              <a href="#changes_to_the_privacy_notice">Modifications de l&#8217;Avis de confidentialité </a>
            </li>
            <li>
              <a href="#contact_us">Nous contacter</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="summary_of_privacy_notice">
        <div>
          <h4>Résumé de l&#8217;Avis de confidentialité </h4>
          <p>
            <strong>Application</strong> &ndash; notre Avis de confidentialité s&#8217;applique au traitement des
            données personnelles fournies par : 1) les visiteurs et utilisateurs des différents sites, applications,
            produits et/ou services de CAI ; 2) les participants aux événements de CAI et du secteur ; 3) les clients et
            clients potentiels et leurs représentants ; 4) les abonnés à nos avis et/ou newsletters ; 5) les
            fournisseurs et partenaires commerciaux et leurs représentants ; 6) les visiteurs de nos bureaux ; et 7)
            toute autre personne qui fournit des données personnelles à CAI à d&#8217;autres fins. Pour plus de détails,
            voir ci-dessous.
          </p>
          <p>
            <strong>Types de données traitées : </strong> &ndash; CAI collecte les données personnelles que vous
            fournissez. Par exemple, lorsque vous vous inscrivez pour ouvrir un compte, nous pouvons collecter des
            informations comme les noms, les adresses e-mail, les adresses physiques, les numéros de téléphone, etc.
            Vous pouvez également fournir des données personnelles pour en apprendre plus sur nos produits ou vous
            inscrire à nos notifications, par exemple. Nous pouvons traiter les données personnelles suivantes que nous
            pouvons collecter (en fonction des circonstances) lors de visites sur notre site Web, d&#8217;interactions
            marketing, de l&#8217;utilisation de nos applications et de l&#8217;utilisation de nos produits et/ou
            services dans le cadre d&#8217;accords avec vous et/ou votre employeur : votre nom, vos coordonnées (adresse
            e-mail, numéro de téléphone, etc.), des informations sur votre emploi (employeur, poste, supérieur, etc.),
            certains numéros d&#8217;identification (permis de conduire, employé, etc.), des informations sur vos
            performances professionnelles et diplômes, des informations de paiement, votre adresse IP, votre
            géolocalisation, votre signature, votre image caméra, votre identifiant et mot de passe, des informations
            syndicales, votre origine ethnique, certaines informations médicales liées à votre emploi, vos messages et
            des données et informations comportementales sur vous reçues grâce au placement de cookies et pixels espions
            pendant l&#8217;utilisation de la plateforme. Pour plus de détails, voir ci-dessous.
          </p>
          <p>
            <strong>Finalité du traitement :</strong> nous traitons vos données personnelles aux fins suivantes : 1)
            réalisation d&#8217;accords avec vous et/ou votre employeur ; 2) suivi, développement, assistance et
            administration des applications, sites Web et plateformes ; 3) sécurité et prévention des fraudes ; 4) nos
            fins marketing (nous ne revendons pas vos données personnelles) ; 5) notre administration commerciale ; et
            6) des analyses comportementales associées à l&#8217;utilisation de la plateforme. Pour plus de détails,
            voir ci-dessous.
          </p>
          <p>
            <strong>Base juridique du traitement : </strong> dans la mesure de ce qui vous concerne, certaines
            juridictions nécessitent que le traitement des données personnelles soit appuyé par une base légale. Nous
            nous appuyons généralement sur les justifications légales suivantes pour nos activités de traitement : 1) la
            réalisation d&#8217;un accord avec vous et/ou votre employeur ; 2) la poursuite de nos intérêts légitimes
            tant que nous n&#8217;outrepassons pas vos intérêts, vos droits et votre liberté ; 3) votre consentement ;
            et 4) la conformité avec une obligation légale. Pour plus de détails, voir ci-dessous.
          </p>
          <p>
            <strong>Transferts de données : </strong> nous pouvons transférer vos données personnelles à d&#8217;autres
            filiales de CAI et/ou à des tiers (par ex. des partenaires commerciaux, des revendeurs, etc.), à des entités
            acquises ou acheteuses, à des prestataires de services et, conformément à la loi en vigueur, à des autorités
            gouvernementales, des tribunaux, des conseillers externes et d&#8217;autres tiers similaires. Certains
            transferts de données peuvent traverser les frontières nationales. Nous utiliserons toutes les mesures
            raisonnables pour veiller à ce que vos données personnelles restent confidentielles lors de ces transferts.
            Nous ne revendons pas vos données personnelles à des tiers. De plus, CAI ne partagera pas vos données avec
            des tiers sans divulgation complète, sauf indication contraire dans le présent Avis de confidentialité. Pour
            plus de détails, voir ci-dessous.
          </p>
          <p>
            <strong>Conservation et suppression des données : </strong> &ndash; vos données personnelles seront
            supprimées une fois qu&#8217;elles ne sont plus nécessaires aux fins du traitement original ou des intérêts
            légitimes, ou lorsque la loi en vigueur l&#8217;exige. Pour plus de détails, voir ci-dessous.
          </p>
          <p>
            <strong>Vos droits et vos choix : </strong> vous pouvez disposer, selon votre juridiction, d&#8217;un
            certain nombre de droits concernant vos données personnelles qui peuvent inclure le droit d&#8217;accès à
            vos données personnelles, le droit d&#8217;obtenir une copie de vos données personnelles, le droit de
            demander le transfert de vos données personnelles à un tiers, le droit de corriger vos données personnelles,
            le droit de restreindre certains traitements et/ou le droit de demander la suppression de vos données
            personnelles. Pour exercer vos droits en lien avec vos données personnelles, utilisez les informations de la
            rubrique « Nous contacter » en bas du présent Avis de confidentialité. Pour plus de détails, voir
            ci-dessous.
          </p>
          <p>
            <strong>Modifications de l&#8217;Avis de confidentialité : </strong> nous nous réservons le droit de
            modifier les conditions du présent Avis de confidentialité à volonté et à tout moment, conformément à
            l&#8217;évolution des pratiques ou de la législation sur la confidentialité. La version actuelle du présent
            Avis de confidentialité restera disponible via un lien sur nos sites Web, plateformes ou la rubrique « Nous
            contacter » en bas du présent Avis de confidentialité. Vous devez consulter le présent Avis de
            confidentialité régulièrement pour vous tenir informé de nos dernières pratiques et politiques.
          </p>
        </div>
      </div>
      <div id="controller_processor">
        <div>
          <h4>Avis de confidentialité détaillé</h4>
          <p>
            <strong>Responsable du traitement des données/sous-traitant des données </strong>CAI peut être le
            responsable du traitement des données ou le sous-traitant des données aux fins du traitement des données
            personnelles, selon les circonstances. Lorsque CAI traite des données pour le compte de nos clients ou de
            leurs sociétés affiliées, CAI agit le plus souvent en tant que sous-traitant des données. Lorsque CAI traite
            des données personnelles à ses propres fins et en dehors des instructions de ses clients ou de leurs
            sociétés affiliées, CAI peut être un responsable du traitement des données. Si vous avez des questions sur
            le rôle de CAI en ce qui concerne vos données personnelles, veuillez utiliser les informations de la
            rubrique « Nous contacter » au bas du présent Avis de confidentialité.
          </p>
        </div>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <div>
          <p>
            <strong>À qui s&#8217;applique cet Avis &mdash;</strong> Notre Avis de confidentialité s&#8217;applique à
            tout traitement, non lié à l&#8217;emploi, de données personnelles par CAI, peu importe la source de la
            collecte. Le présent Avis de confidentialité s&#8217;applique au traitement des données personnelles
            fournies par : 1) les visiteurs et utilisateurs des différents sites, applications, produits et/ou services
            de CAI ; 2) les participants aux événements de CAI ; 3) les clients et clients potentiels et leurs
            représentants ; 4) les abonnés à nos avis, e-mails et/ou newsletters ; 5) les fournisseurs et partenaires
            commerciaux et leurs représentants ; 6) les visiteurs de nos bureaux ; et 7) toute autre personne qui
            fournit des données personnelles à CAI à d&#8217;autres fins.
          </p>
        </div>
      </div>
      <div id="how_we_collect_personal_data">
        <div>
          <h2>Comment nous collectons les données personnelles &mdash;</h2>
          <h3>Données personnelles que vous fournissez :</h3>
          <p>
            CAI collecte les données personnelles fournies par vous-même ou par votre représentant. Cela peut se
            produire lors d&#8217;un événement CAI, lors d&#8217;un événement de soutien, par le biais du marketing,
            lors d&#8217;une interaction en face à face, etc. Voici quelques exemples non exhaustifs des types de
            données personnelles que CAI peut collecter : nom, adresse, numéro de téléphone, adresse e-mail, employeur,
            poste, date d&#8217;embauche, superviseur, ancienneté, numéro de permis de conduire, numéro d&#8217;employé,
            numéro de sécurité sociale, autre numéro d&#8217;identification fiscale, informations sur les performances
            professionnelles et diplômes, informations de paiement, adresse IP et/ou identifiant d&#8217;appareil,
            géolocalisation, signature, photo et/ou image d&#8217;une caméra vidéo, nom d&#8217;utilisateur et mot de
            passe, informations syndicales, origine ethnique, informations médicales liées à l&#8217;emploi, messages et
            données et informations comportementales vous concernant reçues via le placement de cookies ou pixels
            espions pendant l&#8217;utilisation de la plateforme. Si vous avez des questions ou des préoccupations
            concernant ces types de données personnelles, utilisez les informations de la rubrique « Nous contacter » en
            bas du présent Avis de confidentialité.
          </p>
          <h3>Données collectées via nos produits et services : </h3>
          <p>CAI obtient des données personnelles en lien avec la fourniture de ses produits et services, dont : </p>
          <p>(i) Des produits ou services SaaS hébergés pour des clients de CAI ;</p>
          <p>
            (ii) Des produits ou services basés sur le web pour des solutions de commerce collaboratif couvrant la
            totalité du processus de commande et d’achat dans le secteur du marché B2B.
          </p>
          <p>
            (iii) Des produits logiciels sur site sous licence d&#8217;un client pour une utilisation dans ses propres
            locaux via la fourniture de services professionnels ;
          </p>
          <p>
            (iv) Des produits matériels sur site vendus à un client pour une utilisation dans ses propres locaux ; et
          </p>
          <p>
            (v) Des services d’assistance client liés aux produits matériels et logiciels sur site, basés sur le web et
            SaaS.
          </p>
          <p>
            Par exemple, lorsque nos clients utilisent les produits ou services ci-dessus, ils peuvent fournir des
            détails sur leurs employés, y compris leurs noms, postes, adresses e-mail, identifiants, numéros de
            téléphones, adresses, dates de naissance, numéros de permis de conduire et d’autres informations. Certaines
            de nos solutions basées sur le web permettent aux clients, par exemple, de soumettre des données
            personnelles pour créer des utilisateurs de la solution, pour stocker des documents de transaction qui
            peuvent comprendre des données personnelles de signataires ou des contacts commerciaux, pour utiliser la
            géolocalisation et pour stocker des coordonnées associées avec nos partenaires commerciaux.
          </p>
          <p>
            Nous traitons souvent des données personnelles pour le compte de nos clients qui font l&#8217;objet
            d&#8217;un contrat écrit. Nous ne contrôlons pas les pratiques de traitement et de protection des données de
            nos clients (qui peuvent être votre employeur, prestataire de service ou partenaire commercial) et leurs
            conditions peuvent différer de celles stipulées dans le présent Avis de confidentialité. Notez que si CAI
            collecte des données personnelles via des produits ou services contrôlés par nos clients, nos clients sont
            les contrôleurs des données pour les données collectées et comment elles seront utilisées et divulguées.
            Dans ce cas, CAI agit uniquement en tant que responsable du traitement des données. Toute question relative
            à comment nos clients traitent, utilisent et partagent les données personnelles qu’ils collectent via nos
            produits ou services doit être adressée au client concerné.
          </p>
        </div>
      </div>
      <div id="automatically_collected_information">
        <div>
          <h3>Informations collectées automatiquement : </h3>
          <p>
            Lorsque vous naviguez et interagissez avec nos sites Web et/ou produits et applications SaaS, nous pouvons
            utiliser des technologies automatiques de collecte des données pour collecter certaines informations
            concernant votre équipement, vos actions et des modèles (« Informations sur l&#8217;activité de
            l&#8217;utilisateur »), y compris : (i) des détails sur votre utilisation, y compris des données sur le
            trafic, des données sur l&#8217;emplacement via la technologie de géolocalisation, des journaux et autres
            données de communication et les ressources auxquelles vous accédez et que vous utilisez ; et (ii) des
            informations sur votre appareil, y compris votre système d&#8217;exploitation, votre adresse IP et
            d&#8217;autres données sur l&#8217;authentification sur mobile. Certaines de ces informations peuvent être
            considérées comme des données personnelles dans votre juridiction.
          </p>
          <p>
            Les Informations sur l’activité de l&#8217;utilisateur que nous collectons nous aident à améliorer nos sites
            Web et nos produits, et à proposer un meilleur service plus personnalisé en nous permettant d’estimer les
            modèles d’utilisation, d’afficher les informations que vous demandez selon votre fuseau horaire et de vous
            reconnaître quand vous revisitez notre site ou un produit.
          </p>
          <p>
            Les technologies que nous utilisons pour cette collecte automatique des données peuvent inclure des balises
            Web, des balises pixel, des scripts ou des cookies. Un cookie est un petit fichier placé sur le disque dur
            de votre appareil. Nous utilisons des cookies pour aider à analyser l’utilisation, personnaliser nos
            services, mesurer l’efficacité et encourager la confiance et la sécurité. Les cookies sont abordés plus bas
            dans ce document.
          </p>
        </div>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <div>
          <h2>Comment nous utilisons les informations que nous collectons &ndash;</h2>
          <p>
            CAI utilise les informations qu’elle collecte afin de fonctionner efficacement, de fournir ses produits et
            services aux clients, de faciliter les affaires entre les parties de l’industrie et d’administrer et gérer
            ses relations clients. Nous utilisons également les informations que nous collectons pour traiter, évaluer
            et répondre à vos demandes, répondre aux demandes de renseignement et aux candidature, créer, administrer et
            communiquer avec vous sur votre compte (y compris sur vos achats et vos paiements), gérer, évaluer et
            améliorer les affaires de CAI (y compris le développement de nouveaux produits et services, la gestion des
            communications, la réalisation d’études de marché, l’analyse des produits/services/sites Web de CAI et la
            réalisation des activités de comptabilité, audit, facturation, réconciliation et collecte), veiller à la
            sécurité des ressources et services du réseau CAI et respecter les exigences légales en vigueur.
          </p>
        </div>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <div>
          <h2>Comment nous divulguons les informations que nous collectons &ndash;</h2>
          <p>Nous pouvons divulguer certaines données personnelles que nous collectons comme suit : </p>
          <ul>
            <li>À nos filiales et/ou partenaires commerciaux avec qui nous avons une relation contractuelle : </li>
            <li>
              À des tiers comme des entrepreneurs, des prestataires de services, des consultants et d’autres agents («
              Prestataires de services ») qui fournissent une assistance à d’autres entreprises. Dans certains cas, CAI
              travaille avec des Prestataires de services pour une variété de raisons, notamment le traitement des
              paiements par carte de crédit, le suivi des heures de service, l&#8217;hébergement des données et la
              comptabilité. Les Prestataires de services avec qui nous partageons ces données personnelles sont
              généralement liés par des obligations de confidentialité et de respect de la vie privée et une liste de
              ces Prestataires de services se trouve sur notre site (www.commandalkon.com) sous l&#8217;onglet Légal
              (Liste des sous-traitants) ;
            </li>
            <li>
              Pour accomplir les objectifs pour lesquels vous ou votre employeur nous avez fourni de telles données
              personnelles ;
            </li>
            <li>
              À un acheteur ou autre successeur de CAI, ou à toute filière en cas d’acquisition, de fusion, de cession,
              de restructuration, de réorganisation, de dissolution ou autre vente ou transfert de tout ou partie des
              actifs ou titres de CAI ou de l’une de ses filiales, que ce soit dans le cadre d’une entreprise en
              activité ou d’une faillite, liquidation ou de procédures analogues, où les données personnelles font
              partie des actifs transférés ; et
            </li>
            <li>Pour toutes autres fins divulguées par nous lorsque vous fournissez les données personnelles.</li>
          </ul>
          <p>
            Nonobstant toute autre disposition dans le présent Avis de confidentialité, nous nous réservons le droit
            d’accéder à, de conserver et de divulguer toute information que nous collectons si nous pensons
            raisonnablement que cela est nécessaire : (i) pour répondre à des exigences légales, y compris pour
            respecter toute décision de justice, procédure légale ou législative, et pour répondre à toute demande
            réglementaire ou gouvernementale, y compris pour répondre à la police ou autres responsables gouvernementaux
            suite à une demande vérifiée dans le cadre d’une enquête criminelle ou d’une activité illégale présumée ;
            (ii) pour faire appliquer ou pour appliquer nos politiques et nos accords ; (iii) pour détecter, empêcher et
            autrement s’occuper des problèmes de fraude, de sécurité, de confiance, de sûreté et techniques ; (iv) pour
            répondre aux demandes d’assistance de l’utilisateur ; ou (v) pour protéger les droits, la propriété, la
            santé ou la sécurité de CAI, de nos utilisateurs, de tiers ou du public en général.
          </p>
          <h2>Informations supplémentaires pour les résidents de la Californie et d&#8217;autres États américains</h2>
          <p>
            Les informations personnelles que nous collectons incluent des informations appartenant aux catégories de
            données du tableau ci-dessous. Ces catégories représentent également les catégories d&#8217;informations
            personnelles que nous avons collectées au cours des 12 derniers mois. Notez que les catégories répertoriées
            ci-dessous sont définies par la loi de l&#8217;État de Californie. L&#8217;inclusion d&#8217;une catégorie
            dans la liste ci-dessous indique uniquement que, selon les services et produits que nous vous fournissons ou
            que nous fournissons à votre employeur et partenaire commercial, nous pouvons collecter ou éliminer
            certaines informations de cette catégorie. Cela ne signifie pas nécessairement que nous collectons ou
            divulguons toutes les informations répertoriées dans une catégorie particulière, ni que nous collectons
            toutes les catégories d&#8217;informations pour tous les individus.
          </p>
          <p>
            En outre, bien que nous incluions des données collectées dans le contexte interentreprises qui sont
            considérées comme des données personnelles en vertu de certaines lois sur la confidentialité telles que le
            RGPD de l&#8217;UE et du Royaume-Uni et la loi californienne sur les droits à la vie privée de 2020
            (California Privacy Rights Act), elles peuvent ne pas être considérées comme des données personnelles dans
            d&#8217;autres juridictions, y compris dans d&#8217;autres États américains. Nous avons divulgué des
            informations dans chacune des catégories ci-dessous à nos sociétés affiliées et fournisseurs de services à
            des fins commerciales au cours des 12 derniers mois.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Catégorie </strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Sources</strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Finalité du traitement </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Identifiants tels qu&#8217;un nom réel, un pseudonyme, une adresse postale, un identifiant personnel
                    unique, un identifiant en ligne, une adresse de protocole Internet, une adresse e-mail, un nom de
                    compte, un numéro de sécurité sociale, un numéro de permis de conduire ou d&#8217;autres
                    identifiants similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données, ainsi que les informations sur vos tendances d&#8217;achat obtenues auprès de
                    nos partenaires commerciaux, sont également utilisées à des fins de marketing, notamment pour vous
                    proposer des produits et services susceptibles de vous intéresser par le biais de publicités
                    directes et de partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Les informations qui identifient, se rapportent à, décrivent ou sont susceptibles d&#8217;être
                    associées à une personne en particulier, y compris, mais sans s&#8217;y limiter, vos nom, signature,
                    numéro de sécurité sociale, caractéristiques physiques, adresse, numéro de téléphone, numéro de
                    permis de conduire ou de carte d&#8217;identité nationale, informations médicales.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données sont également utilisées à des fins de marketing, notamment pour vous proposer
                    des produits et services susceptibles de vous intéresser par le biais de publicités directes et de
                    partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Les informations commerciales, y compris les enregistrements de biens personnels, de produits ou de
                    services achetés, obtenus ou envisagés, ou d&#8217;autres historiques ou tendances d&#8217;achat ou
                    de consommation.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données, ainsi que les informations sur vos tendances d&#8217;achat obtenues auprès de
                    nos partenaires commerciaux, sont également utilisées à des fins de marketing, notamment pour vous
                    proposer des produits et services susceptibles de vous intéresser par le biais de publicités
                    directes et de partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Informations sur l&#8217;activité d&#8217;Internet ou d&#8217;autres réseaux électroniques, y
                    compris, mais sans s&#8217;y limiter, l&#8217;historique de navigation, l&#8217;historique de
                    recherche et les informations concernant l&#8217;interaction d&#8217;un consommateur avec un site
                    Web, une application ou une publicité Internet.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, ou via l&#8217;utilisation de cookies et de technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données sont également utilisées à des fins de marketing, notamment pour vous proposer
                    des produits et services susceptibles de vous intéresser par le biais de publicités directes et de
                    partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Données de géolocalisation. </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données, ainsi que les informations sur vos tendances d&#8217;achat obtenues auprès de
                    nos partenaires commerciaux, sont également utilisées à des fins de marketing, notamment pour vous
                    proposer et vous fournir des produits et services susceptibles de vous intéresser par le biais de
                    publicités directes et de partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Informations audio, électroniques, visuelles, thermiques, olfactives ou similaires. </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                  <p>
                    Pour les visiteurs, ces informations sont collectées via nos caméras de vidéosurveillance, et pour
                    les appelants, elles sont collectées via des enregistrements audio.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris l&#8217;enregistrement des appels commerciaux
                    et d&#8217;autres appels. Pour les visiteurs, les données visuelles peuvent être traitées afin de
                    maintenir la sécurité et la responsabilisation, et de se conformer aux lois locales, y compris
                    celles liées à la santé et à la sécurité.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">Informations professionnelles ou liées à l&#8217;emploi.</td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, vous protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, et pour la gestion de la conformité.
                  </p>
                  <p>
                    De plus, ces données sont également utilisées à des fins de marketing, notamment pour vous proposer
                    des produits et services susceptibles de vous intéresser par le biais de publicités directes et de
                    partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Inférences tirées de l&#8217;une des catégories d&#8217;informations cidessus pour créer un profil
                    sur un consommateur reflétant les préférences, les caractéristiques, les tendances psychologiques,
                    les prédispositions, le comportement, les attitudes, l&#8217;intelligence, les capacités et les
                    aptitudes du consommateur.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                  <p>
                    De plus, ces données sont également utilisées à des fins de marketing, notamment pour vous proposer
                    des produits et services susceptibles de vous intéresser par le biais de publicités directes et de
                    partenaires.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Les Informations personnelles sensibles, y compris toute information personnelle qui révèle le
                    numéro de sécurité sociale d&#8217;un individu ou un autre numéro d&#8217;identification
                    d&#8217;État ; la connexion à un compte, la géolocalisation ; l’origine ethnique, l’appartenance
                    syndicale ; le contenu des courriers, e-mails ou SMS, à moins que l&#8217;entreprise ne soit le
                    destinataire prévu de la communication ; et les données génétiques.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces informations sont collectées directement auprès de vous ou de votre employeur, de nos
                    partenaires commerciaux, de nos prestataires de services, de votre interaction avec nos services et
                    produits, des dossiers que nous avons à votre sujet dans le cadre de la fourniture de services ou de
                    produits, ou via l&#8217;utilisation de cookies et technologies similaires.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    Ces données sont traitées dans le cadre d&#8217;un certain nombre de nos fonctions opérationnelles
                    pour vous fournir des produits et services, y compris la facilitation des affaires entre les parties
                    du secteur et l&#8217;administration des relations avec les clients.
                  </p>
                  <p>
                    Elles sont également traitées afin d&#8217;aider à gérer et administrer votre compte, ainsi que pour
                    détecter les incidents de sécurité, protéger contre les activités malveillantes, trompeuses,
                    frauduleuses ou illégales, pour la gestion de la conformité, l&#8217;analyse des données et le
                    développement technologique de nos systèmes.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            CAI ne revend pas vos informations à des tiers, y compris aux fins de la loi californienne sur la
            confidentialité des consommateurs de 2018 (California Consumer Privacy Act, « CCPA ») et de la loi
            californienne sur les droits à la vie privée de 2020 (California Privacy Rights Act, « CPRA »).
          </p>
        </div>
      </div>
      <div id="third_party_services">
        <div>
          <h2>Services tiers &ndash;</h2>
          <p>
            Vous pourriez avoir l’opportunité de choisir d’accéder à et d’utiliser des services ou applications de tiers
            (« Services tiers ») via certains de nos sites web et/ou produits. Le présent Avis de confidentialité ne
            traite que de la collecte, l’utilisation et la divulgation des informations collectées par CAI. Elle ne
            s’applique pas aux pratiques de tiers que nous ne possédons, n’employons ou ne gérons pas, notamment les
            Services tiers. En accédant ou en utilisant des services ou applications tiers via nos sites Web ou
            produits, vous nous demandez de divulguer vos informations aux Services tiers en votre nom, comme demandé
            par ce tiers, et vous acceptez que l&#8217;utilisation ou la divulgation de vos informations par le tiers en
            question sera régie par la politique de confidentialité dudit tiers, laquelle peut être différente de la
            façon dont nous utilisons et divulguons vos informations.
          </p>
        </div>
      </div>
      <div id="security">
        <div>
          <h2>Sécurité &ndash;</h2>
          <p>
            CAI utilise des contrôles de sécurité physiques, techniques et administratifs appropriés et raisonnables
            proportionnels aux types de données qu&#8217;elle traite afin de protéger les données personnelles contre la
            perte, la mauvaise utilisation et l&#8217;accès non autorisé, la divulgation, l&#8217;altération et/ou la
            destruction. Même si nous travaillons dur pour protéger votre confidentialité, aucun contrôle de sécurité
            n&#8217;est 100 % efficace et nous ne pouvons garantir la sécurité des informations ou que vos données
            personnelles ou communications privées resterons toujours privées. Une entrée ou une utilisation non
            autorisées, une défaillance matérielle ou logicielle et d’autres facteurs peuvent compromettre la sécurité
            des informations des utilisateurs.
          </p>
          <p>
            La sécurité de vos informations dépend aussi de vous. Lorsque nous vous avons fourni (ou vous avez choisi)
            des identifiants de connexion (c.-à-d. un identifiant utilisateur et un mot de passe) pour accéder à
            certaines parties de nos services ou applications, vous êtes responsable de la confidentialité des
            identifiants de votre compte utilisateur. Nous vous demandons de ne pas partager vos identifiants avec qui
            que ce soit. Vous êtes le seul responsable de la protection des identifiants de votre compte utilisateur et
            de toute utilisation de ces identifiants qui sont sous votre contrôle.
          </p>
        </div>
      </div>
      <div id="retention_of_data">
        <div>
          <h2>Conservation des informations &ndash;</h2>
          <p>
            Nous conservons généralement les données personnelles aussi longtemps que nécessaire pour réaliser les
            objectifs présentés dans le présent Avis de confidentialité. Cette conservation est généralement valable
            pour la durée de la relation plus une courte période de temps, comme indiqué dans notre calendrier de
            conservation des données. Cependant, nous pouvons conserver certaines informations plus longtemps, comme
            exigé par la loi en vigueur (comme pour les taxes, la comptabilité et autres exigences légales) ou pour des
            raisons commerciales légitimes. Une fois que nous n&#8217;avons plus besoin de vos données personnelles pour
            respecter nos obligations, nous les supprimerons de nos systèmes et de nos archives et/ou nous prendrons des
            mesures pour les anonymiser correctement afin que vous ne puissiez plus être identifié. Chacun des usages
            indiqués dans le présent Avis de confidentialité constitue un intérêt légitime de CAI pour traiter ou
            utiliser les données personnelles collectées ou est nécessaire à l&#8217;exécution d&#8217;un contrat. Si
            vous n’êtes pas d’accord avec cette approche, vous pouvez vous opposer au traitement ou à l’utilisation de
            vos données personnelles par CAI en contactant CAI à l’écrit via les méthodes listées cidessous dans la
            section « Nous contacter » en bas du présent Avis de confidentialité.
          </p>
        </div>
      </div>
      <div id="your_rights_and_choices">
        <div>
          <h2>Vos droits et vos choix &ndash;</h2>
          <h3>Requêtes et informations du compte :</h3>
          <p>
            Conformément à la loi en vigueur et en fonction de la juridiction (comme par exemple, l&#8217;EEE depuis le
            25 mai 2018 ou l&#8217;État de Californie depuis le 1er janvier 2020), vous pouvez avoir les droits suivants
            en rapport avec vos données personnelles :
          </p>
          <p>
            1) le droit d&#8217;accès (cela signifie que vous pouvez demander que nous vous fournissions une copie de
            vos données personnelles que nous avons collectées à votre sujet, les catégories de sources à partir
            desquelles les informations ont été collectées, les finalités de la collecte des données, les catégories de
            tiers avec lesquels nous avons partagé les données, et les catégories de données personnelles qui ont été
            partagées avec des tiers à des fins commerciales) ;
          </p>
          <p>
            2) le droit à la correction (cela signifie que vous pouvez nous informer via la section « Nous contacter »
            au bas du présent Avis de confidentialité pour corriger toute erreur dans vos données personnelles ou mettre
            à jour vos préférences ; nous pouvons également ne pas être en mesure de répondre à votre demande si nous
            pensez que cela enfreindrait une loi ou une exigence légale ou rendrait les informations incorrectes ; les
            données conservées uniquement à des fins de sauvegarde de données sont généralement exclues );
          </p>
          <p>
            3) le droit au transfert (cela signifie que vous pouvez demander que nous fournissions une copie de vos
            données personnelles à un tiers de votre choix) ;
          </p>
          <p>
            4) le droit à la restriction (lorsque notre traitement de vos données personnelles est nécessaire à nos
            intérêts légitimes, en fonction de la loi applicable en matière de protection des données/de la vie privée,
            vous pouvez vous opposer à ce traitement à tout moment, sous réserve de certaines limitations et exceptions
            ; vous pouvez également être en mesure de restreindre certains types de traitement de vos données
            personnelles dans certaines circonstances ; en particulier, vous pouvez nous demander de restreindre notre
            utilisation de vos données personnelles si vous contestez leur exactitude, si le traitement de vos données
            personnelles est jugé illégal, ou si nous n&#8217;avons plus besoin de vos données personnelles pour le
            traitement mais que nous les avons conservées conformément à la loi) ;
          </p>
          <p>
            5) le droit au retrait de votre consentement (cela signifie que dans la mesure où notre traitement de vos
            données personnelles est fondé sur votre consentement, vous pouvez retirer votre consentement à tout moment
            ; le retrait de votre consentement n&#8217;affectera toutefois pas la licéité du traitement fondé sur votre
            consentement avant son retrait, et n&#8217;affectera pas la licéité de notre traitement continu fondé sur
            toute autre base juridique pour le traitement de vos données personnelles) ;
          </p>
          <p>
            6) le droit à la suppression (cela signifie que vous pouvez nous demander de supprimer vos données
            personnelles de nos systèmes et nous le ferons à moins que nous ne soyons tenus de conserver ces
            informations afin de vous fournir des services ou que nous ayons besoin de ces données personnelles pour se
            conformer à nos obligations légales ou commerciales en vertu de la loi applicable) ; ou
          </p>
          <p>
            7) le droit de dire « non » à la vente de vos données personnelles (Remarque : CAI ne vend pas de données
            personnelles).
          </p>
          <p>
            Vous pourriez être dans la capacité d’exercer une partie ou l&#8217;ensemble de ces droits en vous
            connectant à votre compte pour les produits que vous utilisez. Autrement, pour demander ces informations
            directement, veuillez soumettre une demande écrite à l’aide des coordonnées fournies dans la section « Nous
            contacter » en bas du présent Avis de confidentialité.
          </p>
          <p>
            Veuillez noter qu&#8217;en vertu de la loi californienne, nous ne sommes tenus de répondre aux demandes
            d&#8217;accès aux informations personnelles d&#8217;un même consommateur qu’à concurrence de deux fois au
            maximum sur une période de 12 mois. En vertu des lois de l&#8217;UE et de la Californie, si une personne
            fait des demandes infondées, répétitives ou excessives (telles que déterminées à notre discrétion
            raisonnable) pour accéder aux données personnelles, nous pouvons facturer des frais soumis à un maximum fixé
            par la loi.
          </p>
          <p>
            N’oubliez pas que dans certaines régions, vous avez aussi le droit de vous plaindre auprès de
            l&#8217;autorité de protection des données (« APD ») de notre collecte et notre utilisation de vos données
            personnelles. Pour en savoir plus, veuillez contacter votre APD locale.
          </p>
          <p>
            Dans le cas où CAI traiterait des données personnelles sur vous pour le compte d’un client, veuillez envoyer
            vos requêtes sur la confidentialité et vos demandes d’accès, de correction ou de suppression de vos données
            personnelles à ce client.
          </p>
          <p>
            Avant de fournir les informations que vous demandez conformément à certains de ces droits, nous devons être
            en mesure de vérifier votre identité. Afin de vérifier votre identité, vous devrez peut-être soumettre des
            informations vous concernant, y compris, dans la mesure du possible, fournir des réponses à des questions de
            sécurité, votre nom, votre numéro d&#8217;identification nationale, votre date de naissance, vos coordonnées
            ou d&#8217;autres informations d&#8217;identification personnelle. Nous comparerons ces informations avec
            celles que nous avons précédemment collectées à votre sujet pour vérifier votre identité et votre demande.
            Si nous ne sommes pas en mesure de vérifier votre identité dans le cadre de votre demande, nous ne pourrons
            pas répondre à celle-ci. Nous ne sommes pas obligés de collecter des informations supplémentaires afin de
            pouvoir confirmer votre identité. Pour les demandes de suppression, vous devrez soumettre une demande
            vérifiable de suppression.
          </p>
          <p>
            Si vous souhaitez nommer un agent autorisé pour faire une demande en votre nom, vous devez fournir à
            l&#8217;agent une autorisation écrite et signée de soumettre des demandes de droit à la confidentialité en
            votre nom, ou fournir une lettre de votre avocat. L&#8217;agent ou l&#8217;avocat doit fournir cette
            autorisation au moment de la demande. Nous pouvons vous demander de vérifier votre identité directement
            auprès de nous avant de fournir les informations demandées à votre agent agréé.
          </p>
          <p>
            Les informations recueillies dans le but de vérifier votre demande ne seront utilisées qu&#8217;à des fins
            de vérification.
          </p>
          <p>
            Si vous avez choisi d&#8217;exercer l&#8217;un de ces droits, dans la mesure où ils s&#8217;appliquent, la
            loi de l&#8217;État américain nous interdit de vous discriminer sur la base du choix d&#8217;exercer vos
            droits à la confidentialité. Nous pouvons toutefois facturer un tarif différent ou fournir un niveau de
            service différent dans la mesure permise par la loi.
          </p>
          <h3>Signaux DNT (« Do Not Track », ne pas suivre) :</h3>
          <p>
            Certains navigateurs Web vous permettent de diffuser un signal vers des sites Web et des services en ligne
            indiquant votre préférence qu&#8217;ils « ne suivent pas » vos activités en ligne. À l&#8217;heure actuelle,
            nous n&#8217;honorons pas ces signaux et nous ne modifions pas les informations que nous recueillons ou la
            manière dont nous utilisons ces informations selon qu&#8217;un tel signal est diffusé ou reçu par nous.
          </p>
          <h3>Communications promotionnelles :</h3>
          <p>
            De temps en temps, nous pouvons vous envoyer des communications marketing sur nos produits, conformément à
            vos préférences. Vous pouvez choisir de ne plus recevoir de messages promotionnels de notre part à tout
            moment en suivant les instructions dans ces messages (il s’agira souvent d’un avertissement ou d’un lien en
            bas du message). Si vous décidez de ne plus les recevoir, nous pourrions toujours vous envoyer des
            communications non promotionnelles, comme celles concernant votre compte ou nos relations commerciales
            continues. Les demandes pour cesser de recevoir les communications promotionnelles peuvent aussi être
            envoyées à CAI par écrit via les méthodes listées ci-dessous dans la section « Nous contacter » en bas du
            présent Avis de confidentialité.
          </p>
          <h3>Cookies:</h3>
          <p>
            Vous pouvez gérer notre utilisation des cookies via la bannière des cookies qui s&#8217;affiche lorsque vous
            accédez à nos sites. Si vous le préférez, vous pouvez généralement choisir de paramétrer votre navigateur
            pour qu’il supprime ou rejette les cookies de navigateur, ou pour qu’il efface le stockage local. Vous
            pouvez également faire des demandes concernant la gestion des cookies en nous contactant par écrit via les
            méthodes listées ci-dessous dans la section « Nous contacter » du présent Avis de confidentialité. Remarquez
            que si vous choisissez de supprimer ou rejeter certains cookies, cela pourrait affecter les fonctionnalités
            de notre site web et services. Nous avons une Politique indépendante sur les cookies disponible sur notre
            site internet (<b>www.commandalkon.com</b>) sous l&#8217;onglet Légal et qui donne plus
            d&#8217;informations.
          </p>
          <h3>Informations sur la localisation : </h3>
          <p>
            Vous pouvez activer et désactiver les services basés sur la localisation en réglant les paramètres de votre
            appareil mobile. Remarquez que si vous choisissez de désactiver les services basés sur la localisation, cela
            pourrait affecter les pleines fonctionnalités de nos produits et services.
          </p>
          <h3>Droits de confidentialité en Californie :</h3>
          <p>
            La loi de Californie permet aux utilisateurs qui résident en Californie de demander et d&#8217;obtenir une
            fois par an, gratuitement, une liste des tiers à qui nous avons divulgué leurs données personnelles (le cas
            échéant) pour leurs objectifs de marketing direct durant l&#8217;année civile précédente, ainsi que le type
            de données personnelles divulguées à ces tiers. Sauf disposition contraire dans le présent Avis de
            confidentialité ou en vertu d&#8217;un accord avec la personne visée, CAI ne vend pas de données
            personnelles à des tiers à leurs propres fins de marketing.
          </p>
        </div>
      </div>
      <div id="international_data_transfers_and_privacy_shield">
        <div>
          <h2>Transferts de données internationaux et le Cadre de Confidentialité des Données &ndash;</h2>
          <p>
            CAI est basée aux États-Unis et possède des bureaux à l’international. Pour faciliter nos opérations et
            aider à livrer nos produits et services, nous pouvons transférer des données personnelles aux États-Unis et
            vers d’autres pays où se trouvent nos filiales, vendeurs, consultants et prestataires de services. Ces pays
            peuvent avoir des lois qui sont différentes, et potentiellement moins protectrices, que les lois de votre
            pays de résidence. Si vous êtes un responsable du traitement des données utilisant CAI en tant que
            sous-traitant des données et si le contenu ou les données que vous stockez sur ou via des logiciels, des
            applications ou sites Web de CAI contiennent des données personnelles d&#8217;individus de l&#8217;EEE, vous
            convenez que vous possédez l&#8217;autorité légale de transférer les données personnelles à CAI, y compris
            pour le transfert vers des pays comme les États-Unis où les protections de la confidentialité et les droits
            des autorités pour accéder aux données personnelles peuvent ne pas être équivalents à ceux de l&#8217;EEE.
          </p>
          <p>
            Lorsque nous transférerons des données personnelles à l’étranger, nous prendrons les mesures de protection
            appropriées pour protéger les informations conformément au présent Avis de confidentialité et nous, ainsi
            que nos destinataires étrangers, veillons à respecter les lois de confidentialité en vigueur. CAI compte sur
            les clauses contractuelles types (« CCT ») de l&#8217;UE pour le transfert légal des données personnelles
            entre ses entités des juridictions soumises au règlement général sur la protection des données (« RGPD ») de
            l’UE vers les États-Unis. CAI compte sur l&#8217;accord de transfert de données du Royaume-Uni pour le
            transfert légal des données personnelles entre ses entités depuis les juridictions soumises au règlement
            général sur la protection des données du Royaume-Uni (« RGPD R.-U. ») vers les États-Unis.
          </p>
          <p>
            En plus des CCT, si CAI découvre qu&#8217;une autorité gouvernementale (y compris les forces de
            l&#8217;ordre) souhaite obtenir l&#8217;accès à ou une copie d&#8217;une partie ou de l&#8217;ensemble des
            données personnelles traitées par CAI, que ce soit sur une base volontaire ou obligatoire, pour des fins en
            lien avec le renseignement entourant la sécurité nationale, alors sauf en cas d&#8217;interdiction légale ou
            d&#8217;une obligation légale obligatoire nécessitant le contraire, CAI : 1) notifiera immédiatement la
            partie à qui les données personnelles s&#8217;appliquent (c.-à-d. le client ou le vendeur) ; 2) informera
            l&#8217;autorité gouvernementale concernée qu&#8217;elle n&#8217;a pas été autorisée à divulguer les données
            personnelles et, sauf en cas d&#8217;interdiction légale, devra immédiatement notifier la partie à qui les
            données personnelles s&#8217;appliquent ; 3) informera l&#8217;autorité gouvernementale qu&#8217;elle doit
            adresser toutes ses demandes ou requêtes directement à la partie à qui les données personnelles
            s&#8217;appliquent ; et 4) ne donnera pas accès aux données personnelles tant que la partie à qui les
            données personnelles s&#8217;appliquent n&#8217;aura pas donné son autorisation par écrit ou tant
            qu&#8217;elle n&#8217;aura pas l&#8217;obligation légale de le faire. En cas d&#8217;obligation légale, CAI
            déploiera des efforts raisonnables et légaux pour contester cette interdiction ou obligation. Si CAI est
            obligée de produire les données personnelles, CAI ne divulguera les données personnelles que dans les
            mesures exigées par la loi, en conformité avec le processus légal applicable.
          </p>
          <p>
            CAI utilise des Addenda de protection des données (« APD ») avec des sous-traitants qui traitent les données
            personnelles pour le compte de CAI lorsque cela est exigé par les lois sur la confidentialité en vigueur.
          </p>
          <p>
            Lorsque nous transférons des données personnelles de l&#8217;Espace économique européen (« EEE »), du
            Royaume-Uni ou de la Suisse vers les États-Unis, CAI se conforme aux règles UE-États-Unis. Data Privacy
            Framework (DPF UE-États-Unis), l&#8217;extension du Royaume-Uni au cadre UE-États-Unis. DPF et le cadre de
            confidentialité des données entre la Suisse et les États-Unis (DPF Suisse-États-Unis), tel qu&#8217;établi
            par le ministère américain du Commerce. CAI a certifié auprès du Département du Commerce des États-Unis
            qu&#8217;elle adhère aux accords UE-États-Unis. Principes du cadre de confidentialité des données (Principes
            DPF UE-États-Unis) en ce qui concerne le traitement des données personnelles reçues de l&#8217;Union
            européenne en s&#8217;appuyant sur les principes du DPF UE-États-Unis. DPF et du Royaume-Uni (et Gibraltar)
            en s&#8217;appuyant sur l&#8217;extension du Royaume-Uni à l&#8217;UE-États-Unis. FAP. CAI a certifié auprès
            du Département du Commerce des États-Unis qu&#8217;elle adhère aux accords suisse-américains. Principes du
            cadre de confidentialité des données (Principes DPF Suisse-États-Unis) en ce qui concerne le traitement des
            données personnelles reçues de Suisse sur la base des accords Suisse-États-Unis. FAP. En cas de conflit
            entre les termes du présent avis de confidentialité et les accords UE-États-Unis. Principes DPF et/ou
            Accords Suisse-États-Unis Principes DPF, les Principes prévaudront. Pour en savoir plus sur le programme
            Data Privacy Framework (DPF) et pour consulter notre certification, veuillez visiter
            <b>https://www.dataprivacyframework.gov/. </b>
          </p>
          <p>
            Conformément à l&#8217;UE-États-Unis. DPF, l&#8217;extension du Royaume-Uni aux relations UE-États-Unis DPF
            et le partenariat Suisse-États-Unis. DPF, CAI s&#8217;engage à résoudre les plaintes liées aux principes DPF
            concernant notre collecte et notre utilisation de vos informations personnelles. Les particuliers de
            l&#8217;UE, du Royaume-Uni et de la Suisse ayant des questions ou des plaintes concernant notre traitement
            des données personnelles reçues dans le cadre du accord UE-États-Unis. DPF, l&#8217;extension du Royaume-Uni
            aux relations UE-États-Unis DPF et/ou Suisse-États-Unis. DPF doit d’abord contacter CAI comme indiqué dans
            la section « Contactez-nous » au bas du présent avis de confidentialité.
          </p>
          <p>
            Une personne a la possibilité, sous certaines conditions, de recourir à un arbitrage exécutoire pour les
            plaintes concernant la conformité du DPF qui ne sont résolues par aucun des autres mécanismes du DPF. Pour
            plus d&#8217;informations, voir
            https://www.dataprivacyframework.gov/s/article/ANNEX-I-introductiondpf?tabset-35584=2. Conformément à
            l&#8217;UE-États-Unis. DPF, l&#8217;extension du Royaume-Uni aux relations UEÉtats-Unis DPF et le
            partenariat Suisse-États-Unis. DPF, CAI s&#8217;engage à renvoyer les plaintes non résolues concernant notre
            traitement des données personnelles reçues en s&#8217;appuyant sur l&#8217;accord UE-États-Unis. DPF,
            l&#8217;extension du Royaume-Uni aux relations UE-États-Unis DPF et/ou Suisse-États-Unis. DPF à JAMS, un
            fournisseur alternatif de règlement des litiges basé aux États-Unis. Si vous ne recevez pas de notre part un
            accusé de réception en temps opportun de votre plainte liée aux principes DPF, ou si nous n&#8217;avons pas
            traité votre plainte liée aux principes DPF à votre satisfaction, veuillez visiter
            https://www.jamsadr.com/filea-dpf -réclamer pour plus d&#8217;informations ou pour déposer une plainte. Les
            services de JAMS sont fournis sans frais pour vous.
          </p>
          <p>
            La Federal Trade Commission des États-Unis a compétence sur la conformité de CAI aux accords UEÉtats-Unis.
            DPF, l&#8217;extension britannique du partenariat UE-États-Unis DPF et le partenariat Suisse-ÉtatsUnis. FAP.
            Si CAI partage des données personnelles collectées dans l&#8217;EEE, au Royaume-Uni ou en Suisse avec un
            fournisseur de services tiers qui traite les données uniquement pour le compte de CAI, alors CAI sera
            responsable du traitement de ces données par ce tiers en violation des Principes. , à moins que CAI puisse
            prouver qu&#8217;elle n&#8217;est pas responsable de l&#8217;événement à l&#8217;origine du dommage.
          </p>
        </div>
      </div>
      <div id="lawful_basis_for_processing">
        <div>
          <h2>Base juridique du traitement des données personnelles d’individus (EEE/Royaume-Uni) &ndash;</h2>
          <p>
            Si vous êtes un individu issu de l’EEE ou du Royaume-Uni, notre base juridique pour la collecte et
            l’utilisation des données personnelles dépendra des données personnelles concernées et du contexte
            spécifique dans lequel elles ont été collectées. Nous n&#8217;utiliserons vos données personnelles
            qu&#8217;aux fins pour lesquelles nous collectons ces données personnelles, comme indiqué ci-dessous, sauf
            si nous devons les utiliser ultérieurement à une autre fin compatible avec l&#8217;objectif initial. Si nous
            devons poursuivre le traitement de vos données personnelles dans un but qui n&#8217;est pas compatible avec
            le but initial de la collecte, nous vous en informerons et vous fournirons une explication de la base
            juridique qui nous permet de le faire. Cependant, nous nous appuyons généralement sur les justifications
            légales suivantes pour nos activités de traitement : 1) la réalisation d&#8217;un accord avec vous et/ou
            votre employeur ; 2) la poursuite de nos intérêts légitimes tant que nous n&#8217;outrepassons pas vos
            intérêts, vos droits et votre liberté ; 3) votre consentement ; et 4) la conformité avec une obligation
            légale. Dans certains cas, nous pouvons également nous reposer sur les dérogations de l&#8217;Article 49 du
            RGPD et avoir l’obligation légale de collecter des données personnelles, ou nous pourrions avoir autrement
            besoin de vos données personnelles pour protéger vos intérêts vitaux ou ceux de quelqu&#8217;un d’autre.
            Plus de détails concernant la base juridique sont fournis ci-dessous.
          </p>
          <table class="margin-top: 30px;" border="1">
            <tbody>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Finalité(s) du traitement </strong>
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <p>
                    <strong>Base juridique du traitement </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Pour traiter les demandes et autres accords pour nos produits et services. </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement de vos données personnelles est nécessaire pour exécuter un contrat ou conclure un
                        contrat avec vous ou votre employeur
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Pour gérer et administrer les contrats, y compris les accords de service avec vous et votre
                    employeur.
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement de vos données personnelles nous est nécessaire pour nous conformer aux
                        obligations légales et réglementaires
                      </p>
                    </li>
                    <li>
                      <p>
                        Le traitement est nécessaire pour soutenir nos intérêts légitimes dans la gestion de notre
                        entreprise (ou celles d&#8217;un tiers) à condition que ces intérêts ne soient pas supplantés
                        par vos intérêts, droits et libertés
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Pour améliorer nos produits et services, pour effectuer des études de marché, pour effectuer des
                    analyses de données, à des fins générales de modélisation des risques et pour des analyses
                    statistiques
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement est nécessaire pour soutenir nos intérêts légitimes dans la gestion de notre
                        entreprise (ou celles d&#8217;un tiers) à condition que ces intérêts ne soient pas supplantés
                        par vos intérêts, droits et libertés
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Marketing</p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Nous demanderons votre consentement au traitement de vos données personnelles à des fins de
                        marketing et vous pouvez retirer celui-ci à tout moment
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>
                    Pour la prévention et la détection de la fraude, du blanchiment d&#8217;argent ou d&#8217;autres
                    délits
                  </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement de vos données personnelles nous est nécessaire pour nous conformer aux
                        obligations légales et réglementaires ou tel qu&#8217;autorisé par la loi applicable
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Pour gérer notre relation avec vous et entre vous et votre employeur </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement de vos données personnelles est nécessaire pour exécuter un contrat ou conclure un
                        contrat avec vous ou votre employeur
                      </p>
                    </li>
                    <li>
                      <p>
                        Le traitement est nécessaire pour soutenir nos intérêts légitimes dans la gestion de notre
                        entreprise (ou celles d&#8217;un tiers) à condition que ces intérêts ne soient pas supplantés
                        par vos intérêts, droits et libertés
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="vertical-align: baseline">
                  <p>Pour vous faire bénéficier de nos produits et services </p>
                </td>
                <td class="vertical-align: baseline">
                  <ul>
                    <li>
                      <p>
                        Le traitement de vos données personnelles est nécessaire pour exécuter un contrat ou conclure un
                        contrat avec vous ou votre employeur
                      </p>
                    </li>
                    <li>
                      <p>
                        Le traitement est nécessaire pour soutenir nos intérêts légitimes dans la gestion de notre
                        entreprise (ou celles d&#8217;un tiers) à condition que ces intérêts ne soient pas supplantés
                        par vos intérêts, droits et libertés
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="children_policy">
        <div>
          <h2>Confidentialité des enfants &ndash;</h2>
          <p>
            Les affaires de CAI ne s&#8217;adressent pas aux enfants et CAI ne collecte pas et ne sollicite pas
            sciemment d&#8217;informations auprès d&#8217;enfants ou de personnes de moins de 13 ans. CAI ne permet pas
            sciemment à ces personnes d&#8217;utiliser nos produits et services. Si nous venons à apprendre que nous
            avons malencontreusement collecté des données personnelles sur un enfant ou quelqu&#8217;un de moins de 13
            ans, nous supprimerons ces informations dès que possible. Si vous pensez que nous pourrions détenir des
            informations de la part de ou concernant un enfant, veuillez nous contacter via les méthodes listées
            cidessous dans la section « Nous contacter » en bas du présent Avis de confidentialité.
          </p>
        </div>
      </div>
      <div id="changes_to_the_privacy_notice">
        <div>
          <h2>Modifications de l&#8217;Avis de confidentialité &ndash;</h2>
          <p>
            Nous pouvons modifier le présent Avis de confidentialité à tout moment en publiant l&#8217;Avis de
            confidentialité modifié sur nos sites Web. La date de la dernière révision de l&#8217;Avis de
            confidentialité est identifiée en haut du présent Avis de confidentialité. Toutes les conditions modifiées
            prennent effet automatiquement après leur publication initiale sur notre site web, sauf si une modification
            des lois applicables nécessite une modification immédiate. Vous êtes responsable de vérifier régulièrement
            si cet Avis de confidentialité a été modifié.
          </p>
        </div>
      </div>
      <div>
        <div>
          <h2>Plaintes &ndash;</h2>
          <p>
            Sans préjudice de tout autre recours administratif ou judiciaire dont vous pourriez disposer, vous pouvez
            avoir le droit de déposer une plainte auprès des autorités nationales de réglementation/de protection des
            données si vous pensez que nous avons enfreint les exigences applicables en matière de confidentialité ou de
            protection des données lors du traitement des données personnelles vous concernant.
          </p>
          <h3>Au Royaume-Uni, vous pouvez contacter : </h3>
          <p>The Information Commissioner&rsquo;s Office</p>
          <p>
            Water Lane, Wycliffe House
            <br />
            Wilmslow &#8211; Cheshire SK9 5AF
          </p>
          <p>Tél. +44 1625 545 700</p>
          <p>
            Courriel: <b>casework@ico.org.uk</b>
          </p>
          <p>
            Site Web: <b>https://ico.org.uk</b>
          </p>
          <h3>Pour l&#8217;UE, veuillez consulter le site:</h3>
          <p>
            <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
          </p>
        </div>
      </div>
      <div id="contact_us">
        <div>
          <h2>Nous contacter &ndash;</h2>
          <p>
            Pour contacter CAI en cas de questions ou d’inquiétudes en rapport avec le présent Avis de confidentialité
            ou les pratiques de CAI concernant les données personnelles, veuillez utiliser les coordonnées ci-dessous :
            Si vous résidez dans l’EEE, notez que si CAI agit en tant que contrôleur de données responsable de vos
            données personnelles, l’entité légale est Command Alkon Incorporated aux États-Unis. CAI répondra à toutes
            les questions et réclamations concernant le présent Avis de confidentialité dans un délai raisonnable.
          </p>
          <p>
            <strong>E-mail:</strong>
          </p>
          <p>
            <b>privacy@commandalkon.com</b>
          </p>
          <p>
            <strong>Ou écrivez à : </strong>
          </p>
          <p>Command Alkon Incorporated</p>
          <p>Chief Privacy Officer c/o The Legal Department</p>
          <p>
            6750 Crosby Court <br />
            Dublin, Ohio 43016, États-Unis
          </p>
          <p>
            <strong>Ou appelez :</strong>
          </p>
          <p>1-800-624-1872 (appel gratuit depuis les ÉtatsUnis) </p>
          <p>0-800-022-9682 (appel gratuit depuis le monde entier)</p>
          <p>
            <strong>Site internet :</strong>
          </p>
          <p>
            <b>www.commandalkon.com</b>
          </p>
        </div>
      </div>
    </Styled>
  );
};
