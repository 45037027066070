import { camelCase, toUpper } from 'lodash';
import { languagesNames } from '../../../../languagesNames';

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const getLanguagesConfig = ({ Components }) => {
  return languagesNames.map(language => {
    const { languageName, variableName, locale } = language;

    const allowVariable = `allow${variableName}Language`;
    const defaultVariable = `default${variableName}Language`;

    return {
      languageName,
      labelId: `languages.${locale}`,
      testId: locale,
      allowVariable,
      defaultVariable,
      allowComponent: Components[pascalCase(allowVariable)],
      defaultComponent: Components[pascalCase(defaultVariable)],
    };
  });
};
