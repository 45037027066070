import cn from 'classnames';
import { Layout, Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from './context/Provider';
import { get } from 'lodash';
import { useTemplatesPASetup } from '../../../MasterDataProvider';

const Styled = styled(Layout.Column)`
  textarea {
    width: 100%;
    height: 100%;
    font-family: monospace;
    padding: 5px 10px;
  }
`;

export const TemplateTextAvailableValues = ({ language, templatesPASetup }) => {
  const { state } = useTemplateContext();

  const availableValues = React.useMemo(() => {
    const udmData = templatesPASetup?.data?.udmData?.[language];
    const customValues = get(state, ['availableCustomValues', language], '');
    return udmData?.concat?.('\n').concat?.(customValues);
  }, [language, state, templatesPASetup?.data?.udmData]);

  return (
    <Styled className={cn('template-text')}>
      <Core.Spinner spin={templatesPASetup.isLoading}>
        <textarea value={availableValues} readOnly={true} />
      </Core.Spinner>
    </Styled>
  );
};
