import React from 'react';
import { Layout, Localization } from 'connex-cds';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import { TotalPumpedQuantity } from './TotalPumpedQuantity';
import { WaterAdd } from './WaterAdd';
import { WaterRelease } from './WaterRelease';
import { DamageWaiverAck } from './DamageWaiverAck';

export const ActivityDesktop = ({ lineItems, includeProductsServices }) => (
  <Layout.Row flex={1}>
    <Layout.Column>
      <Layout.Container flex={1} style={{ padding: '5px 10px' }}>
        <div className="activity-detail">
          <table width="100%">
            <thead>
              <tr>
                <th>
                  <Localization.Translate stringId="activity" data-testid="activity-header-text" />
                </th>
                <th>
                  <Localization.Translate stringId="quantity" data-testid="quantity-header-text" />
                </th>
                <th>
                  <Localization.Translate stringId="materialOnTruck" data-testid="onBoard-header-text" />
                </th>
                <th>
                  <Localization.Translate stringId="reason" data-testid="reason-header-text" />
                </th>
              </tr>
            </thead>
            <tbody>
              <DamageWaiverAck />
              <WaterAdd />
              <WaterRelease />
              {includeProductsServices && <ProductsServices lineItems={lineItems} />}
              <ReturnedMaterial />
              <TotalPumpedQuantity />
            </tbody>
          </table>
        </div>
      </Layout.Container>
    </Layout.Column>
  </Layout.Row>
);
