import { get } from 'lodash';
import constants from '../constants';

export class Or {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token, context } = params;
    if (params?.token?.startsWith?.(constants.OR)) {
      const paths = token.slice(4);
      const [path1, path2] = paths.split(',');
      return get(context, path1) || get(context, path2);
    }

    return this?.next?.handle?.(params);
  }
}
