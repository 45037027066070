import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 10vh;
  .buttons {
    display: flex;
    margin-bottom: 15px;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
  .recipients-instructions {
    margin-bottom: 10px;
  }
  .save-block {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
  .personal-data {
    max-width: 470px;
    font-size: 12px;
  }
  .invalid {
    color: #d8222f;
  }
`;
