import React from 'react';
import { AcceptanceSignature } from '../AcceptanceSignature';
import TermsAndConditions from './TermsAndConditions';
import { Layout, Core, Form, Drawer } from 'connex-cds';
const { Column } = Layout;

export const TermsAndConditionsFlow = ({ ticketId, handleAcceptCancel, activeFormsAndWaivers }) => {
  const { setFieldValue } = Form.useFormContext();
  const { openDrawer } = Drawer.useDrawerContext();

  const handleAcceptTerms = React.useCallback(() => {
    setFieldValue('termsAndConditionsAccepted', true);
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId: 'customerSignature',
      component: <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />,
      width: '100%',
    });
  }, [setFieldValue, openDrawer, handleAcceptCancel, ticketId]);

  return (
    <Column>
      <TermsAndConditions activeFormsAndWaivers={activeFormsAndWaivers} />
      <Core.Button type="primary" stringId="accept" onClick={handleAcceptTerms} data-testid="accept-button" />
    </Column>
  );
};
