import cn from 'classnames';
import { Form, Layout, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../../../TicketContext';

const Styled = styled.div``;

export const TicketNumber = () => {
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();

  const ticketId = React.useMemo(() => {
    return ticket?.id;
  }, [ticket?.id]);

  const signatureName = React.useMemo(() => {
    return values?.signature?.signerName?.trim?.();
  }, [values?.signature?.signerName]);

  return (
    <Styled className={cn('ticket-number')}>
      <Typography.Subtitle>{[ticketId, signatureName].filter(x => !!x).join(' - ')}</Typography.Subtitle>
    </Styled>
  );
};
