import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ListItem } from './ListItem';

const Styled = styled.div`
  padding: 10px;
`;

export const ListMobile = ({ data, ...props }) => {
  return (
    <Styled className={cn('list-mobile')}>
      {data?.map?.((item, idx) => (
        <ListItem {...props} item={item} idx={idx} />
      ))}
    </Styled>
  );
};
