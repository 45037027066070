import cn from 'classnames';
import { Core, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const TrackIt = ({ busy, onSave }) => {
  const { Components } = Form.useFormContext();

  return (
    <Styled className={cn('general')}>
      <Column flex={1}>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.Context />
          </Container>
        </Row>
        <div className="actions" style={{ padding: '15px' }}>
          <Core.Button loading={busy} type="primary" data-testid="save-button" stringId="save" onClick={onSave} />
        </div>
      </Column>
    </Styled>
  );
};
