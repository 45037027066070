import React from 'react';
import { Layout } from 'connex-cds';
import styled from 'styled-components';
import style from './style';

const { Container } = Layout;

const Styled = styled(Container)`
  ${style}
`;

export const ContactCardInfo = ({ email, onRemove }) => {
  return (
    <Styled>
      <Layout.Row style={{ justifyContent: 'space-between' }}>
        <div className="left">
          <span>{email}</span>
        </div>
        <div className="right" onClick={onRemove}>
          <span>
            <i className="fa-regular fa-times" />
          </span>
        </div>
      </Layout.Row>
    </Styled>
  );
};
