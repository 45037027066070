import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  padding-top: 15%;
  width: 979px;
  height: 488px;
`;
