import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { roles } from '../api';

export const useListRoles = () => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['roles', entityRef],
    queryFn: () => roles.listRoles({ entityRef }),
    staleTime: Infinity,
  });

  return query;
};

export const useUpdateUserRole = profileRef => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const userRbacKey = ['userRbac', entityRef, profileRef];
  const userRolesKey = ['userRoles', entityRef, profileRef];
  const usersKey = ['users', entityRef];

  const mutation = useMutation({
    mutationFn: body => roles.assignRole(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userRbacKey });
      queryClient.invalidateQueries({ queryKey: userRolesKey });
      queryClient.invalidateQueries({ queryKey: usersKey });
    },
  });

  return mutation.mutateAsync;
};
