import { css } from 'styled-components';

export default css`
  padding: 0;
  height: 225px;
  overflow: auto;
  flex-grow: 1;
  .water-release-value {
    font-family: 'Work Sans';
    white-space: pre-wrap;
  }
`;
