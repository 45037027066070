import cn from 'classnames';
import React from 'react';
import { TEMPLATES, useTemplate } from './template-processor/useTemplate';

const GeneralDeliveryInfo = ({ data }) => {
  const template = useTemplate({ context: data, templateName: TEMPLATES.GENERAL_DELIVERY_INFORMATION });

  return (
    <pre className={cn('general-delivery-info')} data-testid="general-delivery-info-text">
      {template}
    </pre>
  );
};

export default GeneralDeliveryInfo;
