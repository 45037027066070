import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Core } from 'connex-cds';
import { StyledBox } from './style';

export const icons = {
  default: <i className="fa-solid fa-circle" />,
  ok: <i className="fa-light fa-circle-check" />,
  mandatory: <i className="fa-light fa-triangle-exclamation" />,
  error: <i className="fa-light fa-circle-xmark" />,
  disabled: <i className="fa-light fa-circle" />,
  loading: <i class="fa fa-spinner"></i>,
};

export const STEP_STATUS = {
  DEFAULT: 'default',
  OK: 'ok',
  MANDATORY: 'mandatory',
  ERROR: 'error',
};

export const WizardSteps = ({ steps: rawSteps, currentStep, onClick, labelClassname = '' }) => {
  const handleStepClick = useCallback(
    step => {
      if (step.disabled || step.loading) {
        return;
      }

      document.getElementById(`step-${step.id}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      onClick(step.id);
    },
    [onClick]
  );

  const steps = useMemo(() => rawSteps.filter(step => !step.hidden), [rawSteps]);

  const nextStep = useMemo(() => {
    const index = steps.findIndex(step => step.id === currentStep);
    const step = steps[index + 1];

    return {
      ...step,
      loading: step?.loading ?? false,
      disabled: step?.disabled ?? true,
    };
  }, [steps, currentStep]);

  const prevStep = useMemo(() => {
    const index = steps.findIndex(step => step.id === currentStep);
    const step = steps[index - 1];

    return {
      ...step,
      loading: step?.loading ?? false,
      disabled: step?.disabled ?? true,
    };
  }, [steps, currentStep]);

  return (
    <StyledBox>
      <Core.Button
        className="directional-button"
        type="primary"
        data-testid="back-button"
        disabled={prevStep?.disabled}
        onClick={() => handleStepClick(prevStep)}
      >
        <i className="fa-regular fa-angle-left fa-2x" />
      </Core.Button>

      <div className="overview scroller">
        {steps.map(step => {
          return (
            <div
              key={step.id}
              className={cn('step', step.status, {
                disabled: step.disabled,
                loading: step.loading,
                active: currentStep === step.id,
              })}
              onClick={() => handleStepClick(step)}
              id={`step-${step.id}`}
            >
              <div data-testid={`${step.testId}-pg-nav-emptyLabel`} className="emptyLabel"></div>
              <div data-testid={`${step.testId}-pg-nav-icon`} className="icon">
                {step.loading
                  ? icons.loading
                  : step.disabled && step.status !== STEP_STATUS.MANDATORY
                  ? icons.disabled
                  : icons[step.status]}
              </div>
              <div data-testid={`${step.testId}-pg-nav-label`} className={`label ${labelClassname}`}>
                {step.title}
              </div>
            </div>
          );
        })}
      </div>

      <Core.Button
        className="directional-button"
        type="primary"
        data-testid="next-button"
        disabled={nextStep?.disabled}
        onClick={() => handleStepClick(nextStep)}
      >
        <i className="fa-regular fa-angle-right fa-2x" />
      </Core.Button>
    </StyledBox>
  );
};
