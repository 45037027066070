const removeDuplicates = (elements = []) => {
  if (!elements?.length) {
    return [];
  }

  const productsRef = elements?.map(p => p?.item?.productRef)?.filter(Boolean);
  const uniqueProductsRef = [...new Set(productsRef)];

  return uniqueProductsRef
    .map(productRef => {
      const product = elements?.find(p => p?.item?.productRef === productRef);
      return product;
    })
    .filter(Boolean);
};

export default (ticketProducts = [], formProducts = [], submitted) => {
  if (submitted) {
    return removeDuplicates(ticketProducts);
  }

  return removeDuplicates([...ticketProducts, ...formProducts]);
};
