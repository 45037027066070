import { css } from 'styled-components';

export default css`
  .left-header {
    text-align: left;
    font-size: 15px;
  }

  td {
    text-align: right;
  }
`;
