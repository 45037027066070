import { Layout } from 'connex-cds';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import { DevTools } from './DevTools';
import { TrackitHeader } from './TrackitHeader';

const Styled = styled.div`
  //width: 979px;
  //height: 521px;
  .trackit {
    .trackit-footer {
      background-color: #ffffff;
      height: 33px;
      border-top: 1px solid #ddd;
    }
    &.isMobile {
      height: 821px;
      width: 390px;
      border: 1px solid #666;

      .trackit-header {
        display: none;
      }
      .trackit-footer {
        display: none;
      }
    }
  }
  .dev-tools-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-and-button {
      display: flex;
      gap: 20px;
      & > * {
        &:first-child {
          flex: 1;
        }
      }
    }
  }
`;

export const TrackitWrapper = props => {
  const [isMobile, setIsMobile] = React.useState(false);

  return (
    <Styled className={cn('trackit-wrapper', { isMobile })}>
      <Layout.Column className={cn('trackit', { isMobile })}>
        <TrackitHeader />
        <Outlet />
        <div className="trackit-footer"></div>
      </Layout.Column>
      <DevTools setIsMobile={setIsMobile} isMobile={isMobile} />
    </Styled>
  );
};
