import React from 'react';
import { getCachedItem } from './util/cache';
import { Localization } from './components/localization';
import { PostMessage } from './post-message/PostMessage';
import { AppDataProvider } from './util/app-data/AppData';
import { ThemeProvider } from './components/theme';
import MuiThemeProvider from './util/mui-theme-provider/MuiThemeProvider';
import { AppListener } from './AppListener';

const CommonContextProviders = ({ children, appId, messages, isIframe, initialLocale, initialTimeZone, ...props }) => {
  return (
    <AppDataProvider appId={appId} {...props}>
      <PostMessage isIframe={isIframe} sendMessages={true}>
        <Localization
          messages={messages}
          initialLocale={initialLocale || getCachedItem('language')}
          isIframe={isIframe}
          appId={appId}
          initialTimeZone={initialTimeZone}
        >
          <ThemeProvider isIframe={isIframe}>
            <MuiThemeProvider>
              <AppListener>{children}</AppListener>
            </MuiThemeProvider>
          </ThemeProvider>
        </Localization>
      </PostMessage>
    </AppDataProvider>
  );
};

export default CommonContextProviders;
