import { Localization } from 'connex-cds';
import React from 'react';
import { useCompanySetup } from './MasterDataProvider';

export const useMonitorTimeZone = () => {
  const { setTimeZone } = Localization.useLocalizationContext();

  const { data } = useCompanySetup();

  React.useEffect(() => {
    setTimeZone(data?.timeZone);
  }, [data, setTimeZone]);

  return {};
};
