import cn from 'classnames';
import { Core, Drawer, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../../../../util/useViewport';
import { useCompanySetup } from '../../MasterDataProvider';
import CommentList from './CommentList';
import style from './style';

const { Button, Counter } = Core;
const { Column, Row, Container } = Layout;
const { useDrawerContext } = Drawer;

const Styled = styled(Column)`
  ${style}
`;

const Comments = () => {
  const {
    Components: { DriverComment },
    values,
    setFieldValue,
  } = Form.useFormContext();
  const { openDrawer } = useDrawerContext();

  const dimensions = useViewport();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'commentList',
      component: <CommentList />,
      width: Math.min(500, dimensions?.width),
    });
  }, [dimensions?.width, openDrawer]);

  const companySetup = useCompanySetup();

  React.useEffect(() => {
    if (
      companySetup.data?.driverCommentMaxCharacters &&
      values?.comments?.driver?.length > companySetup.data?.driverCommentMaxCharacters
    ) {
      setFieldValue('comments', {
        ...values?.comments,
        driver: values?.comments?.driver?.slice(0, companySetup.data?.driverCommentMaxCharacters),
      });
    }
  }, [companySetup.data?.driverCommentMaxCharacters, setFieldValue, values?.comments, values?.comments?.driver]);

  return (
    <Styled className={cn('comments')}>
      <div className="driver-comment-section col">
        <DriverComment rows={3} validationText={false} maxLength={companySetup.data?.driverCommentMaxCharacters} />
        <div className="comment-footer">
          <Button
            type="primary"
            onClick={handleClick}
            stringId="list"
            data-testid="list-comments-button"
            size="small"
          />
          <Counter
            maxLength={companySetup.data?.driverCommentMaxCharacters}
            text={values?.comments?.driver}
            className="comment-counter"
            data-testid="driver-comment-counter"
          />
        </div>
      </div>
    </Styled>
  );
};

export default Comments;
