import { AppHeader, NewSidebar } from 'connex-cds';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const HeaderAndSidebar = () => {
  return (
    <NewSidebar>
      <AppHeader />
      <Outlet />
    </NewSidebar>
  );
};
