import { set } from 'lodash';
import React, { useContext } from 'react';

const TemplateContext = React.createContext();

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error(`useTemplateContext cannot be rendered outside of the TemplateContext context provider`);
  }
  return context;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'change': {
      const { template, language, value } = action.payload;
      const newState = { ...state };
      set(newState, [template, language], value);
      return newState;
    }
    default:
      return state;
  }
};

const TemplateContextProvider = ({ initialValues, children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialValues);

  return <TemplateContext.Provider value={{ state, dispatch }}>{children}</TemplateContext.Provider>;
};

export default TemplateContextProvider;
