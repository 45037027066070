import { Responsive, Core } from 'connex-cds';
import { DotWorkflowDesktop } from './DotWorkflowDesktop';
import { DotWorkflowMobile } from './DotWorkflowMobile';
import { useParams } from 'react-router';
import { mobileTicket } from '../../../../../../../api';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTicketContext } from '../../../TicketContext';

export const useGetCocSetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'coc-setup'],
    queryFn: () => mobileTicket.getCocSetup(),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data,
  };
};

export const DotWorkflow = () => {
  const cocSetup = useGetCocSetup();
  const { ticket, order } = useTicketContext();

  const cocData = React.useMemo(() => {
    if (!cocSetup?.isLoading) {
      return cocSetup?.data;
    }

    return null;
  }, [cocSetup?.isLoading, cocSetup?.data]);

  if (cocSetup?.isLoading) return <Core.Spinner spin={cocSetup?.isLoading} />;

  return (
    <Responsive>
      <DotWorkflowMobile data={cocData} ticket={ticket} order={order} />
      <DotWorkflowDesktop data={cocData} ticket={ticket} order={order} />
    </Responsive>
  );
};
