import { get } from 'lodash';
import constants from '../constants';

export class Path {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  // It's assumed this will always be last in the chain.

  handle(params) {
    const { token, context } = params;

    return get(context, token, constants.EMPTY);
  }
}
