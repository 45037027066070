import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { carriers } from '../api';

export const useListCarriers = () => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['carriers', entityRef],
    queryFn: () => carriers.listCarriers({ entityRef }),
    staleTime: Infinity,
  });

  return query;
};
