import cn from 'classnames';
import { Core, Localization } from 'connex-cds';
import { fromPairs, sortBy, toPairs } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEntities } from '../../../MasterDataProvider';
import { TemplateTextCustomValues } from './TemplateTextCustomValues';
import { useTemplateContext } from './context/Provider';

const { Tabs } = Core;

const Styled = styled.div`
  display: flex;
  flex: 1;
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 5px 0px;
  }
  .templates {
    display: flex;
    flex-direction: column;
    .template-section {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    textarea {
      height: 100px;
      width: 100%;
    }
  }
`;

export const TemplateTabCustomValues = ({ templateName }) => {
  const { supportedLanguages } = Localization.useLocalizationContext();
  const translateMessage = Localization.useTranslateMessage();
  const { entityRef } = useParams();
  const { dispatch } = useTemplateContext();
  const entitiesSetup = useEntities();

  const entities = React.useMemo(() => {
    if (!entitiesSetup?.data) {
      return [];
    }

    return entitiesSetup?.data?.filter(entity => entity?.entityRef !== entityRef);
  }, [entitiesSetup?.data]);

  const tabsConfig = React.useMemo(() => {
    const translated = supportedLanguages.reduce((acc, lang) => {
      acc[lang] = translateMessage(`languages.${lang}`);
      return acc;
    }, {});

    const sorted = fromPairs(sortBy(toPairs(translated), 1));
    const sortedKeys = Object.keys(sorted || []);

    return {
      tabs: sortedKeys.reduce((acc, lang) => {
        const handleChange = e => {
          dispatch({ type: 'change', payload: { template: templateName, language: lang, value: e.target.value } });
        };

        acc.push({
          stringId: `languages.${lang}`,
          testId: `${lang}Label'`,
          component: (
            <TemplateTextCustomValues
              onChange={handleChange}
              templateName={templateName}
              language={lang}
              entities={entities}
            />
          ),
        });
        return acc;
      }, []),
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };
  }, [supportedLanguages, templateName, entitiesSetup?.data]);

  return (
    <Styled className={cn('template-tab')}>
      <Tabs config={tabsConfig} tabPosition="left" />
    </Styled>
  );
};
