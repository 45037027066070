import { Localization } from 'connex-cds';
import { find, get, keys, pickBy } from 'lodash';
import React from 'react';

export const usePreparedContext = (props = {}) => {
  const { ticket, mobileTicket } = props;

  const translateMessage = Localization.useTranslateMessage();
  const { formatLongDateTime } = Localization.useFormat();

  const primaryLineItem = React.useMemo(() => {
    return find(ticket?.lineItems, { isPrimary: true });
  }, [ticket?.lineItems]);

  const splitLoadLineItems = React.useMemo(() => {
    return pickBy(ticket?.lineItems, { isSplitLoad: true });
  }, [ticket?.lineItems]);

  const lineItemValue = (itemObject, itemField, subField) => {
    const getLineItemValue = lineItem => {
      const item = lineItem?.[itemObject];
      const field = item?.[itemField];
      if (subField) return field?.[subField];
      if (itemObject.toLowerCase().includes('quantity') && itemField === 'value') {
        return translateMessage(item?.uomCode, { value: field });
      }
      return field;
    };

    if (Object.keys(splitLoadLineItems).length > 0) {
      return Object.values(splitLoadLineItems).map(getLineItemValue).filter(Boolean).join(', ');
    }

    return getLineItemValue(primaryLineItem);
  };

  const splitContainers = React.useMemo(() => ticket?.containers ?? {}, [ticket?.containers]);

  const getContainerValue = (firstPath, secondPath, thirdPath) => {
    const getLineItemValue = containers => {
      const item = containers?.[firstPath];
      const field = item?.[secondPath];
      if (thirdPath) return field?.[thirdPath];
      const isQuantity = [
        firstPath.toLowerCase().includes('quantity'),
        firstPath.toLowerCase().includes('weight'),
      ].some(Boolean);
      if (isQuantity && secondPath === 'value') {
        return translateMessage(item?.uomCode, { value: field });
      }
      return field;
    };

    if (Object.keys(splitContainers).length > 0) {
      return Object.values(splitContainers).map(getLineItemValue).filter(Boolean).join(', ');
    }

    const splitLoadContainer = get(splitContainers, '[0]', {});
    return getLineItemValue(splitLoadContainer);
  };

  const splitCustomDataValue = (path, itinerant) => {
    const value = get(ticket, path);
    const elements = keys(value)
      .filter(key => key.includes(itinerant))
      .map(key => get(value, key));

    return elements?.join(', ');
  };

  const getTicketEvents = isFormatted => {
    // Build an object that contains the formatted ticket event time strings.
    // An asterisk indicates an eventDateTime that has been edited by the driver.
    const merged = { ...ticket?.ticketEvents, ...mobileTicket?.ticketEvents };
    return Object.keys(merged).reduce(
      (acc, key) => {
        const ticketDt = ticket?.ticketEvents?.[key]?.eventDateTime;
        const mobileTicketDt = merged?.[key]?.eventDateTime;
        if (mobileTicketDt)
          acc[key] = `${isFormatted ? formatLongDateTime(mobileTicketDt) : mobileTicketDt}${
            ticketDt !== mobileTicketDt ? '*' : ''
          }`;
        return acc;
      },
      { PRINTED: '', TO_JOB: '', ARRIVE_JOB: '', UNLOADING: '', END_UNLOADING: '', LEAVE_JOB: '', IN_YARD: '' }
    );
  };

  const ticketEvents = React.useMemo(() => getTicketEvents(false), [mobileTicket?.ticketEvents, ticket?.ticketEvents]);
  const ticketEventsFormatted = React.useMemo(
    () => getTicketEvents(true),
    [formatLongDateTime, mobileTicket?.ticketEvents, ticket?.ticketEvents]
  );

  // preparedContext must be flat.
  const preparedContext = React.useMemo(() => {
    const context = {
      printed: ticketEvents?.PRINTED,
      loadingStarted: ticketEvents?.LOADING_STARTED,
      loadingComplete: ticketEvents?.LOADING_COMPLETE,
      toJob: ticketEvents?.TO_JOB,
      arriveJob: ticketEvents?.ARRIVE_JOB,
      unloading: ticketEvents?.UNLOADING,
      endUnloading: ticketEvents?.END_UNLOADING,
      leaveJob: ticketEvents?.LEAVE_JOB,
      inYard: ticketEvents?.IN_YARD,
      pumpingStarted: ticketEvents?.PUMPING_STARTED,
      pumpingComplete: ticketEvents?.PUMPING_COMPLETE,
      readyToPump: ticketEvents?.READY_TO_PUMP,

      'dateTime::printed': ticketEventsFormatted?.PRINTED,
      'dateTime::loadingStarted': ticketEventsFormatted?.LOADING_STARTED,
      'dateTime::loadingComplete': ticketEventsFormatted?.LOADING_COMPLETE,
      'dateTime::toJob': ticketEventsFormatted?.TO_JOB,
      'dateTime::arriveJob': ticketEventsFormatted?.ARRIVE_JOB,
      'dateTime::unloading': ticketEventsFormatted?.UNLOADING,
      'dateTime::endUnloading': ticketEventsFormatted?.END_UNLOADING,
      'dateTime::leaveJob': ticketEventsFormatted?.LEAVE_JOB,
      'dateTime::inYard': ticketEventsFormatted?.IN_YARD,
      'dateTime::pumpingStarted': ticketEventsFormatted?.PUMPING_STARTED,
      'dateTime::pumpingComplete': ticketEventsFormatted?.PUMPING_COMPLETE,
      'dateTime::readyToPump': ticketEventsFormatted?.READY_TO_PUMP,

      loadedQuantity: lineItemValue('quantity', 'value'),
      orderedQuantity: lineItemValue('orderedQuantity', 'value'),
      ticketedQuantity: lineItemValue('ticketedQuantity', 'value'),
      productId: lineItemValue('item', 'id'),
      productDescription: lineItemValue('item', 'description'),
      'origin.description': ticket?.origin?.description,
      'customerParty.id': ticket?.customerParty?.id,
      sapDeliveryNumber: ticket?.id,
      salesOrderNumber: ticket?.originatorRecordId,
      wcContactDateTime: ticket?.customData?.productDetails?.wcContactDateTime,
      'dateTime::wcContactDateTime': formatLongDateTime(ticket?.customData?.productDetails?.wcContactDateTime),
      purchaseOrder: ticket?.purchaseOrder,
      customerPoNumber: ticket?.customData?.orderDetails?.customerPoNumber,
      orderPlacedBy: ticket?.customData?.orderDetails?.orderPlaceBy,
      orderPlacedByPhone: ticket?.customData?.orderDetails?.orderPlaceByPhone,
      vehicleId: ticket?.sk?.vehicleId,
      totalLoadedQuantity: translateMessage(ticket?.dispatchOrder?.totalTicketed?.uomCode, {
        value: ticket?.dispatchOrder?.totalTicketed?.value,
      }),
      loadSpacing: ticket?.dispatchOrder?.deliverySchedule?.deliverySpacing?.value,
      deliveryFlow: ticket?.customData?.orderDetails?.deliveryFlow,
      unloadingMethod: ticket?.unloadingMethod?.description,
      unloadingTime: ticket?.customData?.orderDetails?.unloadingTime,
      matSalesText: splitCustomDataValue('customData.productDetails', 'matSalesText'),
      matSalesText1: ticket?.customData?.productDetails?.matSalesText1,
      matSalesText2: ticket?.customData?.productDetails?.matSalesText2,
      matSalesText3: ticket?.customData?.productDetails?.matSalesText3,
      strengthClass: ticket?.customData?.productDetails?.strengthClass,
      environmentExposure: ticket?.customData?.productDetails?.environmentExposure,
      metExposureClass: ticket?.customData?.productDetails?.metExposureClass,
      dmax: ticket?.customData?.productDetails?.dmax,
      standardCert: ticket?.customData?.productDetails?.certificationText,
      specification: ticket?.customData?.productDetails?.specification,
      workabilityTarget: ticket?.customData?.productDetails?.customerSlumpTarget,
      stoneQuality: ticket?.customData?.productDetails?.stoneQuality,
      sandQuality: ticket?.customData?.productDetails?.sandQuality,
      targetWc: ticket?.customData?.productDetails?.targetWcRatioRecipe,
      controlClass: ticket?.customData?.productDetails?.controlClass,
      chlorideClass: ticket?.customData?.productDetails?.chlorideClass,
      strengthClass1: ticket?.customData?.productDetails?.strengthClass1,
      strengthClass2: ticket?.customData?.productDetails?.strengthClass2,
      additionType: ticket?.customData?.productDetails?.additionType,
      admixtureType: ticket?.customData?.productDetails?.admixtureType,
      orderedTemp: ticket?.customData?.productDetails?.orderedTemperature,
      fiber1Type: ticket?.customData?.productDetails?.fiber1Type,
      fiber2Type: ticket?.customData?.productDetails?.fiber2Type,
      calculatedMaturityTime: ticket?.customData?.productDetails?.maturityColdHot,
      mixIdentity: lineItemValue('item', 'mix', 'id'),
      grade: ticket?.customData?.productDetails?.grade,
      mixType: lineItemValue('item', 'mix', 'type'),
      maxAggSize: ticket?.customData?.productDetails?.maxAggSize,
      maxAggType: ticket?.customData?.productDetails?.maxAggType,
      cementType: lineItemValue('production', 'cementType'),
      admixtures: ticket?.customData?.productDetails?.admixtures,
      addmixDescription: ticket?.customData?.productDetails?.addmixDescription,
      slump: lineItemValue('properties', 'slump'),
      minCementContent: lineItemValue('production', 'minCementContent'),
      maxWatCemRatio: lineItemValue('specifiedProperties', 'maxWatCemRatio'),
      maxWaterToAdd: lineItemValue('production', 'maxWaterToAdd', 'value'),
      mortarMinWorkingLife: lineItemValue('production', 'mortarMinWorkingLife'),
      code: ticket?.customData?.productDetails?.code,
      dcClass: ticket?.customData?.productDetails?.dcClass,
      chloride: ticket?.customData?.productDetails?.chloride,
      signedByHanson: ticket?.customData?.productDetails?.signedByHanson,
      warmStamp: ticket?.customData?.productDetails?.warmStamp,
      coldStamp: ticket?.customData?.productDetails?.coldStamp,
      containsSikatard: ticket?.customData?.productDetails?.containsSikatard,
      containsDelvo: ticket?.customData?.productDetails?.containsDelvo,
      steNoDescription: ticket?.customData?.productDetails?.steNoDescription,
      soldToNumber: ticket?.customData?.orderDetails?.soldToNumber,
      consistencyClass: ticket?.customData?.productDetails?.consistencyClass,
      vctekvMax: ticket?.customData?.productDetails?.vctekvMax,
      minAirContent: ticket?.customData?.productDetails?.minAirContent,
      plantCode: ticket?.origin?.id,
      shipToCode: ticket?.destination?.id,
      customerShipToPoNumber: ticket?.customData?.orderDetails?.customerShipToPoNumber,
      tareWeight: getContainerValue('tareWeight', 'value'),
      grossWeight: getContainerValue('grossWeight', 'value'),
      referenceNumber: splitCustomDataValue('customData', 'referenceNumber'),
    };

    // Ensure everything is defined.
    return Object.keys(context).reduce((acc, key) => {
      acc[key] = context[key] || '';
      return acc;
    }, {});
  }, [ticketEvents, translateMessage, primaryLineItem, ticket, formatLongDateTime]);

  return preparedContext;
};
