import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { find } from 'lodash';
import { FormsAndWaiversEditor } from '../custom-editors/forms-and-waivers-editor/FormsAndWaiversEditor';
import { buildLanguageFields, labelStringIdBullder } from '../languagesNames';
const { FIELD_TYPES } = Form;

export const FILTER_STRATEGY = {
  INCLUDES: 'includes',
  STARTS_WITH: 'startsWith',
  EQUALS: 'equals',
};

const fields = (countries, setupVehicleTypes, companySetup) => {
  return {
    icon: <i className="icon fa-thin fa-file-pen" />,
    validationSchema: Yup.object().shape({
      vehicleTypes: Yup.string().required('required'),
      type: Yup.string().required('required'),
      content: Yup.string().required('required'),
      status: Yup.string().required('required'),
    }),
    customEditor: FormsAndWaiversEditor,
    fields: [
      {
        path: 'vehicleTypes',
        labelStringId: 'vehicleType',
        testId: 'vehicleTypes',
        dataType: FIELD_TYPES.string,
        listTypeId: 'vehicle-type',
        listValues: [],
        listValuePath: 'id',
        listDisplayPath: 'description',
        filterFn: (item, filterStrings) => {
          return item?.includes?.(filterStrings[0]);
        },
        filterComponent: ({ innerRef, ...props }) => {
          return (
            <select ref={innerRef} {...props}>
              <option value="" />
              {setupVehicleTypes?.map?.(vehicleType => (
                <option value={vehicleType.id} key={vehicleType.id}>
                  {vehicleType.description}
                </option>
              ))}
            </select>
          );
        },
        formatter: props => (
          <div data-testclass={'cell-column-vehicleTypes'} data-testid={`row-${props.row.crn}-column-vehicleTypes`}>
            {find(setupVehicleTypes, { id: props.row.vehicleTypes })?.description}
          </div>
        ),
      },
      {
        path: 'type',
        labelStringId: 'type',
        testId: 'type',
        dataType: FIELD_TYPES.string,
        listValues: [],
        listValuePath: 'id',
        listDisplayPath: 'label',
      },
      {
        path: 'content',
        labelStringId: 'content',
        testId: 'content',
        dataType: FIELD_TYPES.string,
        multiline: true,
      },
      {
        path: 'countries',
        labelStringId: 'countries',
        testId: 'countries',
        dataType: FIELD_TYPES.array,
        arrayOf: FIELD_TYPES.string,
        listValues: [],
        listValuePath: 'id',
        listDisplayPath: 'label',
        filterFn: (item, filterStrings) => {
          return item?.includes?.(filterStrings[0]);
        },
        filterComponent: ({ innerRef, ...props }) => {
          return (
            <select ref={innerRef} {...props}>
              <option value="" />
              {countries?.map?.(country => (
                <option value={country.id} key={country.id}>
                  {country.label}
                </option>
              ))}
            </select>
          );
        },
        formatter: props => (
          <div data-testclass={'cell-column-countries'} data-testid={`row-${props.row.crn}-column-countries`}>
            {props.row.countries?.join?.(', ')}
          </div>
        ),
      },
      {
        path: 'status',
        labelStringId: 'status',
        testId: 'status',
        dataType: FIELD_TYPES.string,
        listValues: [
          { id: 'ACTIVE', label: 'Active' },
          { id: 'INACTIVE', label: 'Inactive' },
        ],
        defaultValue: 'ACTIVE',
        filterStrategy: FILTER_STRATEGY.EQUALS,
        filterComponent: ({ innerRef, ...props }) => {
          return (
            <select ref={innerRef} {...props}>
              <option value="" />
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          );
        },
      },
      ...buildLanguageFields({ companySetup, prefix: 'Content', multiline: true }),
    ],
  };
};

export default fields;
