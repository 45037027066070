import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const { ShortDateTime, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled(Layout.Container)``;

const UnloadingPointMobile = ({ className, deliveryAddress, siteContact, ticket }) => {
  return (
    <Styled className={cn('unloading-point', className)}>
      <Subtitle>
        <Translate stringId="unloadingPoint" data-testid="unloadingPointLabel" />
      </Subtitle>
      <div className="label">
        <Translate stringId="siteAddress" data-testid="siteAddressLabel" />:{' '}
      </div>
      <span data-testid="deliveryAddressValue">{deliveryAddress}</span>
      <div className="label">
        <Translate stringId="customer" data-testid="customerLabel" />:{' '}
      </div>
      <span data-testid="customerName">
        {ticket?.customerParty?.id}/{ticket?.customerParty?.name}
      </span>
      <div className="label">
        <Translate stringId="siteContactName" data-testid="siteContactNameLabel" />:{' '}
      </div>
      <span data-testid="siteContactName">{siteContact.name}</span>
      <div className="label">
        <Translate stringId="siteContactPhoneNumber" data-testid="customerPhoneNumberLabel" />:{' '}
      </div>
      <span data-testid="customerPhoneNumber">
        {' '}
        <a href={`tel:${siteContact.phone}`}>{siteContact.phone}</a>{' '}
      </span>
      {ticket?.customData?.ticketType !== 'PMP' && (
        <div>
          <div className="label">
            <Translate stringId="unloadingScheduleStart" data-testid="unloadingScheduleStartLabel" />
            {': '}
          </div>
          <span style={{ whiteSpace: 'nowrap' }} data-testid="unloadingScheduleStart">
            <ShortDateTime date={ticket?.customData?.schedule?.deliveryTimeFrom} />
          </span>
        </div>
      )}
      <div className="label">
        <Translate stringId="agreedUnloading" data-testid="agreedUnloadingLabel" />
        {': '}
      </div>
      <span style={{ whiteSpace: 'nowrap' }} data-testid="agreedUnloading">
        <ShortDateTime date={ticket?.customData?.schedule?.agreeWindowFrom} />
        {' - '}
      </span>
      <span>
        <ShortDateTime date={ticket?.customData?.schedule?.agreeWindowTo} />
      </span>
    </Styled>
  );
};

export default UnloadingPointMobile;
