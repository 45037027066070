import { Core, Form, Drawer, User } from 'connex-cds';
import React from 'react';
import { users, roles, carriers } from '../../../../../query-hooks';
import { InviteUser } from './invite-user/InviteUser';
import { UserEditor } from './UserEditor';

import _config from './config';
import { getUserRbac } from '../../../../../query-hooks/users';

const { DETAIL_TYPES, ListAndDetail } = Form;

const filterCarriers = (userCarriers, haulerCarriers) => {
  if (!userCarriers) {
    return {
      isSameCarrier: true,
      carriers: [],
    };
  }
  let newCarriersList = userCarriers,
    isSameCarrier = true;
  for (let carrier of userCarriers) {
    if (!haulerCarriers?.find?.(carr => carr === carrier)) {
      newCarriersList = newCarriersList.filter(item => item !== carrier);
      isSameCarrier = false;
    }
  }
  return {
    isSameCarrier,
    carriers: newCarriersList,
  };
};

export const View = () => {
  const { openDrawer } = Drawer.useDrawerContext();

  const listUsers = users.useListUsers({ isHauler: true });
  const listRoles = roles.useListRoles();
  const listCarriers = carriers.useListCarriers();
  const { user } = User.useUserContext();
  const haulerRbac = getUserRbac(user?.profileRef);

  const filterUserList = React.useMemo(
    () =>
      listUsers.data
        ?.map(user => {
          const haulerCarriers = haulerRbac.data?.carriers?.map(carrier => carrier.carrierRef);
          const { isSameCarrier, carriers } = filterCarriers(user.carriers, haulerCarriers);
          if (!user.carriers || user.carriers?.length === 0 || (user.carriers?.length === 1 && !isSameCarrier)) {
            return;
          }
          if (user.carriers?.length === 1 && isSameCarrier) {
            return user;
          }
          return {
            ...user,
            carriers,
          };
        })
        .filter(user => user),
    [listUsers, haulerRbac, filterCarriers]
  );

  const listUserFilter = React.useMemo(() => {
    return {
      ...listUsers,
      data: filterUserList,
    };
  }, [listUsers, filterUserList]);

  const customActions = React.useMemo(() => {
    const onClick = () => {
      openDrawer?.({
        titleStringId: `inviteUser`,
        component: <InviteUser refetch={listUsers.refetch} />,
      });
    };
    return [{ stringId: 'inviteUser', testId: 'invite-user', onClick: onClick }];
  }, [openDrawer]);

  const haulerCarriersList = React.useMemo(() => {
    return haulerRbac?.data?.carriers?.map(carr => ({ ...carr, crn: carr.carrierRef }));
  }, [haulerRbac?.data?.carriers]);

  const config = React.useMemo(() => {
    return _config(listRoles?.data, haulerCarriersList);
  }, [listCarriers, listRoles]);

  return (
    <Core.Spinner spin={listUsers.isLoading || listUsers.isFetching}>
      <ListAndDetail
        detailType={DETAIL_TYPES.DRAWER}
        Editor={UserEditor}
        showHeader={false}
        config={config}
        listQuery={listUserFilter}
        customActions={customActions}
      />
    </Core.Spinner>
  );
};
