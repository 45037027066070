import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { Layout, Responsive } from 'connex-cds';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../../../../MasterDataProvider';
import { useTicketContext } from '../../../../TicketContext';
import { ActivityMobile } from './mobile/ActivityMobile';
import { ActivityDesktop } from './ActivityDesktop';
import style, { hideableRow } from './style';

const Styled = styled(Layout.Column)`
  ${style}
`;

const Row = styled(Layout.Row)`
  ${hideableRow}
`;

export const Activity = ({ lineItems, mobileLayout }) => {
  const { ticket } = useTicketContext();

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const includeProductsServices = React.useMemo(() => {
    if (!companySetup?.data?.isMaterialServiceInWorkflow) {
      return false;
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialServiceInWorkflow !== false &&
        vehicleTypeData?.isMaterialServiceInWorkflow !== true
      ) {
        return false;
      }
    }
    return true;
  }, [companySetup?.data?.isMaterialServiceInWorkflow, vehicleTypeData]);

  const showActivity = React.useMemo(() => {
    const companyWorkflows = [
      companySetup?.data?.isMaterialServiceInWorkflow,
      companySetup?.data?.isReturnedMaterialInWorkflow,
      companySetup?.data?.isWaterInWorkflow,
    ];

    if (companyWorkflows.every(value => !value)) {
      return false;
    }

    if (!vehicleTypeData) {
      return true;
    }

    return [
      vehicleTypeData?.isMaterialServiceInWorkflow,
      vehicleTypeData?.isWaterInWorkflow,
      vehicleTypeData?.isReturnedMaterialInWorkflow,
    ].some(value => value);
  }, [companySetup, vehicleTypeData]);

  const content = (
    <Styled className="activity" hasContent={showActivity}>
      <Responsive>
        <ActivityMobile includeProductsServices={includeProductsServices} lineItems={lineItems} />
        <ActivityDesktop includeProductsServices={includeProductsServices} lineItems={lineItems} />
      </Responsive>
    </Styled>
  );

  return mobileLayout ? (
    content
  ) : (
    <Row flex={1} hasContent={showActivity}>
      {content}
    </Row>
  );
};
