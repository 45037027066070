import { useCallback } from 'react';

import { usePostMessageListener } from './util/entity-provider/usePostMessageListener';
import { useWhatFix } from './hooks/useWhatFix';

export const WhatFixIdentify = () => {
  const { turnOnWhatFix, turnOffWhatFix } = useWhatFix();

  const handleMessageEvent = useCallback(
    message => {
      if (message.type === 'allow-whatfix') {
        message.payload ? turnOnWhatFix() : turnOffWhatFix();
      }
    },
    [turnOnWhatFix, turnOffWhatFix]
  );

  usePostMessageListener(handleMessageEvent);
};
