import React from 'react';

export const useDoOnce = fn => {
  const done = React.useRef(false);

  React.useEffect(() => {
    if (!done.current) {
      fn?.();
      done.current = true;
    }
  }, [fn]);
};
