import { API, usePostMessageContext } from 'connex-cds';
import React from 'react';
import packageJson from '../package.json';
import { Logger } from './util/log/logger';

const clientVersion = packageJson.version;

export const withVersionHandler = Component => {
  return () => {
    const { sendMessage } = usePostMessageContext();

    React.useEffect(() => {
      API.setVersionHandler({
        clientVersion,
        callback: version => {
          sendMessage(JSON.stringify({ type: 'refresh', version }));
          Logger.debug(`Refresh: ${clientVersion} -> ${version} : ${window?.location?.href}`);
        },
      });
    }, [sendMessage]);

    return <Component />;
  };
};
