import { css } from 'styled-components';

export default css`
  padding: 0 10px;
  gap: 0;

  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .status-timeline-row {
    & > .container {
      padding-top: 10px;
    }
    .accordion-card {
      i.icon {
        font-weight: 400;
        font-size: 16px;
      }
    }
    .body-section.open {
      @media (max-width: 950px) {
        max-height: inherit;
      }
    }
    @media (min-width: 951px) {
      flex: 6;
    }
  }

  .table-section {
    margin: 0 10px;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
  }
`;
