import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
import { CarriersEditor } from '../custom-editors/carriers-editor/CarriersEditor';

const { FIELD_TYPES } = Form;

const fields = {
  icon: <i className="icon fa-thin fa-people-carry-box" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  customEditor: CarriersEditor,
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'companyFleet',
      labelStringId: 'companyFleet',
      testId: 'companyFleet',
      dataType: FIELD_TYPES.boolean,
      defaultValue: false,
      hidden: true,
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};

export default fields;
