import { Form, Responsive } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';
import React from 'react';

import { TotalPumpedQuantityMobile } from './TotalPumpedQuantityMobile';
import { TotalPumpedQuantityDesktop } from './TotalPumpedQuantityDesktop';
import { find } from 'lodash';

export const TotalPumpedQuantity = () => {
  const { ticket } = useTicketContext();

  const { values, setFieldValue } = Form.useFormContext();

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const mixInfo = React.useMemo(() => {
    return {
      mix: `${primaryLineItem?.item?.id} / ${primaryLineItem?.item?.description}`,
    };
  }, [primaryLineItem]);

  React.useEffect(() => {
    if (!values?.totalPumpedQuantity && primaryLineItem?.quantity) {
      setFieldValue('totalPumpedQuantity', {
        value: primaryLineItem?.quantity?.value,
        uom: primaryLineItem?.quantity?.uomCode,
      });
    }
  }, [
    primaryLineItem?.quantity,
    primaryLineItem?.quantity?.uomCode,
    primaryLineItem?.quantity?.value,
    setFieldValue,
    values?.totalPumpedQuantity,
  ]);

  return (
    <Responsive>
      <TotalPumpedQuantityMobile mixInfo={mixInfo} primaryLineItem={primaryLineItem} />
      <TotalPumpedQuantityDesktop mixInfo={mixInfo} primaryLineItem={primaryLineItem} />
    </Responsive>
  );
};
