import constants from '../constants';

export class ReferenceNumber {
  constructor() {
    this.next = null;
  }

  setNext(handler) {
    this.next = handler;
    return handler;
  }

  handle(params) {
    const { token } = params;

    if (token.includes(`${constants.SPLIT_LOAD_REFERENCE_NUMBER}`)) {
      const regex = new RegExp(constants.SPLIT_LOAD_REFERENCE_NUMBER, 'g');
      const newToken = token.replace(regex, 'customData.referenceNumber1');

      return this?.next?.handle?.({ ...params, token: newToken });
    }

    return this?.next?.handle?.(params);
  }
}
