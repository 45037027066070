import React from 'react';
import { Localization, Responsive, useQrCode } from 'connex-cds';
import { useParams } from 'react-router-dom';
import { useCompanySetup } from '../MasterDataProvider';
import { Alert } from 'antd';
import QrWrapper from './style';

const { useTranslateMessage } = Localization;

export const BuildQrCode = ({ summaryRef }) => {
  const companySetup = useCompanySetup();
  const { entityRef } = useParams();
  const translateMessage = useTranslateMessage();

  const { origin } = window?.location;

  const isLinkBased = React.useMemo(() => {
    if (!companySetup?.data?.qrMethod) {
      return true;
    }

    return companySetup?.data?.qrMethod === 'linkBased';
  }, [companySetup?.data?.qrMethod]);

  const stringLength = React.useMemo(() => String(summaryRef)?.length, [summaryRef]);

  const qrCodeData = React.useMemo(() => {
    if (summaryRef && isLinkBased) {
      const data = btoa(`${entityRef}::${summaryRef}`);

      return `${origin}/summary/${data}`;
    }

    if (summaryRef && !isLinkBased) {
      return summaryRef;
    }

    return '';
  }, [entityRef, isLinkBased, summaryRef]);

  const qrCodeLarge = useQrCode({ data: qrCodeData });
  const qrCodeSmall = useQrCode({ data: qrCodeData, size: 'xsmall' });

  const qrWrapper = React.useCallback(
    qr => {
      if (isLinkBased) {
        return qr;
      }

      if (!isLinkBased) {
        return (
          <QrWrapper>
            {stringLength > 1024 && (
              <Alert
                message={translateMessage('QrMethod_contentBasedWarning', { amount: stringLength })}
                type="warning"
              />
            )}
            <div>{qr}</div>
          </QrWrapper>
        );
      }
    },
    [isLinkBased]
  );

  return (
    <Responsive>
      {qrWrapper(qrCodeSmall)}
      {qrWrapper(qrCodeLarge)}
    </Responsive>
  );
};
