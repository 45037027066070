import { Core, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useTicketContext } from '../../TicketContext';

const Styled = styled.div`
  .actions {
    display: flex;
    margin-top: 20px;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
`;

export const UnFinalize = ({ onOkay, onCancel }) => {
  const [password, setPassword] = React.useState('');
  const inputRef = React.useRef();

  const handleClick = React.useCallback(() => {
    onOkay(password);
  }, [onOkay, password]);

  React.useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus?.();
    }, 100);
  }, []);

  return (
    <Styled className={cn('un-finalize')}>
      <div className="label">
        <div data-testid="un-finalize-password-label">
          <Localization.Translate stringId="password" data-testid="password" />:
        </div>
        <div data-testid="un-finalize-password">
          <Core.Input value={password} onChange={setPassword} ref={inputRef} />
        </div>
      </div>
      <div className="actions">
        <div>
          <Core.Button stringId="cancel" data-testid="cancel-button" onClick={onCancel} />
        </div>
        <div>
          <Core.Button
            type="primary"
            stringId="submit"
            data-testid="submit-button"
            onClick={handleClick}
            disabled={!password}
          />
        </div>
      </div>
    </Styled>
  );
};
