import { css } from 'styled-components';

export default css`
  gap: 0;
  margin: 10px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .subcategory-section {
    flex: 1;
  }

  .buttons {
    & > * {
      &:first-child {
        margin-right: 10px;
      }
    }
    &.true {
      .trueButton {
        background-color: var(--color-background-chip-success);
        color: var(--color-text-chip-success);
      }
    }
  }
`;
